import React, { useRef } from 'react';
import styles from './index.module.css';
import { RowWrapper } from '../RowWrapper';
interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
}

export const PasswordInput: React.FC<Props> = ({
    value,
    onChange,
    placeholder
}) => {

    
    return (

        <RowWrapper>
        <input
            className={styles.input}
            type="password"
            maxLength={18}
            value={value}
            onChange={e => onChange(e.target.value)}
            autoComplete="off"
            placeholder={placeholder}
        />
    </RowWrapper>
    )
}