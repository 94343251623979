import { Lang } from './Lang';
export const vi: Lang = {

    auth: {
        signIn: 'đăng nhập',
        signUp: 'đã đăng ký',
        touristSignIn: 'Đăng nhập của khách',
        logOut: 'Đăng xuất', 
        gotoSignIn: 'Đăng nhập',
        gotoSignUp: 'Đi đăng ký',
        country: 'Quốc gia',
        smsCodeSending: 'gửi',
        messages: {
            checkPasswordEqual: 'Mật khẩu đã nhập hai lần là khác nhau, vui lòng đặt lại',
            mobileIsExists: 'Số điện thoại đã được đăng ký'
        },
        placeholder: {
            mobile: 'Vui lòng điền số điện thoại của bạn',
            password: 'Vui lòng điền mật khẩu',
            setPassword: 'Vui lòng đặt mật khẩu',
            setConfirmPassword: 'vui lòng nhập lại mật khẩu',
            grahicInput: 'không phân biệt chữ hoa chữ thường',
            getSmsCode: 'lấy mã xác minh',
            smsCodeInput: 'Mã xác minh SMS',

        }
    },

    form: {

        actions: {
            delete: 'xóa bỏ',
            add: 'Thêm vào',
            new: 'Thêm vào',
            detail: 'Chi tiết',
            edit: 'biên tập',
            save: 'tiết kiệm',
            submit: 'Gửi đi',
            cancel: 'hủy bỏ',
            change: 'sửa đổi',
            clear: 'Trống',
            upload: 'Tải lên',
            update: 'Cập nhật',
            addLocalization: 'Ngôn ngữ mới',
            search: 'tìm kiếm',
            confirm: 'xác nhận',
            back:"trở về",
            confirmAndNext:"Xác nhận, bước tiếp theo"
        },
        

        rules: {
            maxLength: 'Độ dài tối đa là ${{length}}',
            ltSize: 'Kích thước hình ảnh không thể vượt quá${{size}}',
            noLocalization: 'Thông tin bằng ít nhất một ngôn ngữ là bắt buộc, vui lòng thêm',
            makeSure: 'Lỗi mẫu, vui lòng kiểm tra'
        },
        messages: {
            success: '${{action}} sự thành công',
            fail: '${{action}} sự thất bại',
        },
        alert: {
            isSure: 'Bạn có chắc không？'
        }
    },
    home: {
        title: 'Tương Tường',
        orderNow: 'Đặt hàng ngay',
        newOrder: 'đơn hàng mới',
        recorder: 'Đơn hàng quá khứ',
        changeLanguage: 'Ngôn ngữ',
        menu:'thực đơn',
        homePage:'Trang Chủ',
        messages: {
            switchStoreWillClearCart: 'Chuyển đổi cửa hàng sẽ xóa giỏ hàng'
        }
    },
    orderType: {
        0: 'Lấy đi',
        1: 'Bữa ăn',
        2: 'Contactless Pick-Up',
    },
    productType: {
        0: 'Bữa ăn bình thường',
        1: 'Bữa ăn năm ngày',
        fiveDayDescription: 'Bữa ăn năm ngày (yêu cầu đặt trước một ngày, cắt giảm đơn hàng lúc 5 giờ chiều. Các bữa ăn có thể được nhận hoặc giao vào ngày hôm sau. Sau 5 giờ chiều, các bữa ăn có thể được nhận hoặc giao vào ngày hôm sau)',
        alert: {
            crossToFiveDayMeal: 'Bạn đã chọn một món ăn không phải từ "bữa ăn năm ngày". Nếu bạn chuyển sang bữa ăn năm ngày, giỏ hàng sẽ bị xóa.',
            crossFromFiveDayMeal: 'Bạn đã chọn các món ăn từ "bữa ăn năm ngày". Nếu bạn chuyển sang một bữa ăn không năm ngày, giỏ hàng sẽ bị xóa.',
        }
    },
    orderTime: {
        asap: {
            delivery: 'Giao ngay',
            pickUp: 'Đưa tôi ngay',
            laterly:"Một lát sau"
        },
        later: 'thời gian lựa chọn',
        businessHour: 'Giờ kinh doanh',
        error: {
            selectTime: 'Vui lòng chọn giờ ăn',
            nowOutOfHour: 'Đã hết giờ làm việc, chỉ chấp nhận đặt phòng',
            selectedTimeOutOfHour: 'Thời gian đã chọn hết giờ làm việc',
            selectedTimeExpire: 'Thời gian đã chọn đã hết hạn',
            nowNearClose: 'Giờ làm việc sắp bị vượt quá, vui lòng đặt hàng hoặc đặt chỗ càng sớm càng tốt'
        }
    },
    ourMenu: {
        title: 'Khuyến nghị nóng',
        category: 'Danh mục món ăn'
    },
    menuInCategory: {
        searchPlaceholder: 'Tìm kiếm món ăn',
        addToOrder: 'Thêm đơn hàng',
    },

    foodDetail: {
        ingredients: 'chính',
        customize: 'Hương vị tùy chỉnh',
        addToOrder: 'Thêm đơn hàng',
        update: 'Cập nhật',
        next: 'Bước tiếp theo',
        addToCart:'thêm vào giỏ hàng'
    },
    taste: {
        child: {
            radio: {
                0: "Không",
                1: "rất ít",
                2: "ít hơn",
                3: "Bình thường",
                4: "Khác",
                5: "Siêu nhiều"
            }
        }
    },
    cart: {
        header: {
            title: 'giỏ hàng',
            keepOrdering: 'Tiếp tục đặt hàng'
        },
        next: 'Giải quyết',
        detail:'Chi tiết giỏ hàng'
    },
    statements: {
        header: {
            title: 'đơn đặt hàng của bạn'
        },
        error: {
            enterMobile: 'Vui lòng nhập số điện thoại di động dành riêng'
        },
        next: 'Gửi đơn đặt hàng',
        mobile: 'số điện thoại',
        placeholder: {
            mobile: "Vui lòng nhập số điện thoại di động dành riêng",
            remark: "Vui lòng nhập nhận xét",
        },
        messages: {
            submitTo: 'Đơn hàng hiện tại sẽ được gửi tới ${{name}}'
        },
        addRemark: 'Thêm ghi chú',
        remark: 'Nhận xét',
        summary: {
            title: 'tóm lược',
            subtotal: 'Tổng phụ',
            discount: 'Giảm giá',
            taxes: 'Thuế',
            total: 'toàn bộ',
            addPromoCode: 'Thêm mã khuyến mãi',
            postage: 'Vận chuyển hàng hóa'
        }
    },
    order: {
        shortStatus: {
            all: 'All',
            5: 'Awaiting',
            10: 'Unpaid',
            11: 'Paid',
            12 :'Paying' ,
            20: 'Preparing',
            21: 'Ready',
            22: 'Moved to Front Desk',
            30: 'Completed',
            98: 'Invalid',
        },
        status: {
            5: 'Đặt hàng',
            10: 'Được trả tiền',
            11: 'Trả tiền',
            12: 'thanh toán đang diễn ra',
            20: 'Đang chuẩn bị',
            21: 'Sẵn sàng hoàn thành',
            22: 'Moved to Front Desk',
            30: 'Đã hoàn thành',
            98: 'Vô hiệu hóa',
        },
        actions: {
            cancel: 'hủy đơn hàng',
            delete: 'Xóa đơn hàng',
            pay: 'thanh toán',
            confirm: 'Xác nhận',
            offlinePay: 'Chuyển sang thanh toán ngoại tuyến',
            onlinePay: 'Trả',
        },
        payMethod: {
            1: 'Thanh toán trực tuyến',
            2: 'Thanh toán ngoại tuyến'
        },
        total: 'toàn bộ',
        listEmpty: 'Bạn không có đơn hàng liên quan',
        info: 'thông tin đặt hàng',
        id: 'Số thứ tự',
        storeName: 'Tên cửa hàng',

        createTime: 'Thời gian sáng tạo',
        // 支付时间
        payTime: 'Thời gian thanh toán',
        // 准备完成时间
        prepareCompleteTime: 'Thời gian chuẩn bị',
        // 完成时间
        completeTime: 'Hoàn thành thời gian',
        // 失效时间
        invalidTime: 'Thời gian hết hạn',

        payClosedTime: 'Thời gian đóng cửa giao dịch',
        totalPrice:'Tổng giá',
        orderStatus:"Tình trạng đặt hàng",
        takeCode: 'Take Code',
        amount:"Định lượng",
        name:"Đặt hàng",
        noYet:"Không",
        mealTime: {
            rapidPickUp: 'Thời gian bữa ăn dự kiến',
            deliveryStart: 'Thời gian giao hàng dự kiến',
            deliveryEnd: 'Thời gian giao hàng dự kiến'
        },
        error: {
            can_not_deliver: {
                lt_price: 'Giá khởi điểm cho giao hàng của chúng tôi là $${{value}}(Sau khi chơi)'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: 'Có những món ăn với hoạt động tăng đột biến theo thứ tự của bạn: ${{nameText}}, Đơn hàng này cần chọn ngay hôm nay [tự chọn] [giao bữa ăn], vui lòng thay đổi ngày và thử lại.'
                }
            }
        },
        pay: {
            title: 'Trả',
            credit:{
                name:'thẻ tín dụng',
                total:'toàn bộ',
                item:' ',
                more:'Cho xem nhiều hơn',
            },
            error: {
                download_app_to_pay: 'Tải xuống App thanh toán trực tuyến',
                download_app_to_pay_or_offline: 'Vui lòng tải xuống App thực hiện thanh toán trực tuyến hoặc nhấp vào nút "chuyển sang thanh toán ngoại tuyến" ',
                store_doesnt_support_online_payment: 'Cửa hàng hiện tại không hỗ trợ thanh toán trực tuyến',
                doesnt_support_offline_payment: 'The current eat method does not support offline payment.',
                enter_right_cardholder_detail: 'Vui lòng điền chính xác thông tin chủ thẻ',
                coupon_not_found: 'Mã phiếu giảm giá không tồn tại',
                coupon_cannot_use: 'Mã phiếu giảm giá không thể được sử dụng',
                coupon_num_is_zero: 'Không có đủ mã số phiếu giảm giá có sẵn',
                coupon_not_use_condition: 'Mã phiếu giảm giá không đáp ứng các điều kiện sử dụng',
                order_payment_in_progress:'Thanh toán đơn đặt hàng',
                order_does_not_exist:'Order does not exist',
                order_has_been_paid:'Order has been paid',
            },
            cardHolder: {
                title: 'Thông tin chủ thẻ',
                name: 'tên chủ thẻ',
                phone: 'điện thoại',
                email: 'hộp thư',
                zipcode: 'Mã bưu điện',
            },
            cardDetails: {
                title: 'Thông tin thẻ ngân hàng',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: 'tiết kiệm',
            add: 'Thêm vào',
        },
        shippingInfo: 'Thông tin liên lạc',
        enterShippingInfo: 'Vui lòng điền thông tin liên lạc',
        name: 'Tên',
        phone: 'điện thoại',
        address: 'Thông tin nhận xét (tùy chọn)',
        fillInAddress:'Vui lòng điền địa chỉ',
        selectAddress:"Chọn địa chỉ giao hàng",
        coordinateDesc: 'Địa chỉ',
        makeFormRight: 'Vui lòng xác nhận thông tin liên lạc là chính xác',
        empty: 'Không có thông tin liên lạc thường được sử dụng, xin vui lòng thêm',
        pleaseSelect: 'Không có thông tin liên lạc, xin vui lòng chọn',
        pleaseEnter: 'vui lòng nhập',
        shippingMethod:"phương thức vận chuyển",
        selectShippingMethod:"Vui lòng chọn phương thức giao hàng",
        selectShippingMethod1:"Chào mừng, xin vui lòng chọn cách đặt hàng",
        store:'Cửa hàng',
        selectStore:'Vui lòng chọn một cửa hàng',
        contactName:"Tên Liên lạc",
        contactPhone:"số liên lạc",
        contactInfo:"Vui lòng điền thông tin liên lạc",
        eatType:"Phương pháp ăn uống",
        eatTime:"Giờ ăn"
    },
    history:{
        historyOrders:"Trật tự lịch sử"
    },
    me:{
        name:"cá nhân"
    },
    address:{
        addressManagement:"Quản lý địa chỉ",
        add:"Thêm địa chỉ",
        management:"sự quản lý",
        setAsDefault:"đặt làm mặc định",
        default: "mặc định",
        addressDelivery:"Địa chỉ giao hàng",
        fullAddress:"Địa chỉ",
        noAddress:"Xin lỗi, không có địa chỉ",
        selectDeliveryAddress:"Vui lòng chọn một địa chỉ giao hàng",
        messages: {
            cannotChangeWhenUsing: 'Địa chỉ này đang được sử dụng và không thể sửa đổi'
        }
    },

    dayInWeek: {
        0: 'Chủ nhật',
        1: 'Thứ Hai',
        2: 'Thứ ba',
        3: 'Thứ tư',
        4: 'Thứ năm',
        5: 'Thứ Sáu',
        6: 'Thứ bảy',
    },
    searchProduct:{
        search:"Bắt đầu tìm kiếm",
        noResult:"Không có kết quả tìm kiếm, hãy thử một từ khác ~"
    },
    activity: {
        name: 'Chương trình khuyến mãi',
        time: {
            start: 'Thời gian bắt đầu',
            end: 'Thời gian kết thúc',
            full: 'Mọi hoạt động đều hiệu quả.'
        },
        placeholder: {
            chooseTimeToView: 'Chọn giao bữa ăn hoặc thời gian chọn bữa ăn, kiểm tra giá chiết khấu tương ứng'
        },

        description: 'Mô tả sự kiện',
        activityStock: 'Hàng tồn kho',
        singlePurchaseLimit: 'Giới hạn mua hàng đơn'
    },

    activityProduct: {
        label: {
            event_starts_later: 'Sự kiện chưa bắt đầu',
            event_is_over: 'Sự kiện đã kết thúc',
            purchase_limit_reached: 'Đã vượt quá giới hạn mua hàng cá nhân',
            not_enough_items_to_sell: 'Hàng tồn kho không đủ của hàng hoạt động còn lại',
        },
        error: {
            purchase_limit_reached: 'Đã vượt quá giới hạn mua tối đa',
            not_enough_items: 'Cổ phiếu không đủ',
            unable_to_add: 'Không thể tiếp tục thêm',
            event_is_over_please_check: 'Một số hoạt động tăng đột biến đã kết thúc, vui lòng kiểm tra danh sách giỏ hàng ',
            purchase_limit_reached_please_check: '${{name}} Đã vượt quá giới hạn mua tối đa, vui lòng kiểm tra danh sách giỏ hàng',
            not_enough_items_please_check: '${{name}} Số lượng đã vượt quá hàng tồn kho sự kiện hiện có, vui lòng kiểm tra danh sách giỏ hàng',
        }
    },
    coupon: {
        promoCode: {
            name: 'Mã khuyến mại',
            error: {
                coupon_not_found: 'Mã phiếu giảm giá không tồn tại',
                coupon_cannot_use: 'Mã phiếu giảm giá không thể được sử dụng',
                coupon_num_is_zero: 'Không có đủ mã số phiếu giảm giá có sẵn',
                coupon_not_use_condition: 'Mã phiếu giảm giá không đáp ứng các điều kiện sử dụng'
            },
            placeholder: {
                chooseTimeToView: 'Chọn giao bữa ăn hoặc thời gian chọn bữa ăn, kiểm tra giá chiết khấu tương ứng'
            },
            
        }
    }
};