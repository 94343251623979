import {} from 'react'
import { useI18n } from 'context/i18n';
import { useStore } from 'modules/store';
import { selectors } from '../store';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Product } from '../models';

export const useProductDetail = (productCode: string) => {
    const { lang } = useI18n();
    const detail = useSelector<RootState, Product>(
        state => selectors.getProductOfCode(state, { language: lang, code: productCode })
    );

    return { detail };

}