import React, { useMemo } from 'react';
import styles from './index.module.css';
import { NormalActivityVO, DiscountVO } from '../../models';
import { DiscountItem } from '../DiscountItem';
import dayjs from 'dayjs';
import { useI18n } from 'context/i18n';

interface Props {
    className?: string;
    activity: NormalActivityVO;
    currentDiscount?: DiscountVO;
}

const formatTime = (ts: string | number): string => dayjs(Number(ts)).format('MM-DD-YYYY HH:mm');

export const ActivityItem: React.FC<Props> = ({
    className = '',
    activity,
    currentDiscount
}) => {
    const { t } = useI18n();

    const activityTime = useMemo(() => {
        const { startTime, endTime } = activity;
        if (startTime && endTime) {
            return `${formatTime(startTime)} -- ${formatTime(endTime)}`;
        }
        if (startTime) {
            return `${t('activity.time.start')}: ${formatTime(startTime)}`;
        }
        if (endTime) {
            return `${t('activity.time.end')}: ${formatTime(endTime)}`;
        }
        return '';
    }, [activity]);

    const currentClassName = useMemo(() => {
        return currentDiscount?.activityCode === activity.code ? styles.current : '';
    }, [activity, currentDiscount]);

    return (
        <div className={`${styles.activityItem} ${className}`}>

            <div className={`${styles.name} ${currentClassName}`}>{activity.name}</div>

            <div className={`${styles.activityTime} ${currentClassName}`}>{activityTime}</div>

            <div className={styles.discountList}>
                {activity.discounts.map(discount => (
                    <DiscountItem currentDiscount={currentDiscount} key={discount.id} discount={discount}/>
                ))}
            </div>

        </div>
    )
}
