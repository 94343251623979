import { useState, useCallback } from 'react';

interface State<T> {
    loading: boolean;
    value?: T;
    error?: string;
}


type SetState<T> = (p: Partial<State<T>>) => void

export const useRequestState = <T>(initialState: Partial<State<T>> = {}): [State<T>, SetState<T>] => {
    const [ state, set ] = useState<State<T>>({
        loading: false,
        value: undefined,
        ...initialState,
    });

    const setState = useCallback((p: Partial<State<T>>) => {
        set(s => ({...s, ...p}));
    }, [set])

    return [state, setState];
}
