import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { ReceiverInfo } from '../../models';
import { useI18n } from 'context/i18n';
import { useForm, Controller } from 'react-hook-form';
import { message } from 'components/Message';
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { AddressGeoForm, GeoFormProps } from '../AddressGeoForm';


export type ReceiverInfoFormProps = ReceiverInfo & { geo: GeoFormProps };

interface Props {
    value?: ReceiverInfoFormProps;
}
export interface RefProps {
    submit: (fn: (formData: ReceiverInfoFormProps) => void) => void;
}
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

export const AddressForm = forwardRef<RefProps, Props>(({
    value,
}, ref) => {
    const { t } = useI18n();
    const { control, handleSubmit:submitForm, errors, triggerValidation, setValue} = useForm<ReceiverInfoFormProps>({
        mode: 'onBlur',
        defaultValues: value,
    });
    useEffect(() => {
        if (value) {
            (Object.keys(value) as Array<keyof typeof value>).forEach(name => setValue(name, value[name]))
        }
    }, [value]);
    useImperativeHandle(ref, () => ({
        submit: (fn) => {
            triggerValidation().then(noError => {
                if (noError) {
                    submitForm(data => {
                        if (!data.geo.coordinateDesc) {
                            message.error(t('receiverInfo.selectAddress'));
                            return;
                        }
                        fn(data);
                    })();
                } else {
                    message.error(t('form.rules.makeSure'));
                }
            })
        }
    }))


    return (
        <>
            <div style={{padding: '0 16px'}}>
                <Form.Item {...formItemLayout} label={t('address.addressDelivery')}>
                    <Controller
                        as={<AddressGeoForm />}
                        name="geo"
                        control={control}
                    />
                </Form.Item>
            </div>
            <div style={{padding: '0 16px'}}>
                <Form.Item {...formItemLayout} label={t('address.fullAddress')}>
                    <Controller
                        as={<Input.TextArea autoSize={{ minRows: 3 }} />}
                        name="detailAddress"
                        control={control}
                    />
                </Form.Item>
            </div>

            <div style={{padding: '0 16px'}}>
                <Form.Item {...formItemLayout} label={t('receiverInfo.contactName')}>
                    <Controller
                        as={<Input />}
                        name={'receiverName'}
                        control={control}
                        rules={{ required: t('form.rules.required') }}
                    />
                </Form.Item>
            </div>

            <div style={{padding: '0 16px'}}>
                <Form.Item {...formItemLayout} label={t('receiverInfo.contactPhone')}>
                    <Controller
                        as={<Input />}
                        name={'receiverMobile'}
                        control={control}
                        rules={{ required: t('form.rules.required') }}
                    />
                </Form.Item>
            </div>
            
        </>
    )
})