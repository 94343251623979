import { useLocation } from "react-router-dom";
import * as country from './country';
import { DELIVERY_TYPE } from '../modules/order-info/models';
export { country };


const _toString = (arg: any) => Object.prototype.toString.call(arg);
export const isObject = (obj: any) => _toString(obj) === '[object Object]';
export const isArray = (arg: any) => _toString(arg) === '[object Array]'
const isNil = (arg: any) => arg === undefined || arg === null;
const queryStringParse = (str: string): { [key: string]: string } => {
    return str.replace(/^\?/, '').split('&').reduce((acc, section) => {
        const [key, value] = section.split('=');
        return isNil(value)
            ? acc
            : Object.assign({}, acc, { [key]: value })
    }, {})
}

const queryStringStringify = (query: {[key: string]: any}) => {
    if (!isObject(query)) {
        throw new Error('query string . stringify need an object type argument');
    } else {
        return Object.keys(query)
            .filter(key => !isNil(query[key]))
            .map(key => `${key}=${query[key]}`)
            .join('&')
    }
}

export const queryString = {
    parse: queryStringParse,
    stringify: queryStringStringify
}

export const useLocationSearch = () => {
    const { search } = useLocation();
    return queryString.parse(search);
}

export const telNumberMaptoString = (tel?: string) => {
    if (!tel || tel.length !== 10) return '';
    return `(${tel.slice(0, 3)})${tel.slice(3,6)}-${tel.slice(6)}`;
}

export const telStringMaptoNumber = (tel?: string): string => {
    if (!tel) return '';
    return tel.replace(/\(|\)|-/g, '');
}

// 计算价格
export function getPrice(price:number | string, count: number) {
    return `$${((Number(price) * 100 * count)/100).toFixed(2)}`;
}




export function getTotalPrice(products: Array<{
    price: number | string;
    count: number;
}>) {
    const total100 =  products.reduce<number>((acc, cur) => {
        return acc + (Number(cur.price) * 100 * cur.count);
    }, 0);
    return total100 / 100;
}


export const getStorageMobile = () => window.localStorage.getItem('MOBILE');
export const setStorageMobile = (mobile: string) => {
    window.localStorage.setItem('MOBILE', mobile);
}

export const isPickUpOrder = (deliveryType: DELIVERY_TYPE) => {
    return (deliveryType === DELIVERY_TYPE.RAPID_PICK_UP) || (deliveryType === DELIVERY_TYPE.LOCKER_PICK_UP);
};