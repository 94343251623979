import React, { useCallback, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ActivityProductDetailModal, ActivityProductDetailProps } from '../components/ActivityProductDetailModal';
import { useAccessAndOrderInfo } from 'context/accessAndOrderInfo';
import { actions as buyActions } from 'modules/buy/store';
import { message } from 'components/Message';
import { useDispatch } from 'react-redux';
import { ActivityProductVO } from '../models';
import { useI18n } from 'context/i18n';

type ActivityProductDetail = ActivityProductDetailProps['activityProductDetail'];
interface ProviderProps {
    children: React.ReactNode;
}
interface ContextValue{
    addToCart: ActivityProductDetailProps['onAddToCart'];
    showProductDetail: (product: ActivityProductVO) => void;
    editProductDetail: (productDetail: ActivityProductDetail) => void;
}

const contextValue: ContextValue = {
    addToCart: () => {},
    showProductDetail: () => {},
    editProductDetail: () => {},
}

export const productToCartContext = React.createContext(contextValue);

export const ActivityProductToCartProvider: React.FC<ProviderProps> = ({
    children,
}) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [detailModalVisable, setDetailModalVisable] = useState<boolean>(false);
    const [detailProduct, setDetailProduct] = useState<ActivityProductDetail>();

    const { pathname } = useLocation();
    const { hasOrderInfo, editOrderInfo } = useAccessAndOrderInfo();


     const handleAddToCart: ActivityProductDetailProps['onAddToCart'] = useCallback(({ activityProduct, count, customizableTasteSetting }) => {
        if (!hasOrderInfo()) {
            setDetailModalVisable(false);
            editOrderInfo(pathname);
        } else {
            dispatch(buyActions.addActivityProductToCart({
                activityProductId: activityProduct.id,
                count,
                customizableTasteSetting,
            }));
            message.success(t('form.messages.success', { action: t('form.actions.add') }));
            setDetailModalVisable(false);
        }
    }, [hasOrderInfo, pathname, dispatch, t]);



    const handleChangeProductInCart: ActivityProductDetailProps['onChangeProductInCart'] = useCallback(({id, count, customizableTasteSetting}) => {
        dispatch(buyActions.updateActivityProductInCart({
            cartId: id,
            count,
            customizableTasteSetting,
        }))
        message.success(t('form.messages.success', { action: t('form.actions.change') }));
        setDetailModalVisable(false);
    }, [dispatch, t]);

    const showProductDetail = useCallback((activityProduct: ActivityProductVO) => {
        setDetailProduct({ activityCode: activityProduct.activityCode, activityProductId: activityProduct.id });
        setDetailModalVisable(true);
    }, []);

    const editProductDetail = useCallback((activityProductDetail: ActivityProductDetail) => {
        setDetailProduct(activityProductDetail);
        setDetailModalVisable(true);
    }, []);

    return (
        <productToCartContext.Provider value={{
            addToCart: handleAddToCart,
            showProductDetail,
            editProductDetail,
        }}>
            {children}
            <ActivityProductDetailModal
                activityProductDetail={detailProduct!}
                visable={detailModalVisable}
                onAddToCart={handleAddToCart}
                onChangeProductInCart={handleChangeProductInCart}
                onCancel={() => setDetailModalVisable(false)}
            />
        </productToCartContext.Provider>
    )
}
export const useActivityProductToCart = () => {
    return useContext(productToCartContext);
}