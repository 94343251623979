import React, { useState, useRef, useLayoutEffect, useMemo } from 'react';

import styles from './index.module.css';
import { Counter } from 'components/Counter';
import { Language, useI18n } from 'context/i18n';
import { SUB_TASTE_RADIO } from 'modules/product-menu';
import { Input } from 'components/Input';
import { getPrice } from 'globalUtils';
import { useDiscountName } from 'modules/product-menu';
import { ActivityProductInCart } from 'modules/buy/models';
// 获取自定义口味的文案
function getCustomText(
    productInCart: ActivityProductInCart,
    t: (s: string) => string,
    lang: Language
): string {
    const { customizableTasteSetting, activityProduct: { product: { customizableTasteList } } } = productInCart;

    // 中文的拼中文逗号
    const joinText = /ch/.test(lang) ? '，' : ',';

    return customizableTasteList.reduce<string[]>((acc, taste) => {
        const selected = customizableTasteSetting[taste.code];
        if (selected === SUB_TASTE_RADIO.NORMAL) return acc;

        const middleText = /ch/.test(lang)  ? '' : ' ';

        return [...acc, `${t(`taste.child.radio.${selected}`)}${middleText}${taste.name}`];

    }, []).join(joinText);
}

interface Props {
    activityProductInCart: ActivityProductInCart;
    onCountChange: (productInCart: ActivityProductInCart, count: number) => void;
    onDelete: (productInCart: ActivityProductInCart) => void;
    onItemClick: (productInCart: ActivityProductInCart) => void;
    onChangeClick: (productInCart: ActivityProductInCart) => void;
    onChangeRemark: (productInCart: ActivityProductInCart, remark: string) => void;
}

export const ActivityProductInCartItem: React.FC<Props> = ({
    activityProductInCart,
    onCountChange,
    onDelete,
    onItemClick,
    onChangeClick,
    onChangeRemark
}) => {
    
    const remarkInputRef = useRef<HTMLTextAreaElement>(null);

    const { t, getLang } = useI18n();
    // 数量加
    const onInc = () => onCountChange(activityProductInCart, activityProductInCart.count + 1);

    // 数量减
    const onDec = () => {
        activityProductInCart.count > 1 && onCountChange(activityProductInCart, activityProductInCart.count - 1);
    }
    const { activityProduct, count, remark } = activityProductInCart;

    const { product } = activityProduct;

    const [ remarkEdit, setRemarkEdit ] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (remarkEdit && remarkInputRef.current) {
            remarkInputRef.current.focus();
        }
    }, [remarkInputRef, remarkEdit])

    const priceTextNode = useMemo(() => {
        const originPrice = getPrice(product.price, activityProductInCart.count);
        
        const salePrice = getPrice(activityProduct.salePrice, activityProductInCart.count);
        return (
            <>
                <div aria-label="Price" className={styles.salePrice}>{salePrice}</div>
                <div className={styles.unitPrice}>{originPrice}</div>
                <div className="clear"></div>
            </>
        )
    }, [activityProduct, activityProductInCart]);

    return (
        <div className={styles.productInCartItem}>
            <div className={styles.productInCartInfo}>
                <div className={styles.left} onClick={() => onItemClick(activityProductInCart)}>
                    <img className={styles.img} src={product.images[0].url} alt=""/>
                </div>
                <div className={styles.middle}>
                    <div className={styles.name}>{product.name}</div>
                    <div className={styles.price}>{priceTextNode}</div>
                    <div className={styles.custom}>{getCustomText(activityProductInCart, t, getLang())}</div>
                </div>
                <div className={styles.right}>
                    <Counter
                        className={styles.counter}
                        count={count}
                        onDec={onDec}
                        onInc={onInc}
                    />
                    <div className={styles.actions}>
                        { product.customizableTasteList.length > 0 && <div className={styles.actionButton} onClick={() => onChangeClick(activityProductInCart)}>{t('foodDetail.customize')}</div> }
                        <div className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => onDelete(activityProductInCart)}>{t('form.actions.delete')}</div>
                    </div>
                    { !remarkEdit && !remark && <div style={{textAlign: 'right', marginTop: '10px'}} className={styles.actionButton} onClick={() => setRemarkEdit(true)}>{t('statements.addRemark')}</div> }
                </div>
            </div>
            <div className={styles.discountList}>
                <div>{`${t('activity.name')}: ${activityProductInCart.activityProduct.activity.name}`}</div>
            </div>
            
            {activityProductInCart.error &&
                <div className={styles.error}>{t(activityProductInCart.error)}</div>
            }
            { (remark || remarkEdit) &&
                <div style={{paddingTop: '10px'}}>
                    <div style={{ float: 'left', width: '60px', paddingTop: '4px' }}>{t('statements.remark')}</div>
                    <Input.TextArea
                        placeholder={t('statements.placeholder.remark')}
                        style={{ float: 'left', width: 'calc(100% - 60px)' }}
                        rows={3}
                        ref={remarkInputRef as any}
                        onBlur={() => setRemarkEdit(false)}
                        onFocus={() => setRemarkEdit(true)}
                        value={remark}
                        onChange={e => onChangeRemark(activityProductInCart, e.target.value)}
                    />
                    <div className="clear"></div>
                </div>
            }
        </div>
    )
}