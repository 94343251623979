import React, { createRef, useMemo } from 'react';
import styles from './index.module.css';
import { Product, CustomizableTasteSetting, ActivityProductVO, ACTIVITY_PRODUCT_STATUS } from '../../models';
import { CustomizableSetting, RefProps } from '../CustomizableSetting';
import { Counter, useCounter } from 'components/Counter';
import { useActivityProductInCart } from 'modules/buy';
import { CommonButton } from 'components/CommonButton';
import { useI18n } from 'context/i18n';
import { ProductImageWithTag } from '../ProductImageWithTag';
import { useActivityProductDetail } from '../../facades/useActivityProductDetail';
import { useActivityTime } from 'hooks/useActivityTime';
import { useTheme } from 'modules/store';

export interface Props {
    activityProductDetail: {
        activityCode: string;
        activityProductId: string,
        id?: string,
        count?: number,
        customizableTasteSetting?: CustomizableTasteSetting
    }
    onAddToCart: (result: {
        activityProduct: ActivityProductVO,
        count: number,
        customizableTasteSetting: CustomizableTasteSetting
    }) => void,
    onChangeProductInCart: (result: {
        id: string,
        count: number,
        customizableTasteSetting: CustomizableTasteSetting
    }) => void,
}

export const ActivityProductDetail: React.FC<Props> = ({
    activityProductDetail,
    onAddToCart,
    onChangeProductInCart
}) => {
    const { theme: { primary } } = useTheme();
    const { count: activityProductInCartCount } = useActivityProductInCart(activityProductDetail.activityProductId);
    const { count, onInc, onDec } = useCounter(activityProductDetail.count || 1);
    const formRef = createRef<RefProps>();
    const { detail: activityProduct } = useActivityProductDetail(activityProductDetail.activityCode, activityProductDetail.activityProductId);
    const { activityTime } = useActivityTime(activityProduct.activity);

    const { t } = useI18n();
    const isEdit = useMemo(() => {
        return !!activityProductDetail.id;
    }, [activityProductDetail]);

    const handleSubmitButton = () => {
        const params = {
            activityProduct,
            count,
            customizableTasteSetting: formRef.current!.getCustomizableTasteSetting()
        };
        isEdit
            ? onChangeProductInCart({...params, id: activityProductDetail.id!})
            : onAddToCart(params);
    }

    const maxBuyNum = useMemo(() => {
        if (!activityProduct) return 0;
        return activityProduct.maxBuyNum - activityProduct.buyNum - activityProductInCartCount;
    }, [activityProduct, activityProductInCartCount]);

    const normalSubmitButtonLabel = useMemo(() => {
        return isEdit ? t('form.actions.confirm'): t('foodDetail.addToCart');
    }, [isEdit]);

    const submitButtonLabel = useMemo(() => {
        if (!activityProduct || !activityProduct.activity) return `${normalSubmitButtonLabel}`;
        if (activityProduct.activityStatus === ACTIVITY_PRODUCT_STATUS.PEDDING) {
            return t('activityProduct.label.event_starts_later');
        }
        if (activityProduct.activityStatus === ACTIVITY_PRODUCT_STATUS.COMPLETE) {
            return t('activityProduct.label.event_is_over');
        }
        if (activityProduct.buyNum >= activityProduct.maxBuyNum) {
            return t('activityProduct.label.purchase_limit_reached');
        }
        if (count > maxBuyNum) {
            return t('activityProduct.label.purchase_limit_reached');
        }
        if (count > activityProduct.inventory) {
            return t('activityProduct.label.not_enough_items_to_sell');
        }
        return normalSubmitButtonLabel;
    }, [activityProduct, count, maxBuyNum, normalSubmitButtonLabel]);
    const buttonDisabled = useMemo(() => {
        if (!activityProduct) return false;
        return [ ACTIVITY_PRODUCT_STATUS.PEDDING, ACTIVITY_PRODUCT_STATUS.COMPLETE].includes(activityProduct.activityStatus)
            || activityProduct.buyNum >= activityProduct.maxBuyNum
            || count > maxBuyNum
            || count > activityProduct.inventory;
    }, [activityProduct, count, maxBuyNum]);

    const salePrice = useMemo(() => {
        if (!activityProduct) return '';
        return activityProduct.salePrice
    }, [activityProduct]);
    const originPrice = useMemo(() => {
        if (!activityProduct) return '';
        return activityProduct.product.price;
    }, [activityProduct]);

    return (
        <div className={styles.productDetail}>
            <div className={styles.left}>
                <ProductImageWithTag
                    className={styles.image}
                    product={activityProduct.product}
                    tagCount={0}
                />
                <div className={`${styles.blank} ${styles.activityInformation}`}>
                    <div className={styles.activityTitle}>{t('activity.description')}</div>
                    <div className={styles.activityName}>{activityProduct.activity.name}</div>
                    {activityTime && <div className={styles.activityTime}>{activityTime}</div>}

                    <div className={styles.activityRow}>
                        <div className={styles.activityLabel}>{t('activity.activityStock')}:</div>
                        <div className={styles.activityValue}>{`${activityProduct.inventory}/${activityProduct.totalInventory}`}</div>
                    </div>
                    <div className={styles.activityRow}>
                        <div className={styles.activityLabel}>{t('activity.singlePurchaseLimit')}:</div>
                        <div className={styles.activityValue}>{activityProduct.maxBuyNum}</div>
                    </div>
                </div>
                <div className={styles.price}>
                    <div aria-label="Price" className={styles.salePrice}>${salePrice}</div>
                    {originPrice && <div className={styles.unitPrice}>${originPrice}</div>}
                    <div className="clear"></div>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.name}>{activityProduct.product.name}</div>
                <div className={styles.description}>{activityProduct.product.description}</div>
                
                {activityProduct.product.rawMaterials && <div className={styles.description}>{activityProduct.product.rawMaterials}</div>}
                
                <CustomizableSetting
                    taste={activityProduct.product.customizableTasteList}
                    tasteSelected={activityProductDetail.customizableTasteSetting}
                    ref={formRef}
                />
                <div className={styles.blank}></div>

                <div className={styles.buttons}>
                    <Counter className={styles.counter} count={count} onInc={onInc} onDec={onDec} />
                    <CommonButton
                        className={styles.addToCartButton}
                        style={{ background: primary.bg, color: primary.color }}
                        onClick={handleSubmitButton}
                        count={activityProductInCartCount}
                        disabled={buttonDisabled}
                    >{submitButtonLabel}</CommonButton>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}