import React, { useCallback, CSSProperties } from 'react';
import styles from './index.module.css';
import { Taste, SUB_TASTE_RADIO } from '../../models';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';
interface Props {
    taste: Taste;
    selected: SUB_TASTE_RADIO;
    onSelected: (tasteCode: Taste['code'], tasteOptionId: SUB_TASTE_RADIO) => void;
}

export const CustomizableSettingItem: React.FC<Props> = ({
    taste,
    onSelected,
    selected
}) => {
    const { theme: { primary, secondary } } = useTheme();
    const { t } = useI18n();
    const renderOption = useCallback((option: SUB_TASTE_RADIO) => {
        const isSelected = selected === option;
        const optionStyle: CSSProperties = isSelected ? { borderColor: primary.bg, color: primary.bg } : { borderColor: secondary.bg, color: '#333' };
        return (
            <div className={styles.option} style={optionStyle} key={option} onClick={() => onSelected(taste.code, option)}>
                {t(`taste.child.radio.${option}`)}
            </div>
        )
    }, [selected, taste, onSelected]);

    return (
        <div className={styles.container}>
            <div role="label" aria-label={taste.name} className={styles.label}>{taste.name}</div>
            <div className={styles.checkboxList}>
                { taste.child.options.map(renderOption) }
            </div>
            <div className="clear"></div>
        </div>
    )
}