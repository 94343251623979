import React, { useState, useEffect, useCallback } from 'react'
import styles from './index.module.css';
import { CommonButton } from 'components/CommonButton';
import { useReceiverInfoList, ReceiverInfoItem, RECEIVER_INFO_DEFAULT_STATUS, ReceiverInfo, AddressEmpty } from 'modules/me';
import { useLocation, useHistory } from 'react-router-dom';
import { DELIVERY_TYPE } from '../../models';
import { message } from 'components/Message';
import { ActionButton } from 'components/ActionButton';
import { Layout } from '../Layout';
import { Header } from '../Header';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';
interface Props {
    initialSelectedId?: any,
    onNext: (selected: any) => void
}

export const ReceiverList: React.FC<Props> = ({
    initialSelectedId,
    onNext
}) => {
    const { theme: { primary } } = useTheme();
    const { pathname } = useLocation();
    const { push } = useHistory();

    const { loading, list } = useReceiverInfoList();

    const [ selected, setSelected ] = useState<any>();

    useEffect(() => {
        const selectedItem = list.find(item => item.id === initialSelectedId);
        const defaultSelectedItem = list.find(item => item.defaultStatus === RECEIVER_INFO_DEFAULT_STATUS.IS);
        if (selectedItem) {
            setSelected(selectedItem);
        } else if (defaultSelectedItem){
            setSelected(defaultSelectedItem);
        } else if (list.length > 0){
            setSelected(list[0]);
        } else {
            setSelected(undefined);
        }
    }, [list]);

    const handleNext = useCallback(() => {
        if (!selected) return;
        onNext(selected);
    }, [selected, onNext]);

    const  { t } = useI18n();
    
    const handleEdit = useCallback((receiverInfo: ReceiverInfo) => {
        if (initialSelectedId === receiverInfo.id) {
            message.error(t('address.messages.cannotChangeWhenUsing'));
        } else {
            push(`/me/address/${receiverInfo.id}?redirect=${encodeURIComponent(`${pathname}?type=${DELIVERY_TYPE.DELIVERY}`)}`);
        }

    }, [pathname, push]);

    const handleAdd = useCallback(() => {
        push(`/me/address/create?redirect=${encodeURIComponent(`${pathname}?type=${DELIVERY_TYPE.DELIVERY}`)}`);
    }, [pathname]);

    const renderList =() => {
        const nextButtonStyle = selected ? { background: primary.bg, color: primary.color } : {background: '#f8f8f8', color: '#333'};
        return (
            <>
                <div className={styles.listWrapper}>
                    {list.map(item => (
                        <ReceiverInfoItem
                            className={styles.receiverInfoItem}
                            key={item.id}
                            receiverInfo={item}
                            selectedId={selected?.id}
                            onClick={() => setSelected(item)}
                            onEditClick={handleEdit}
                        />
                    ))}
                    <div className="clear"></div>
                </div>
                
                
                <CommonButton
                    className={`${styles.nextButton}`}
                    style={nextButtonStyle}
                    onClick={handleNext}
                    height={'54px'}
                >{t('form.actions.confirmAndNext')}</CommonButton>
            </>
        )
    }

    return (
        <Layout header={
            <Header title={t('address.selectDeliveryAddress')}>
                <ActionButton  onClick={handleAdd}>{t('address.add')}</ActionButton>
            </Header>
        }>
            {loading && <div className={styles.loading}>loading...</div>}

            {!loading && list.length === 0 &&
                <AddressEmpty onAddClick={handleAdd}/>
            }

            {!loading && list.length > 0 &&
                renderList()
            }
        </Layout>
    )
}