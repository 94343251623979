import { ReceiverInfo, PickUpReceiverInfo } from 'modules/me';

export const ASAP_TIME = '0';

// 配送方式
export enum DELIVERY_TYPE {
    // 自取
    RAPID_PICK_UP = 0,
    // 外卖
    DELIVERY = 1,
    // 无接触餐柜
    LOCKER_PICK_UP = 2,
}

export type OnFinish = (params: {
    deliveryType: DELIVERY_TYPE;
    storeId: string;
    receiverInfo?: ReceiverInfo;
    pickUpReceiverInfo?: PickUpReceiverInfo,
}) => void;
