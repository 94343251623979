import React, { CSSProperties } from 'react';
import styles from './index.module.css';
import { DiscountVO, NormalActivityVO } from '../../models';



interface Props {
    discountVO?: DiscountVO,
    activity?: NormalActivityVO,
    style?: CSSProperties,
    className?: string,
}

export const DiscountTag: React.FC<Props> = ({
    discountVO,
    activity,
    style = {},
    className = ''
}) => {
    if (activity) return <div className={`${styles.discount} ${className}`} style={style}>{activity.name}</div>
    if (discountVO) return <div className={`${styles.discount} ${className}`} style={style}>{discountVO.value}% OFF</div>
    return null;
}