import React from 'react';
import styles from './index.module.css';
import { ActivityProductInOrderItem } from '../ActivityProductInOrderItem';
import { ActivityProductInOrder } from '../../models';

interface Props {
    className?: string;
    activityProductList: ActivityProductInOrder[]
}

export const ActivityProductInOrderList: React.FC<Props> = ({
    className = '',
    activityProductList
}) => {

    return (
        <div className={`${className}`}>
            {activityProductList.map((item, index) => (
                <ActivityProductInOrderItem
                    key={index}
                    activityProductInOrder={item}
                />
            ))}
        </div>
    )
}