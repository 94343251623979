import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styles from './index.module.css';
import { OrderDetail, useOrderDetail } from 'modules/buy';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Spin } from 'components/Spin';
import { Header } from '../../components/Header';
import { ActionButton } from 'components/ActionButton';
import { useI18n } from 'context/i18n';
import { ORDER_PAY_METHOD, ORDER_PAY_STATUS, ORDER_STATUS } from 'modules/buy/models';
import { PaymentModal } from 'modules/buy/components/PaymentModal'
import { message } from 'components/Message';

export const MyOrderDetail: React.FC = () => {
    const { goBack, push } = useHistory();
    const { id } = useParams<{ id: string }>();
    const { loading, order, methods } = useOrderDetail(id!);
    const { t } = useI18n();

    useEffect(() => {
        methods.getOrderDetail()
            .catch((err) => {
                err?.data?.msg && message.error(t(err.data.msg))
            })
    }, [methods.getOrderDetail]);
    const headerContent = useMemo(() => {
        if (!order) return '#';

        return `#${order.code} ${order.storeName}`;

    }, [order]);
    const [ paymentModalVisable, setPaymentModalVisable ] = useState<boolean>(false);


    const handlePaymentCancel = () => {
        setPaymentModalVisable(false);
    }

    const handleOfflinePayment = () => {
        setPaymentModalVisable(false);
        methods.changePayMethodToOffline()
            .catch((err) => {
                err?.data?.msg && message.error(t(err.data.msg))
            })
    }
    const handlePay = useCallback(() => {
        push(`/pay/${id}?from=order`);
        return;
    }, [push])

    const actions = useMemo(() => {
        if (!order) return null;
        // 去支付 //转为闲暇支付
        if (order.payMethod === ORDER_PAY_METHOD.NULL && order.status !== ORDER_STATUS.INVALID) {
            if (order.store.onlinePayment)
            
            return order.status === ORDER_PAY_STATUS.PAYING?<ActionButton onClick={()=>{}}>{t(`order.status.${ORDER_PAY_STATUS.PAYING}`)}</ActionButton> :<ActionButton onClick={handlePay}>{t('order.actions.onlinePay')}</ActionButton>
        }
        return null;
    }, [order]);

    return (
        <div className={styles.orderDetail}>
            <Spin spinning={loading}>
                <Header title={headerContent}>
                    <ActionButton onClick={goBack}>{t('form.actions.back')}</ActionButton>
                    {actions}
                </Header>
                
                {!loading && order &&
                    <div className={styles.listWrapper}>
                        <OrderDetail order={order!}/>
                    </div>
                }
            </Spin>
            <PaymentModal
                online={order?.store.onlinePayment.enable || false}
                offline={order?.store.offlinePayment.enable || false}
                visable={paymentModalVisable}
                onCancel={handlePaymentCancel}
                offlinePayment={handleOfflinePayment}
            />
        </div>
    );
}
