import React, { useState, useMemo, useCallback, useEffect, CSSProperties } from 'react';
import styles from './index.module.css';
import icon from './images/easterly.png';
import { NotAuthorizedModal } from 'modules/auth';
import { selectors, actions } from 'modules/auth/store';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderNormalMealMenu } from './components/HeaderNormalMealMenu';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useStore, useTheme } from 'modules/store';
import { useCategoryList } from 'modules/product-menu';
import { OrderInfoHeader } from './components/OrderInfoHeader';
import { useAccessAndOrderInfo } from 'context/accessAndOrderInfo';
import { LanguagePicker } from './components/LanguagePicker';
import { Person } from './components/Person';
import { useI18n } from 'context/i18n';

enum PAGE_STATUS {
    LOADING_USER = 'loading_user', // 获取current user
    NOT_AUTHORIZED = 'not authorized', // 没登录
    AUTHORIZED = 'authorized' // 已经登录
}



const ORDER_INFO_HEADER_WHITE_LIST = [
    'normal-meal',
    'five-day-meal'
]

export const Header: React.FC = () => {
    const { theme, webImageFontLogo } = useTheme();
    // 初始化店铺列表
    // 初始化分类列表
    const { methods: { init } } = useStore();
    const { methods: categoryMethods } = useCategoryList();
    const { login } = useAccessAndOrderInfo();
    // useEffect(() => {
    //     init();
    // }, []);
    useEffect(() => {
        categoryMethods.getList();
    }, [categoryMethods.getList]);

    const { push } = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectors.getCurrentUser);
    const {t} = useI18n();

    const { url } = useRouteMatch();
    const { pathname } = useLocation();

    const checkActive = useCallback((p: string) => {
        return pathname.indexOf(`${url.replace(/\/$/, '')}/${p}`) > -1;
    }, [pathname, url]);

    const getActiveClassName = useCallback((p: string) => {
        return checkActive(p) ? styles.activeMenu : '';
    }, [checkActive]);
    const getActiveStyle = useCallback((p: string): CSSProperties => {
        return checkActive(p) ? { color: theme.primary.bg } : {};
    }, [checkActive])

    const [ pageStatus, setPageStatus ] = useState<PAGE_STATUS>();


    const showOrderInfoHeader = useMemo(() => {
        return pageStatus === PAGE_STATUS.AUTHORIZED && ORDER_INFO_HEADER_WHITE_LIST.reduce((acc, curr) => {
            return acc ? acc : pathname.split('/').indexOf(curr) > -1;
        }, false)
    }, [pathname, pageStatus]);

    const rightClassName = useMemo(() => {
        const klass = showOrderInfoHeader ? styles.smallMenu : styles.bigMenu;
        return `${styles.right} ${klass}`;
    }, [showOrderInfoHeader]);

    const handleLoginButtonClick = () => {
        if (pageStatus === PAGE_STATUS.NOT_AUTHORIZED) {
            // setAuthModalVisable(true);
            login();
            
        } else if (pageStatus === PAGE_STATUS.AUTHORIZED) {
            dispatch(actions.logout());
        }
    }

    const getCurrentUser = useCallback(() => {
        setPageStatus(PAGE_STATUS.LOADING_USER);
        (dispatch(actions.getCurrentUser()) as any as Promise<any>)
            .catch(() => setPageStatus(PAGE_STATUS.NOT_AUTHORIZED));
    }, [setPageStatus, dispatch]);
    
    const refreshJwt = useCallback(() => {
        setPageStatus(PAGE_STATUS.LOADING_USER);
        (dispatch(actions.refreshJwt()) as any as Promise<any>)
            .catch(() => setPageStatus(PAGE_STATUS.NOT_AUTHORIZED));
    }, [setPageStatus, dispatch])
    // 决定启动时的逻辑
    useEffect(() => {
        if (!currentUser && window.localStorage.getItem("access_token")) {
            getCurrentUser();
        } else if (!currentUser && window.localStorage.getItem("jwt")) {
            refreshJwt();
        } else if (currentUser) {
            setPageStatus(PAGE_STATUS.AUTHORIZED);
        } else {
            setPageStatus(PAGE_STATUS.NOT_AUTHORIZED);
        }
    }, [currentUser, getCurrentUser, setPageStatus]);


    const onMenuClick = useCallback((p: string) => {
        if (p !== pathname) {
            push(p)
        }
    }, [pathname, push]);

    return (
        <div className={styles.header}>
            <div className={styles.content}>
                <img className={styles.icon} src={webImageFontLogo} alt=""/>
                <div className={`${styles.right} ${styles.bigMenu}`}>
                    <div className={styles.menu}>
                        <div className={styles.menuWrapper}>
                            <div className={styles.menuItem} style={getActiveStyle('home')} onClick={() => onMenuClick('/home')}>{t('home.homePage')}</div>
                            <HeaderNormalMealMenu className={styles.menuItem} style={getActiveStyle('normal-meal')}/>
                            <LanguagePicker className={styles.menuItem} />
                            {pageStatus === PAGE_STATUS.NOT_AUTHORIZED &&
                                <div className={styles.menuItem} onClick={handleLoginButtonClick}>{t('auth.signIn')}/{t('auth.signUp')}</div>
                            }

                            
                            {pageStatus === PAGE_STATUS.AUTHORIZED &&
                                <Person className={styles.menuItem}  style={getActiveStyle('me')} />
                            }

                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                {showOrderInfoHeader &&
                    <OrderInfoHeader />
                }
            </div>
            
            
            
           
        </div>
    )   
}