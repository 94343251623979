import {
    SuccessResponse,
    ListResponse,
    ajaxGet
} from 'api';

import {
    Brand, BrandResponseDTO, Theme, BrandAnalyticsInfo,
    Store, StoreReference, HourAndMinute, DeliveryTimeRange,
    HourData
} from '../models';
import { DELIVERY_TYPE } from 'modules/order-info';
import { last } from 'ramda';

interface StoreListResponse {
    result: {
        list: StoreReference[]
    }
}
function formatHourAndMinute(hourAndMinute: HourAndMinute) {
    const { hour, minute } = hourAndMinute;
    const h = hour > 12 ? hour - 12 : hour;
    const m = minute > 10 ? minute : `0${minute}`;
    const suffix = hour > 12 ? 'pm' : 'am';
    return `${h}:${m}${suffix}`;
 }
 
 function formatHourData(hourData: HourData[]) {
     if (hourData.length === 0) return ['Closed'];
     return hourData.map(({start, end}) => {
         return `${formatHourAndMinute(start)}-${formatHourAndMinute(end)}`;
     })
 }
export interface GeoParams {
    latitude: string,
    longitude: string,
}
export const getStoreList = (brandCode: string, params?: GeoParams) => {
    return ajaxGet<SuccessResponse<StoreListResponse>>(
        '/repast/api/v2/store/getStoreListByBrand',
        { ...params, brandCode },
    ).then(({ data }) => {
        return data.data.result.list.map(item => {
            const formatHourAndMinute = ({hour, minute}: { hour: number | string, minute: number | string }) => {
                return { hour: Number(hour), minute: Number(minute) }
            }
            let hourDataOfWeek = (['0', '1', '2', '3', '4', '5', '6'] as ['0', '1', '2', '3', '4', '5', '6']).map(day => {
                
                return item.time[day].map(({ start, end, rapidPickUpStart }) => ({
                    start: formatHourAndMinute(start),
                    end: formatHourAndMinute(end),
                    rapidPickUpStart: formatHourAndMinute(rapidPickUpStart)
                }));
            });
            const hourDataTextOfWeek = hourDataOfWeek.map(formatHourData);
            hourDataOfWeek = hourDataOfWeek.reduce<{
                result: HourData[][],
                pre: HourData[],
            }>(({ result, pre }, curr) => {

                let currHourData = curr;
                
                const preLastRange = last(pre);

                const currLastRange = last(currHourData);
                if (currLastRange && currLastRange.end.hour < currLastRange.start.hour) {
                    currHourData = [
                        ...currHourData.slice(0, -1),
                        {
                            start: currLastRange.start,
                            end: { hour: 24, minute: 0 },
                            rapidPickUpStart: currLastRange.rapidPickUpStart
                        }
                    ];
                }

                if (preLastRange && preLastRange.end.hour < preLastRange.start.hour) {
                    // 隔天
                    const start = { hour: 0, minute: 0 };
                    const end = { ...preLastRange.end };
                    currHourData = [{
                        start,
                        end,
                        rapidPickUpStart: start,
                    }, ...currHourData];
                }

                return {
                    result: [...result, currHourData],
                    pre: curr
                }
            }, { result: [], pre: last(hourDataOfWeek)! }).result;
            console.log("hourDataOfWeek =>", hourDataOfWeek);

            const rapidPickUpHourData = hourDataOfWeek.map(hourData => {
                return hourData.map(({ start, end, rapidPickUpStart }) => ({
                    start: rapidPickUpStart,
                    end,
                    rapidPickUpStart,
                }))
            })
            
            const deliveryTimeRangeOfWeek = hourDataOfWeek.map(hourData => {
                return hourData.reduce<DeliveryTimeRange[]>((acc, curr) => {
                    const range = getDeliveryTimeRange(curr.start, { hour: curr.start.hour + 1, minute: curr.start.minute }, curr.end, []);
                    return [...acc, ...range.slice(1)];
                }, []);
            })

            const store: Store = {...item, hourData: hourDataOfWeek, deliveryTimeRangeOfWeek, hourDataTextOfWeek, rapidPickUpHourData };
            return store;
        });
    });
}

const mte = (src: HourAndMinute, target: HourAndMinute) => {
    return src.hour > target.hour
        || (src.hour === target.hour && src.minute >= target.minute)
}

const getDeliveryTimeRange = (start: HourAndMinute, currentEnd: HourAndMinute, end: HourAndMinute, result: DeliveryTimeRange[] = []): DeliveryTimeRange[] => {
    if (mte(currentEnd, end)) {
        return [...result, { start, end }];
    }
    return getDeliveryTimeRange(currentEnd, { hour: currentEnd.hour + 1, minute: currentEnd.minute }, end, [...result, { start, end: currentEnd }]);

}


// 获取当前机构
export const getCurrentBrand = () => {
    const code = process.env.NODE_ENV === 'production'
        ? 'current'
        : '1247491267995242496';
    return getBrandOfCode(code);
}

// code:'1247491267995242496' // juanxiang
// code:'1247491267995242499' //  西贡
// code:'1247491267995242497' //  饺子
// code:'1247491267995242498' //  上海

const getBrandOfCode = (
    code: string
): Promise<Brand> => {
    const url = `/repast/api/v2/brand/${code}`;

    return ajaxGet<SuccessResponse<ListResponse<BrandResponseDTO, never>>>(url)
        .then(({ data }) => {
            const { value } = data.data;
            return value.map(brand => {
                const eatTypes = brand.eatTypes ? brand.eatTypes.split(',').map(item => Number(item)) as DELIVERY_TYPE[] : [];
                const analytics = brand.analytics
                    ? JSON.parse(brand.analytics) as BrandAnalyticsInfo
                    : undefined;
                return {
                    ...brand,
                    theme: JSON.parse(brand.theme),
                    eatTypes,
                    analytics
                };
            })[0];
        });
}


