import React, { useCallback, useMemo, useState, CSSProperties } from 'react';
import styles from './index.module.css';
import { Order, ORDER_STATUS, ORDER_PAY_STATUS, ORDER_PREPARE_STATUS } from '../../models';
import { useI18n } from 'context/i18n';
import { OrderItem } from '../OrderItem';
import { useTheme } from 'modules/store';

interface Props {
    list: Order[];
    onItemClick: (id: Order['id']) => void;
}

interface Tab {
    key: 'all' | ORDER_STATUS | ORDER_PAY_STATUS | ORDER_PREPARE_STATUS;
    title: string;
}
export const OrderList: React.FC<Props> = ({
    list,
    onItemClick,
}) => {
    const { t } = useI18n();
    const tabs: Tab[] = [
        { key: 'all' as 'all'},
        { key: ORDER_PAY_STATUS.PENDING },
        { key: ORDER_PAY_STATUS.COMPLETED},
        { key: ORDER_PREPARE_STATUS.PENDING},
        { key: ORDER_PREPARE_STATUS.COMPLETED},
        { key: ORDER_STATUS.COMPLETED},
        { key: ORDER_STATUS.INVALID},
    ].map(item => ({
        key: item.key,
        title: t(`order.shortStatus.${item.key}`)
    }));
    const [active, setActive] = useState<Tab['key']>(tabs[0].key);

    const showList = useMemo(() => {
        return active === 'all' ? list : list.filter(item => item.status.includes(active));
    }, [active, list]);


    return (
        <>
            <Tabs tabs={tabs} current={active} onClick={setActive}/>
            <div className={styles.container}>
                {showList.length === 0 && <div className={styles.listEmpty}>{t('order.listEmpty')}</div>}
                {showList.length > 0 && <div className={styles.orderList}>
                    {showList.map(order => <OrderItem
                        key={order.id}
                        order={order}
                        onClick={({ id }) => onItemClick(id)}
                    />)}
                </div>}
            </div>
        </>
    );
}


export const Tabs: React.FC<{
    tabs: Tab[];
    current: Tab['key'];
    onClick: (key: Tab['key']) => void;
}> = ({
    tabs,
    current,
    onClick
}) => {
    const { theme: { primary } } = useTheme();
    const className = useCallback((key: Tab['key']) => {
        return current === key ? styles.active : '';
    }, [current]);
    const getStyle = useCallback((key: Tab['key']): CSSProperties => {
        return current === key ? { color: primary.bg } : {};
    }, [current]);

    return (
        <div>
            {tabs.map(tab => (
                <div
                    className={`${styles.tab} ${className(tab.key)}`}
                    style={getStyle(tab.key)}
                    key={tab.key}
                    onClick={() => onClick(tab.key)}
                >{tab.title}</div>
            ))}
            <div className="clear"></div>
        </div>
    )
}