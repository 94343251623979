import { action, payload } from 'ts-action';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { getActionType } from '../constants';
import { getProductInCartOfProductCode, getAllProductInCart, getProductInCartOfId, getAllActivityProductInCart, getPromoData } from '../selectors';
import { ReceiverInfo } from 'modules/me';
import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as storeSelectors } from 'modules/store/store';
import { selectors as orderInfoSelectors } from 'modules/order-info/store';
import { DELIVERY_TYPE, ASAP_TIME } from 'modules/order-info';
import { Product, CustomizableTasteSetting } from 'modules/product-menu';
import {
    ProductInCartStore,
    Order
} from '../../models';

import { createOrder as createOrderApi, CreateOrderParams } from '../../api';
import {
    makeProductInCartId,
    findProductInCartWithSameCustomizableTaste,
    isSameCustomizableTaste
} from '../utils';
import { getAsapTimestamp } from '../../components/OrderBasicInfo';
import { Language } from 'context/i18n';
import { addProductToCartAnalytics, deleteProductInCartAnalytics } from 'analytics/cart-analytics';
import { createOrderAnalytics } from 'analytics/order-analytics';
export {
    addActivityProductToCart,
    updateActivityProductInCart,
    deleteActivityProductInCart
} from './activity-product-in-cart';

export {
    setPromoCode,
    changePromoCodeEnable
} from './coupon';

type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;


export const putProductInCartEntity = action(
    getActionType('Put Product In Cart'),
    payload<{
        storeId: string;
        productInCartStore: ProductInCartStore
    }>()
)

export const deleteProductInCartEntity = action(
    getActionType('Delete Product In Cart'),
    payload<{
        storeId: string;
        id: ProductInCartStore['id'];
    }>()
)

/**
 * 增加商品成功，
 * 如果要增加的商品的自定义信息。和已有的无重复，则增加
 */
export const addProductToCartSuccess = action(
    getActionType('Add Product To Cart Success'),
    payload<{
        storeId: string,
        userId: number,
        productCode: Product['code'],
        cartId: ProductInCartStore['id'],
    }>()
)


export function addProductToCart({
    productCode,
    count,
    customizableTasteSetting
}: {
    productCode: Product['code'],
    count: number,
    customizableTasteSetting: CustomizableTasteSetting
}): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        const productInCartWithSameCustomizable = findProductInCartWithSameCustomizableTaste(
            customizableTasteSetting,
            getProductInCartOfProductCode(getState(), { code: productCode })
        );
        // 查看是否和已经存在的商品定制化重复
        if (productInCartWithSameCustomizable) {
            // 如果存在，累加数量
            dispatch(mergeProductInCartSuccess({
                storeId,
                userId,
                productCode,
                cartId: { from: 'new', to: productInCartWithSameCustomizable.id }
            }));
            dispatch(putProductInCartEntity({
                storeId,
                productInCartStore: {
                    ...productInCartWithSameCustomizable,
                    count: productInCartWithSameCustomizable.count + count
                }
            }));
        } else {
            // 如果不存在, 生成cartId， 增加
            const cartId = makeProductInCartId(productCode);
            dispatch(putProductInCartEntity({
                storeId,
                productInCartStore: {
                    productCode,
                    id: cartId,
                    count,
                    customizableTasteSetting
                }
            }));
            dispatch(addProductToCartSuccess({
                storeId,
                userId,
                productCode,
                cartId
            }));
        }
        addProductToCartAnalytics(productCode);
    }
}

/**
 * 更新商品信息成功, 不被reducer处理，用于日志
 */
export const updateProductInCartSuccess = action(
    getActionType('Update Product In Cart Success'),
    payload<{
        productCode: Product['code']; // 字段不被reducer处理，用于日志
        cartId: ProductInCartStore['id']; // 字段不被reducer处理，用于日志
    }>()
)

/**
 * 合并商品成功
 * 如果修改后的定制化信息。和已有的重复，那么合并
 */
export const mergeProductInCartSuccess = action(
    getActionType('Merge Product In Cart Success'),
    payload<{
        storeId: string;
        userId: number;
        productCode: Product['code'];
        cartId: {
            from: ProductInCartStore['id'];
            to: ProductInCartStore['id'];
        };
    }>()
)


/**
 * 如果count变为0
 * 则删除
 */
export const deleteProductInCartSuccess = action(
    getActionType('Delete Product In Cart Success'),
    payload<{
        storeId: string;
        userId: number;
        productCode: Product['code'];
        cartId: ProductInCartStore['id'];
    }>()
)

export function deleteProductInCart({
    productCode,
    cartId,
}: {
    productCode: Product['code'];
    cartId: ProductInCartStore['id'];
}): ThunkResult<void> {
    return (dispatch, getState) => {
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        const userId = authSelectors.getCurrentUser(getState())!.id;
        dispatch(deleteProductInCartSuccess({ storeId, userId, productCode, cartId }));
        dispatch(deleteProductInCartEntity({
            storeId,
            id: cartId
        }));
        deleteProductInCartAnalytics(productCode);
    }
}

/**
 * 修改已经在购物车那的商品
 * 可以修改 数量 | 口味
 */
export function updateProductInCart({
    cartId,
    count,
    customizableTasteSetting,
    remark,
}: {
    cartId: ProductInCartStore['id'];
    count: number;
    customizableTasteSetting: CustomizableTasteSetting;
    remark?: string
}): ThunkResult<void> {
    return async (dispatch, getState) => {
        const productInCart = getProductInCartOfId(getState(), { id: cartId })!;
        
        if (count === 0) {
            throw new Error('update product in cart error: count is 0');
        }
        const oldCount = productInCart.count;
        if (count > oldCount) {
            addProductToCartAnalytics(productInCart.productCode);
        } else if (count < oldCount) {
            deleteProductInCartAnalytics(productInCart.productCode);
        }

        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        if (isSameCustomizableTaste(customizableTasteSetting, productInCart.customizableTasteSetting)) {
            // 定制化没有修改
            dispatch(putProductInCartEntity({
                storeId,
                productInCartStore: {
                    ...productInCart,
                    count,
                    remark: remark === undefined ? productInCart.remark : remark
                }
            }));
        } else {
            // 定制化改变了
            const productInCartWithSameCustomizable = findProductInCartWithSameCustomizableTaste(
                customizableTasteSetting,
                getProductInCartOfProductCode(getState(), { code: productInCart.productCode })
            );
            // 查看是否和其他已经存在的定制化相同
            if (productInCartWithSameCustomizable) {
                const { id: targetCartId } = productInCartWithSameCustomizable;
                // 如果有相同，累加数量
                dispatch(mergeProductInCartSuccess({
                    storeId,
                    userId,
                    productCode: productInCart.productCode,
                    cartId: { from: cartId, to: targetCartId }
                }));
                dispatch(deleteProductInCartEntity({
                    storeId,
                    id: cartId,
                }));
                dispatch(putProductInCartEntity({
                    storeId,
                    productInCartStore: {
                        ...productInCartWithSameCustomizable,
                        count: productInCartWithSameCustomizable.count + count,
                        remark: remark === undefined ? productInCartWithSameCustomizable.remark : remark
                        
                    }
                }));
            } else {
                // 用作日志
                dispatch(updateProductInCartSuccess({ productCode: productInCart.productCode, cartId }));
                // 如果没有不同，修改口味和修改数量
                dispatch(putProductInCartEntity({
                    storeId,
                    productInCartStore: {
                        ...productInCart,
                        count,
                        customizableTasteSetting,
                        remark: remark === undefined ? productInCart.remark : remark
                    }
                }));
            }
        }
    }
}



export function createOrder(language: Language):ThunkResult<Promise<String>> {
    return (dispatch, getState) => {
        const allProductInCart = getAllProductInCart(getState(), {});
        const productList = allProductInCart.map(item => {
            const { customizableTasteSetting, count: buyNum, productCode, product, remark } = item;
            const tasteList = Object.keys(customizableTasteSetting).map(tasteCode => ({
                code: tasteCode, selected: customizableTasteSetting[tasteCode]
            }));
            return { buyNum, code: productCode, taste: tasteList, remark }
        });
        const allActivityProductInCart = getAllActivityProductInCart(getState(), {});
        const activityProductList = allActivityProductInCart.map(item => {
            const { customizableTasteSetting, count: buyNum, activityProductId } = item;
            const tasteList = Object.keys(customizableTasteSetting).map(tasteCode => ({
                code: tasteCode, selected: customizableTasteSetting[tasteCode]
            }));
            return { buyNum, activityProductId, taste: tasteList, remark: item.remark }
        });
        

        const eatType = orderInfoSelectors.getDeliveryType(getState())!;
        const receiver = orderInfoSelectors.getOrderReceiver(getState());
        const rapidPickUpReceiver = orderInfoSelectors.getOrderPickUpReceiver(getState());
        const remark = orderInfoSelectors.getRemark(getState())!;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        const store = storeSelectors.getCurrentStore(getState())!;
        const mealTime = orderInfoSelectors.getOrderMealTime(getState())!;
        const couponData = getPromoData(getState());
        const params: CreateOrderParams = {
            storeId,
            eatType,
            productType: '0',
            productList,
            activityProductList,
            mealTime:  (eatType === DELIVERY_TYPE.DELIVERY  && mealTime === ASAP_TIME) ? `${getAsapTimestamp(store)}`:`${mealTime}`,
            remark,
            promoCode: couponData.enable ? couponData.code : ''
        };
        if (eatType === DELIVERY_TYPE.DELIVERY) {
            params.receiverId = receiver!.id;
        } else {
            params.receiverMobile = rapidPickUpReceiver!.receiverMobile;
            params.receiverName = rapidPickUpReceiver!.receiverName || '';
        }

        return createOrderApi(params, language).then(id => {
            createOrderAnalytics(
                id,
                [
                    ...productList,
                    ...activityProductList.map(({buyNum, activityProductId}) => ({ buyNum, code: activityProductId }))
                ]
            );

            return id;
        });
    }
}


export const clearSuccess = action(
    getActionType('Clear'),
    payload<{
        storeId: string;
        userId: number;
    }>()
)

export function clear(): ThunkResult<void> {
    return (dispatch, getState) => {
        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        dispatch(clearSuccess({ storeId, userId }));
    }
}