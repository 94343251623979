import React, { useEffect } from 'react';
import styles from './index.module.css';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'modules/auth/store';
import { useRouteMatch, useHistory } from 'react-router-dom';



export const Body: React.FC<{
    children: React.ReactNode
}> = ({
    children
}) => {

    const { url } = useRouteMatch();
    const { push } = useHistory();
    
    const currentUser = useSelector(authSelectors.getCurrentUser);

    // 没有身份的时候，统一回到home
    useEffect(() => {
        if (!currentUser && url !== '/home') {
            push('/home');
        }
    }, [url, currentUser]);

    return (
        <div className={styles.body}>
            {children}
        </div>
    )   
}