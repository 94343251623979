import React, { useMemo } from 'react';
import styles from './index.module.css';
import { DiscountVO, CYCLE_TYPE, RULE_TYPE } from '../../models';
import { useI18n } from 'context/i18n';


interface Props {
    className?: string;
    discount: DiscountVO;
    currentDiscount?: DiscountVO;
}

export const DiscountItem: React.FC<Props> = ({
    className = '',
    discount,
    currentDiscount
}) => {
    const { t } = useI18n();
    const discountTimeDescription = useMemo(() => {
        if (discount.cycleType === CYCLE_TYPE.ALWAYS) {
            return t('activity.time.full');
        }
        if (discount.cycleType === CYCLE_TYPE.DAY_IN_WEEK) {
            return discount.cycleData.map(data => t(`dayInWeek.${data}`)).join(',');
        }
        return '';
    }, [discount, t]);

    const discountValue = useMemo(() => {
        if (discount.ruleType === RULE_TYPE.OFF) {
            return `${discount.value}% OFF`;
        }
        return '';
    }, [discount]);

    const currentClassName = useMemo(() => {
        return discount.id === currentDiscount?.id ? styles.current : '';
    }, [discount, currentDiscount]);

    return (
        <div className={`${styles.discount} ${currentClassName} ${className}`}>
            <div className={styles.timeDescription}>{discountTimeDescription}</div>
            <div className={styles.value}>{discountValue}</div>
        </div>
    )
}
