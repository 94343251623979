import React, { useCallback, useRef, useEffect, useContext, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as orderInfoSelectors } from 'modules/order-info/store';
import { useHistory } from 'react-router-dom';

interface ProviderProps {
    children: React.ReactNode;
    login: (redirect?: string) => void
}
interface ContextValue{
    login: (redirect?: string) => void,
    hasOrderInfo: () => boolean,
    editOrderInfo: (redirect?: string) => void
}

const contextValue: ContextValue = {
    login: () => {},
    hasOrderInfo: () => false,
    editOrderInfo: () => {}
}

export const accessAndOrderInfoContext = React.createContext(contextValue);

export const AccessAndOrderInfoProvider: React.FC<ProviderProps> = ({
    children,
    login
}) => {
    const { push } = useHistory();
    const currentUser = useSelector(authSelectors.getCurrentUser);
    const currentUserOrderInfo = useSelector(orderInfoSelectors.getCurrentUserOrderInfo);
    
    const editOrderInfo = useCallback((redirect) => {
        const url = `/order-info?redirect=${encodeURIComponent(redirect)}`;
        if (!currentUser) {
            login(url);
        } else{
            push(url);
        }
    }, [currentUser, login, push]);
    const hasOrderInfo = useCallback(() => {
        return !!currentUser && !!currentUserOrderInfo;
    }, [currentUser, currentUserOrderInfo]);
    return (
        <accessAndOrderInfoContext.Provider value={{
            login,
            editOrderInfo,
            hasOrderInfo
        }}>
            {children}
        </accessAndOrderInfoContext.Provider>
    )
}
export const useAccessAndOrderInfo = () => {
    return useContext(accessAndOrderInfoContext);
}