import React from 'react';
import styles from './index.module.css';
import logo from '../images/logo.png';
import qrcode from 'assets/qrcode.png';
import { useTheme, useStore } from 'modules/store';

export const Footer: React.FC = () => {
    const { webFontLogo, downloadAppQrcode } = useTheme();
    const { currentBrand } = useStore();
    return (
        <div className={styles.footer}>
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div>
                    <img src={webFontLogo} className={styles.img}/>
                </div>
                <div className={styles.line}>
                     {`Email | ${currentBrand?.email || 'support@kitbot.io'}`}
                </div>
                <div className={`${styles.line} ${styles.copyright}`}>
                Copyright &copy;2020 KitBot, Inc. All rights reserved.
                </div>
            </div>
            <div className={styles.right}>
                <img src={downloadAppQrcode} alt="" className={styles.qrcode}/>
            </div>
        </div>
    </div>
    )   
}