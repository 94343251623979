import { FEATURE_NAME } from './constants';
import { GlobalState, ModelInLanguage } from './reducer';
import { createSelector } from 'reselect';
import { ActivityReference, Product, DiscountVO, CYCLE_TYPE } from '../models';
import { selectors as storeSelectors } from 'modules/store/store';
import { Language } from 'context/i18n';
import { pathOr, sortBy } from 'ramda';

const select = (state: GlobalState) => state[FEATURE_NAME];
export const getDataOfStoreId = createSelector(
    [select, storeSelectors.getCurrentStoreId],
    (state, storeId) => {
        return storeId && state[storeId] ? state[storeId] : null;
    }
)


/**
 * 分类
 */
export const getCategoryDataOfStoreId = createSelector(getDataOfStoreId, dataOfStoreId => {
    return dataOfStoreId ? dataOfStoreId.category : null;
});


export const getCategoryCachedOfLanguage = createSelector(
    getCategoryDataOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (categoryData, language) => pathOr(false, ['status', language, 'cached'], categoryData),
);

export const getCategoryFetchingOfLanguage = createSelector(
    getCategoryDataOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (categoryData, language) => {
        console.log(`xixi ${language} =>`, categoryData?.status[language]?.fetching);
        return pathOr(false, ['status', language, 'fetching'], categoryData);
    }
);

export const getCategoryLoadingOfStoreId = createSelector(
    getCategoryCachedOfLanguage, getCategoryFetchingOfLanguage,
    (cached, fetching) => !cached && fetching,
);

export const getCategoryCodesOfStoreId = createSelector(getCategoryDataOfStoreId, categoryData => {
    return categoryData ? categoryData.codes : [];
})
export const getCategoryEntitiesOfStoreId = createSelector(getCategoryDataOfStoreId, categoryData => {
    return categoryData ? categoryData.entities : {};
})


export const getCategoryListOfLanguage = createSelector(
    getCategoryCodesOfStoreId,
    getCategoryEntitiesOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (codes, entities, language) => {
        return codes.map(code => {
            const entity = entities[code];
            return entity[language] || entity.default;
        })
    }
)


/**
 * 菜品
 */
export const getProductDataOfStoreId = createSelector(getDataOfStoreId, dataOfStoreId => {
    return dataOfStoreId ? dataOfStoreId.product : null;
})

export const getProductEntitiesOfStoreId = createSelector(getProductDataOfStoreId, productDataOfStoreId => {
    return productDataOfStoreId ? productDataOfStoreId.entities : {};
})
export const getProductOfCode = createSelector(
    (_: any, props: { language: Language, code: string }) => props,
    getProductEntitiesOfStoreId,
    ({ language, code }, entities) => {
        const entity = entities[code];
        return entity[language] || entity.default;
    }
)



/**
 * 菜品 按 分类
 */
export const getProductDataOfCategoryCodeMap = createSelector(getProductDataOfStoreId, productDataOfStoreId => {
    return productDataOfStoreId ? productDataOfStoreId.dataOfCategoryCode : {};
});

export const getProductDataOfCategoryCode = createSelector(
    getProductDataOfCategoryCodeMap,
    (_: any, props: {categoryCode: string}) => props.categoryCode,
    (dataOfCategoryCode, categoryCode) => dataOfCategoryCode[categoryCode],
);

export const getProductCachedOfCategoryCodeAndLanguage = createSelector(
    getProductDataOfCategoryCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfCategoryCode, language) => pathOr(false, ['status', language, 'cached'], productDataOfCategoryCode),
);

export const getProductFetchingOfCategoryCodeAndLanguage = createSelector(
    getProductDataOfCategoryCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfCategoryCode, language) => pathOr(false, ['status', language, 'fetching'], productDataOfCategoryCode),
);

export const getProductLoadingOfCategoryCode = createSelector(
    getProductCachedOfCategoryCodeAndLanguage,
    getProductFetchingOfCategoryCodeAndLanguage,
    (cached, fetching) => !cached && fetching,
);

export const getProductCodesOfCategoryCode = createSelector(
    getProductDataOfCategoryCode,
    pathOr([], ['codes'])
)

export const getProductListOfCategoryCode = createSelector(
    getProductCodesOfCategoryCode,
    getProductEntitiesOfStoreId,
    (_: any, props: { categoryCode: string, language: Language }) => props.language,
    (codes, entities, language) => codes.map(code => {
        const entity = entities[code];
        return entity[language] || entity.default;
    }),
)



/**
 * 活动菜品 按 活动 activityProduct
 */
const getActivityProductDataOfStoreId = createSelector(
    getDataOfStoreId,
    dataOfStoreId => dataOfStoreId ? dataOfStoreId.activityProduct : null
);
export const getActivityProductEntities = createSelector(
    getActivityProductDataOfStoreId,
    activityProductDataOfStoreId => activityProductDataOfStoreId?.entities || {}
)
const getActivityProductDataOfAcitivityMap = createSelector(
    getActivityProductDataOfStoreId,
    activityProductDataOfStoreId => {
        return activityProductDataOfStoreId ? activityProductDataOfStoreId.dataOfActivityCode : {};
    }
);

const getActivityProductDataOfActivityCode = createSelector(
    getActivityProductDataOfAcitivityMap,
    (_: any, props: {activityCode: string}) => props.activityCode,
    (dataOfActivityCode, activityCode) => dataOfActivityCode[activityCode],
);
const getActivityProductCachedOfActivityCodeAndLanguage = createSelector(
    getActivityProductDataOfActivityCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfActivityCode, language) => pathOr(false, ['status', language, 'cached'], productDataOfActivityCode),
);
export const getActivityProductFetchingOfActivityCodeAndLanguage = createSelector(
    getActivityProductDataOfActivityCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfActivityCode, language) => pathOr(false, ['status', language, 'fetching'], productDataOfActivityCode),
);
export const getActivityProductLoadingOfActivityCode = createSelector(
    getActivityProductCachedOfActivityCodeAndLanguage,
    getActivityProductFetchingOfActivityCodeAndLanguage,
    (cached, fetching) => !cached && fetching,
);

const getActivityProductIdsOfActivityCode = createSelector(
    getActivityProductDataOfActivityCode,
    pathOr([], ['ids'])
)
export const getActivityProductListOfActivityCode = createSelector(
    getActivityProductIdsOfActivityCode,
    getActivityProductEntities,
    (_: any, props: { activityCode: string, language: Language }) => props,
    (ids, entities, { activityCode, language }) => ids.map(id => {
        const entity = entities[id];
        return entity[language] || entity.default;
    })
)
export const getActivityProductOfId = createSelector(
    (_: any, props: { language: Language, activityProductId: string }) => props,
    getActivityProductEntities,
    ({ language, activityProductId }, entities) => {
        const entity = entities[activityProductId];
        return entity[language] || entity.default;
    }
)



/**
 * 菜品 按 活动
 */
const getProductDataOfAcitivityMap = createSelector(
    getProductDataOfStoreId,
    productDataOfStoreId => {
        return productDataOfStoreId ? productDataOfStoreId.dataOfActivityCode : {};
    }
);

const getProductDataOfActivityCode = createSelector(
    getProductDataOfAcitivityMap,
    (_: any, props: {activityCode: string}) => props.activityCode,
    (dataOfActivityCode, activityCode) => dataOfActivityCode[activityCode],
);

const getProductCachedOfActivityCodeAndLanguage = createSelector(
    getProductDataOfActivityCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfActivityCode, language) => pathOr(false, ['status', language, 'cached'], productDataOfActivityCode),
);

export const getProductFetchingOfActivityCodeAndLanguage = createSelector(
    getProductDataOfActivityCode,
    (_: any, props: { language: Language }) => props.language,
    (productDataOfActivityCode, language) => pathOr(false, ['status', language, 'fetching'], productDataOfActivityCode),
);

export const getProductLoadingOfActivityCode = createSelector(
    getProductCachedOfActivityCodeAndLanguage,
    getProductFetchingOfActivityCodeAndLanguage,
    (cached, fetching) => !cached && fetching,
);


const getProductCodesOfActivityCode = createSelector(
    getProductDataOfActivityCode,
    pathOr([], ['codes'])
)

type DiscountVOOfActivityProductList = DiscountVO & { products: Product[] };
export type ActivityProductList = ActivityReference & { discounts: DiscountVOOfActivityProductList[] };

function sortDiscountVOListOfActivityProductList(list: DiscountVOOfActivityProductList[]): DiscountVOOfActivityProductList[] {
    return sortBy((item: DiscountVOOfActivityProductList) => {
        if (item.cycleType === CYCLE_TYPE.DAY_IN_WEEK) return Number(item.cycleData);
        if (item.cycleType === CYCLE_TYPE.ALWAYS) return 1;
        return 1
    }, list);
}

export const getProductListOfActivityCode = createSelector(
    getProductCodesOfActivityCode,
    getProductEntitiesOfStoreId,
    (_: any, props: { activityCode: string, language: Language }) => props,
    (codes, entities, { activityCode, language }) => {
        if (codes.length === 0) return undefined;
        const products = codes.map(code => {
            const entity = entities[code];
            return entity[language] || entity.default;
        });
        const allDiscountsOfCurrentActivity = products.reduce<DiscountVO[]>((acc, currProduct) => {
            const activity = currProduct.activities.find(activity => activity.code === activityCode)!;
            if (!activity) {
                throw new Error(`没有找到对应的Avtivity, productCode: ${currProduct.code}, activityCode: ${activityCode}`);
            }
            activity.discounts.forEach(discount => {
                
                if (!acc.find(item => item.id === discount.id)
                    && !!discount.productCodes // 有菜
                ) {
                    acc = [...acc, discount];
                }
            })
            return acc;
        }, []);


        const [oneProduct] = products;

        const oneActivity = oneProduct.activities.find(activity => activity.code === activityCode)!;

        const discountVOOfActivityProductList: DiscountVOOfActivityProductList[] = allDiscountsOfCurrentActivity.map(discount => {
            const productCodes = discount.productCodes ? discount.productCodes.split(',') : [];

            const productsOfDiscount: Product[] = products.filter(p => productCodes.includes(p.code))  
                .map(product => {
                    const activity = product.activities.find(item => item.code === activityCode)!
                    if (!activity) {
                        throw new Error(`没有找到对应的Avtivity, productCode: ${product.code}, activityCode: ${activityCode}`);
                    }
                    const currentDiscount = activity.discounts.find(d => d.discountId === discount.id)!;
                    return { ...product, currentDiscount };
                })
            return { ...discount, products: productsOfDiscount }
        })



        const discountsGroupByCycleType = discountVOOfActivityProductList.reduce<{
            [CYCLE_TYPE.ALWAYS]: DiscountVOOfActivityProductList[],
            [CYCLE_TYPE.DAY_IN_WEEK]: DiscountVOOfActivityProductList[]
        }>((acc, curr) => {
            return { ...acc, [curr.cycleType]: [...acc[curr.cycleType], curr] }
        }, { [CYCLE_TYPE.ALWAYS]: [], [CYCLE_TYPE.DAY_IN_WEEK]: [] })

        const discounts = [
            ...sortDiscountVOListOfActivityProductList(discountsGroupByCycleType[CYCLE_TYPE.ALWAYS]),
            ...sortDiscountVOListOfActivityProductList(discountsGroupByCycleType[CYCLE_TYPE.DAY_IN_WEEK]),
        ]

        const ret: ActivityProductList = { ...oneActivity, discounts };
        return ret;
    },
)


/**
 * 活动列表
 */


export const getActivityListDataOfStoreId = createSelector(getDataOfStoreId, dataOfStoreId => {
    return dataOfStoreId ? dataOfStoreId.showInCategoryActivityList : null;
});


export const getActivityListCachedOfLanguage = createSelector(
    getActivityListDataOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (data, language) => pathOr(false, ['status', language, 'cached'], data),
);

export const getActivityListFetchingOfLanguage = createSelector(
    getActivityListDataOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (data, language) => {
        return pathOr(false, ['status', language, 'fetching'], data);
    }
);

export const getActivityListLoadingOfStoreId = createSelector(
    getActivityListCachedOfLanguage, getActivityListFetchingOfLanguage,
    (cached, fetching) => !cached && fetching,
);

export const getActivityCodesOfStoreId = createSelector(getActivityListDataOfStoreId, data => {
    return data ? data.codes : [];
})
export const getActivityEntitiesOfStoreId = createSelector(getActivityListDataOfStoreId, data => {
    return data ? data.entities : {};
})


export const getActivityListOfLanguage = createSelector(
    getActivityCodesOfStoreId,
    getActivityEntitiesOfStoreId,
    (_: any, props: { language: Language }) => props.language,
    (codes, entities, language) => {
        return codes.map(code => {
            const entity = entities[code];
            return entity[language] || entity.default;
        })
    }
)
