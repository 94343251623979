
import { getFirebaseAppAnalytics } from 'analytics/get-firebase-app';
export const viewAnalyticsLogger = (pathname: string) => {
    if (/normal-meal\/category/.test(pathname)) {
        getFirebaseAppAnalytics()?.logEvent('view_item_list', {
            item_list_name: 'Category List',
        });
    }
    if (/normal-meal\/category\//.test(pathname)) {
        getFirebaseAppAnalytics()?.logEvent('view_item_list', {
            item_list_name: 'Product List',
            item_list_id: pathname.replace('/normal-meal/category/', '')
        });
    }
    if (/normal-meal\/activity\//.test(pathname)) {
        getFirebaseAppAnalytics()?.logEvent('view_item_list', {
            item_list_name: 'Normal Activity Product List',
            item_list_id: pathname.replace('/normal-meal/activity/', '')
        });
    }
    if (/normal-meal\/kill-activity\//.test(pathname)) {
        getFirebaseAppAnalytics()?.logEvent('view_item_list', {
            item_list_name: 'Kill Activity Product List',
            item_list_id: pathname.replace('/normal-meal/kill-activity/', '')
        });
    }

    if (/\/cart/.test(pathname)) {
        getFirebaseAppAnalytics()?.logEvent('view_cart', {});
    }

}