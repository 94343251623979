import { Lang } from './Lang';
export const ko: Lang = {

    auth: {
        signIn: '로그인',
        signUp: '등기',
        touristSignIn: '손님 로그인',
        logOut: '로그 아웃', 
        gotoSignIn: '로그인',
        gotoSignUp: '등록으로 이동',
        country: '국가',
        smsCodeSending: '배상',
        messages: {
            checkPasswordEqual: '두 번 입력 한 비밀번호가 다릅니다. 재설정하십시오',
            mobileIsExists: '전화 번호가 등록되었습니다'
        },
        placeholder: {
            mobile: '전화 번호를 입력하십시오',
            password: '비밀번호를 입력 해주세요',
            setPassword: '비밀번호를 설정하십시오',
            setConfirmPassword: '비밀번호를 다시 입력하십시오',
            grahicInput: '대소 문자를 구분하지 않음',
            getSmsCode: '인증 코드 받기',
            smsCodeInput: 'SMS 인증 코드',

        }
    },

    form: {

        actions: {
            delete: '지우다',
            add: '추가',
            new: '더하다',
            detail: '세부',
            edit: '편집하다',
            save: '저장',
            submit: '제출하다',
            cancel: '취소',
            change: '수정하다',
            clear: '빈',
            upload: '업로드',
            update: '최신 정보',
            addLocalization: '새로운 언어',
            search: '검색',
            confirm: '확인하다',
            back:"반환",
            confirmAndNext:"다음 단계 확인"
        },
        

        rules: {
            maxLength: '최대 길이는${{length}}',
            ltSize: '이미지 크기는 초과 할 수 없습니다${{size}}',
            noLocalization: '하나 이상의 언어로 된 정보가 필요합니다. 추가하십시오',
            makeSure: '양식 오류, 확인하시기 바랍니다'
        },
        messages: {
            success: '${{action}}성공',
            fail: '${{action}}실패',
        },
        alert: {
            isSure: '확실합니까？'
        }
    },
    home: {
        title: '샹 시앙',
        orderNow: '즉시 주문',
        newOrder: '새로운 주문',
        recorder: '과거 주문',
        changeLanguage: '언어',
        menu:'메뉴',
        homePage:'집',
        messages: {
            switchStoreWillClearCart: '상점을 바꾸면 장바구니가 비워집니다'
        }
    },
    orderType: {
        0: '테이크 아웃',
        1: '식사 배달',
        2: 'Contactless Pick-Up',
    },
    productType: {
        0: '보통 식사',
        1: '5 일 식사',
        fiveDayDescription: '5 일 식사 (사전 예약 필요, 오후 5시 주문 마감. 다음 날 식사 제공 또는 다음날 오후 5시 이후에는 식사 제공)',
        alert: {
            crossToFiveDayMeal: '"5 일 식사"가 아닌 요리를 선택했습니다. 5 일 식사로 전환하면 쇼핑 카트가 지워집니다.',
            crossFromFiveDayMeal: '"5 일 식사"에서 요리를 선택했습니다. 5 일 외 식사로 전환하면 장바구니가 지워집니다.',
        }
    },

    orderTime: {
        asap: {
            delivery: '즉시 배송',
            pickUp: '나를 지금 데려가요',
            laterly:"나중"
        },
        later: '선택 기간',
        businessHour: '업무 시간',
        error: {
            selectTime: '식사 시간을 선택하십시오',
            nowOutOfHour: '영업 시간을 초과했으며 예약 만 허용됩니다',
            selectedTimeOutOfHour: '선택한 시간이 업무 시간 이외입니다',
            selectedTimeExpire: '선택한 시간이 만료되었습니다',
            nowNearClose: '영업 시간이 초과 될 예정입니다. 가능한 빨리 주문하거나 예약하십시오.'
        }
    },
    ourMenu: {
        title: '뜨거운 추천',
        category: '요리 카테고리'
    },
    menuInCategory: {
        searchPlaceholder: '요리 검색',
        addToOrder: '주문 추가',
    },

    foodDetail: {
        ingredients: '주성분',
        customize: '주문 취향',
        addToOrder: '주문 추가',
        update: '최신 정보',
        next: '다음 단계',
        addToCart:'장바구니에 담기'
    },
    taste: {
        child: {
            radio: {
                0: "아니오",
                1: "매우",
                2: "덜",
                3: "정상",
                4: "더",
                5: "아주 많이"
            }
        }
    },
    cart: {
        header: {
            title: '쇼핑 카트',
            keepOrdering: '주문 계속'
        },
        next: '정착',
        detail:'쇼핑 카트 정보'
    },
    statements: {
        header: {
            title: '주문'
        },
        error: {
            enterMobile: '예약 된 휴대폰 번호를 입력하십시오'
        },
        next: '주문 제출',
        mobile: 'số điện thoại',
        placeholder: {
            mobile: "예약 된 휴대폰 번호를 입력하십시오",
            remark: "비고를 입력하십시오",
        },
        messages: {
            submitTo: '현재 주문이에 제출됩니다${{name}}'
        },
        addRemark: '메모 추가',
        remark: '비고',
        summary: {
            title: '요약',
            subtotal: '소계',
            discount: '할인',
            taxes: '세',
            total: '합계',
            addPromoCode: '프로모션 코드 추가',
            postage: '화물'
        }
    },
    order: {
        shortStatus: {
            all: 'All',
            5: 'Awaiting',
            10: 'Unpaid',
            11: 'Paid',
            12: 'Paying',
            20: 'Preparing',
            21: 'Ready',
            22: 'Moved to Front Desk',
            30: 'Completed',
            98: 'Invalid',
        },
        status: {
            5 : '주문',
            10 : '지불하다',
            11 : '유료',
            12 : '결제 진행 중',
            20 : '준비중',
            21 : '완료 준비 완료',
            22 : 'Moved to Front Desk',
            30 : '완료',
            98 : '사용 안함',
        },
        actions: {
            cancel: '주문 취소',
            delete: '주문 삭제',
            pay: '지불',
            confirm: '영수증 확인',
            offlinePay: '오프라인 결제로 전환',
            onlinePay: '지불',
        },
        payMethod: {
            1 : '온라인 결제',
            2 : '오프라인 결제'
        },
        total: '합계',
        listEmpty: '관련 주문이 없습니다',
        info: '주문 정보',
        id: '주문 번호',
        storeName: '가게 이름',

        createTime: '제작 시간',
        // 支付时间
        payTime: '지불 시간',
        // 准备完成时间
        prepareCompleteTime: '준비 시간',
        // 完成时间
        completeTime: '완료 시간',
        // 失效时间
        invalidTime: '만료 시간',

        payClosedTime: '거래 마감 시간',
        totalPrice:'총 가격',
        orderStatus:"주문 상태",
        takeCode: 'Take Code',
        amount:"수량",
        name:"주문",
        noYet:"아니",
        mealTime: {
            rapidPickUp: '예상 식사 시간',
            deliveryStart: '예상 배달 시간',
            deliveryEnd: '예상 배달 시간'
        },
        error: {
            can_not_deliver: {
                lt_price: '배송 시작 가격은$${{value}}(할인 후)'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: '주문에 스파이크 활동이있는 요리가 있습니다 : ${{nameText}}, 이 주문은 오늘 [셀프 픽] [식사 배달]을 선택해야합니다. 날짜를 변경하고 다시 시도하십시오.'
                }
            }
        },
        pay: {
            title: '지불',
            credit:{
                name:'신용 카드',
                total:'합계',
                item:' ',
                more:'자세히보기',
            },
            error: {
                download_app_to_pay: '다운로드 App 온라인 결제',
                download_app_to_pay_or_offline: '다운로드 App 온라인 결제를하거나 "오프라인 결제로 전환"버튼을 클릭하십시오 ',
                store_doesnt_support_online_payment: '현재 상점은 온라인 결제를 지원하지 않습니다',
                doesnt_support_offline_payment: 'The current eat method does not support offline payment.',
                enter_right_cardholder_detail: '카드 소지자 정보를 정확하게 입력하십시오',
                coupon_not_found: '쿠폰 코드가 존재하지 않습니다',
                coupon_cannot_use: '쿠폰 코드를 사용할 수 없습니다',
                coupon_num_is_zero: '사용 가능한 쿠폰 코드가 충분하지 않습니다',
                coupon_not_use_condition: '쿠폰 코드가 사용 조건을 충족하지 않습니다',
                order_payment_in_progress:'주문 결제',
                order_does_not_exist:'Order does not exist',
                order_has_been_paid:'Order has been paid',
            },
            cardHolder: {
                title: '카드 소지자 정보',
                name: '카드 소지자 이름',
                phone: '전화',
                email: '사서함',
                zipcode: '우편 번호',
            },
            cardDetails: {
                title: '은행 카드 정보',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: '저장',
            add: '더하다',
        },
        shippingInfo: '연락 정보',
        enterShippingInfo: '연락 정보를 기입하십시오',
        name: '이름',
        phone: '전화',
        address: '비고 정보 (선택 사항)',
        fillInAddress:'주소를 기입하십시오',
        selectAddress:"배송지 선택",
        coordinateDesc: '주소',
        makeFormRight: '연락처 정보가 정확한지 확인하십시오',
        empty: '일반적으로 사용되는 연락처 정보가 없습니다. 추가하십시오',
        pleaseSelect: '연락처 정보가 없습니다. 선택하십시오',
        pleaseEnter: '들어 오세요',
        shippingMethod:"배달 방법",
        selectShippingMethod:"배송 방법을 선택하세요",
        selectShippingMethod1:"환영합니다, 주문 방법을 선택하십시오",
        store:'저장',
        selectStore:'가게를 선택하십시오',
        contactName:"담당자 이름",
        contactPhone:"연락 번호",
        contactInfo:"연락 정보를 기입하십시오",
        eatType:"식사 방법",
        eatTime:"식사 시간"
    },
    history:{
        historyOrders:"역사적 순서"
    },
    me:{
        name:"개인적인"
    },
    address:{
        addressManagement:"주소 관리",
        add:"주소 추가",
        management:"조치",
        setAsDefault:"기본값으로 설정",
        default: "기본",
        addressDelivery:"배달 주소",
        fullAddress:"주소",
        noAddress:"죄송합니다. 주소가 없습니다",
        selectDeliveryAddress:"배송지 주소를 선택하십시오",
        messages: {
            cannotChangeWhenUsing: '이 주소는 사용 중이며 수정할 수 없습니다'
        }
    },

    dayInWeek: {
        0 : '일요일',
        1 : '월요일',
        2 : '화요일',
        3 : '수요일',
        4 : '목요일',
        5 : '금요일',
        6 : '토요일',
    },
    searchProduct:{
        search:"검색 시작",
        noResult:"검색 결과가 없습니다. 다른 단어를 사용해보세요 ~"
    },
    activity: {
        name: '프로모션',
        time: {
            start: '시작 시간',
            end: '종료 시간',
            full: '모든 활동이 효과적입니다',
        },
        placeholder: {
            chooseTimeToView: '식사 배달 또는 식사 시간 선택, 해당 할인 가격 확인'
        },
        description: '이벤트 설명',
        activityStock: '활성 인벤토리',
        singlePurchaseLimit: '단일 구매 한도'
    },

    activityProduct: {
        label: {
            event_starts_later: '이벤트가 시작되지 않았습니다',
            event_is_over: '이벤트가 끝났습니다',
            purchase_limit_reached: '개별 구매 한도를 초과했습니다',
            not_enough_items_to_sell: '남아있는 활성 상품의 재고 부족',
        },
        error: {
            purchase_limit_reached: '최대 구매 한도를 초과했습니다',
            not_enough_items: '재고 부족',
            unable_to_add: '계속 추가 할 수 없습니다',
            event_is_over_please_check: '일부 스파이크 활동이 종료되었습니다. 장바구니 목록을 확인하십시오. ',
            purchase_limit_reached_please_check: '${{name}}최대 구매 한도를 초과했습니다. 장바구니 목록을 확인하십시오.',
            not_enough_items_please_check: '${{name}}수량이 기존 이벤트 인벤토리를 초과했습니다. 장바구니 목록을 확인하십시오.',
        }
    },
    coupon: {
        promoCode: {
            name: '프로모션 코드',
            error: {
                coupon_not_found: '쿠폰 코드가 존재하지 않습니다',
                coupon_cannot_use: '쿠폰 코드를 사용할 수 없습니다',
                coupon_num_is_zero: '사용 가능한 쿠폰 코드가 충분하지 않습니다',
                coupon_not_use_condition: '쿠폰 코드가 사용 조건을 충족하지 않습니다'
            },
            placeholder: {
                chooseTimeToView: '식사 배달 또는 식사 시간 선택, 해당 할인 가격 확인'
            },
        }
    }
};