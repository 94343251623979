import React, { useMemo } from 'react';
import styles from './index.module.css';
import { Order } from '../../models';
import dayjs from 'dayjs';
import { OrderBasicInfo } from '../OrderBasicInfo';
import { Summary } from '../Summary';
import { ProductInOrderList } from '../ProductInOrderList'
import { ActivityProductInOrderList } from '../ActivityProductInOrderList'
import { OrderTimeInfo } from '../OrderTimeInfo';
import { PaymentInformation } from '../PaymentInformation';
interface Props {
    order: Order
}

export const OrderDetail: React.FC<Props> = ({
    order
}) => {
    const totalCount = useMemo(() => {
        return [...order.productList, ...order.activityProductList].reduce<number>((acc, item) => {
            return acc + item.buyNum;
        }, 0);
    }, [order]);
    console.log(" order detail => ", order);
    return (
        <div>

            <div className={styles.listWrapper}>
                <OrderBasicInfo order={order}/>
            </div>

            <div className={`${styles.listWrapper} ${styles.border}`}>
                <ProductInOrderList productList={order.productList}/>
                <ActivityProductInOrderList activityProductList={order.activityProductList} />
                <Summary {...order} totalCount={totalCount}/>
            </div>


            <div className={styles.listWrapper}>
                <OrderTimeInfo order={order}/>
            </div>

            {!(order.payTime || order.cancelTime) && 
                <PaymentInformation className={styles.paymentInformation} store={order.store}/>
            }
        </div>
    )
}
