import React, { Ref } from 'react';
import styles from './index.module.css';
import { useSize } from 'react-use';

interface Props {
    src: string;
    insideRef?: Ref<HTMLImageElement>
}

export const BigBackgroundImage: React.FC<React.DOMAttributes<HTMLImageElement> & Props> = ({
    insideRef,
    ...props
}) => {
    return (
        <img ref={insideRef} className={styles.background} {...props}/>
    );
}
