import React, { useCallback, useState } from 'react';
import styles from './index.module.css';

import { DELIVERY_TYPE, OnFinish } from '../../models';
import { PickUpReceiverInfo } from 'modules/me';

import { RapidPickUpReceiver } from '../../components/RapidPickUpReceiver';
import { StoreList } from '../../components/StoreList';
import { useOrderInfo } from '../../facades/useOrderInfo';

enum STEP {
    RECEIVER = 0,
    STORE = 1,
}

interface Props {
    deliveryType: DELIVERY_TYPE.RAPID_PICK_UP | DELIVERY_TYPE.LOCKER_PICK_UP
    onFinish: OnFinish;
}

export const PickUpInfo: React.FC<Props> = ({ deliveryType, onFinish }) => {
    const { currentStoreId, pickUpReceiver: initialReceiver } = useOrderInfo();
    const [step, setStep] = useState<STEP>(STEP.RECEIVER);
    const [receiver, setReceiver] = useState<PickUpReceiverInfo | undefined>(initialReceiver);

    const handleOnNext = useCallback((info: PickUpReceiverInfo) => {
        setReceiver(info);
        setStep(STEP.STORE);
    }, [setReceiver]);

    const handleFinish = useCallback((storeId: string) => {
        onFinish({
            deliveryType,
            storeId,
            pickUpReceiverInfo: receiver
        });
    }, [onFinish, receiver]);

    return (
        <div className={styles.rapidPickUpInfo}>
            {step === STEP.RECEIVER && (
                <RapidPickUpReceiver
                    initial={receiver}
                    onNext={handleOnNext}
                />
            )}
            {step === STEP.STORE && (
                <StoreList
                    geo={true}
                    initialSelectedId={currentStoreId}
                    deliveryType={deliveryType}
                    onNext={handleFinish}
                    goBack={() => setStep(STEP.RECEIVER)}
                />
            )}
        </div>
    );
};
