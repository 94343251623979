import React from 'react';
import styles from './index.module.css';
import { ProductInOrderItem } from '../ProductInOrderItem';
import { ProductInOrder } from '../../models';

interface Props {
    className?: string;
    productList: ProductInOrder[]
}

export const ProductInOrderList: React.FC<Props> = ({
    className = '',
    productList
}) => {

    return (
        <div className={`${className}`}>
            {productList.map((item, index) => (
                <ProductInOrderItem
                    key={index}
                    productInOrder={item}
                />
            ))}
        </div>
    )
}