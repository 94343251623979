import { useMemo } from 'react';
import { ActivityProductInCartStore } from '../models';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { selectors, actions } from '../store';

export const useActivityProductInCart = (activityProductId: string) => {

    const productsInCart = useSelector<RootState, ActivityProductInCartStore[]>(state =>
        selectors.getActivityProductInCartOfActivityProductId(state, { activityProductId })
    );

    const count = useMemo(() => {
        return productsInCart.reduce((acc, curr) => {
            return acc + curr.count;
        }, 0);
    }, [productsInCart]);


    return {
        count,
        productsInCart,
    };
}