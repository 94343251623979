import { useCallback, useMemo } from 'react';
import { ProductInCart, ProductInCartStore } from '../models';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { selectors, actions } from '../store';
import { useI18n } from 'context/i18n';

export const useProductInCart = (productCode: string) => {
    const { lang } = useI18n();

    const dispatch = useDispatch();
    const productsInCart = useSelector<RootState, ProductInCartStore[]>(state => selectors.getProductInCartOfProductCode(state, { code: productCode }));

    const count = useMemo(() => {
        return productsInCart.reduce((acc, curr) => {
            return acc + curr.count;
        }, 0);
    }, [productsInCart]);


    return {
        count,
        productsInCart,
    };
}