import {
    on,
    reducer as createReducer,
} from 'ts-action';
import {
    setOrderInfoSuccess,
    setMealTimeSuccess,
    setRemarkSuccess,
    clearRemarkSuccess,
} from './actions';

import { FEATURE_NAME, DataOfUserId, initialDataOfUserId } from './constants';
import { assoc } from 'ramda';


export interface State {
    [key: number/**userId */]: DataOfUserId
}

export interface GlobalState {
    [FEATURE_NAME]: State;
};

export const initialState: State = {};

export const reducer = createReducer(
    initialState,
    on(
        setOrderInfoSuccess,
        (state, { payload: {
            userId,
            ...data
        } }) => {
            let dataOfUserId = state[userId] || initialDataOfUserId;
            dataOfUserId = { ...dataOfUserId, ...data };
            return assoc(`${userId}`, dataOfUserId, state);
        }
    ),
    on(
        setMealTimeSuccess,
        (state, { payload: { userId, time: mealTime } }) => {
            let dataOfUserId = state[userId] || initialDataOfUserId;
            dataOfUserId = { ...dataOfUserId, mealTime };
            return assoc(`${userId}`, dataOfUserId, state);
        }
    ),
    on(
        setRemarkSuccess,
        (state, { payload: { userId, remark } }) => {
            let dataOfUserId = state[userId] || initialDataOfUserId;
            dataOfUserId = { ...dataOfUserId, remark };
            return assoc(`${userId}`, dataOfUserId, state);
        }
    ),
    on(
        clearRemarkSuccess,
        (state, { payload: { userId } }) => {
            let dataOfUserId = state[userId] || initialDataOfUserId;
            dataOfUserId = { ...dataOfUserId, remark: '' };
            return assoc(`${userId}`, dataOfUserId, state);
        }
    )
);
