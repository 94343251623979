import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    PaymentRequestButtonElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import {
    loadStripe,
    PaymentMethod,
    PaymentRequest,
    StripeCardElementChangeEvent,
    StripeElementStyle
} from '@stripe/stripe-js';

import { useOrderDetail } from '../../facades/useOrderDetail';
import { useI18n } from 'context/i18n';
import { useUrlParams } from 'hooks/useUrlParams';
import { ThemeDiv } from 'modules/store';
import { createPaymentIntent, getPayStatus, getStripeCards, payIntent } from '../../api';
import { purchaseAnalytics } from 'analytics/order-analytics';
import { selectors as authSelectors } from 'modules/auth/store';
import { Card } from 'modules/buy/models';

import { Spin } from 'components/Spin';
import { message } from 'components/Message';
import { SubPageLayout } from 'layout/SubPageLayout';
import { Summary } from '../../components/Summary';
import { CardItem } from './components/CardItem';
import { DELIVERY_TYPE } from '../../../order-info/models';

const ELEMENT_OPTIONS = {
    style: {
        paymentRequestButton: {
            type: 'buy' as const,
            theme: 'dark' as const,
        },
    },
};

const style: StripeElementStyle = {
    base: {
        color: '#424770',
        fontSize: '20px',
        lineHeight: '40px',
        letterSpacing: '0.025em',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    invalid: {
        color: '#9e2146',
    },
};

export const Pay: React.FC = () => {
    const { t } = useI18n();
    const stripe = useStripe();
    const elements = useElements();
    const { goBack, replace } = useHistory();
    const { params } = useUrlParams({
        defaultValues: {
            from: ''
        }
    });
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const [errorMessage, setErrorMessage] = useState(null);
    const [notAvailable, setNotAvailable] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [cardListShow, setCardListShow] = useState<boolean>(true);
    const [checkedCardIndex, setCheckedCardIndex] = useState<number>();
    const [checkedCardId, setCheckedCardId] = useState<string>();

    const { orderId } = useParams<{ orderId: string }>();
    const { order, loading, methods: { getOrderDetail, changePayMethodToOffline, getKey } } = useOrderDetail(orderId!);
    console.log('order => ', order);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const [cardList, setCardList] = useState<Array<any>>([]);
    const [showCardList, setShowCardList] = useState<Card[]>([]);

    const { push } = useHistory();

    const currentUser = useSelector(authSelectors.getCurrentUser);

    const [cardHolderName, setCardHolderName] = useState<string>('');
    const [cardHolderPhone, setCardHolderPhone] = useState<string>(currentUser?.mobile || '');
    const [cardHolderEmail, setCardHolderEmail] = useState<string>(currentUser?.email || '');
    const [billingZipcode, setBillingZipcode] = useState<string>('');

    useEffect(() => {
        getOrderDetail();
        getPayCardList();
    }, []);

    useEffect(() => {
        if (!stripe || !order) {
            // We can't create a PaymentRequest until Stripe.js loads.
            return;
        }
        const payAmountStrArr = `${order.payAmount}`.split('.');
        let amount = 0;
        if (payAmountStrArr.length === 2) {
            const numberStr = order.payAmount.replace('.', '');
            amount = payAmountStrArr[1].length === 2 ? Number(numberStr) : Number(`${numberStr}0`);
        } else {
            amount = Number(`${order.payAmount}00`);
        }
        const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'KITBOT',
                amount
            },
            requestPayerEmail: true
        });

        pr.on('paymentmethod', async (event) => {
            setPaymentMethod(event.paymentMethod);
            if (!event.payerEmail) {
                event.complete('invalid_payer_email');
            } else {
                setPaymentLoading(true);
                return payIntent(event.paymentMethod.id, orderId!, event.payerEmail).then(ret => {
                    purchaseAnalytics(orderId!);
                    console.log('payIntent ret =>', ret);
                    setPaymentLoading(false);
                    event.complete('success');
                    if (params.from === 'order') {
                        goBack();
                    } else {
                        replace(`/me/order/${orderId!}`);
                    }
                }).catch(err => {
                    setPaymentLoading(false);
                    event.complete('fail');
                    setTimeout(() => {
                        message.error('Payment fail');
                    }, 300);
                });
            }
        });

        pr.canMakePayment().then((canMakePaymentRes) => {
            console.log('canMakePaymentRes =>', canMakePaymentRes);
            if (canMakePaymentRes) {
                setPaymentRequest(pr);
            } else {
                setNotAvailable(true);
            }
        });
    }, [stripe, order]);

    const getPayCardList = useCallback(() => {

        getStripeCards().then(({ data: { data } }) => {

            setCardList(data);
            setShowCardList(data.length > 2 ? data.slice(0, 2) : data);

        });

    }, [cardList, getStripeCards]);

    const totalCount = useMemo(() => {
        if (!order) return 0;
        return order.productList.reduce((acc, { buyNum }) => acc + buyNum, 0)
            + order.activityProductList.reduce((acc, { buyNum }) => acc + buyNum, 0);
    }, [order]);

    const handleCardChange = (event: StripeCardElementChangeEvent) => {
        if (event.error) {
            console.log('event.error =>', event.error.message);
            // Show `event.error.message` in the payment form.
        }
    };

    const canOnlinePay = useMemo(() => {
        if (!order) return false;
        return order.store.onlinePayment.enable;
    }, [order]);

    const canOfflinePay = useMemo(() => {
        if (!order) return false;
        return order.store.offlinePayment.enable && order.eatType !== DELIVERY_TYPE.LOCKER_PICK_UP;
    }, [order]);

    const handleSubmit = async () => {
        if (!stripe || !elements) return;

        setPaymentLoading(true);
        if (checkedCardIndex && checkedCardId) {
            payIntent(checkedCardId, orderId!, cardHolderEmail).then(ret => {
                purchaseAnalytics(orderId!);
                console.log('payIntent ret =>', ret);
                setPaymentLoading(false);
                if (params.from === 'order') {
                    goBack();
                } else {
                    replace(`/me/order/${orderId!}`);
                }
            }).catch(err => {
                setPaymentLoading(false);
                setTimeout(() => {
                    message.error('Payment fail');
                }, 300);
            });
        } else {
            if (!cardHolderName || !cardHolderPhone || !cardHolderEmail || !billingZipcode) {
                message.error(t('order.pay.error.enter_right_cardholder_detail'));
                setPaymentLoading(false);
                return;
            }

            createPaymentIntent({
                items: [{ id: 'photo-subscription' }],
                currency: 'usd',
                email: cardHolderEmail,
                orderId
            }).then(({ data }) => {
                if (data.error) {
                    //   Toast.fail(t(data.error.message))
                    setPaymentLoading(false);
                    message.error(`${t(`order.pay.error.${data.error.message}`)}`);
                    return;
                }
                stripe
                    .confirmCardPayment(data.client_secret, {
                        payment_method: {
                            card: elements.getElement(CardNumberElement)!,
                            billing_details: {
                                name: cardHolderName,
                                phone: cardHolderPhone,
                                email: cardHolderEmail,
                                address: { postal_code: billingZipcode }
                            }
                        },
                        setup_future_usage: 'off_session'
                    }).then((ret) => {
                    if (ret?.paymentIntent?.status != 'succeeded') {
                        message.error(ret?.error?.message);
                        setPaymentLoading(false);
                        return;
                    } else {
                        return getPayStatus(orderId, cardHolderEmail).then((data) => {
                            purchaseAnalytics(orderId!);
                            console.log('payIntent ret =>', ret);
                            setPaymentLoading(false);
                            if (params.from === 'order') {
                                goBack();
                            } else {
                                replace(`/me/order/${orderId!}`);
                            }

                        }).catch((err) => {
                            setPaymentLoading(false);
                            err?.data?.msg && message.error(`${t(`order.pay.error.${data.message}`)}`);
                        });
                    }
                    // purchaseAnalytics(orderId!);
                    // console.log("payIntent ret =>", ret);
                    // setPaymentLoading(false);
                    // if (params.from === 'order') {
                    //     goBack();
                    // } else {
                    //     replace(`/me/order/${orderId!}`)
                    // }
                }).catch((err) => {
                    setPaymentLoading(false);
                    err?.data?.msg && message.error(`${t(`order.pay.error.${err.data.message}`)}`);
                });
            });
        }
    };

    const handleOfflinePayment = () => {
        changePayMethodToOffline().then(() => {
            if (params.from === 'order') {
                goBack();
            } else {
                replace(`/me/order/${orderId!}`);
            }
        }).catch((err) => {
            if (err?.msg) {
                message.error(t(`order.pay.${err?.msg}`), 1.5);
            }
        });
    };

    const showAll = () => {
        setShowCardList(cardList);
    };

    const handleSavedCardPay = (index: number, id: string) => {
        if (index === checkedCardIndex) {
            setCheckedCardIndex(undefined);
            setCheckedCardId(undefined);
            return;
        } else {
            setCheckedCardIndex(index);
            setCheckedCardId(id);
        }
    };

    const goCardList = () => {
        push(`/pay/${orderId}/card`);
    };

    return (
        <SubPageLayout>
            <Spin spinning={loading || paymentLoading}>
                {order && (
                    <div className={styles.pay}>
                        <div className={styles.header}>
                            <div className={styles.title}>{t('statements.summary.total')}</div>
                            <div className={styles.price}>{`$${order.payAmount}`}</div>
                        </div>
                        {cardList && cardList?.length > 0 && (
                            <div>
                                <p className={styles.cardTitle}>
                            <span
                                className={styles.title}>{t('order.pay.credit.name')}</span> <span
                                    className={styles.total}
                                    onClick={goCardList}>{t('order.pay.credit.total')} {cardList.length}{t('order.pay.credit.item')}</span>
                                </p>
                                {showCardList.map((card, index) => {
                                    return (<CardItem key={index + 1} handleItemClick={() => {
                                        handleSavedCardPay(index + 1, card.id);
                                    }} card={card} checkedIndex={checkedCardIndex} index={index + 1}
                                                      showCheck={true} />);
                                })}
                                {cardList.length > 2 && showCardList.length < cardList.length &&
                                <button className={styles.more} onClick={() => {
                                    showAll();
                                }}>{t('order.pay.credit.more')}</button>}
                            </div>
                        )}
                        {canOnlinePay && (
                            <div className={styles.onlinePayment}>
                                {paymentRequest &&
                                <PaymentRequestButtonElement options={{
                                    ...ELEMENT_OPTIONS,
                                    paymentRequest,
                                }} />
                                }
                                {!checkedCardIndex && <div className={styles.cardWrapper}>
                                    <div className={styles.cardTitle}>{t('order.pay.cardHolder.title')}</div>
                                    <div className={styles.cardHolderInfo}>
                                        <input
                                            value={cardHolderName}
                                            onChange={e => setCardHolderName(e.target.value)}
                                            className={styles.cardHolderInfoInput}
                                            type="text"
                                            placeholder={t('order.pay.cardHolder.name')}
                                        />
                                        <input
                                            value={cardHolderPhone}
                                            onChange={e => setCardHolderPhone(e.target.value)}
                                            className={styles.cardHolderInfoInput}
                                            type="text"
                                            placeholder={t('order.pay.cardHolder.phone')}
                                        />
                                        <input
                                            value={cardHolderEmail}
                                            onChange={e => setCardHolderEmail(e.target.value)}
                                            className={styles.cardHolderInfoInput}
                                            type="email"
                                            placeholder={t('order.pay.cardHolder.email')}
                                        />
                                        <input
                                            value={billingZipcode}
                                            onChange={e => setBillingZipcode(e.target.value)}
                                            className={styles.cardHolderInfoInput}
                                            type="text"
                                            placeholder={t('order.pay.cardHolder.zipcode')}
                                        />
                                    </div>

                                    <div className={styles.cardTitle}>{t('order.pay.cardDetails.title')}</div>
                                    <div className={styles.cardDetail}>
                                        <CardNumberElement
                                            className={styles.cardNumber}
                                            options={{ style }}
                                        />
                                        <div className={styles.secondRow}>
                                            <CardCvcElement
                                                className={styles.cardCvc}
                                                options={{ style }}
                                            />
                                            <CardExpiryElement
                                                className={styles.cardExpiry}
                                                options={{ style }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                }
                                <ThemeDiv
                                    className={`${styles.submitButton}`}
                                    type="primary"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >{t('order.actions.onlinePay')}</ThemeDiv>
                            </div>
                        )}
                        {!canOnlinePay && (
                            <div>
                                <div className={styles.cannotOnlinePay}>
                                    {t('order.pay.error.store_doesnt_support_online_payment')}
                                </div>
                            </div>
                        )}
                        {canOfflinePay ? (
                            <ThemeDiv className={`${styles.submitButton}`} onClick={handleOfflinePayment}>
                                {t('order.actions.offlinePay')}
                            </ThemeDiv>
                        ) : (
                            <div>
                                <div className={styles.cannotOfflinePay}>
                                    {t('order.pay.error.doesnt_support_offline_payment')}
                                </div>
                            </div>
                        )}
                        <Summary
                            className={styles.summary}
                            {...order!}
                            totalCount={totalCount}
                        />
                    </div>
                )}
            </Spin>
        </SubPageLayout>
    );
};

export const PayContainer: React.FC = () => {
    const [payKey, setPayKey] = useState<string>();
    const { orderId } = useParams<{ orderId: string }>();
    const { methods: { getKey } } = useOrderDetail(orderId!);

    useEffect(() => {
        getKey().then((key) => {
            setPayKey(key);
        });
    }, [getKey]);

    const stripePromise = useMemo(() => {
        if (!payKey) return;
        return loadStripe(payKey);
        // return loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
    }, [payKey, getKey]);

    return (
        <>
            {stripePromise && payKey && <Elements stripe={stripePromise} options={{ locale: 'en' }}>
                <Pay />
            </Elements>}
        </>
    );
};
