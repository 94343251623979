import React from 'react';
import styles from './index.module.css';



interface Props {
    title: string;
    children?: React.ReactNode
}

export const Header: React.FC<Props> = ({
    title,
    children,
}) => {
    return (
        <div className={styles.header}>
            {title}
            { children &&
                <div className={styles.actions}>
                    {children}
                    <div className="clear"></div>
                </div>
            }
        </div>
    )
}