import { useState, useEffect, useRef, useCallback } from 'react';

export const useThrottleLoading = (interval: number) => {
    const loadingTag = useRef<boolean>();
    const timerId = useRef<number>();

    const [ _loading, _setLoading ] = useState<boolean>(false);

    useEffect(() => () => {
        timerId.current && window.clearTimeout(timerId.current);
    }, []);

    const setLoading = useCallback((target: boolean) => {
        loadingTag.current = target;
        // 正在跑定时器，证明是loading
        if (timerId.current) return;

        /**
         * 没有timer
         */
        if (target) {
            _setLoading(true);
            timerId.current = window.setTimeout(() => {
                _setLoading(!!loadingTag.current);
                timerId.current = 0;
            }, interval)
        } else if (!loadingTag.current){
            _setLoading(false);
        }
    }, [loadingTag, timerId]);

    return [ _loading, setLoading ] as [boolean, (t: boolean) => void];
}
