import React, { useCallback, useMemo, useEffect, useState } from 'react';
import styles from './index.module.css';
import { ProductInCartList } from '../../components/ProductInCartList';
import { ActivityProductInCartList } from '../../components/ActivityProductInCartList';
import dayjs from 'dayjs';
import { SubPageLayout } from 'layout/SubPageLayout';
import { useProductListInCart } from '../../facades/useProductListInCart';
import { Summary } from '../../components/Summary';
import { CommonButton } from 'components/CommonButton';
import { useCreateOrder, CREATE_ORDER_ERROR_CODE } from '../../facades/useCreateOrder';
import { Spin } from 'components/Spin';
import { useI18n } from 'context/i18n';
import { message } from 'components/Message';
import { useHistory } from 'react-router-dom';
import { useOrderInfo, ASAP_TIME, DELIVERY_TYPE } from 'modules/order-info';
import { OrderBasicInfo } from '../../components/OrderBasicInfo';
import { isInHour, useTheme } from 'modules/store';
import { Input } from 'components/Input';
import { PaymentInformation } from '../../components/PaymentInformation';
import { OrderAmount, Order } from '../../models';
import { Switch } from 'components/Switch';
import { PromoCodeModal } from '../../components/PromoCodeModal';
import { isPickUpOrder } from '../../../../globalUtils';
const { TextArea } = Input;

export const CartPriceInfo: React.FC<{
    loading: boolean,
    orderAmount?: OrderAmount,
    totalCount: number
}> = ({
    loading, orderAmount, totalCount
}) => {
    return (
        <div>
            {loading && <div>loading...</div>}
            {!loading && orderAmount &&
                <Summary {...orderAmount} totalCount={totalCount}/>
            }
        </div>
    )
}

export const Cart: React.FC = () => {
    const { theme: { primary } } = useTheme();
    const { t } = useI18n();
    const { loading, methods } = useCreateOrder();
    const { replace } = useHistory();
    const [ promoCodeModalVisable, setPromoCodeModalVisable ] = useState<boolean>(false);
    const {
        deliveryType,
        receiver,
        pickUpReceiver,
        remark,
        currentStoreName,
        mealTime,
        currentStore,
        methods: { setOrderMealTime, setRemark },
    } = useOrderInfo();
    const {
        productListInCartVO,
        activityProductList,
        loading: orderAmountLoading,
        orderAmount,
        count: totalCount,
        promoCodeData,
        methods: { calculate, setPromoCode, changePromoCodeEnable }
    } = useProductListInCart();
    useEffect(() => {
        calculate();
    }, [calculate])
    
    const order: Partial<Order> = useMemo(() => {
        if (deliveryType === DELIVERY_TYPE.DELIVERY) {
          
            return {
                eatType: deliveryType,
                mealTime: mealTime !== undefined ? `${mealTime}` : mealTime,
                ...receiver,
                receiverAddress: receiver!.detailAddress || ''
            }
        } else {
            return {
                eatType: deliveryType,
                mealTime: mealTime !== undefined ? `${mealTime}` : mealTime,
                ...(pickUpReceiver || {})
            }
        }
    }, [deliveryType, mealTime, receiver, pickUpReceiver]);

    const handleCreate = useCallback(() => {
        if (!mealTime) {
            message.error(t('orderTime.error.selectTime'));
            return;
        }


        if (mealTime === ASAP_TIME && deliveryType === DELIVERY_TYPE.DELIVERY && !isInHour(dayjs().add(30, 'minute').valueOf(), currentStore!.hourData)) {
            message.error(t('orderTime.error.nowOutOfHour'));
            return;
        }

        if (mealTime === ASAP_TIME && isPickUpOrder(deliveryType) && !isInHour(new Date(), currentStore!.rapidPickUpHourData)) {
            message.error(t('orderTime.error.nowOutOfHour'));
            return;
        }

        if (mealTime !== ASAP_TIME && deliveryType === DELIVERY_TYPE.DELIVERY && !isInHour(dayjs(mealTime).subtract(1, 'hour').valueOf(), currentStore!.hourData)) {
            message.error(t('orderTime.error.selectedTimeOutOfHour'));
            return;
        }

        if (mealTime !== ASAP_TIME && isPickUpOrder(deliveryType) && !isInHour(mealTime, currentStore!.rapidPickUpHourData)) {
            message.error(t('orderTime.error.selectedTimeOutOfHour'));
            return;
        }
        

        if (mealTime !== ASAP_TIME && mealTime <= Date.now()) {
            message.error(t('orderTime.error.selectedTimeExpire'));
            return;
        }
        
        methods.createOrder().then(id => {
            replace(`/pay/${id}`);
        }).catch(({ data }) => {
            if (data?.code === CREATE_ORDER_ERROR_CODE.FAIL) {
                calculate();
                return;
            }
            if (data?.code === CREATE_ORDER_ERROR_CODE.FLASH_SALE_TIME_OUT) {
                const activityProductNamesText = activityProductList.map(item => item.activityProduct.product.name).join(',');
                message.error(t(`order.${data.msg}`, { nameText: activityProductNamesText }));
                return;
            }
            if (data?.code === CREATE_ORDER_ERROR_CODE.MEAL_TIME_INVALID) {
                message.error(t('orderTime.error.selectedTimeOutOfHour'));
                return;
            }
            data && data.msg && message.error(t(`order.${data.msg}`, data.data || {}));
        })
    }, [methods.createOrder, mealTime, activityProductList])

    const inputPromoCode = () => {
        setPromoCodeModalVisable(true);
    }

    return (
        <SubPageLayout>
            <Spin spinning={loading}>
                <div className={styles.cart}>
                    <div className={styles.header}>{t('cart.detail')} {currentStoreName}</div>
                    <div className={styles.listWrapper}>
                        <OrderBasicInfo store={currentStore} order={order} onTimeEdit={time => setOrderMealTime({ time })}/>
                    </div>

                    <div className={`${styles.listWrapper} ${styles.border}`}>
                        <ProductInCartList productList={productListInCartVO} showDiscountPlaceholder={!mealTime}/>
                        <ActivityProductInCartList activityProductList={activityProductList}/>
                        <CartPriceInfo loading={orderAmountLoading} orderAmount={orderAmount} totalCount={totalCount}/>
                    </div>

                    <div className={`${styles.promoCode} ${styles.border}`}  onClick={inputPromoCode}>
                        <div className={styles.promoCodeHeader}>
                            <div className={styles.promoCodeTitle}>{t('coupon.promoCode.name')}</div>
                            {promoCodeData.code && <div className={styles.promoCodeValue} onClick={inputPromoCode}>{promoCodeData.code}</div>}
                            {promoCodeData.code &&
                                <Switch
                                    checked={promoCodeData.enable}
                                    onChange={enable => changePromoCodeEnable(enable)}
                                    onClick={(_, event) => event.stopPropagation()}
                                />
                            }
                        </div>
                        {!mealTime && promoCodeData.enable && promoCodeData.code &&
                            <div className={styles.chooseTimeToViewPromo}>{t('coupon.promoCode.placeholder.chooseTimeToView')}</div>
                        }
                        
                    </div>

                    <div className={styles.remark}>
                        <TextArea
                            style={{borderRadius: '22px', padding: '18px 38px'}}
                            placeholder={t('statements.placeholder.remark')}
                            value={remark}
                            onChange={e => setRemark(e.target.value)}
                            rows={3}
                        />
                    </div>

                    <div className={styles.footer}>
                        <PaymentInformation className={styles.paymentInformation} store={currentStore!}/>
                        <div className={styles.buttonWrapper}>
                            <div className={styles.blank}></div>
                            <CommonButton
                                style={{background: primary.bg, color: primary.color}}
                                className={styles.button}
                                height='54px'
                                onClick={handleCreate}
                            >{t('statements.next')}</CommonButton>
                        </div>
                    </div>
                </div>
                <PromoCodeModal
                    visable={promoCodeModalVisable}
                    onCancel={() => setPromoCodeModalVisable(false)}
                    onConfirm={setPromoCode}
                />
            </Spin>
        </SubPageLayout>
    );
}