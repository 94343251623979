import React, { useCallback, useMemo, CSSProperties } from 'react';

import styles from './index.module.css';
import { ThemeDiv } from 'modules/store';

interface Props {
    className: string;
    children: React.ReactNode;
    count?: number;
    onClick: () => void;
    height?: string;
    loading?: boolean;
    style?: CSSProperties;
    disabled?: boolean;
}

export const CommonButton: React.FC<Props> = ({
    className,
    children,
    count = 0,
    onClick,
    height,
    loading = false,
    style = {},
    disabled = false,
}) => {
    const innerStyle: CSSProperties = useMemo(() => {
        let s: CSSProperties = height ? { height, lineHeight: height } : {};
        s = {...s, ...style};
        if (disabled) {
            s = { ...s, background: '#999', color: 'black' };
        }
        return s;
    }, [height, style, disabled]);

    const countMax = useMemo(() => {
        return count > 99;
    }, [count]);

    const handleClick = useCallback(() => {
        if (loading || disabled) return;
        onClick();
    }, [loading, disabled, onClick]);



    return (
        <div className={` ${className} ${styles.button}`} onClick={handleClick} style={innerStyle}>
            {children}
            {count > 0 && <div className={`${styles.count} ${countMax ? styles.countMax : ''}`}>{countMax ? '...' : count}</div>}
        </div>
    )
}