import { Middleware } from 'redux';
import { actions as fromAuth } from 'modules/auth/store';

import firebase from 'firebase';
import { getFirebaseAppAnalytics } from 'analytics/get-firebase-app';
export const AnalyticsLogger: Middleware = api => next => action => {
    const firebaseApp = firebase.apps[0];
    if (firebaseApp) {
        if (action.type === fromAuth.getCurrentUserSuccess.type) {
            getFirebaseAppAnalytics()?.logEvent('login', {});
        }
    }

    return next(action);
};