import { useCallback } from 'react';
import {useI18n} from 'context/i18n';
import { DiscountVO, DiscountReference, CYCLE_TYPE } from '../models';
export const useDiscountName = () => {
    const { t } = useI18n();
    const getName = useCallback((discount: DiscountVO | DiscountReference) => {
        if (discount.cycleType === CYCLE_TYPE.ALWAYS) {
            return t('activity.time.full');
        }
        if (discount.cycleType === CYCLE_TYPE.DAY_IN_WEEK) {
            return discount.cycleData.map(data => t(`dayInWeek.${data}`)).join(',');
        }
        return ''
    }, [t]);
    return { methods: { getName } }
}