import React, { useMemo, CSSProperties, HTMLAttributes,forwardRef } from 'react';
import { useTheme } from '../../context/theme';

export const ThemeDiv = forwardRef((props:{type?: 'default' | 'primary' | 'secondary',ref?:any,} & HTMLAttributes<HTMLDivElement>,ref:any)=>{
  
        const {type = 'default', style = {}, ...restProps} = props;
        const {theme} = useTheme();
        const currentTypeTheme = useMemo(() => {
            if (type === 'secondary') return theme.secondary;
            return theme.primary;
        }, [type, theme]);
    
        const currentStyle: CSSProperties = useMemo(() => {
            if (type === 'default') {
                return {
                    backgroundColor: '#fff',
                    border: `1px solid ${currentTypeTheme.bg}`,
                    color: currentTypeTheme.bg
                }
            }
            const { color, bg: backgroundColor } = currentTypeTheme
            return {
                backgroundColor,
                color,
            }
        }, [type, currentTypeTheme]);
        return (
            <div style={{...currentStyle, ...style}}  {...restProps} ref={ ref }>{props.children}</div>
        )
    })
    
