
import { ajaxGet, ajaxPost, ajaxPut, SuccessResponse, ListResponse, ItemResponse } from 'api';
import { DELIVERY_TYPE, ASAP_TIME } from 'modules/order-info';
import { ReceiverInfo } from 'modules/me';
import { Taste, Product, SUB_TASTE_RADIO, Category, MODEL_TYPE as PRODUCT_MODEL_TYPE } from 'modules/product-menu';
import {
    OrderAmount,
    Order,
    OrderReference,
    ProductReference,
    TasteReference,
    ActivityProductInOrder,
    ProductInOrder,
    ORDER_PAY_METHOD,
    OrderActivityProduct,
    COUPON_TYPE,
    IS_COUPON_OPTIMAL
} from '../models';
import { Store, MODEL_TYPE as STORE_MODEL_TYPE } from 'modules/store';
import { Language } from 'context/i18n';
import { DiscountReference, ActivityReference, productReferencesToProduct } from 'modules/product-menu';
import {
    ActivityProductResponseDTO,
    ActivityProductVO,
    ACTIVITY_TYPE,
    KillActivityReference,
} from 'modules/product-menu/models';
import { uniq } from 'ramda';
import { store as reduxStore } from 'store';
import { selectors } from 'modules/store/store';

export interface OrderGood {
    code: Product['code'],
    buyNum: number;
    taste: Array<{
        code: Taste['code'];
        selected: SUB_TASTE_RADIO;
    }>;
    remark?: string;
}

export interface CalculateAmountParams {
    storeId: string;
    productList: OrderGood[];
    activityProductList: OrderActivityProduct[];
    eatType: DELIVERY_TYPE
    receiverId?: ReceiverInfo['id'];
    mealTime: number | '0';
    promoCode: string;
}

export type CalculateAmountResponse = OrderAmount & {
    productList: Array<{
        id: string;
        buyNum: number;
        code: string;
        discountIds: string;
        salePrice: string;
    }>
}
interface ProductInCalculateAmountResult {
    id: string;
    buyNum: number;
    code: string;
    discountIds: string;
    discounts: Array<DiscountReference & { activity: ActivityReference }>
    salePrice: string;
}

export type CalculateAmountResult = OrderAmount & {
    productList: ProductInCalculateAmountResult[];
    activityProductVOList: ActivityProductVO[];
    couponList: CouponInCalculate[]
}
interface CouponInCalculate {
    promoCode: string;
    couponType: COUPON_TYPE.PROMO_CODE;
    type: PRODUCT_MODEL_TYPE.COUPON;
    isOptimal: IS_COUPON_OPTIMAL;
    message: string;
}

type CalculateAmountRefs = DiscountReference
    | ActivityReference
    | ActivityProductResponseDTO
    | ProductReference
    | TasteReference
    | Category
    | CouponInCalculate

export const calculateAmount = (lang: Language, params: CalculateAmountParams) => {
    const url = `/repast/api/v2/order/money`;
    const productList = params.productList.map(product => ({ ...product, taste: JSON.stringify(product.taste) }));
    const activityProductList = params.activityProductList.map(product => ({ ...product, taste: JSON.stringify(product.taste) }));

    return ajaxPost<SuccessResponse<ItemResponse<CalculateAmountResponse, CalculateAmountRefs>>>(url, {
        ...params,
        productList: JSON.stringify(productList),
        activityProductList: JSON.stringify(activityProductList),
    }, { 'Accept-Language': lang }).then(({ data }) => {
        if (data.code !== 200) return Promise.reject(data);

        const { value, references } = data.data;
        const discountRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.DISCOUNT) as DiscountReference[];
        const activityRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.ACTIVITY) as ActivityReference[];
        const couponInCalculateRef = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.COUPON) as CouponInCalculate[];

        const productList = value.productList.map(product => {
            const discountIds = product.discountIds
                ? product.discountIds.split(',')
                : [];
            const discounts = discountIds.map(id => {
                const discount = discountRefs.find(ref => ref.id === id);
                if (!discount) throw new Error(`没有对应的discount ref, id: ${id}`);
                const activity = activityRefs.find(ref => ref.code === discount.activityCode);
                if (!activity) throw new Error(`没有对应的activity ref, code: ${discount.activityCode}`);
                const ret: DiscountReference & { activity: ActivityReference } = { ...discount, activity };
                return ret;
            })
            const ret: ProductInCalculateAmountResult = { ...product, discounts };
            return ret;
        })
        const activityProductIds = uniq(params.activityProductList.map(activityProduct => {
            return activityProduct.activityProductId;
        }));
        const activityProductVOList = activityProductIds.map(activityProductId => {
            const activityProductDTO = references.find(ref => ref.type === PRODUCT_MODEL_TYPE.ACTIVITY_PRODUCT && ref.id === activityProductId)! as ActivityProductResponseDTO;
            const productRef = references.find(ref => ref.type === PRODUCT_MODEL_TYPE.PRODUCT && ref.code === activityProductDTO.productCode)! as ProductReference;
            const activityRef = activityRefs.find(ref => ref.activityType === ACTIVITY_TYPE.KILL && ref.code === activityProductDTO.activityCode) as KillActivityReference;
            if (!activityRef) {
                console.error("NO activityRef activity code: ", activityProductDTO.activityCode);
            }
            const [product] = productReferencesToProduct([productRef], references as Array<TasteReference | DiscountReference | ActivityReference>);
            const activityProductVO: ActivityProductVO = {
                ...activityProductDTO,
                product,
                activity: activityRef || {}
            };
            return activityProductVO;

            
        });
        const calculateAmountResult: CalculateAmountResult = { ...value, productList, activityProductVOList, couponList: couponInCalculateRef };
        return calculateAmountResult;
    });
}
export interface CreateOrderParams {
    storeId: string;
    productList: OrderGood[];
    activityProductList: OrderActivityProduct[];
    eatType: DELIVERY_TYPE,
    productType: '0',
    mealTime: string;
    receiverId?: ReceiverInfo['id'];
    receiverMobile?: string;
    receiverName?: string;
    remark: string;
    promoCode: string;
}

export const createOrder = (order: CreateOrderParams, language: Language): Promise<string> => {
    const url = `/repast/api/v2/order/v1`;
    const productList = order.productList.map(product => ({ ...product, taste: JSON.stringify(product.taste) }));
    const activityProductList = order.activityProductList.map(product => ({ ...product, taste: JSON.stringify(product.taste) }));
    return ajaxPost<SuccessResponse<ItemResponse<OrderReference, Category | ProductReference | TasteReference | Store>>>(url, {
        ...order,
        productList: JSON.stringify(productList),
        activityProductList: JSON.stringify(activityProductList),
    }, { 'Accept-Language': language }).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
        const { value: { id } } = data.data.data;
        return id;
    });
}




// 获取订单列表
export const getOrderList = (language: Language, brandCode: string): Promise<SuccessResponse<Order[]>> => {
    let url = `/repast/api/v2/order/getUserOrder`;
    return ajaxGet<SuccessResponse<ListResponse<OrderReference, Category | ProductReference | TasteReference | Store | ActivityReference | DiscountReference | ActivityProductResponseDTO>>>(url, {
        pageNum: 1,
        pageSize: 999,
        language,
        brandCode,
    }).then(({ data }) => {
        if (data.code !== 200) return Promise.reject(data);
        const { value, references } = data.data;
        return { ...data, data: orderReferencesToOrders(value, references) };
    });
}

// : Promise<SuccessResponse<Order>> 
export const getOrderOfId = (
    language: Language,
    orderId: Order['id']
)=> {
    const url = `/repast/api/v2/order/${orderId}`;
    return ajaxGet<SuccessResponse<ItemResponse<OrderReference, Category | ProductReference | TasteReference | ActivityReference | DiscountReference | ActivityProductResponseDTO>>>(url, { language })
        .then(({ data }) => {
            if (data.code !== 200) return Promise.reject(data);
            const { value, references } = data.data;
            return { ...data, data: orderReferencesToOrders([value], references)[0] };
        })
}


export const orderReferencesToOrders = (
    orderRefs: OrderReference[],
    references: Array<Category | ProductReference | TasteReference | Store | DiscountReference | ActivityReference | ActivityProductResponseDTO>
): Order[] => {
    const tasteRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.TASTE) as TasteReference[];
    const discountRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.DISCOUNT) as DiscountReference[];
    const activityRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.ACTIVITY) as ActivityReference[];
    const activityProductRefs = references.filter(ref => ref.type === PRODUCT_MODEL_TYPE.ACTIVITY_PRODUCT) as ActivityProductResponseDTO[];

    const orderList: Order[] = orderRefs.map(item => {

        const productList = item.productList.filter(item => !item.activityProductId);
        const activityProductList  = item.productList.filter(item => item.activityProductId);

        const productListVO: ProductInOrder[] = productList.map(productInOrderRes => {

            const productRef = references.find(
                ref => ref.type === PRODUCT_MODEL_TYPE.PRODUCT && ref.code === productInOrderRes.code
            )! as ProductReference;
            

            if (!productRef) console.error('No productRef orderId: ', item.id, 'productCode:', productInOrderRes.code);

            const parsedTasteList = productInOrderRes.taste
                ? JSON.parse(productInOrderRes.taste) as Array<{ code: Taste['code'], selected: SUB_TASTE_RADIO }>
                : [];

            const tasteList = parsedTasteList.map(({ code, selected }) => {
                const tasteRef = tasteRefs.find(ref => ref.code === code);
                if (!tasteRef) {
                    console.log("null taste code", code)
                }
                return { name: tasteRef!.name, code, selected };
            });

            let images = productRef.images.map(imgObj => ({...imgObj, url: `/repast/${imgObj.url}`}));
            if (images.length === 0) images = [{
                id: '-1',
                url: `/repast/images/${selectors.getCurrentBrand(reduxStore.getState())!.abbreviation}/product_default_image_1.png`,
            }]
            const product: ProductInOrder['product'] = {
                ...productRef,
                images
            };
            const discountIds = productInOrderRes.discountIds ? productInOrderRes.discountIds.split(',') : [];

            const discounts: ProductInOrder['discounts'] = discountIds.map(id => {
                const discount = discountRefs.find(ref => ref.id === id);
                if (!discount) throw new Error(`没有对应的discount ref, id: ${id}`);
                const activity = activityRefs.find(ref => ref.code === discount.activityCode);
                if (!activity) throw new Error(`没有对应的activity ref, code: ${discount.activityCode}`);
                const ret: DiscountReference & { activity: ActivityReference } = { ...discount, activity };
                return ret;
            })
            return {
                code: productRef.code,
                product,
                buyNum: productInOrderRes.buyNum,
                taste: tasteList,
                remark: productInOrderRes.remark,
                salePrice: productInOrderRes.salePrice,
                discounts,
            }
        })

        const activityProductListVO: ActivityProductInOrder[] = activityProductList.map(activityProductInOrderRes => {

            const activityProductRef = activityProductRefs.find(ref => ref.id === activityProductInOrderRes.activityProductId)!;
            console.log("activityProductRef =>", activityProductRef);
            if (!activityProductRef) {
                console.error("缺少activityProductRef, activityProductId:", activityProductInOrderRes.activityProductId, "orderId: ", item.id);
            }
            const productRef = references.find(
                ref => ref.type === PRODUCT_MODEL_TYPE.PRODUCT && ref.code === activityProductRef.productCode
            )! as ProductReference;
            if (!productRef) console.error('No productRef orderId: ', item.id, 'productCode:', activityProductRef.productCode);
            
            const parsedTasteList = activityProductInOrderRes.taste
                ? JSON.parse(activityProductInOrderRes.taste) as Array<{ code: Taste['code'], selected: SUB_TASTE_RADIO }>
                : [];

            const tasteList = parsedTasteList.map(({ code, selected }) => {
                const tasteRef = tasteRefs.find(ref => ref.code === code);
                if (!tasteRef) throw new Error(`Taste code ${code}, productCode: ${activityProductRef.productCode}`);
                return { name: tasteRef!.name, code, selected };
            });

            let images = productRef.images.map(imgObj => ({...imgObj, url: `/repast/${imgObj.url}`}));
            if (images.length === 0) images = [{
                id: '-1',
                url: `/repast/images/${selectors.getCurrentBrand(reduxStore.getState())!.abbreviation}/product_default_image_1.png`,
            }]

            const product: any = {
                ...productRef,
                images
            };
            const activityRef = activityRefs.find(ref => ref.activityType === ACTIVITY_TYPE.KILL && ref.code === activityProductRef.activityCode)! as KillActivityReference;
            const activityProductVO: ActivityProductVO = { ...activityProductRef, product, activity: activityRef };
            if (!activityRef) {
                console.error('没有 activityRef, activityRef code: ', activityProductVO.activityCode, 'orderId: ', item.id);
            }
            return {
                activityProduct: activityProductVO,
                buyNum: activityProductInOrderRes.buyNum,
                taste: tasteList,
                remark: activityProductInOrderRes.remark,
                salePrice: activityProductInOrderRes.salePrice
            }
        });

        const store = (references.find(ref => ref.type === STORE_MODEL_TYPE.STORE && item.storeId === ref.storeID)! as Store);
        // const mealTime = item.mealTime === ASAP_TIME ? item.mealTime : Number(item.mealTime);
        return { ...item, store, storeName: store.name, productList: productListVO, activityProductList: activityProductListVO };
    })
    return orderList;
}



// 转为线下支付
export const changeToOfflinePayMethod = (orderId: Order['id']) => {
    const url = `/repast/api/v2/order/${orderId}/payMethod`;
    return ajaxPut<SuccessResponse<void>>(url, { payMethod: ORDER_PAY_METHOD.OFFLINE })
        .then(({ data }) => data);
}



export const payIntent = (paymentMethodId: string, orderId: string, email: string) => {
    const url = `/repast/api/v2/pay/intent`;
    return ajaxPost<SuccessResponse<any>>(url, {paymentMethodId, orderId, email});
}

export const getOnlinePayKey = (orderId:Order['id'])=>{
    return ajaxGet<SuccessResponse<any>>(`/repast/api/v2/order/${orderId}/price`).then(({data})=>{
        if(data.code!== 200)return Promise.reject(data);
        return data;
    })
}

export const createPaymentIntent = (params:any) => {
    return ajaxPost<SuccessResponse<any>>(`/repast/api/v2/pay/createIntent`,params).then(({data})=>{
            if(data.code!== 200)return Promise.reject(data);
            return data
    })
}

export const getStripeCards = () => {
    return ajaxGet<SuccessResponse<any>>(`/repast/api/v2/pay/stripe/cards`).then(({data})=>{
           if(data.code !== 200) return Promise.reject(data);
           return data;
    })
}

export const getPayStatus = (orderId:string,email:string) =>{
    return ajaxPost<SuccessResponse<any>>(`/repast/api/v2/pay/stripe/confirm`,{orderId,email}).then(({data})=>{
        if(data.code !== 200) return Promise.reject(data);
        return data;
    })
}