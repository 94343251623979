import dayjs from 'dayjs';
import { Store } from './models'


const NEAR_MINUTE = 30;

// 是不是营业时间以内
export const isInHour = (date: Date | dayjs.Dayjs | number, storeHourData: Store['hourData']) => {
    const day = dayjs(date).day();
    const hourData = storeHourData[day];
    const currentHour = dayjs(date).hour();
    const currentMinute = dayjs(date).minute();
    return hourData.reduce<boolean>((acc, curr) => {
        if (acc) return acc;
        const { start, end } = curr;
        const isMoreThanStart = currentHour > start.hour || (currentHour === start.hour && currentMinute >= start.minute);
        const isLessThanEnd = currentHour < end.hour || (currentHour === end.hour && currentMinute <= end.minute);
        return isMoreThanStart && isLessThanEnd;
    }, false);
}

// 是不是快要到点了 30分钟
export const isNearClose = (date: Date | dayjs.Dayjs | number, storeHourData: Store['hourData']) => {
    const day = dayjs(date).day();
    const hourData = storeHourData[day];
    const currentHour = dayjs(date).hour();
    const currentMinute = dayjs(date).minute();
    return hourData.reduce<boolean>((acc, curr) => {
        if (acc) return acc;
        const { end } = curr;
        const diff = (end.hour * 60 + end.minute) - (currentHour * 60 + currentMinute)
        return diff > 0 && diff < NEAR_MINUTE;
    }, false);
}

