import React from 'react';
import styles from './index.module.css';
import { BigBackgroundImage } from 'components/BigBackgroundImage';

interface Props {
    backgroundSrc?: string;
    children: React.ReactNode
}

export const SubPageLayout: React.FC<Props> = ({
    backgroundSrc,
    children
}) => {
    return (
        <div className={styles.subPageLayout}>
            { backgroundSrc && <BigBackgroundImage src={backgroundSrc} /> }
            <div className={styles.container}>
                {children}
            </div>
        </div>
    );
}
