import { useMemo, useCallback } from 'react';
import { Order, ORDER_PAY_METHOD } from '../models';
import { useI18n } from 'context/i18n';
export const getOrderStatusText = (t: (key: string) => string, status: string) => {
    return status.split(',').reduce<string[]>((acc, cur) => {
        return [...acc, t(`order.status.${cur}`)];
    }, []).join(',')
}

export const useOrderStatus = (order: Partial<Order>) => {
    const { t } = useI18n();
    return useMemo(() => {
        const { status, payMethod } = order;
        if (!status) return '';
        const orderStatus = getOrderStatusText(t, status);
        const payMethodText = (order.payMethod === ORDER_PAY_METHOD.NULL || !order.payMethod)
            ? ''
            : ` (${t(`order.payMethod.${order.payMethod}`)})`;

        return `${orderStatus}${payMethodText}`;
        
    }, [order]);
}
