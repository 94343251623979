import { DELIVERY_TYPE } from '../models';
import { getActionTypeFn } from 'store/utils';
import { ReceiverInfo, PickUpReceiverInfo } from 'modules/me';

export const FEATURE_NAME = 'Order Info';
export const getActionType = getActionTypeFn(FEATURE_NAME);

export interface DataOfUserId {
    deliveryType: DELIVERY_TYPE,
    receiverInfo?: ReceiverInfo,
    pickUpReceiverInfo?: PickUpReceiverInfo
    mealTime?: number | '0',
    remark: string,
}

export const initialDataOfUserId: DataOfUserId = {
    deliveryType: DELIVERY_TYPE.RAPID_PICK_UP,
    // mealTime: '0',
    remark: ''
};