import { useEffect, useCallback } from 'react';
import { updateReceiverInfo, getReceiverInfoOfId } from '../api';
import { ReceiverInfo } from '../models';
import { useMountedState } from 'react-use';
import { useRequestState } from 'hooks/useRequestState';
import { ReceiverInfoFormProps } from '../components/AddressForm';


export const useReceiverInfoEdit = (receiverId: string) => {
    const [ state, setState ] = useRequestState<ReceiverInfoFormProps>()
    const mounted = useMountedState();
    useEffect(() => {
        setState({ loading: true });
        getReceiverInfoOfId(receiverId)
            .then(result => {
                if (mounted()) {
                    setState({ loading: false, value: {
                        ...result,
                        geo: {
                            coordinateDesc: result.coordinateDesc,
                            latitude: result.latitude,
                            longitude: result.longitude
                        }
                    } });
                }
            })
    }, [receiverId, mounted]);
    const submit = useCallback((receiverInfo: ReceiverInfo) => {
        setState({ loading: true });
        return new Promise((resolve, reject) => {
            updateReceiverInfo(receiverInfo)
                .then(ret => mounted() && resolve(ret))
                .catch(err => mounted() && reject(err));
        })
    }, [setState, mounted]);
    return {
        ...state,
        methods: { submit }
    };
}