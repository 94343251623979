import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import styles from './index.module.css';
import { useMountedState } from 'react-use';
import { useI18n } from 'context/i18n';
import * as authService from '../services';
import { message } from 'components/Message';
import { RowWrapper } from '../components/RowWrapper';
import { NextButton } from '../components/NextButton';
import { Mobile, MobileWithCountryCode } from '../components/Mobile';


const errorToast = (text: string) => {
    message.error(text, 2);
}

interface Props {
    onNext: (mobile: string, auth_code: string) => void;
}

const isAllNumber = (str: string): boolean => {
    return /^[0-9]*$/.test(str);
}

export const SmsAuth: React.FC<Props> = ({
    onNext
}) => {
    const { t } = useI18n();
    const refreshCaptchaDisableTimer = useRef<number>();
    const getCodeTimer = useRef<number>();
    const mounted = useMountedState();

    // 手机号
    const [mobileWithCountryCode, setMobileWithCountryCode] = useState<MobileWithCountryCode>(new MobileWithCountryCode());

    const [ captchaUrl, setCaptchaUrl ] = useState("");
    const [ captchaHash, setCaptchaHash ] = useState("");
    // 发送短信验证码倒计时
    const [authCodeBtnCountDown, setAuthCodeBtnCountDown] = useState(0);
    // 短信验证码发送状态
    const [authCodeSending, setAuthCodeSending] = useState<boolean>(false);

    const [captchaCode, setCaptchaCode] = useState("");
    const [code, setCode] = useState("");


    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [refreshCaptchaDisabled, setRefreshCaptchaDisabled] = useState<boolean>(false);

    // 禁用提交按钮，防止重复点击
    const disableRefreshCaptcha = useCallback(() => {
        setRefreshCaptchaDisabled(true);
        refreshCaptchaDisableTimer.current = window.setTimeout(() => {
            mounted() && setRefreshCaptchaDisabled(false);
        }, 2000);
    }, [mounted, setRefreshCaptchaDisabled]);

    const getCaptcha = useCallback(() => {
        if (refreshCaptchaDisabled) return;
        disableRefreshCaptcha();
        authService.getCaptcha().then(({ image_url, hash }) => {
            if (mounted()) {
                setCaptchaUrl(image_url);
                setCaptchaHash(hash);
            }
        })
    }, [refreshCaptchaDisabled, disableRefreshCaptcha, mounted]);
    // 获取图形验证码
    useEffect(() => {
        // 获取图形验证码
        getCaptcha();
    }, []);

    useEffect(() => {
        // 销毁组件时，清空定时器
        return () => {
            if (refreshCaptchaDisableTimer.current) {
                window.clearTimeout(refreshCaptchaDisableTimer.current);
            }
            if (getCodeTimer.current) {
                window.clearTimeout(getCodeTimer.current);
            }
        }
    }, []);

    // 填写了图形验证码
    const isFillCaptchaCode = useMemo(() => {
        return captchaCode.length === 4;
    }, [captchaCode]);

    const isFillMobile = useMemo(() => {
        return !mobileWithCountryCode.isEmptyMobile();
    }, [mobileWithCountryCode]);

    const isFillAuthCode = useMemo(() => {
        return code.length > 0;
    }, [code]);
    // 决定发送短信验证码按钮状态
    const isCanSendAuthCode = useMemo(() => {
        return !authCodeSending
            && isFillCaptchaCode
            && isFillMobile
            && authCodeBtnCountDown === 0;
    }, [authCodeSending, isFillCaptchaCode, isFillMobile, authCodeBtnCountDown])
    const isCanSubmit = useMemo(() => {
        return isFillMobile && isFillCaptchaCode && isFillAuthCode;
    }, [isFillMobile, isFillCaptchaCode, isFillAuthCode])


    // 短信验证码按钮倒计时
    useEffect(() => {
        if (authCodeBtnCountDown > 0) {
            getCodeTimer.current = window.setTimeout(() => {
                setAuthCodeBtnCountDown(authCodeBtnCountDown - 1);
            }, 1000);
        }
    }, [authCodeBtnCountDown, setAuthCodeBtnCountDown])


    const sendAuthCodeSuccess = useCallback((res: any) => {
        message.info(res.data.desc, 1.5);
        setAuthCodeSending(false);
        setAuthCodeBtnCountDown(60);
    }, [setAuthCodeBtnCountDown]);

    const sendAuthCodeFail = useCallback((res: any) => {
        setAuthCodeSending(false);
        if (res.status === 400 || res.status === 403) {
            errorToast(res.data.errors.message);
        } else {
            errorToast(t('当前网络状态不好，请稍后尝试'));
        }
        setAuthCodeBtnCountDown(5);
    }, []);


    const sendAuthCode = useCallback(() => {
        if (!mobileWithCountryCode.isMobileValid()) {
            return;
        }
        setAuthCodeSending(true);
        authService.sendAuthCode({
            mobile: mobileWithCountryCode.getFullMobileText(),
            captcha_hash: captchaHash,
            captcha_code: captchaCode.toLocaleLowerCase()
        }).then(sendAuthCodeSuccess).catch(sendAuthCodeFail);
        // 发送
    }, [mobileWithCountryCode, captchaHash, captchaCode]);

    // 提交
    const submit = useCallback(() => {
        setSubmitLoading(true);
        const mobile = mobileWithCountryCode.getFullMobileText();
        authService.validateAuthCode({
            mobile,
            auth_code: code
        })
        .then(() => {
            if (mounted()) {
                setSubmitLoading(false);
                onNext(mobile, code);
            }
        })
        .catch(res => {
            if (mounted()) {
                var msg = res.status === 400 ? res.data.errors.message : '当前网络状况不佳，请稍后重试';
                errorToast(msg);
            }
        });
    }, [setSubmitLoading, mobileWithCountryCode, code, mounted]);



    return <div>


        <Mobile
            value={mobileWithCountryCode}
            onChange={setMobileWithCountryCode}
        />

        <RowWrapper>
            <input
                type="text"
                className={styles.captchaCodeInput}
                maxLength={4}
                value={captchaCode}
                onChange={event => setCaptchaCode(event.target.value)}
                placeholder={t('auth.placeholder.grahicInput')}
            />
            <img
                className={styles.captchaImg}
                src={captchaUrl}
                onClick={getCaptcha}
            />
        </RowWrapper>

        <RowWrapper>
            <input
                className={styles.authCodeInput}
                maxLength={6}
                value={code}
                onChange={event => setCode(event.target.value)}
                placeholder={t('auth.placeholder.smsCodeInput')}
            />
            <button
                className={styles.authCodeButton}
                onClick={sendAuthCode}
                disabled={!isCanSendAuthCode}
            >
                <>
                    {t("auth.placeholder.getSmsCode")}
                    {authCodeBtnCountDown > 0 &&
                        <span>({authCodeBtnCountDown})</span>
                    }
                </>
            </button>
        </RowWrapper>
        
        <NextButton
            disabled={!isCanSubmit}
            onClick={submit}
        >{t('form.actions.confirm')}</NextButton>
    </div>
}