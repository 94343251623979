import React, { useRef, useState } from 'react';
import { Input } from 'components/Input';
import { GoogleMapModal } from '../MapModal';

export interface GeoFormProps {
    coordinateDesc: string;
    latitude: string;
    longitude: string;
}
interface Props {
    value?: GeoFormProps,
    onChange?: (v: GeoFormProps) => void
}

export const AddressGeoForm: React.FC<Props> = ({
    value = {
        coordinateDesc: '',
        latitude: '',
        longitude: ''
    },
    onChange = () => {}
}) => {

    const [ visable, setVisable ] = useState<boolean>(false)
    const inputRef = useRef<Input>(null);

    const handleFocus = () => {
        setVisable(true);
        inputRef.current!.blur();
    }
    const handleSelect = (v: GeoFormProps) => {
        onChange(v);
        setVisable(false);

    }

    return (
        <>
            <Input value={value!.coordinateDesc} onFocus={handleFocus} ref={inputRef} onChange={() => {}}/>
            <GoogleMapModal
                visable={visable}
                onCancel={() => setVisable(false)}
                onSelect={handleSelect}
            />
        </>
    )
}