
import { country as countryUtils, getStorageMobile } from 'globalUtils';
import { Language } from 'context/i18n';


export class MobileWithCountryCode {
    constructor(private countryCode?: string, private mobile?: string) {    
    }

    public getMobile(): string {
        return this.mobile || '';
    }
    public getCountryCode() {
        return this.countryCode || '';
    }

    public isMobileValid(): boolean {
        return !this.isEmptyMobile() && /^[0-9]*$/.test(this.getMobile());
    }

    public isEmptyMobile() {
        return this.getMobile().length === 0;
    }

    private isEmptyCountryCode() {
        return this.getCountryCode().length === 0;
    }

    // 是否有空的字段
    public isHasEmpty() {
        return this.isEmptyMobile() || this.isEmptyCountryCode()
    }

    public init(defaultCountryCode: string) {
        const countryCode = countryUtils.getStorageCountryCode() || defaultCountryCode;
        countryUtils.setStorageCountryCode(countryCode);
        const mobile = getStorageMobile() || '';
        return new MobileWithCountryCode(countryCode, mobile);
    }

    public getCountryNumber(): string {
        if (!this.countryCode) return '';
        return countryUtils.getCountryNumberOfCode(this.countryCode);
    }

    public getFullMobileText(): string {
        return `+${this.getCountryNumber()}${this.mobile}`;
    }

    public changeMobile(mobile: string) {
        return new MobileWithCountryCode(this.countryCode, mobile);
    }
    public setCountryCode(countryCode: string) {
        return new MobileWithCountryCode(countryCode, this.getMobile());
    }

    public getCountryName(lang: Language): string {

        if (!this.countryCode) return '';

        return countryUtils.getCountryNameOfCode(lang, this.countryCode);
    }
}