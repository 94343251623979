import React, { useState, createRef } from 'react';
import styles from './index.module.css';
import { RECEIVER_INFO_DEFAULT_STATUS } from '../../models';
import { useUrlParams } from 'hooks/useUrlParams';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { ActionButton } from 'components/ActionButton';
import { useReceiverInfoCreate } from '../../facades/useReceiverInfoCreate';
import { AddressForm, RefProps } from '../../components/AddressForm';
import { omit } from 'ramda';
import { Spin } from 'components/Spin';
import { message } from 'components/Message';
import { useI18n } from 'context/i18n';

export const AddressCreate: React.FC = () => {
    const { t } = useI18n();
    const [ loading, setLoading ] = useState<boolean>(false);

    const formRef = createRef<RefProps>();
    const { goBack } = useHistory();
    const { id } = useParams<{ id: string }>();
    const { methods } = useReceiverInfoCreate();


    const handleBack = () => {
        goBack();
    }

    const handleOk = () => {
        if (loading) return;
        formRef.current!.submit((data) => {
            setLoading(true);
            const formData = {
                ...data,
                ...data.geo,
                defaultStatus: RECEIVER_INFO_DEFAULT_STATUS.NO,
            };
            
            methods.submit(omit(['geo'], formData)).then(() => {
                setLoading(false);
                goBack();
            }).catch(({ data }) => {
                setLoading(false);
                data.msg && message.error(t(data.msg))
            })
        })
    }

    return (
        <div>
            <Spin spinning={loading}>
                <Header title={t('receiverInfo.formAction.add')}>
    <ActionButton onClick={handleOk}>{t('form.actions.confirm')}</ActionButton>
    <ActionButton onClick={handleBack}>{t('form.actions.cancel')}</ActionButton>
                </Header>
                <div className={styles.addressFormWrapper}>

                    <AddressForm ref={formRef}/>
                </div>
            </Spin>
        </div>
    )
}