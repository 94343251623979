import Modal from 'antd/es/modal';
import 'antd/es/modal/style/css';
export { Modal };


export const promiseAlert = (translate: (s: string) => string, title: string, content?: string) => new Promise(resolve => {
    content = content || translate('form.alert.isSure');
    Modal.confirm({
        autoFocusButton: 'ok',
        cancelText: translate('form.actions.cancel'),
        okText: translate('form.actions.confirm'),
        onCancel: () => {
            resolve('cancel');
        },
        onOk: () => {
            resolve('ok')
        },
        content,
        title,
    });
})