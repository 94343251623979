import {
    on,
    reducer as createReducer,
} from 'ts-action';
import {
    getCurrentUserSuccess,
    loginSuccess,
    logoutSuccess,
    touristLoginSuccess
} from './actions';
import { FEATURE_NAME } from './constants';
import { User } from '../models';
import { omit, assoc, equals, reject, assocPath } from 'ramda';


export interface State {
    current?: User,
    accessToken: string,
    jwt: string,
}

export interface GlobalState {
    [FEATURE_NAME]: State;
};

export const initialState: State = {
    accessToken: window.localStorage.getItem('access_token') || '',
    jwt: window.localStorage.getItem('jwt') || ''
};

export const reducer = createReducer(
    initialState,
    on(
        getCurrentUserSuccess,
        (state, { payload: currentUser }) => assoc('current', currentUser, state)
    ),
    on(
        loginSuccess,
        (state, { payload: { accessToken } }) => assoc('accessToken', accessToken, state)
    ),
    on(
        touristLoginSuccess,
        (state, { payload: { jwt } }) => assoc('jwt', jwt, state)
    ),
    on(
        logoutSuccess,
        () => ({
            ...initialState,
            accessToken: '',
            jwt: ''
        })
    )
);
