import { useHistory, useLocation } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { equals } from 'ramda';
import qs from 'querystring';

export const useUrlParams = <T extends {[key: string]: string}>({
    defaultValues
}: {
    defaultValues: T
}) => {

    const { push } = useHistory();
    const location = useLocation();

    const params = useMemo(() => {
        const locationSearch = qs.parse(location.search.replace('?', '')) as any as Partial<T>;
        return {...defaultValues, ...locationSearch} as T;
    }, [location.search, defaultValues]);


    const changeParams = useCallback((p: Partial<T>) => {
        const newParamsMap: T = {...params, ...p};
        
        if (equals(newParamsMap, params)) return; // 如果参数没有变化，不更改url

        // 过滤掉空字符串
        const paramsWithoutNull = Object.keys(newParamsMap).reduce<Partial<T>>((acc, key) => {
            if (newParamsMap[key] !== '') {
                return { ...acc, [key]: newParamsMap[key] }
            }
            return acc;
        }, {});
        push({search: `?${qs.stringify(paramsWithoutNull)}`})
    }, [params]);

    return {
        params,
        changeParams
    }
}


