
import { getStripeCards } from 'modules/buy/api';
import React, { useCallback ,useEffect,useState } from 'react';
import { useUnmountPromise } from 'react-use';
import { Card } from 'modules/buy/models';



export const usePayCardList = () =>{
    const [ cardList,setCardList] = useState<Array<Card>>([]);
    const [ loading ,setLoading] = useState<boolean>(false);

    const getPayCardList = useCallback(()=>{
        setLoading(true)
           return getStripeCards().then(({data:{data}})=>{
               setLoading(false);
                setCardList(data);
           }).catch((err)=>{
               setLoading(false);
           })
    },[])

    useEffect(()=>{
        getPayCardList();
    },[getPayCardList])

    return {
        loading,
       cardList
    }
}