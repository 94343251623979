import firebase from 'firebase';

let isAnalytics = false;

export const getFirebaseAppAnalytics = () => {
    console.log("isAnalytics =>", isAnalytics);
    return (firebase.apps[0] && isAnalytics) ? firebase.apps[0].analytics() : undefined;
}

export const setAnalytics = (is: boolean) => {
    isAnalytics = is;
}