import React, { useEffect, useMemo } from 'react';

import styles from './index.module.css';
import { useProductListInCart } from '../../facades/useProductListInCart';
import { ProductInCartList } from '../ProductInCartList';
import { ActivityProductInCartList } from '../ActivityProductInCartList';
import { useI18n } from 'context/i18n';
import { useOrderInfo } from 'modules/order-info';


interface Props {
    className: string;
}

export const CartMenu: React.FC<Props> = ({
    className,
}) => {
    const { t } = useI18n();
    const { count, subTotalPrice, productListInCartVO, activityProductList, methods: { calculate } } = useProductListInCart();
    const { mealTime } = useOrderInfo();
    useEffect(() => {
        calculate();
    }, [calculate])
    return (
        <div className={`${styles.cartMenu} ${className}`} >
            {count === 0 && <div className={styles.empty}>{t('order.noYet')}</div>}
            {count > 0 &&
                <div className={styles.list}>
                    <ProductInCartList
                        productList={productListInCartVO}
                        showDiscountPlaceholder={!mealTime}
                    />
                    <ActivityProductInCartList activityProductList={activityProductList}/>
                </div>
            }
            {count > 0 &&
                <div className={styles.priceInfo}>
                    <div className={styles.subTotalLabel}>{t('statements.summary.subtotal')}</div>
                    <div className={styles.subTotal}>${subTotalPrice.toFixed(2)}</div>
                </div>
            }
        </div>
    )
}
