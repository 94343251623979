import { Lang } from './Lang';
export const en: Lang = {

    auth: {
        signIn: 'Sign In',
        signUp: 'Sign Up',
        touristSignIn: 'Browse as Guest',
        logOut: 'Log out', 
        gotoSignIn: 'Goto Sign In',
        gotoSignUp: 'Goto Sign Up',
        country: 'Country',
        smsCodeSending: 'Sending',
        messages: {
            checkPasswordEqual: 'The retyped password does not match. Please reset.',
            mobileIsExists: 'This phone number has been registered.'
        },
        placeholder: {
            mobile: 'Mobile number',
            password: 'Please enter your password',
            setPassword: 'Please enter your password',
            setConfirmPassword: 'Please enter your password again',
            grahicInput: 'Case insensitive',
            getSmsCode: 'Obtain Code',
            smsCodeInput: 'SMS code',
        }
    },

    form: {
        actions: {
            delete: 'Delete',
            add: 'Add',
            new: 'New',
            detail: 'Detail',
            edit: 'Edit',
            save: 'Save',
            submit: 'Submit',
            cancel: 'Cancel',
            change: 'Change',
            clear: 'Clear',
            upload: 'Upload',
            update: 'Update',
            addLocalization: 'Add a localization',
            search: 'Search',
            confirm: 'Confirm',
            back:'Back',
            confirmAndNext:"Confirm and Next"
        },
        rules: {
            maxLength: 'Max length is ${{length}}',
            ltSize: 'Image must smaller than ${{size}}',
            noLocalization: 'Please add at least one localization.',
            makeSure: 'Please make sure you entered the information correctly.'
        },
        messages: {
            success: '${{action}} Successful',
            fail: '${{action}} failed',
        },
        alert: {
            isSure: 'Are you sure?'
        }
    },
    home: {
        title: 'EASTERLY',
        orderNow: 'Order Now',
        newOrder: 'New Order',
        recorder: 'Reorder',
        changeLanguage: 'Language',
        menu:'Menu',
        homePage:'Home',
        messages: {
            switchStoreWillClearCart: 'If you switch store, the current cart will be emptied.'
        }

    },
    orderType: {
        0: 'Rapid Pick-Up',
        1: 'Delivery',
        2: 'Contactless Pick-Up',
    },
    productType: {
        0: 'Normal Meal',
        1: 'Five-Day Meal',
        fiveDayDescription: 'Five-Day Meal (Need to order 1 day in advance. The cutoff time is 5pm. Can pick up or deliver the next day. If order after 5pm, can pick up or deliver the day after tomorrow.)',
        alert: {
            crossToFiveDayMeal: 'You\'ve selected items not in "Five-Day Meal" category. If you switch to "Five-Day Meal", the current cart will be emptied.',
            crossFromFiveDayMeal: 'You\'ve selected items in "Five-Day Meal" category. If you switch to non-"Five-Day Meal", the current cart will be emptied.',
        }
    },
    orderTime: {
        asap: {
            delivery: 'ASAP',
            pickUp: 'ASAP',
            laterly:'Laterly'
        },
        later: 'Select Date & Time',
        businessHour: 'Business Hour',
        error: {
            selectTime: 'Please select time',
            nowOutOfHour: 'Out of business hours, reservations only',
            selectedTimeOutOfHour: 'The selected time is out of business hours',
            selectedTimeExpire: 'The selected time has expired',
            nowNearClose: 'Please order or make a reservation as soon as possible'
        }
    },
    ourMenu: {
        title: 'CHEF SPECIAL',
        category: 'CATEGORY'
    },
    menuInCategory: {
        searchPlaceholder: 'Search',
        addToOrder: 'Add To Order',
    },
    foodDetail: {
        ingredients: 'Ingredients',
        customize: 'Customize',
        addToOrder: 'Add To Order',
        update: 'Update',
        next: 'Next',
        addToCart:"Add To Cart"
    },
    taste: {
        child: {
            radio: {
                0: "None",
                1: "Very Light",
                2: "Light",
                3: "Normal",
                4: "Extra",
                5: "Super Extra"
            }
        }
    },
    cart: {
        header: {
            title: 'Cart',
            keepOrdering: 'Keep Ordering'
        },
        next: 'Calculate',
        detail:'Cart Detail'
    },
    statements: {

        header: {
            title: 'YOUR ORDER',
        },
        error: {
            enterMobile: 'Please enter mobile'
        },
        placeholder: {
            mobile: "Please enter mobile",
            remark: "Please enter note",
        },
        remark: 'Note',
        addRemark: 'Add Note',
        summary: {
            title: 'Summary',
            subtotal: 'Subtotal',
            discount: 'Discounts',
            taxes: 'Taxes',
            total: 'Total',
            addPromoCode: 'Add Promo Code',
            postage: 'Delivery'
        },
        messages: {
            submitTo: 'This order will be submitted to ${{name}}'
        },
        mobile: 'Mobile',
        next: 'Submit Order'
    },
    order: {
        shortStatus: {
            all: 'All',
            5: 'Awaiting',
            10: 'Unpaid',
            11: 'Paid',
            12: 'Paying',
            20: 'Preparing',
            21: 'Ready',
            22: 'Moved to Front Desk',
            30: 'Completed',
            98: 'Invalid',
        },
        status: {
            5: 'Awaiting Confirm',
            10: 'Awaiting Payment',
            11: 'Paid',
            12: 'Paying',
            20: 'Preparing',
            21: 'Ready',
            22: 'Moved to Front Desk',
            30: 'Completed',
            98: 'Invalid',
        },
        actions: {
            cancel: 'Cancel Order',
            delete: 'Delete Order',
            pay: 'Pay',
            confirm: 'Confirm Receipt',
            offlinePay: 'Offline Payment',
            onlinePay: 'Make Payment',
        },
        payMethod: {
            1: 'Online Payment',
            2: 'Offline Payment'
        },
        total: '',
        listEmpty: "You don't have any orders.",
        info: 'Order Detail',
        id: 'Order Number',
        storeName: 'Store',
        createTime: 'Creation Time',
        payTime: 'Payment Time',
        prepareCompleteTime: 'Prepare Completion Time',
        completeTime: 'Completion Time',
        invalidTime: 'Invalid Time',
        payClosedTime: 'Closed Time',
        totalPrice:"Total Price",
        orderStatus:"Order Status",
        takeCode: 'Take Code',
        amount:"Amount",
        name:"Order",
        noYet:"No products yet",
        mealTime: {
            rapidPickUp: 'Estimated Pickup Time',
            deliveryEnd: 'Estimated Delivery By',
            deliveryStart: 'Out for Delivery Start (est.)',
        },
        error: {
            can_not_deliver: {
                lt_price: 'Minimum order price (after discount) for food delivery is $${{value}}'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: 'Your order has flash sale items: ${{nameText}}. Order [pickup] [delivery] date need to be today. Please change the date and try again.'
                }
            }
        },
        pay: {
            title: 'Payment',
            credit:{
                name:'Credit Card',
                total:'total',
                item:' ',
                more:'Show More',
            },
            error: {
                download_app_to_pay: 'Download the app to make mobile payments.',
                download_app_to_pay_or_offline: 'Download the app to make mobile payments or click "Offline Payment" button below.',
                store_doesnt_support_online_payment: "The current store does not support online payment.",
                doesnt_support_offline_payment: 'The current eat method does not support offline payment.',
                enter_right_cardholder_detail: "Please make sure you entered the information correctly.",
                coupon_not_found: ' Coupon not exist',
                coupon_cannot_use: 'Coupon can not used',
                coupon_num_is_zero: 'There are not enough coupon available',
                coupon_not_use_condition: 'Coupon does not meet the conditions of use',
                order_payment_in_progress:'order payment in progress',
                order_does_not_exist:'Order does not exist',
                order_has_been_paid:'Order has been paid'
            },
            cardHolder: {
                title: 'Cardholder Details',
                name: 'Cardholder Name',
                phone: 'Phone',
                email: 'Email',
                zipcode: 'Billing Zipcode',
            },
            cardDetails: {
                title: 'Card Details',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: 'Save',
            add: 'Add'
        },
        shippingInfo: 'User Information',
        enterShippingInfo: 'Please enter the user information.',
        name: 'Name',
        phone: 'Phone',
        coordinateDesc: 'Address',
        address: 'Address detail',
        fillInAddress:'Please fill in your address',
        selectAddress:'Select receivers\' address',
        makeFormRight: 'Please make sure you entered the information correctly.',
        empty: 'No saved user information found. Please add one.',
        pleaseSelect: 'No user information found. Please select one.',
        pleaseEnter: 'Please Enter',
        shippingMethod:"Shipping Method",
        selectShippingMethod:"Please select",
        selectShippingMethod1:"Welcome! Please select shipping method",
        store:'Store',
        selectStore:'Please select store',
        contactName:"Contact Name",
        contactPhone:"Contact Phone",
        contactInfo:"Please fill in your contact information",
        eatType:"Eat Method",
        eatTime:"Time"
    },
    history:{
        historyOrders:"History orders"
    },
    me:{
        name:"Me"
    },
    address:{
        addressManagement:"Address management",
        add:'Add Address',
        management:"Management",
        setAsDefault:"Set as default",
        default: 'Default',
        addressDelivery:"Delivery address",
        fullAddress:"Full Address",
        noAddress:"No Address",
        selectDeliveryAddress:"Select delivery address",
        messages: {
            cannotChangeWhenUsing: 'Cannot change when using'
        }
    },

    dayInWeek: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    },
    searchProduct:{
        search:"search",
        noResult:"no result"
    },

    activity: {
        name: 'Event',
        time: {
            start: 'Start',
            end: 'End',
            full: 'All the time',
        },
        placeholder: {
            chooseTimeToView: 'Set Pickup / Delivery Time to View Discounts'
        },
        description: 'Event description',
        activityStock: 'Product stock',
        singlePurchaseLimit: 'Single purchase limit'
    },
    activityProduct: {
        label: {
            event_starts_later: 'Event Starts Later',
            event_is_over: 'Event is Over',
            purchase_limit_reached: 'Purchase Limit Reached',
            not_enough_items_to_sell: 'Not Enough Items to Sell',
        },
        error: {
            purchase_limit_reached: 'Purchase limit reached',
            not_enough_items: 'Not enough items',
            unable_to_add: 'unable to add',
            event_is_over_please_check: 'Certain flash sale event is over; please check your shopping cart. ',
            purchase_limit_reached_please_check: 'Purchase limit reached for ${{name}}; please check your shopping cart.',
            not_enough_items_please_check: 'Currently we do not have enough ${{name}}. please check your shopping cart and adjust the quantity.',
        }
    },
    coupon: {
        promoCode: {
            name: 'Coupon',
            error: {
                coupon_not_found: ' Coupon not exist',
                coupon_cannot_use: 'Coupon can not used',
                coupon_num_is_zero: 'There are not enough coupon available',
                coupon_not_use_condition: 'Coupon does not meet the conditions of use'
            },
            placeholder: {
                chooseTimeToView: 'Set Pickup / Delivery Time to View Discounts'
            }
        }
    }
};