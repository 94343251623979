import React,{ useState } from 'react';
import style from './index.module.css';

interface Card{
    billing_details:{
        address:{
            postal_code:string
        },
        email:string;
        name:string;
        phone:string
    },
    card:{
        brand:string;
        country:string;
        funding:string;
        last4:string;
        exp_month:string;
        exp_year:string
    }
}

export const CardItem:React.FC<{ handleItemClick?:(index:number)=>void,card:Card,index?:number,checkedIndex?:number,showCheck:boolean}> = ({
    handleItemClick,
    card,
    index,
    checkedIndex,
    showCheck
}) =>{

    return (<div className={style.cardList}>
        <li className={style.item}  onClick={()=>{handleItemClick!(index!)}}>
            <span className={style.brand}>{(card.card.brand || "").toLocaleUpperCase()}</span>
            <span className={style.code}>{card.card.last4}</span> 
            <span className={style.exp}>{card.card.exp_month}/{card.card.exp_year}</span>
            {showCheck && <span className={`${style.check} ${checkedIndex === index? style.checked:''}`}></span>}
        </li>
    </div>)
}