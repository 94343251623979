import { useCallback } from 'react';
import { getReceiverInfoList, updateReceiverInfo, deleteReceiverInfoOfId } from '../api';
import { ReceiverInfo, RECEIVER_INFO_DEFAULT_STATUS } from '../models';
import { useMountedState } from 'react-use';
import { useState, useEffect } from 'react';
import { useListState } from 'hooks/useListState';
import { useThrottleLoading } from 'hooks/useThrottleLoading';


export const useReceiverInfoList = (storeId?: string) => {
    const [ state, setState ] = useListState<ReceiverInfo>();

    const mounted = useMountedState();
    useEffect(() => {
        setState({ loading: true, empty: false, list: [], error: '' });
        getReceiverInfoList(storeId)
            .then(list => {
                if (mounted()) {
                    setState({ loading: false, list, empty: list.length === 0 });
                }
            })
            .catch(({ data }) => {
                if (mounted()) {
                    setState({ loading: false, error: data.msg || 'Error' });
                }
            })
    }, [storeId, mounted]);


    const setDefaultStatus = useCallback((id: string) => {
        const info = state.list.find(item => item.id === id)!;
        const formData: ReceiverInfo = { ...info, defaultStatus: RECEIVER_INFO_DEFAULT_STATUS.IS};
        return new Promise((resolve, reject) => {
            setState({ loading: true });
            updateReceiverInfo(formData)
                .then(() => {
                    if (mounted()) {
                        return getReceiverInfoList().then(list => {
                            if (mounted()) {
                                setState({ loading: false, list, empty: list.length === 0 });
                                resolve();
                            }
                        })
                    }
                })
                .catch((err) => {
                    const { data } = err;
                    if (mounted()) {
                        setState({ loading: false, error: data.msg || 'Error' });
                        reject(err);
                    }
                })
        })
    }, [state.list]);

    const deleteReceiverInfo = useCallback((id: string) => {
        setState({ loading: true });
        return new Promise((resolve, reject) => {
            deleteReceiverInfoOfId(id)
                .then(() => {
                    if (mounted()) {
                        const list = state.list.filter(item => item.id !== id);
                        setState({ loading: false, list, empty: list.length === 0 });
                        resolve();
                    }
                })
                .catch(err => {
                    mounted() && reject(err)
                })
        });
    }, [state.list]);

    return {
        ...state,
        methods: { setDefaultStatus, deleteReceiverInfo }
    };


}