import { useState, useCallback } from 'react';
import { actions } from '../store';
import { useDispatch } from 'react-redux';
import { Order } from '../models';
import { useMountedState } from 'react-use';
import { actions as orderInfoActions } from 'modules/order-info/store';
import { changeToOfflinePayMethod } from '../api';
import { useI18n } from 'context/i18n';

export enum CREATE_ORDER_ERROR_CODE {
    FAIL = 500,
    FLASH_SALE_TIME_OUT = 20041,
    MEAL_TIME_INVALID = 20042
}

export const useCreateOrder = () => {
    const { getLang } = useI18n();
    const mounted = useMountedState();
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState<boolean>(false);


    const createOrder: () => Promise<String> = useCallback(() => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            (dispatch(actions.createOrder(getLang())) as any as Promise<String>)
                .then(id => {
                    if (mounted()) {
                        setLoading(false);
                        resolve(id)
                    }
                    dispatch(actions.clear());
                    dispatch(orderInfoActions.clearRemark());
                }).catch(err => {
                    if (mounted()) {
                        setLoading(false);
                        reject(err);
                    }
                })
        })
    }, [mounted, dispatch, getLang]);


    const changePayMethodToOffline = useCallback((id: string) => {
        setLoading(true);
        return changeToOfflinePayMethod(id).then(() => {
            if (mounted()) {
                setLoading(false);
            }
        }).catch(err => {
            if (mounted()) {
                setLoading(false);
                return Promise.reject(err);
            }
        })
    }, [setLoading, mounted]);

    return {
        loading,
        methods: { createOrder, changePayMethodToOffline }
    }
}
