import { Lang } from './Lang';
export const ch: Lang = {

    auth: {
        signIn: '登录',
        signUp: '注册',
        touristSignIn: '游客登录',
        logOut: '登出', 
        gotoSignIn: '去登录',
        gotoSignUp: '去注册',
        country: '国家',
        smsCodeSending: '正在发送',
        messages: {
            checkPasswordEqual: '两次输入的密码不相同, 请重新设置',
            mobileIsExists: '该手机号已被注册'
        },
        placeholder: {
            mobile: '请填写手机号',
            password: '请填写密码',
            setPassword: '请设置密码',
            setConfirmPassword: '请再次填写密码',
            grahicInput: '不区分大小写',
            getSmsCode: '获取验证码',
            smsCodeInput: '短信验证码',

        }
    },

    form: {

        actions: {
            delete: '删除',
            add: '添加',
            new: '新增',
            detail: '详情',
            edit: '编辑',
            save: '保存',
            submit: '提交',
            cancel: '取消',
            change: '修改',
            clear: '清空',
            upload: '上传',
            update: '更新',
            addLocalization: '新增语言',
            search: '搜索',
            confirm: '确认',
            back:"返回",
            confirmAndNext:"确认,下一步"
        },
        

        rules: {
            maxLength: '最大长度为${{length}}',
            ltSize: '图片尺寸不能超过${{size}}',
            noLocalization: '至少需要一种语言的信息，请您添加',
            makeSure: '表单错误，请检查'
        },
        messages: {
            success: '${{action}}成功',
            fail: '${{action}}失败',
        },
        alert: {
            isSure: '您确认么？'
        }
    },
    home: {
        title: '眷湘',
        orderNow: '立刻点餐',
        newOrder: '新订单',
        recorder: '以往订单',
        changeLanguage: '语言',
        menu:'菜单',
        homePage:'首页',
        messages: {
            switchStoreWillClearCart: '切换店铺将会清空购物车'
        }

    },
    orderType: {
        0: '自取外卖',
        1: '送餐',
        2: '无接触取餐',
    },
    productType: {
        0: '普通餐',
        1: '五日餐',
        fiveDayDescription: '五日餐（需提前一天预订，下午5点截单。第二天可取或送餐。5点之后点餐后天可取或可送餐）',
        alert: {
            crossToFiveDayMeal: '您已经选择了不是来自“五日餐”的菜品，如果切换到五日餐，将会清空购物车。',
            crossFromFiveDayMeal: '您已经选择了来自“五日餐”的菜品，如果切换到非“五日餐”，将会清空购物车。',
        }
    },

    orderTime: {
        asap: {
            delivery: '立刻送餐',
            pickUp: '立刻取餐',
            laterly:"稍后"
        },
        later: '选择时间',
        businessHour: '营业时间',
        error: {
            selectTime: '请选择就餐时间',
            nowOutOfHour: '已经超出营业时间，只接受预定',
            selectedTimeOutOfHour: '选择的时间已超出营业时间范围',
            selectedTimeExpire: '选择的时间已过期',
            nowNearClose: '即将超出营业时间，请尽快点餐或进行预定'
        }
    },
    ourMenu: {
        title: '今日推荐',
        category: '菜品分类'
    },
    menuInCategory: {
        searchPlaceholder: '搜索菜品',
        addToOrder: '加入订单',
    },

    foodDetail: {
        ingredients: '主要原料',
        customize: '定制口味',
        addToOrder: '加入订单',
        update: '更新',
        next: '下一步',
        addToCart:'加入购物车'
    },
    taste: {
        child: {
            radio: {
                0: "不要",
                1: "非常少",
                2: "少",
                3: "正常",
                4: "多",
                5: "超级多"
            }
        }
    },
    cart: {
        header: {
            title: '购物车',
            keepOrdering: '继续点餐'
        },
        next: '结算',
        detail:'购物车详情'
    },
    statements: {
        header: {
            title: '您的订单'
        },
        error: {
            enterMobile: '请输入预留手机号'
        },
        next: '提交订单',
        mobile: '手机号',
        placeholder: {
            mobile: "请输入预留手机号",
            remark: "请输入备注",
        },
        messages: {
            submitTo: '当前订单会被提交到${{name}}'
        },
        addRemark: '添加备注',
        remark: '备注',
        summary: {
            title: '概要',
            subtotal: '小计',
            discount: '优惠',
            taxes: '税',
            total: '总计',
            addPromoCode: '添加优惠码',
            postage: '运费'
        }
    },
    order: {
        shortStatus: {
            all: '全部',
            5: '待确认',
            10: '待支付',
            11: '已支付',
            12: '支付中',
            20: '准备中',
            21: '准备完成',
            22: '已移动至前台',
            30: '已完成',
            98: '已失效',
        },
        status: {
            5: '待确认',
            10: '待支付',
            11: '已支付',
            12: '支付中',
            20: '准备中',
            21: '准备完成',
            22: '已移动至前台',
            30: '已完成',
            98: '已失效',
        },
        actions: {
            cancel: '取消订单',
            delete: '删除订单',
            pay: '付款',
            confirm: '确认收货',
            offlinePay: '转为线下支付',
            onlinePay: '去支付',
        },
        payMethod: {
            1: '线上支付',
            2: '线下支付'
        },
        total: '合计',
        listEmpty: '您还没有相关订单',
        info: '订单信息',
        id: '订单编号',
        storeName: '店铺名称',

        createTime: '创建时间',
        // 支付时间
        payTime: '支付时间',
        // 准备完成时间
        prepareCompleteTime: '准备完成时间',
        // 完成时间
        completeTime: '完成时间',
        // 失效时间
        invalidTime: '失效时间',

        payClosedTime: '交易关闭时间',
        totalPrice:'总价',
        orderStatus:"订单状态",
        takeCode: '取餐码',
        amount:"数量",
        name:"订单",
        noYet:"暂无",
        mealTime: {
            rapidPickUp: '预计取餐时间',
            deliveryStart: '预计配送时间',
            deliveryEnd: '预计送达时间'
        },
        error: {
            can_not_deliver: {
                lt_price: '我们外送的起送价是$${{value}}(折后)'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: '您的订单中有秒杀活动的菜品: ${{nameText}}, 此订单需要选择今日[自取][送餐]，请更改日期后重试。'
                }
            }
        },
        pay: {
            title: '支付',
            credit:{
                name:'信用卡',
                total:'共',
                item:'张',
                more:'显示更多',
            },
            error: {
                download_app_to_pay: '下载App进行在线支付',
                download_app_to_pay_or_offline: '请下载App进行在线支付，或点击“转为线下支付”按钮 ',
                store_doesnt_support_online_payment: '当前店铺不支持线上支付',
                doesnt_support_offline_payment: '当前就餐方式不支持线下支付',
                enter_right_cardholder_detail: '请正确填写持卡人信息',
                coupon_not_found: '优惠码不存在',
                coupon_cannot_use: '优惠码无法使用',
                coupon_num_is_zero: '优惠码可用数量不足',
                coupon_not_use_condition: '优惠码未达到使用条件',
                order_payment_in_progress:'订单支付中',
                order_does_not_exist:'订单不存在',
                order_has_been_paid:'订单已支付',
            },
            cardHolder: {
                title: '持卡人信息',
                name: '持卡人姓名',
                phone: '电话',
                email: '邮箱',
                zipcode: '邮编',
            },
            cardDetails: {
                title: '银行卡信息',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: '保存',
            add: '新增'
        },
        shippingInfo: '联系方式',
        enterShippingInfo: '请填写联系方式',
        name: '姓名',
        phone: '电话',
        address: '详细地址',
        fillInAddress:'请填写地址',
        selectAddress:"选择收货地址",
        coordinateDesc: '地址',
        makeFormRight: '请确认联系方式填写正确',
        empty: '暂无常用联系方式，请添加',
        pleaseSelect: '暂无联系方式，请选择',
        pleaseEnter: '请输入',
        shippingMethod:"配送方式",
        selectShippingMethod:"请选择配送方式",
        selectShippingMethod1:"欢迎您,请选择订餐方式",
        store:'门店',
        selectStore:'请选择店铺',
        contactName:"联系人姓名",
        contactPhone:"联系人电话",
        contactInfo:"请填写联系人方式",
        eatType:"就餐方式",
        eatTime:"就餐时间"
    },
    history:{
        historyOrders:"历史订单"
    },
    me:{
        name:"个人"
    },
    address:{
        addressManagement:"地址管理",
        add:"添加地址",
        management:"管理",
        setAsDefault:"设为默认",
        default: "默认",
        addressDelivery:"送餐地址",
        fullAddress:"详细地址",
        noAddress:"很抱歉，没有地址",
        selectDeliveryAddress:"请选择送餐地址",
        messages: {
            cannotChangeWhenUsing: '该地址正在使用，无法修改'
        }
    },

    dayInWeek: {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
    },
    searchProduct:{
        search:"开始搜索",
        noResult:"无搜索结果,换个词试试吧~"
    },
    activity: {
        name: '优惠活动',
        time: {
            start: '开始时间',
            end: '结束时间',
            full: '全活动有效',
        },
        placeholder: {
            chooseTimeToView: '选择送餐或取餐时间，查看对应优惠价'
        },
        description: '活动说明',
        activityStock: '活动库存',
        singlePurchaseLimit: '单人最大购买限制'
    },

    activityProduct: {
        label: {
            event_starts_later: '活动还未开始',
            event_is_over: '活动已结束',
            purchase_limit_reached: '已超出个人购买限制',
            not_enough_items_to_sell: '剩余活动商品库存不足',
        },
        error: {
            purchase_limit_reached: '已超出最大购买限制',
            not_enough_items: '剩余库存不足',
            unable_to_add: '无法继续添加',
            event_is_over_please_check: '部分秒杀活动已结束，请检查购物车列表 ',
            purchase_limit_reached_please_check: '${{name}}的数量已超出最大购买限制，请检查购物车列表',
            not_enough_items_please_check: '${{name}}的数量已超出现有活动库存，请检查购物车列表',
        }
    },
    coupon: {
        promoCode: {
            name: '优惠码',
            error: {
                coupon_not_found: '优惠码不存在',
                coupon_cannot_use: '优惠码无法使用',
                coupon_num_is_zero: '优惠码可用数量不足',
                coupon_not_use_condition: '优惠码未达到使用条件'
            },
            placeholder: {
                chooseTimeToView: '选择送餐或取餐时间，查看对应优惠'
            },
        }
    }
};