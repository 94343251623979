import { ajaxGet, SuccessResponse, ListResponse, ItemResponse } from 'api';
import { Category, Product, Taste, MODEL_TYPE, SubTasteRadio,
    DiscountReference,
    ActivityReference,
    DiscountVO,
    NormalActivityVO,
    IS_DISCOUNT_OPTIMAL,
    KillActivityReference,
    KillActivityVO,
    ActivityProductVO,
    ActivityProductResponseDTO,
    ACTIVITY_TYPE
} from '../models';
import defaultProductImage from 'assets/default_product.jpg';
import { IS_DEFAULT_LANGUAGE, Language } from 'context/i18n';

import { store } from 'store';
import { selectors } from 'modules/store/store';
export type TasteReference = Omit<Taste, 'child'> & { child: string };

export type ProductReference = Omit<Product, 'customizableTasteList'> & {
    customizableTasteList: Pick<TasteReference, 'code' | 'child'>[]
}

export const getProductsOfCategoryCode = (
    storeId: string,
    language: Language,
    categoryCode: Category['code']
): Promise<SuccessResponse<Product[]>> => {
    const params = { categoryCode, storeId, pageNum: 1, pageSize: 99 };
    const url = `/repast/api/v2/product`;
    return ajaxGet<SuccessResponse<ListResponse<ProductReference, TasteReference | DiscountReference | ActivityReference>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const { value, references } = data.data;
        return { ...data, data: productReferencesToProduct(value, references) }
    });
}


export const getProductsOfActivityCode = (
    storeId: string,
    language: Language,
    activityCode: string,
) => {
    const params = { storeId };
    const url = `/repast/api/v2/activity/${activityCode}/product`;

    return ajaxGet<SuccessResponse<ListResponse<ProductReference, TasteReference | DiscountReference | ActivityReference>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const { value, references } = data.data;
        return { ...data, data: productReferencesToProduct(value, references) }
    });
}



export const getActivityDetailOfCode = (
    storeId: string,
    language: Language,
    activityCode: string,
): Promise<KillActivityVO> => {
    const params = { storeId }
    const url = `/repast/api/v2/activity/${activityCode}`;

    return ajaxGet<SuccessResponse<ItemResponse<KillActivityReference, Product | TasteReference | DiscountReference | ActivityReference>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const { value, references } = data.data;

        const activityProducts = value.activityProducts.map(activityProductDTO => {
            const productRef = references.find(ref => ref.type === MODEL_TYPE.PRODUCT && ref.code === activityProductDTO.productCode)! as any as ProductReference;
            
            const [product] = productReferencesToProduct([productRef], (references as Array<TasteReference | DiscountReference | ActivityReference>))
            const activityProductVO: ActivityProductVO = {
                ...activityProductDTO,
                product,
                activity: value
            };
            return activityProductVO;
        })
        return {...value, activityProducts };

    });
}



export const getActivityProductOfId = (
    storeId: string,
    language: Language,
    activityCode: string,
    activityProductOfId: string,
) => {

    const params = { storeId };
    const url = `/repast/api/v2/activity/${activityCode}/product/${activityProductOfId}`;

    return ajaxGet<SuccessResponse<ItemResponse<ActivityProductResponseDTO, Product | TasteReference | DiscountReference | ActivityReference>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const { value, references } = data.data;
        const productRef = references.find(ref => ref.type === MODEL_TYPE.PRODUCT && ref.code === value.productCode)! as any as ProductReference;
        const activityReference = references.find(ref => ref.type === MODEL_TYPE.ACTIVITY && ref.activityType === ACTIVITY_TYPE.KILL && ref.code === value.activityCode)! as any as KillActivityReference;
        const [product] = productReferencesToProduct([productRef], (references as Array<TasteReference | DiscountReference | ActivityReference>))
        const activityProductVO: ActivityProductVO = {
            ...value,
            product,
            activity: activityReference,
        };
        return {
            activityProductVO,
            activityReference
        };
    })
}


// 根据产品code获取产品信息
export const getProductOfCode = (
    storeId: string,
    productCode: string,
): Promise<SuccessResponse<Product>> => {
    const params = { storeId };
    const url = `/repast/api/v2/product/${productCode}`;
    return ajaxGet<SuccessResponse<ItemResponse<ProductReference, TasteReference | DiscountReference | ActivityReference>>>(url, params).then(({ data }) => {
        const { value, references } = data.data;
        const products = productReferencesToProduct([value], references);
        return { ...data, data: products[0] };
    });
}

export const productReferencesToProduct = (
    productRefs: ProductReference[],
    references: Array<Category | ProductReference | TasteReference | DiscountReference | ActivityReference>,
): Product[] => {
    const tasteRefs = references.filter(ref => ref.type === MODEL_TYPE.TASTE) as TasteReference[];
    const discountRefs = references.filter(ref => ref.type === MODEL_TYPE.DISCOUNT) as DiscountReference[];
    const activityRefs = references.filter(ref => ref.type === MODEL_TYPE.ACTIVITY) as ActivityReference[];
    const newData = productRefs.map(product => {
        const customizableTasteList: Taste[] = product.customizableTasteList.map(taste => {
            const tasteRef = tasteRefs.find(ref => ref.code === taste.code)!;
            if (!tasteRef) console.error('no taste ref taste Code:=>', taste.code, 'product code: ', product.code);
            return { ...tasteRef, child: JSON.parse(taste.child) as SubTasteRadio }
        })

        let images = product.images.map(img => ({...img, url: `/repast/${img.url}`}));
        if (images.length === 0) {
            images = [{
                id: '-1',
                url: `/repast/images/${selectors.getCurrentBrand(store.getState())!.abbreviation}/product_default_image_1.png`,
            }]
        }

        // 所有优惠VO
        const discountVOs: DiscountVO[] = product.discountPrices.map(discountInProductRef => {
            const discountRef = discountRefs.find(ref => ref.id === discountInProductRef.discountId)!;
            if (!discountRef) console.error('No discountRef discountId: ', discountInProductRef.discountId, 'product:', product);
            return { ...discountInProductRef, ...discountRef };
        });

        // 当前生效的优惠
        const currentDiscountVO = discountVOs.find(d => d.isOptimal === IS_DISCOUNT_OPTIMAL.IS);

        const discountsGroupByActivity = discountVOs.reduce<{
            ofActivityCode: { [key: string]: DiscountVO[] };
            activityCodes: string[];
        }>((acc, curr) => {
            const { ofActivityCode, activityCodes } = acc;
            
            const newActivityCodes = ofActivityCode[curr.activityCode] ? activityCodes : [...activityCodes, curr.activityCode];

            const newDiscountVOsOfActivityCode = [...(ofActivityCode[curr.activityCode] || []), curr];
            const newOfActivityCode = { ...ofActivityCode, [curr.activityCode]: newDiscountVOsOfActivityCode };
            
            return {
                ofActivityCode: newOfActivityCode,
                activityCodes: newActivityCodes,
            };
        }, { ofActivityCode: {} , activityCodes: [] })
        const { ofActivityCode, activityCodes } = discountsGroupByActivity;

        const activityVOs: NormalActivityVO[] = activityCodes.map(activityCode => {
            const currentActivityDiscountVOs = ofActivityCode[activityCode];
            const activityRef = activityRefs.find(ref => ref.code === activityCode)!;
            if (!activityRef) console.error('no activityRef activityCode: ', activityCode, 'product: ', product);
            return { ...activityRef, discounts: currentActivityDiscountVOs };
        });

        const p: Product = {
            ...product,
            images,
            customizableTasteList,
            currentDiscount: currentDiscountVO,
            activities: activityVOs,
        };

        return p;
    });

    return newData;
}