import React, { useCallback, useMemo, useState, useRef } from 'react';
import styles from './index.module.css';
import { OrderInfoItem } from '../OrderInfoItem';
import { CartMenu } from 'modules/buy';
import { useHeaderOrderInfo } from '../../facades/useHeaderOrderInfo';
import { useProductListInCart } from 'modules/buy';
import { useHistory } from 'react-router-dom';
import cartEmptyIcon from '../../images/cart-empty.png';
import cartIcon from '../../images/cart.png';
import { useClickAway } from 'react-use';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';
export const OrderInfoHeader: React.FC = () => {
    const { theme: { primary } } = useTheme();
    const { push } = useHistory();
    const { count, subTotalPrice } = useProductListInCart();
    const { t } = useI18n();
    const { list: orderInfoList } = useHeaderOrderInfo();
    
    const [ showCartMenu, setShowCartMenu ] = useState<boolean>(false);
    const cartMenuRef = useRef<HTMLDivElement>(null);


    const handleCartButtonClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.nativeEvent.stopImmediatePropagation();
        setShowCartMenu(!showCartMenu);
    }, [showCartMenu]);
    
    useClickAway(cartMenuRef, () => {
        setShowCartMenu(false);
        console.log("xixi");
    });

    const cartEmpty = useMemo(() => {
        return count === 0;
    }, [count]);
    const countMax = useMemo(() => {
        return count > 99;
    }, [count]);
    const handleGotoCart = useCallback(() => {
        if (cartEmpty) return;

        push('/cart');
    }, [cartEmpty])

    const cartButtonImage = useMemo(() => {
        return cartEmpty ? cartEmptyIcon : cartIcon;
    }, [cartEmpty]);

    return (
        <div className={styles.orderInfoHeader}>

            <div className={styles.orderInfo}>
                {orderInfoList.map(item => (
                    <OrderInfoItem
                        className={styles.orderInfoItem}
                        key={item.label}
                        {...item}
                    />
                ))}

                <div className="clear"></div>
            </div>

            <div className={styles.cartInfo}>
                <div className={styles.cartButton} ref={cartMenuRef}>
                    <div className={styles.cartButtonImgWrapper} onClick={handleCartButtonClick}>
                        <svg width="37px" height="26px" viewBox="0 0 37 26" version="1.1" className={styles.image}>
                            <title>购物车</title>
                            <desc>Created with Sketch.</desc>
                            <g id="页面-1" stroke="none" strokeWidth="1" fill={cartEmpty ? '#999' : primary.bg} fillRule="evenodd">
                                <path d="M29.0286135,10.0443479 C33.4310887,10.0443479 37,13.6161423 37,18.0221739 C37,22.4282056 33.4310887,26 29.0286135,26 C24.6261383,26 21.057227,22.4282056 21.057227,18.0221739 C21.057227,13.6161423 24.6261383,10.0443479 29.0286135,10.0443479 Z M11.0083368,22.5077534 C11.9337595,22.5077534 12.6839628,23.2585627 12.6839628,24.1847329 C12.6839628,25.1109032 11.9337595,25.8617125 11.0083368,25.8617125 C10.0829142,25.8617125 9.33271092,25.1109032 9.33271092,24.1847329 C9.33271092,23.2585627 10.0829142,22.5077534 11.0083368,22.5077534 Z M19.8243478,22.5077534 C20.7497704,22.5077534 21.4999737,23.2585627 21.4999737,24.1847329 C21.4999737,25.1109032 20.7497704,25.8617125 19.8243478,25.8617125 C18.8989251,25.8617125 18.1487219,25.1109032 18.1487219,24.1847329 C18.1487219,23.2585627 18.8989251,22.5077534 19.8243478,22.5077534 Z M5.47351673,0 C6.37555228,0.0262941044 7.12578898,0.654431044 7.33889123,1.49584239 L7.33889123,1.49584239 L7.63956975,4.03176268 L26.3225068,4.03176268 C26.3925679,4.03760581 26.4597097,4.04637051 26.5268515,4.05805678 C27.6390701,4.27133119 27.9718599,5.5246835 27.6069588,6.45082029 L27.6069588,6.45082029 L26.1582794,9.51668996 C23.35491,10.4639262 21.1722788,12.7604201 20.3816952,15.6342392 L9.10793183,16.2526781 L9.32103408,18.0435988 C9.39985272,18.3474418 9.70636966,18.5870103 10.0304019,18.6366769 L10.0304019,18.6366769 L20.0814927,18.6366613 C20.1379063,19.4716576 20.3084312,20.2753329 20.5775874,21.032195 L9.02911319,21.032362 C8.03950137,21.032362 7.21920366,20.3078133 7.0674048,19.3612256 L7.0674048,19.3612256 L5.18451504,3.62858641 L5.12321166,3.11146902 L5.11445403,3.03842984 C5.05023143,2.69076335 4.69408794,2.42197917 4.33210604,2.39568507 L4.33210604,2.39568507 L1.19687566,2.39568507 C0.534215232,2.39568507 -2.14939178e-13,1.85811671 -2.14939178e-13,1.19784254 C-2.14939178e-13,0.53464679 0.537134441,0 1.19687566,0 L1.19687566,0 Z" id="购物车" fillRule="nonzero"></path>
                            </g>
                        </svg>
                        <div className={`${styles.count} ${cartEmpty ? styles.empty : ''} ${countMax ? styles.max : ''}`}>{countMax ? '...' : count}</div>
                    </div>
                    <div className={styles.subTotalPrice}>${subTotalPrice.toFixed(2)}</div>
                    {showCartMenu && <CartMenu className={styles.cartMenu}/>}
                </div>
                <div
                    className={`${styles.submitButton} ${cartEmpty ? styles.disabled : ''}`}
                    style={cartEmpty ? {} : { background: primary.bg, color: primary.color }}
                    onClick={handleGotoCart}
                >{t('form.actions.confirm')}</div>
                
                <div className="clear"></div>
            </div>
            <div className="clear"></div>
        </div>
    );
}