import { CountryCodeAndName } from './types';
export const list: CountryCodeAndName[] = [
    {
        "code": "US",
        "name": "美國"
    },
    {
        "code": "CN",
        "name": "中國"
    },

    {
        "code": "SG",
        "name": "新加坡"
    },

    {
        "code": "TW",
        "name": "台灣（中國）"
    },
    {
        "code": "AD",
        "name": "安道爾"
    },
    {
        "code": "AE",
        "name": "阿拉伯聯合酋長國"
    },
    {
        "code": "AF",
        "name": "阿富汗"
    },
    {
        "code": "AL",
        "name": "阿爾巴尼亞"
    },
    {
        "code": "AM",
        "name": "亞美尼亞"
    },
    {
        "code": "AO",
        "name": "安哥拉"
    },
    {
        "code": "AR",
        "name": "阿根廷"
    },
    {
        "code": "AT",
        "name": "奧地利"
    },
    {
        "code": "AU",
        "name": "澳大利亞"
    },
    {
        "code": "AZ",
        "name": "阿塞拜疆"
    },
    {
        "code": "BD",
        "name": "孟加拉國"
    },
    {
        "code": "BE",
        "name": "比利時"
    },
    {
        "code": "BF",
        "name": "布基納法索"
    },
    {
        "code": "BG",
        "name": "保加利亞"
    },
    {
        "code": "BH",
        "name": "巴林"
    },
    {
        "code": "BI",
        "name": "布隆迪"
    },
    {
        "code": "BJ",
        "name": "貝寧"
    },
    {
        "code": "BN",
        "name": "汶萊"
    },
    {
        "code": "BO",
        "name": "玻利維亞"
    },
    {
        "code": "BR",
        "name": "巴西"
    },
    {
        "code": "BS",
        "name": "巴哈馬"
    },
    {
        "code": "BW",
        "name": "博茨瓦納"
    },
    {
        "code": "BY",
        "name": "白俄羅斯"
    },
    {
        "code": "BZ",
        "name": "伯利茲"
    },
    {
        "code": "CA",
        "name": "加拿大"
    },
    {
        "code": "CF",
        "name": "中非共和國"
    },
    {
        "code": "CG",
        "name": "剛果（布）"
    },
    {
        "code": "CH",
        "name": "瑞士"
    },
    {
        "code": "CK",
        "name": "庫克群島"
    },
    {
        "code": "CL",
        "name": "智利"
    },
    {
        "code": "CM",
        "name": "喀麥隆"
    },
    {
        "code": "CO",
        "name": "哥倫比亞"
    },
    {
        "code": "CR",
        "name": "哥斯達黎加"
    },
    {
        "code": "CU",
        "name": "古巴"
    },
    {
        "code": "CY",
        "name": "塞浦路斯"
    },
    {
        "code": "CZ",
        "name": "捷克共和國"
    },
    {
        "code": "DE",
        "name": "德國"
    },
    {
        "code": "DJ",
        "name": "吉布提"
    },
    {
        "code": "DK",
        "name": "丹麥"
    },
    {
        "code": "DZ",
        "name": "阿爾及利亞"
    },
    {
        "code": "EC",
        "name": "厄瓜多爾"
    },
    {
        "code": "EE",
        "name": "愛沙尼亞"
    },
    {
        "code": "EG",
        "name": "埃及"
    },
    {
        "code": "ES",
        "name": "西班牙"
    },
    {
        "code": "ET",
        "name": "埃塞俄比亞"
    },
    {
        "code": "FI",
        "name": "芬蘭"
    },
    {
        "code": "FJ",
        "name": "斐濟"
    },
    {
        "code": "FR",
        "name": "法國"
    },
    {
        "code": "GA",
        "name": "加蓬"
    },
    {
        "code": "GB",
        "name": "英國"
    },
    {
        "code": "GE",
        "name": "格魯吉亞"
    },
    {
        "code": "GF",
        "name": "法屬圭亞那"
    },
    {
        "code": "GH",
        "name": "加納"
    },
    {
        "code": "GI",
        "name": "直布羅陀"
    },
    {
        "code": "GM",
        "name": "岡比亞"
    },
    {
        "code": "GN",
        "name": "幾內亞"
    },
    {
        "code": "GR",
        "name": "希臘"
    },
    {
        "code": "GT",
        "name": "危地馬拉"
    },
    {
        "code": "GY",
        "name": "圭亞那"
    },
    {
        "code": "HK",
        "name": "香港（中國）"
    },
    {
        "code": "HN",
        "name": "洪都拉斯"
    },
    {
        "code": "HT",
        "name": "海地"
    },
    {
        "code": "HU",
        "name": "匈牙利"
    },
    {
        "code": "ID",
        "name": "印度尼西亞"
    },
    {
        "code": "IE",
        "name": "愛爾蘭"
    },
    {
        "code": "IL",
        "name": "以色列"
    },
    {
        "code": "IN",
        "name": "印度"
    },
    {
        "code": "IQ",
        "name": "伊拉克"
    },
    {
        "code": "IR",
        "name": "伊朗"
    },
    {
        "code": "IS",
        "name": "冰島"
    },
    {
        "code": "IT",
        "name": "意大利"
    },
    {
        "code": "JM",
        "name": "牙買加"
    },
    {
        "code": "JO",
        "name": "約旦"
    },
    {
        "code": "JP",
        "name": "日本"
    },
    {
        "code": "KE",
        "name": "肯雅"
    },
    {
        "code": "KG",
        "name": "吉爾吉斯斯坦"
    },
    {
        "code": "KH",
        "name": "柬埔寨"
    },
    {
        "code": "KP",
        "name": "朝鮮"
    },
    {
        "code": "KR",
        "name": "韓國"
    },
    {
        "code": "KW",
        "name": "科威特"
    },
    {
        "code": "KZ",
        "name": "哈薩克斯坦"
    },
    {
        "code": "LA",
        "name": "老撾"
    },
    {
        "code": "LB",
        "name": "黎巴嫩"
    },
    {
        "code": "LI",
        "name": "列支敦士登"
    },
    {
        "code": "LK",
        "name": "斯里蘭卡"
    },
    {
        "code": "LR",
        "name": "利比里亞"
    },
    {
        "code": "LS",
        "name": "萊索托"
    },
    {
        "code": "LT",
        "name": "立陶宛"
    },
    {
        "code": "LU",
        "name": "盧森堡"
    },
    {
        "code": "LV",
        "name": "拉脫維亞"
    },
    {
        "code": "LY",
        "name": "利比亞"
    },
    {
        "code": "MA",
        "name": "摩洛哥"
    },
    {
        "code": "MC",
        "name": "摩納哥"
    },
    {
        "code": "MD",
        "name": "摩爾多瓦"
    },
    {
        "code": "MG",
        "name": "馬達加斯加"
    },
    {
        "code": "ML",
        "name": "馬里"
    },
    {
        "code": "MM",
        "name": "緬甸"
    },
    {
        "code": "MN",
        "name": "蒙古"
    },
    {
        "code": "MO",
        "name": "澳門（中國）"
    },
    {
        "code": "MT",
        "name": "馬耳他"
    },
    {
        "code": "MU",
        "name": "毛里裘斯"
    },
    {
        "code": "MV",
        "name": "馬爾代夫"
    },
    {
        "code": "MW",
        "name": "馬拉維"
    },
    {
        "code": "MX",
        "name": "墨西哥"
    },
    {
        "code": "MY",
        "name": "馬來西亞"
    },
    {
        "code": "MZ",
        "name": "莫桑比克"
    },
    {
        "code": "NA",
        "name": "納米比亞"
    },
    {
        "code": "NE",
        "name": "尼日爾"
    },
    {
        "code": "NG",
        "name": "尼日利亞"
    },
    {
        "code": "NI",
        "name": "尼加拉瓜"
    },
    {
        "code": "NL",
        "name": "荷蘭"
    },
    {
        "code": "NO",
        "name": "挪威"
    },
    {
        "code": "NP",
        "name": "尼泊爾"
    },
    {
        "code": "NR",
        "name": "瑙魯"
    },
    {
        "code": "NZ",
        "name": "新西蘭"
    },
    {
        "code": "OM",
        "name": "阿曼"
    },
    {
        "code": "PA",
        "name": "巴拿馬"
    },
    {
        "code": "PE",
        "name": "秘魯"
    },
    {
        "code": "PF",
        "name": "法屬波利尼西亞"
    },
    {
        "code": "PG",
        "name": "巴布亞新幾內亞"
    },
    {
        "code": "PH",
        "name": "菲律賓"
    },
    {
        "code": "PK",
        "name": "巴基斯坦"
    },
    {
        "code": "PL",
        "name": "波蘭"
    },
    {
        "code": "PT",
        "name": "葡萄牙"
    },
    {
        "code": "PY",
        "name": "巴拉圭"
    },
    {
        "code": "QA",
        "name": "卡塔爾"
    },
    {
        "code": "RO",
        "name": "羅馬尼亞"
    },
    {
        "code": "RU",
        "name": "俄羅斯"
    },
    {
        "code": "SA",
        "name": "沙特阿拉伯"
    },
    {
        "code": "SB",
        "name": "所羅門群島"
    },
    {
        "code": "SC",
        "name": "塞舌爾"
    },
    {
        "code": "SD",
        "name": "蘇丹"
    },
    {
        "code": "SE",
        "name": "瑞典"
    },
    {
        "code": "SI",
        "name": "斯洛文尼亞"
    },
    {
        "code": "SK",
        "name": "斯洛伐克"
    },
    {
        "code": "SL",
        "name": "塞拉利昂"
    },
    {
        "code": "SM",
        "name": "聖馬力諾"
    },
    {
        "code": "SN",
        "name": "塞內加爾"
    },
    {
        "code": "SO",
        "name": "索馬里"
    },
    {
        "code": "SR",
        "name": "蘇里南"
    },
    {
        "code": "ST",
        "name": "聖多美和普林西比"
    },
    {
        "code": "SV",
        "name": "薩爾瓦多"
    },
    {
        "code": "SY",
        "name": "敘利亞"
    },
    {
        "code": "SZ",
        "name": "斯威士蘭"
    },
    {
        "code": "TD",
        "name": "乍得"
    },
    {
        "code": "TG",
        "name": "多哥"
    },
    {
        "code": "TH",
        "name": "泰國"
    },
    {
        "code": "TJ",
        "name": "塔吉克斯坦"
    },
    {
        "code": "TL",
        "name": "東帝汶"
    },
    {
        "code": "TM",
        "name": "土庫曼斯坦"
    },
    {
        "code": "TN",
        "name": "突尼斯"
    },
    {
        "code": "TO",
        "name": "湯加"
    },
    {
        "code": "TR",
        "name": "土耳其"
    },
    {
        "code": "TZ",
        "name": "坦桑尼亞"
    },
    {
        "code": "UA",
        "name": "烏克蘭"
    },
    {
        "code": "UG",
        "name": "烏干達"
    },
    {
        "code": "UY",
        "name": "烏拉圭"
    },
    {
        "code": "UZ",
        "name": "烏茲別克斯坦"
    },
    {
        "code": "VE",
        "name": "委內瑞拉"
    },
    {
        "code": "VN",
        "name": "越南"
    },
    {
        "code": "YE",
        "name": "也門"
    },
    {
        "code": "ZA",
        "name": "南非"
    },
    {
        "code": "ZM",
        "name": "贊比亞"
    },
    {
        "code": "ZW",
        "name": "津巴布韋"
    }
]