import { useState, useCallback } from 'react';

export const useCounter = (initialCount: number = 0) => {
    const [count, setCount] = useState(initialCount);

    const onInc = useCallback(() => {
        setCount(value => value + 1);
    }, [setCount]);
    const onDec = useCallback(() => {
        if (count > 1) setCount(count - 1);
    }, [count, setCount])
    return { count, onInc, onDec };
};