import { DELIVERY_TYPE } from 'modules/order-info';
export enum MODEL_TYPE {
    STORE = 'store'
}

export enum IS_EXCEED {
    IS = '1', // 超出
    NO = '0' // 没超
}


export interface HourAndMinute {
    hour: number;
    minute: number;
}

export interface HourData {
    start: HourAndMinute;
    end: HourAndMinute;
    rapidPickUpStart: HourAndMinute;
}

export interface DeliveryTimeRange {
    start: HourAndMinute;
    end: HourAndMinute;
}
export interface Store {
    storeID: string;
    name: string;
    tel: string;
    addr: string;
    code: '',
    hour: {
        Su: string;
        T: string;
        Th: string;
        F: string;
        W: string;
        M: string;
        Sa: string;
    };
    hourData: Array<HourData[]>;
    rapidPickUpHourData: Array<HourData[]>;
    hourDataTextOfWeek: string[][];
    deliveryTimeRangeOfWeek: Array<DeliveryTimeRange[]>;
    distance: string; //  距离 单位min
    isExceed: IS_EXCEED; // 是否超出范围
    type: MODEL_TYPE.STORE;

    eatTypes: DELIVERY_TYPE[];
    offlinePayment: {
        enable: boolean;
        paypalAccount: string;
        venmoAccount: string;
        zelleAccount: string;
    }
    onlinePayment: {
        enable: boolean,
    },

    time: Record<'0'|'1'|'2'|'3'|'4'|'5'|'6', Array<HourData>>
}

export type StoreReference = Omit<Store, 'hourData' | 'deliveryTimeRangeOfWeek' | 'hourDataTextOfWeek' | 'rapidPickUpHourData'>;




export interface Theme {
    primary:{
        bg:string,
        color:string
    },
    secondary:{
        bg:string,
        color:string
    }
}
export interface BrandAnalyticsInfo {

    platform: string;
    id: string;
    
}
export interface Brand {
    abbreviation: string;
    mobileHomeImages: string;
    theme: Theme;
    code: string;
    name: string;
    email: string;
    eatTypes: DELIVERY_TYPE[];
    defaultLanguage: string;
    analytics?: BrandAnalyticsInfo
}

export interface BrandResponseDTO {
    abbreviation: string;
    mobileHomeImages: string;
    theme: string;
    code: string;
    name: string;
    email: string;
    eatTypes: string;
    defaultLanguage: string;
    analytics?: string;
}