import React, { useEffect, useMemo, useCallback } from 'react';
import styles from './index.module.css';
import { country as countryUtils } from 'globalUtils';
import { useI18n } from 'context/i18n';

import { RowWrapper } from '../RowWrapper';
import { MobileWithCountryCode } from './MobileWithCountryCode';
import { Select } from 'components/Select';
const { Option } = Select;

export { MobileWithCountryCode };

interface Props {
    value: MobileWithCountryCode
    onChange: (mobileWithCountryCode: MobileWithCountryCode) => void
}

export const Mobile: React.FC<Props> = ({
    value: mobileWithCountryCode,
    onChange
}) => {

    const { t, getLang } = useI18n();
    const countryList = useMemo(() => {
        return countryUtils.getList(getLang());
    }, [getLang]);

    useEffect(() => {
        if (mobileWithCountryCode.isHasEmpty()) {
            const newMobileWithCountryCode = mobileWithCountryCode.init(countryList[0].code);
            onChange(newMobileWithCountryCode);
        }
    }, []);


    const changeMobile = useCallback((mobile: string) => {
        onChange(mobileWithCountryCode.changeMobile(mobile));
    }, [onChange, mobileWithCountryCode])

    const changeCountry = useCallback((code: string | number) => {
        onChange(mobileWithCountryCode.setCountryCode(`${code}`));
    }, [onChange, mobileWithCountryCode])

    const countryCode = useMemo(() => {
        return mobileWithCountryCode.getCountryCode();
    }, [mobileWithCountryCode]);
    const countryNumber = useMemo(() => {
        return mobileWithCountryCode.getCountryNumber();
    }, [mobileWithCountryCode]);


    const countryPickerList = useMemo(() => {
        return countryUtils.getList(getLang()).map(({ code, name }) => ({
            value: code,
            label: name
        }));
    }, [getLang]);


    return (
        <>
            <RowWrapper>
                    <div>
                        <div className={styles.prefix}>{t("auth.country")}</div>
                        <Select
                            showSearch
                            value={countryCode}
                            style={{ width: 'calc(100% - 100px)', marginTop: '4px' }}
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={changeCountry}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {countryPickerList.map(country => (
                                <Option key={country.value} value={country.value}>{country.label}</Option>
                            ))}
                        </Select>
                    </div>
                    
            </RowWrapper>

            <RowWrapper>
                <div className={styles.prefix}>+{countryNumber}</div>

                <input
                    className={styles.mobileInput}
                    value={mobileWithCountryCode.getMobile()}
                    onChange={event => changeMobile(event.target.value)}
                    placeholder={t('auth.placeholder.mobile')}
                />
            </RowWrapper>
        </>
    )

}