import React from 'react';
import { useTheme } from 'modules/store';



interface Props {
    disabled?: boolean,
    children: React.ReactNode,
    onClick: () => void
}

export const NextButton: React.FC<Props> = ({
    disabled = false,
    children,
    onClick
}) => {
    const { theme: { primary } } = useTheme();
    console.log("next button =>", primary)
    const style: React.CSSProperties = {
        width: '100%',
        height: '40px',
        backgroundColor: primary.bg,
        borderRadius: '20px',
        marginTop: '20px',
        color: primary.color,
        border: 0,
    }
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={style}
        >{children}</button>
    )
}