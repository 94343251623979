import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';

import { actions, selectors } from '../store';
import { Store } from '../models';
import { getStoreList as getStoreListApi } from '../api';

export const useStore = () => {
    const dispatch = useDispatch();
    const mounted = useMountedState();

    const storeList = useSelector(selectors.getStoreList);
    const currentStore = useSelector(selectors.getCurrentStore);
    const currentBrand = useSelector(selectors.getCurrentBrand);
    const isInitLoading = useSelector(selectors.isInitLoading);
    const isInitError = useSelector(selectors.isInitError);

    const currentStoreId = useMemo(() => {
        return currentStore?.storeID;
    }, [currentStore]);

    const init = useCallback(() => {
        dispatch(actions.init());
    }, [dispatch]);

    const getStoreList = useCallback((geo?: {
        latitude: string,
        longitude: string
    }) => {
        return getStoreListApi(currentBrand!.code, geo).then(list => {
            if (mounted()) return list;
        }) as Promise<Store[]>;
    }, [currentBrand, mounted]);

    const changeCurrentStoreById = useCallback((id: Store['storeID']) => {
        dispatch(actions.setCurrentStore(id));
    }, [dispatch]);

    return {
        storeList,
        currentStore,
        currentStoreId,
        currentBrand,
        isInitLoading,
        isInitError,
        methods: {
            init,
            getStoreList,
            changeCurrentStoreById
        }
    };
};
