import React, { useState, useCallback } from 'react';
import styles from './index.module.css';
import { ReceiverList } from '../../components/ReceiverList';
import { ReceiverInfo } from 'modules/me';
import { StoreList } from '../../components/StoreList';
import { useStore } from 'modules/store';
import { DELIVERY_TYPE, OnFinish } from '../../models';
import { useOrderInfo } from '../../facades/useOrderInfo';


enum STEP {
    RECEIVER = 0,
    STORE = 1,
}

export interface Props {
    onFinish: OnFinish
}

export const DeliveryInfo: React.FC<Props> = ({
    onFinish
}) => {
    const { currentStoreId, receiver: initialReceiver } = useOrderInfo();
    const [ step, setStep ] = useState<STEP>(STEP.RECEIVER);
    const [ receiver, setReceiver ] = useState<ReceiverInfo | undefined>(initialReceiver);

    const handleFinish = useCallback((storeId: string) => {
        onFinish({
            deliveryType: DELIVERY_TYPE.DELIVERY,
            storeId,
            receiverInfo: receiver!
        })
    }, [receiver])

    const handleSelectReceiver = (r: ReceiverInfo) => {
        setReceiver(r);
        setStep(STEP.STORE);
    }


    return (
        <div className={styles.deliveryInfo}>
            {step === STEP.RECEIVER &&
                <ReceiverList initialSelectedId={receiver?.id} onNext={handleSelectReceiver}/>
            }
            {step === STEP.STORE &&
                <StoreList
                    geo={receiver!}
                    initialSelectedId={currentStoreId}
                    onNext={handleFinish}
                    deliveryType={DELIVERY_TYPE.DELIVERY}
                    goBack={() => setStep(STEP.RECEIVER)}
                />
            }
        </div>
    );
}