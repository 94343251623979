import { actions as authActions, selectors as authSelectors } from 'modules/auth/store';
import { queryString } from '../globalUtils';
import qs from 'querystring';
import Axios from 'axios';
import { store } from 'store';

export interface ErrorResponse {
    status: string;
    data: { message: string };
    xhr: any;
}
interface Response<T> {
    data: T;
    status: string;
    xhr: any;
};


export interface SuccessResponse<T> {
    data: T;
    code: number;
    message: string;
}
export interface ListResponse<T, R> {
    total: number;
    pageNum: number;
    pageSize: number;
    value: T[];
    references: R[];
}
export interface ItemResponse<T, R = never> {
    value: T;
    references: R[]
}


export const axiosAjax = <T>(params: any): Promise<Response<T>> => {
    if (params.type === 'POST' || params.type === 'PUT') {
        params = {...params,data: qs.stringify(params.data)}
    }


    let headers: { [key: string]: string } = {
        // Network_id: 1209,
        // 'Accept-Language': language,
        'Content-Type': 'application/x-www-form-urlencoded',
        ...(params.headers || {})
    }

    const accessToken = authSelectors.getAccessToken(store.getState());
    const currentUser = authSelectors.getCurrentUser(store.getState());
    const jwt = authSelectors.getJwt(store.getState());


    if (accessToken) {
        headers = {...headers, AUTHORIZATION: `bearer ${accessToken}`,  };
    } else if (jwt) {
        headers = {...headers, AUTHORIZATION: `${jwt}` };
    }
    // headers = { ...headers, 'NETWORK-ID': '138' }

    // if (currentUser) {
    //     const network = currentUser.joined_networks.find(item => !item.default);
    //     if (network) {
    //         headers = { ...headers, 'NETWORK-ID': `${network.id}` }
    //     }
    // }
    return (Axios({
        ...params,
        method: params.type,
        headers,
    }) as any as Promise<Response<T>>).catch(({ response }) => {
        if (response.status === 401) {
            store.dispatch(authActions.logout() as any);
        }
        return Promise.reject(response);
    });

}


export const ajaxGet = <T>(url: string, query?: { [key: string]: any }, headers?: {[key: string]: any}) => {
    url = query ? `${url}?${queryString.stringify(query)}` : url;
    console.log('ajaxGet url =>' + url);
    const params = {
        type: 'GET',
        url,
        headers
    }
    return axiosAjax<T>(params);
}

export const ajaxPost = <T>(url: string, data: { [key: string]: any }, headers?: {[key: string]: any}) => {
    return axiosAjax<T>({
        type: 'POST',
        url,
        data,
        headers
    })
}

export const ajaxPut = <T>(url: string, data: { [key: string]: any }) => {
    return axiosAjax<T>({
        type: 'PUT',
        url,
        data
    })
}

export const ajaxDelete = <T>(url: string, id?: string | number) => {
    const uri = id ? `${url}/id=${id}` : url;
    return axiosAjax<T>({
        type: 'DELETE',
        url: uri
    })
}

