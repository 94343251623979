import React, { useEffect } from 'react';
import background from './images/background_1.jpg';
import styles from './index.module.css';
import { BigBackgroundImage } from 'components/BigBackgroundImage';
import { useAccessAndOrderInfo } from 'context/accessAndOrderInfo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useI18n } from 'context/i18n';
import { ThemeDiv, useTheme } from 'modules/store';
import { queryString } from 'globalUtils'; 
import { languageGroups } from 'context/i18n';

export const Home: React.FC = () => {
    const { t ,setLang} = useI18n();
    const { hasOrderInfo, editOrderInfo } = useAccessAndOrderInfo();
    const { push } = useHistory();
    const { theme: { primary }, webHomeBanner_1 } = useTheme();
   
    useEffect(()=>{
        const search = queryString.parse(window.location.search);
        if(search && search.lang){
            const inLangGroup = languageGroups.find((lang)=>{ return lang.value === search.lang})
            inLangGroup && setLang(inLangGroup.value)
        }
    },[])
    const onClick = () => {
        if (!hasOrderInfo()) {
            editOrderInfo('/normal-meal');
        } else {
            push('/normal-meal')
        }
    }
    return (
        <div className={styles.home}>
            <BigBackgroundImage src={webHomeBanner_1}/>
            <ThemeDiv className={styles.button} onClick={onClick} type="primary">
                <div className={styles.content}>{t('home.orderNow')}</div>
                <div className={styles.rightArrow}>
                    <svg width="20px" height="20px" viewBox="0 0 16 16" version="1.1">
                        <title>right</title>
                        <desc>Created with Sketch.</desc>
                        <g id="页面-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                            <polygon id="right" fill={primary.color} fillRule="nonzero" transform="translate(8.000000, 8.000000) rotate(-180.000000) translate(-8.000000, -8.000000) " points="3.828 6.97145796 16 6.97145796 16 9.02854204 3.828 9.02854204 9.192 14.5456416 7.778 16 0 8 7.778 0 9.192 1.45435845"></polygon>
                        </g>
                    </svg>
                </div>
            </ThemeDiv>
        </div>
    )
}
