import { useMemo, useCallback } from 'react';
import { DELIVERY_TYPE } from 'modules/order-info';
import { selectors as orderInfoSelectors } from 'modules/order-info/store';
import { selectors as storeSelectors } from 'modules/store/store';
import { useSelector } from 'react-redux';
import { useI18n } from 'context/i18n';
import { useLocation, useHistory } from 'react-router-dom';


export const useHeaderOrderInfo = () => {
    const { t } = useI18n();
    const { push } = useHistory();
    const { pathname } = useLocation();
 
    const deliveryType = useSelector(orderInfoSelectors.getDeliveryType);
    const receiver = useSelector(orderInfoSelectors.getOrderReceiver);
    const currentStore = useSelector(storeSelectors.getCurrentStore);


    const deliveryTypeContent = useMemo(() => {
        if (deliveryType === DELIVERY_TYPE.DELIVERY) {
            return t(`orderType.${DELIVERY_TYPE.DELIVERY}`);
        }
        if (deliveryType === DELIVERY_TYPE.RAPID_PICK_UP) {
            return t(`orderType.${DELIVERY_TYPE.RAPID_PICK_UP}`);
        }
        if (deliveryType === DELIVERY_TYPE.LOCKER_PICK_UP) {
            return t(`orderType.${DELIVERY_TYPE.LOCKER_PICK_UP}`);
        }
        return '';
    }, [deliveryType, t]);

    const storeContent = useMemo(() => {
        if (currentStore) return currentStore.name;
        return '';
    }, [currentStore]);

    const receiverContent = useMemo(() => {
        if (!receiver) return '';
        return `${receiver.coordinateDesc} ${receiver.detailAddress}`;
    }, [receiver]);

    const editDeliveryType = useCallback(() => {
        push(`/order-info?redirect=${encodeURIComponent(pathname)}`)
    }, [push, pathname]);

    const editStore = useCallback(() => {
        const targetUrl = `/order-info?redirect=${encodeURIComponent(pathname)}`;
        if (deliveryType) {
            push(`${targetUrl}&edit=store`);
        } else {
            push(targetUrl);
        }
    }, [push, pathname, deliveryType]);
    
    const orderInfoList = (() => {
        const list = [{
            label:t('receiverInfo.shippingMethod'),
            buttonLabel: t('receiverInfo.selectShippingMethod'),
            content: deliveryTypeContent,
            onEditClick: editDeliveryType,
            onButtonClick: editDeliveryType,
        }, {
            label: t('receiverInfo.store'),
            buttonLabel: t('receiverInfo.selectStore'),
            content: storeContent,
            onEditClick: editStore,
            onButtonClick: editDeliveryType,
        }];
        if (deliveryType === DELIVERY_TYPE.DELIVERY) {
            list.push({
                label: t('receiverInfo.address'),
                buttonLabel: t('receiverInfo.fillInAddress'),
                content: receiverContent,
                onEditClick: editDeliveryType,
                onButtonClick: editDeliveryType,
            })
        }
        return list;
    })()


    return {
        list: orderInfoList,
    }
}