import { CountryCodeAndName } from './types';
export const list: CountryCodeAndName[] = [
    {
        "code": "US",
        "name": "미국"
    },
    {
        "code": "CN",
        "name": "중국"
    },
    {
        "code": "SG",
        "name": "싱가포르"
    },
    {
        "code": "TW",
        "name": "대만 (중국)"
    },
    {
        "code": "ad",
        "name": "안도라"
    },
    {
        "code": "AE",
        "name": "아랍 에미리트"
    },
    {
        "code": "AF",
        "name": "아프가니스탄"
    },
    {
        "code": "AL",
        "name": "알바니아"
    },
    {
        "code": "AM",
        "name": "아르메니아"
    },
    {
        "code": "AO",
        "name": "앙골라"
    },
    {
        "code": "AR",
        "name": "아르헨티나"
    },
    {
        "code": "AT",
        "name": "오스트리아"
    },
    {
        "code": "AU",
        "name": "호주"
    },
    {
        "code": "AZ",
        "name": "아제르바이잔"
    },
    {
        "code": "BD",
        "name": "방글라데시"
    },
    {
        "code": "BE",
        "name": "벨기에"
    },
    {
        "code": "BF",
        "name": "부키 나 파소"
    },
    {
        "code": "BG",
        "name": "불가리아"
    },
    {
        "code": "BH",
        "name": "바레인"
    },
    {
        "code": "BI",
        "name": "부룬디"
    },
    {
        "code": "BJ",
        "name": "베냉"
    },
    {
        "code": "BN",
        "name": "브루나이"
    },
    {
        "code": "BO",
        "name": "볼리비아"
    },
    {
        "code": "BR",
        "name": "브라질"
    },
    {
        "code": "BS",
        "name": "바하마"
    },
    {
        "code": "BW",
        "name": "보츠와나"
    },
    {
        "code": "BY",
        "name": "벨라루스"
    },
    {
        "code": "BZ",
        "name": "벨리즈"
    },
    {
        "code": "CA",
        "name": "캐나다"
    },
    {
        "code": "CF",
        "name": "중앙 아프리카 공화국"
    },
    {
        "code": "CG",
        "name": "콩고 공화국)"
    },
    {
        "code": "CH",
        "name": "스위스"
    },
    {
        "code": "CK",
        "name": "섬"
    },
    {
        "code": "CL",
        "name": "칠레"
    },
    {
        "code": "CM",
        "name": "카메룬"
    },
    {
        "code": "CO",
        "name": "콜롬비아"
    },
    {
        "code": "CR",
        "name": "코스타리카"
    },
    {
        "code": "CU",
        "name": "쿠바"
    },
    {
        "code": "CY",
        "name": "키프로스"
    },
    {
        "code": "CZ",
        "name": "체코 공화국"
    },
    {
        "code": "DE",
        "name": "독일"
    },
    {
        "code": "DJ",
        "name": "지부티"
    },
    {
        "code": "DK",
        "name": "덴마크"
    },
    {
        "code": "DZ",
        "name": "알제리"
    },
    {
        "code": "EC",
        "name": "에콰도르"
    },
    {
        "code": "EE",
        "name": "에스토니아"
    },
    {
        "code": "EG",
        "name": "이집트"
    },
    {
        "code": "ES",
        "name": "스페인"
    },
    {
        "code": "ET",
        "name": "에티오피아"
    },
    {
        "code": "FI",
        "name": "핀란드"
    },
    {
        "code": "FJ",
        "name": "피지"
    },
    {
        "code": "FR",
        "name": "프랑스"
    },
    {
        "code": "GA",
        "name": "가봉"
    },
    {
        "code": "GB",
        "name": "영국"
    },
    {
        "code": "GE",
        "name": "그루지야"
    },
    {
        "code": "GF",
        "name": "프랑스 령 기아나"
    },
    {
        "code": "GH",
        "name": "가나"
    },
    {
        "code": "GI",
        "name": "지브롤터"
    },
    {
        "code": "GM",
        "name": "감비아"
    },
    {
        "code": "GN",
        "name": "기니"
    },
    {
        "code": "GR",
        "name": "그리스"
    },
    {
        "code": "GT",
        "name": "과테말라"
    },
    {
        "code": "GY",
        "name": "가이아나"
    },
    {
        "code": "HK",
        "name": "홍콩 (중국)"
    },
    {
        "code": "HN",
        "name": "온두라스"
    },
    {
        "code": "HT",
        "name": "아이티"
    },
    {
        "code": "HU",
        "name": "헝가리"
    },
    {
        "code": "ID",
        "name": "인도네시아"
    },
    {
        "code": "IE",
        "name": "아일랜드"
    },
    {
        "code": "IL",
        "name": "이스라엘"
    },
    {
        "code": "IN",
        "name": "인도"
    },
    {
        "code": "IQ",
        "name": "이라크"
    },
    {
        "code": "IR",
        "name": "이란"
    },
    {
        "code": "IS",
        "name": "아이슬란드"
    },
    {
        "code": "IT",
        "name": "이탈리아"
    },
    {
        "code": "JM",
        "name": "자메이카"
    },
    {
        "code": "JO",
        "name": "요르단"
    },
    {
        "code": "JP",
        "name": "일본"
    },
    {
        "code": "KE",
        "name": "케냐"
    },
    {
        "code": "KG",
        "name": "키르기스스탄"
    },
    {
        "code": "KH",
        "name": "북한"
    },
    {
        "code": "KP",
        "name": "대한민국"
    },
    {
        "code": "KR",
        "name": "대한민국"
    },
    {
        "code": "KW",
        "name": "쿠웨이트"
    },
    {
        "code": "KZ",
        "name": "카자흐스탄"
    },
    {
        "code": "LA",
        "name": "라오스"
    },
    {
        "code": "LB",
        "name": "레바논"
    },
    {
        "code": "LI",
        "name": "리히텐슈타인"
    },
    {
        "code": "LK",
        "name": "스리랑카"
    },
    {
        "code": "LR",
        "name": "라이베리아"
    },
    {
        "code": "LS",
        "name": "레소토"
    },
    {
        "code": "LT",
        "name": "리투아니아"
    },
    {
        "code": "LU",
        "name": "룩셈부르크"
    },
    {
        "code": "LV",
        "name": "라트비아"
    },
    {
        "code": "LY",
        "name": "리비아"
    },
    {
        "code": "MA",
        "name": "모로코"
    },
    {
        "code": "MC",
        "name": "모나코"
    },
    {
        "code": "MD",
        "name": "몰도바"
    },
    {
        "code": "MG",
        "name": "마다가스카르"
    },
    {
        "code": "ML",
        "name": "말리"
    },
    {
        "code": "MM",
        "name": "미얀마"
    },
    {
        "code": "MN",
        "name": "몽골리아"
    },
    {
        "code": "MO",
        "name": "마카오 (중국)"
    },
    {
        "code": "MT",
        "name": "몰타"
    },
    {
        "code": "MU",
        "name": "모리셔스"
    },
    {
        "code": "MV",
        "name": "몰디브"
    },
    {
        "code": "MW",
        "name": "말라위"
    },
    {
        "code": "MX",
        "name": "멕시코"
    },
    {
        "code": "MY",
        "name": "말레이시아"
    },
    {
        "code": "MZ",
        "name": "모잠비크"
    },
    {
        "code": "NA",
        "name": "나미비아"
    },
    {
        "code": "NE",
        "name": "니제르"
    },
    {
        "code": "NG",
        "name": "나이지리아"
    },
    {
        "code": "NI",
        "name": "니카라과"
    },
    {
        "code": "NL",
        "name": "네덜란드"
    },
    {
        "code": "NO",
        "name": "노르웨이"
    },
    {
        "code": "NP",
        "name": "네팔"
    },
    {
        "code": "NR",
        "name": "나우루"
    },
    {
        "code": "NZ",
        "name": "뉴질랜드"
    },
    {
        "code": "OM",
        "name": "오만"
    },
    {
        "code": "PA",
        "name": "파나마"
    },
    {
        "code": "PE",
        "name": "페루"
    },
    {
        "code": "PF",
        "name": "프랑스 령 폴리네시아의"
    },
    {
        "code": "PG",
        "name": "파푸아 뉴기니"
    },
    {
        "code": "PH",
        "name": "필리핀 제도"
    },
    {
        "code": "PK",
        "name": "파키스탄"
    },
    {
        "code": "PL",
        "name": "폴란드"
    },
    {
        "code": "PT",
        "name": "포르투갈"
    },
    {
        "code": "PY",
        "name": "파라과이"
    },
    {
        "code": "QA",
        "name": "카타르"
    },
    {
        "code": "RO",
        "name": "루마니아"
    },
    {
        "code": "RU",
        "name": "러시아"
    },
    {
        "code": "SA",
        "name": "사우디 아라비아"
    },
    {
        "code": "SB",
        "name": "솔로몬 제도"
    },
    {
        "code": "SC",
        "name": "세이셸"
    },
    {
        "code": "SD",
        "name": "수단"
    },
    {
        "code": "SE",
        "name": "스웨덴"
    },
    {
        "code": "SI",
        "name": "슬로베니아"
    },
    {
        "code": "SK",
        "name": "슬로바키아"
    },
    {
        "code": "SL",
        "name": "시에라 리온"
    },
    {
        "code": "SM",
        "name": "산 마리노"
    },
    {
        "code": "SN",
        "name": "세네갈"
    },
    {
        "code": "SO",
        "name": "소말리아"
    },
    {
        "code": "SR",
        "name": "수리남"
    },
    {
        "code": "ST",
        "name": "상투 메 프린시 페"
    },
    {
        "code": "SV",
        "name": "엘살바도르"
    },
    {
        "code": "SY",
        "name": "시리아"
    },
    {
        "code": "SZ",
        "name": "스와질란드"
    },
    {
        "code": "TD",
        "name": "차드"
    },
    {
        "code": "TG",
        "name": "토고"
    },
    {
        "code": "TH",
        "name": "태국"
    },
    {
        "code": "TJ",
        "name": "타지키스탄"
    },
    {
        "code": "TL",
        "name": "동 티모르"
    },
    {
        "code": "TM",
        "name": "투르크 메니스탄"
    },
    {
        "code": "TN",
        "name": "튀니지"
    },
    {
        "code": "TO",
        "name": "통가"
    },
    {
        "code": "TR",
        "name": "터키"
    },
    {
        "code": "TZ",
        "name": "탄자니아"
    },
    {
        "code": "UA",
        "name": "우크라이나"
    },
    {
        "code": "UG",
        "name": "우간다"
    },
    {
        "code": "UY",
        "name": "우루과이"
    },
    {
        "code": "UZ",
        "name": "우즈베키스탄"
    },
    {
        "code": "VE",
        "name": "베네수엘라"
    },
    {
        "code": "VN",
        "name": "베트남"
    },
    {
        "code": "YE",
        "name": "예멘"
    },
    {
        "code": "ZA",
        "name": "남아프리카"
    },
    {
        "code": "ZM",
        "name": "잠비아"
    },
    {
        "code": "ZW",
        "name": "짐바브웨"
    }
]