import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectors } from 'modules/auth/store';


export const useCheckAccess = () => {
    const currentUser = useSelector(selectors.getCurrentUser);
    const isLogin = useMemo(() => {
        return !!currentUser;
    }, []);

    const isCanSsoLogin = () => !!window.localStorage.getItem("access_token");
    return {
        isLogin,
        isCanSsoLogin
    }
}