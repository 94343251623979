
import { action, payload } from 'ts-action';
import { getActionType } from '../constants';


export const setPromoCode = action(
    getActionType('Set Promo Code'),
    payload<{
        storeId: string,
        userId: number,
        code: string,
    }>()
)

export const changePromoCodeEnable = action(
    getActionType('Change Promo Code Enable'),
    payload<{
        storeId: string,
        userId: number,
        enable: boolean
    }>()
)
