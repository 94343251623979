import { useI18n } from 'context/i18n';
import { actions, selectors } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ActivityProductVO } from '../models';
import { useEffect } from 'react';
import { useStore } from 'modules/store';


export const useActivityProductDetail = (activityCode: string, activityProductId: string) => {
    const dispatch = useDispatch();
    const { lang } = useI18n();
    const { currentStore } = useStore();


    useEffect(() => {
        dispatch(actions.fetchActivityProductOfId(currentStore!.storeID, lang, activityCode, activityProductId));
    }, [currentStore, lang, activityCode, activityProductId]);

    const detail = useSelector<RootState, ActivityProductVO>(
        state => selectors.getActivityProductOfId(state, { language: lang, activityProductId })
    );

    return { detail };

}