import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { Category, Product, ActivityReference } from '../../models';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { Language } from 'context/i18n';
import * as categoryApi from '../../api/category';
import * as productApi from '../../api/product';
import * as selectors from '../selectors';

type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;

export const fetchShowInCategoryActivityListRequest = action(
    getActionType('Fetch Show_In_Category Activity List'),
    payload<{
        storeId: string,
        language: Language,
    }>()
);

export const fetchShowInCategoryActivityListSuccess = action(
    getActionType('Fetch Show_In_Category Activity List Success'),
    payload<{
        storeId: string,
        language: Language,
        list: ActivityReference[]
    }>()
);

export const fetchShowInCategoryActivityListFailure = action(
    getActionType('Fetch Show_In_Category Activity List Fail'),
    payload<{
        storeId: string,
        language: Language,
    }>()
);


export const fetchShowInCategoryActivityDetailSuccess = action(
    getActionType('Fetch Show_In_Category Activity Detail Success'),
    payload<{
        storeId: string,
        language: Language,
        activity: ActivityReference
    }>()
)


export function fetchShowInCategoryActivityList(storeId: string, language: Language): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        if (selectors.getActivityListFetchingOfLanguage(getState(), { language })) return Promise.resolve();
        
        dispatch(fetchShowInCategoryActivityListRequest({ storeId, language }));
        return categoryApi.getShowInCategoryActivityList(storeId, language)
            .then(({ data }) => {
                dispatch(fetchShowInCategoryActivityListSuccess({
                    storeId,
                    language,
                    list: data
                }));
            })
            .catch(err => {
                dispatch(fetchShowInCategoryActivityListFailure({
                    storeId,
                    language,
                }));
                return Promise.reject(err)
            });
    }
}
