import { useState, useEffect } from 'react';
import { getOrderList } from '../api';
import { Order } from '../models';
import { useMountedState } from 'react-use';
import { useI18n } from 'context/i18n';
import { useStore } from 'modules/store';

export const useOrderList = () => {
    const { getLang } = useI18n();
    const { currentBrand } = useStore();
    const mounted = useMountedState();

    const [ state, setState ] = useState<{
        loading: boolean,
        value: Order[],
        empty: boolean,
        error: string
    }>({
        loading: true,
        value: [],
        empty: false,
        error: ''
    });


    useEffect(() => {
        getOrderList(getLang(), currentBrand!.code).then(data => {
            console.log("data =>", data);
            if (data.code !== 200) return Promise.reject(data)
            if (mounted()) {
                setState({
                    loading: false,
                    value: data.data,
                    empty: data.data.length === 0,
                    error: ''
                })
            }
        }).catch((error) => {
            console.log("erro +>", error);
            if (mounted()) {
                setState({
                    loading: false,
                    value: [],
                    empty: false,
                    error: error?.data?.msg
                })
            }
        })
    }, [getLang, currentBrand]);

    return {
        ...state,
    };
}