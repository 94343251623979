

export enum IS_EXCEED {
    IS = '1', // 超出
    NO = '0' // 没超
}

export enum RECEIVER_INFO_DEFAULT_STATUS {
    IS = 1,
    NO = 0
}
export interface ReceiverInfo {

    id: string;
    // 收货人姓名
    receiverName: string;
    // 收货人手机号
    receiverMobile: string;
    // 收货人地址
    detailAddress?: string;
    defaultStatus: RECEIVER_INFO_DEFAULT_STATUS;

    // 类型
    tag: string;

    coordinateDesc: string;
    latitude: string;
    longitude: string;

    isExceed?: IS_EXCEED; // 是否超出范围
}
export interface PickUpReceiverInfo {
    // 收货人姓名
    receiverName: string;
    // 收货人手机号
    receiverMobile: string;
}