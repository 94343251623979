import React, { useState, useContext, useEffect } from 'react';
import { Brand } from '../models';
import { mergeDeepLeft } from 'ramda';
import { useStore } from '../facades/useStore';

type BrandTheme = {
    webHomeBanner_1: string;
    webImageFontLogo: string;
    webFontLogo: string;
    downloadAppQrcode: string;
    webDeliveryBanner_1: string;
    webHeaderMenuImage_1: string;
    webMeBanner_1: string;
    webMenuBanner_1: string;
} & Pick<Brand, 'mobileHomeImages' | 'theme'>;
const contextValue: BrandTheme = {
    mobileHomeImages:'',
    webHomeBanner_1: '',
    webImageFontLogo: '',
    webFontLogo: '',
    downloadAppQrcode: '',
    webDeliveryBanner_1: '',
    webHeaderMenuImage_1: '',
    webMeBanner_1: '',
    webMenuBanner_1: '',
    theme:{
        primary:{
            bg:'fff',
            color:'fff'
        },
        secondary:{
            bg:'fff',
            color:'fff'
        }
    }
}


export const themeContext = React.createContext(contextValue);
export const  useTheme = () => {
    return useContext(themeContext);
}

interface ProviderProps {
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ProviderProps> = ({
    children
}) => {
    const [ brandThemeInfo, setBrandThemeInfo ]  = useState<BrandTheme>(contextValue); 

    const { currentBrand } = useStore();


    useEffect(() => {
        if (currentBrand) {
            let { mobileHomeImages, theme, abbreviation } = currentBrand;
            setBrandThemeInfo(old => mergeDeepLeft({
                theme,
                mobileHomeImages: `/repast/${mobileHomeImages.replace(/^\//, '')}`,
                webHomeBanner_1: `/repast/images/${abbreviation}/web_home_banner_1.png`,
                webImageFontLogo: `/repast/images/${abbreviation}/web_image_font_logo.png`,
                webFontLogo: `/repast/images/${abbreviation}/web_font_logo.png`,
                downloadAppQrcode: `/repast/images/${abbreviation}/download_app_qrcode.png`,
                webDeliveryBanner_1: `/repast/images/${abbreviation}/web_delivery_banner_1.png`,
                webHeaderMenuImage_1: `/repast/images/${abbreviation}/web_header_menu_image_1.png`,
                webMeBanner_1: `/repast/images/${abbreviation}/web_me_banner_1.png`,
                webMenuBanner_1: `/repast/images/${abbreviation}/web_menu_banner_1.png`,
            }, old));
        }
    }, [currentBrand]);

    return (
        <themeContext.Provider value={{...brandThemeInfo}}>
            {children}
        </themeContext.Provider>
    )
}
