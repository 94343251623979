import React, { useEffect } from 'react';
import styles from './index.module.css';
import { ProductInCartItem } from '../ProductInCartItem';
import { useProductInCartAction } from '../../facades/useProductInCartAction'
import { ProductInCartVO } from '../../models';
interface Props {
    className?: string;
    productList: ProductInCartVO[];
    showDiscountPlaceholder: boolean;
}

export const ProductInCartList: React.FC<Props> = ({
    className = '',
    productList,
    showDiscountPlaceholder
}) => {

    const { methods: { deleteProduct, changeCount, onProductClick, onChangeClick, changeRemark } } = useProductInCartAction();
    return (
        <div className={`${className} ${styles.productInCartList}`}>
            {productList.map(item => (
                <ProductInCartItem
                    key={item.id}
                    productInCart={item}
                    onCountChange={changeCount}
                    onDelete={deleteProduct}
                    onItemClick={onProductClick}
                    onChangeClick={onChangeClick}
                    onChangeRemark={changeRemark}
                    showDiscountPlaceholder={showDiscountPlaceholder}
                />
            ))}
        </div>
    )
}