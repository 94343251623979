import { useMemo, useCallback } from 'react';
import { DELIVERY_TYPE } from '../models';
import { ReceiverInfo, PickUpReceiverInfo } from 'modules/me';
import { selectors as orderInfoSelectors, actions } from '../store';
import { selectors as storeSelectors, actions as storeActions } from 'modules/store/store';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from 'context/i18n';
import { useLocation, useHistory } from 'react-router-dom';



export const useOrderInfo = () => {
    const dispatch = useDispatch();
    const deliveryType = useSelector(orderInfoSelectors.getDeliveryType);
    const receiver = useSelector(orderInfoSelectors.getOrderReceiver);
    const pickUpReceiver = useSelector(orderInfoSelectors.getOrderPickUpReceiver);
    const mealTime = useSelector(orderInfoSelectors.getOrderMealTime);
    const currentStore = useSelector(storeSelectors.getCurrentStore);
    const remark = useSelector(orderInfoSelectors.getRemark);

    const setOrderInfo = useCallback((params: {
        storeId: string,
        deliveryType: DELIVERY_TYPE,
        receiverInfo?: ReceiverInfo,
        pickUpReceiverInfo?: PickUpReceiverInfo
    }) => {
        dispatch(actions.setOrderInfo(params));
        dispatch(storeActions.setCurrentStore(params.storeId));
    }, []);
    const setOrderMealTime = useCallback((params: { time: number | '0' }) => {
        dispatch(actions.setMealTime(params));
    }, []);
    const setRemark = useCallback((remark: string) => {
        dispatch(actions.setRemark(remark));
    }, []);

    return {
        deliveryType: deliveryType || DELIVERY_TYPE.RAPID_PICK_UP,
        receiver,
        pickUpReceiver,
        remark,
        mealTime,
        currentStoreId: currentStore?.storeID,
        currentStoreName: currentStore?.name || '',
        currentStore,
        methods: {
            setOrderInfo,
            setOrderMealTime,
            setRemark
        }
    }
}