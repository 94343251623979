import { useEffect, useState, useCallback } from 'react';
import { getOrderOfId, changeToOfflinePayMethod,getOnlinePayKey } from '../api';
import { Order } from '../models';
import { useMountedState } from 'react-use';
import { useI18n } from 'context/i18n';
import { decrypt } from 'modules/auth/store/encrypt';

const IV = "a6s2qm4rfq9o5uh7";
const KEY = "saba2020";


export const useOrderDetail = (id: Order['id']) => {
    const { getLang } = useI18n();

    const [ state, setState ] = useState<{
        loading: boolean;
        order?: Order;
    }>({ loading: true, order: undefined });

    const mounted = useMountedState();


    const getOrderDetail = useCallback(() => {
        setState(s => ({ ...s, loading: true }));
        return getOrderOfId(getLang(), id).then(data => {
            if (mounted()) {
                const { data: order } = data;
                setState({
                    loading: false,
                    order,
                })
            }
        }).catch(err => {
            if (mounted()) {
                setState(s => ({ ...s, loading: false }));
                return Promise.reject(err);
            }
        })
    }, [id, getLang, setState])


    const changePayMethodToOffline = useCallback(() => {
        setState(s => ({ ...s, loading: true }));
        return changeToOfflinePayMethod(state.order!.id).then((data) => {
            if(data?.code!==200){
                return Promise.reject(data);
            }
            if (mounted()) {
                return getOrderDetail();
            }
        }).catch(err => {
            if (mounted()) {
                setState(s => ({ ...s, loading: false }));
                return Promise.reject(err);
            }
        })
    }, [setState, state, getOrderDetail]);


    const getKey = useCallback(() => {
        setState(s => ({ ...s, loading: true }));
        return getOnlinePayKey(id).then(({data})=>{
            const { value : { secret } } = data;
            const key = decrypt(secret,KEY,IV);
            console.log(key);
            return Promise.resolve(key)
        }).catch(err => {
            setState(s => ({ ...s, loading: false }));
            return Promise.reject(err);
        })

    }, [setState,getOnlinePayKey]);

    return {
        ...state,
        methods: { changePayMethodToOffline, getOrderDetail,getKey }
    }
}