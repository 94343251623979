import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styles from './index.module.css';
import { useHistory } from 'react-router-dom';

// import background from '../../images/background.png';
import { useI18n } from 'context/i18n';
import { useUrlParams } from 'hooks/useUrlParams';
import { useStore, useTheme } from 'modules/store';
import { DELIVERY_TYPE, OnFinish } from '../../models';
import { useOrderInfo } from '../../facades/useOrderInfo';

import { BigBackgroundImage } from 'components/BigBackgroundImage';
import { DeliveryInfo } from '../DeliveryInfo';
import { PickUpInfo } from '../PickUpInfo';

const deliveryTypeList: DELIVERY_TYPE[] = [
    DELIVERY_TYPE.RAPID_PICK_UP,
    DELIVERY_TYPE.DELIVERY,
    DELIVERY_TYPE.LOCKER_PICK_UP
];

export const OrderInfo: React.FC = () => {
    const { push } = useHistory();
    const { currentBrand } = useStore();
    const { theme: { secondary }, webDeliveryBanner_1 } = useTheme();
    const { methods: { setOrderInfo }, currentStoreId, deliveryType: initialDeliveryType } = useOrderInfo();
    const { params, changeParams } = useUrlParams({
        defaultValues: { redirect: '', type: `${initialDeliveryType}` }
    });

    const { t } = useI18n();
    const [deliveryType, setDeliveryType] = useState<DELIVERY_TYPE>();

    useEffect(() => {
        const eatTypes = currentBrand?.eatTypes || [];
        if (params.type && eatTypes.includes(Number(params.type))) {
            setDeliveryType(Number(params.type));
        } else if (eatTypes.includes(initialDeliveryType)) {
            setDeliveryType(initialDeliveryType);
        } else {
            setDeliveryType(eatTypes[0]);
        }
    }, [params.type, currentBrand, initialDeliveryType]);

    const deliveryButtonActiveStyle = useCallback((d: DELIVERY_TYPE): CSSProperties => {
        return deliveryType === d ? { background: secondary.bg, color: secondary.color } : {};
    }, [deliveryType, secondary.bg, secondary.color]);

    const handleFinish: OnFinish = (result) => {
        if (result.storeId === currentStoreId && params.redirect) {
            push(decodeURIComponent(params.redirect));
        } else {
            push('/normal-meal');
        }
        setOrderInfo(result);
    };

    const changeDeliveryType = (t: DELIVERY_TYPE) => {
        changeParams({ type: `${t}` });
    };

    return (
        <div className={styles.orderInfo}>
            <BigBackgroundImage src={webDeliveryBanner_1} />
            <div className={styles.deliveryType}>
                <div className={styles.deliveryTypeTitle}>
                    {t('receiverInfo.selectShippingMethod1')}
                </div>
                {currentBrand?.eatTypes && (
                    <div
                        className={`${styles.buttons} ${currentBrand?.eatTypes?.length > 1 ? styles.justifyCenter : styles.justifyAround}`}
                    >
                        {deliveryTypeList.map((dt) => currentBrand?.eatTypes?.includes(dt) ? (
                            <div
                                key={dt}
                                className={`${styles.button} ${styles.pickUpButton}`}
                                style={deliveryButtonActiveStyle(dt)}
                                onClick={() => changeDeliveryType(dt)}
                            >{t(`orderType.${dt}`)}</div>
                        ) : null)}
                    </div>
                )}
            </div>
            <div className={styles.container}>
                <div className={styles.orderInfoDetail}>
                    {deliveryType === DELIVERY_TYPE.DELIVERY && <DeliveryInfo onFinish={handleFinish} />}
                    {(deliveryType === DELIVERY_TYPE.RAPID_PICK_UP || deliveryType === DELIVERY_TYPE.LOCKER_PICK_UP) && (
                        <PickUpInfo deliveryType={deliveryType} onFinish={handleFinish} />
                    )}
                </div>
            </div>
        </div>
    );
};

