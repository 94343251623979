import React, { useMemo, useCallback } from 'react';
import styles from './index.module.css';
import { ReceiverInfo, RECEIVER_INFO_DEFAULT_STATUS } from '../../models';


import editIcon from 'assets/edit.png';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';


interface Props {
    className?: string;
    receiverInfo: ReceiverInfo;
    selectedId?: ReceiverInfo['id'];
    onClick?: () => void;
    onEditClick: (receiverInfo: ReceiverInfo) => void
}

export const ReceiverInfoItem: React.FC<Props> = ({
    className = '',
    receiverInfo,
    selectedId,
    onClick = () => {},
    onEditClick
}) => {
    const { theme: { secondary } } = useTheme();
    const { t } = useI18n();
    const selected = useMemo(() => {
        return receiverInfo.id === selectedId;
    }, [selectedId, receiverInfo]);

    const handleEditClick = useCallback((event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
        onEditClick(receiverInfo)
        
    }, [onEditClick]);

    return (
        <div
            className={`${styles.receiverInfoItem} ${className} ${selected ? styles.selected : ''}` }
            style={selected ? {borderColor: secondary.bg} : {}}
            onClick={onClick}
        >
            <div className={styles.info}>
                <div className={styles.userName}>
                    {receiverInfo.receiverName}
                    <img className={styles.editIcon} onClick={handleEditClick} src={editIcon} alt=""/>
                </div>
                <div className={styles.userMobile}>
                    {receiverInfo.receiverMobile}
                </div>
                <div className={styles.myAddress}>
                    {receiverInfo.defaultStatus === RECEIVER_INFO_DEFAULT_STATUS.IS &&
                        <div className={styles.default}>{t('address.default')}</div>
                    }
                    <div className={styles.address}>{receiverInfo.coordinateDesc}{receiverInfo.detailAddress}</div>
                </div>

            </div>
        </div>
    )
}