import React, { useMemo } from 'react';

import styles from './index.module.css';
import { ActivityProductInOrder } from '../../models';
import { Language, useI18n } from 'context/i18n';
import { SUB_TASTE_RADIO } from 'modules/product-menu';
import { getPrice } from 'globalUtils';

// 获取自定义口味的文案
function getCustomText(
    productInOrder: ActivityProductInOrder,
    t: (s: string) => string,
    lang: Language
): string {
    const { taste } = productInOrder;

    // 中文的拼中文逗号
    const joinText = /ch/.test(lang) ? '，' : ',';

    return taste.reduce<string[]>((acc, item) => {
        if (item.selected === SUB_TASTE_RADIO.NORMAL) return acc;

        const middleText = /ch/.test(lang)  ? '' : ' ';

        return [...acc, `${t(`taste.child.radio.${item.selected}`)}${middleText}${item.name}`];

    }, []).join(joinText);
}

interface Props {
    activityProductInOrder: ActivityProductInOrder;
}

export const ActivityProductInOrderItem: React.FC<Props> = ({
    activityProductInOrder,
}) => {
    const { t, getLang } = useI18n();
    

    const priceTextNode = useMemo(() => {
        const originPrice = getPrice(activityProductInOrder.activityProduct.product.price, activityProductInOrder.buyNum);

        return (
            <>
                <div aria-label="Price" className={styles.salePrice}>${activityProductInOrder.salePrice}</div>
                <div className={styles.unitPrice}>{originPrice}</div>
                <div className="clear"></div>
            </>
        )
    }, [activityProductInOrder]);

    const { activityProduct, buyNum: count } = activityProductInOrder;
    const { product } = activityProduct;
    return (
        <div className={styles.productInOrderItem}>
            <div className={styles.productInOrderInfo}>
                <div className={styles.left}>
                    <img className={styles.img} src={product.images[0].url} alt=""/>
                </div>
                <div className={styles.middle}>
                    <div className={styles.name}>{product.name}</div>
                    <div className={styles.price}>{priceTextNode}</div>
                    <div className={styles.custom}>{getCustomText(activityProductInOrder, t, getLang())}</div>
                </div>
                <div className={styles.right}>
                    <div className={styles.count}>{t('order.amount')}：{count}</div>
                    
                    {/* <div className={styles.blank}></div>
                    <div className={`${styles.subTotalPrice}`}>{t('statements.summary.subtotal')}:${(product.price * count).toFixed(2)}</div> */}
                </div>
            </div>
            <div className={styles.discountList}>

                <div className={styles.discountItem}>{`${t('activity.name')}: ${activityProduct.activity.name}`}</div>

            </div>
            { activityProductInOrder.remark &&
                <div className={styles.remarkWrapper}>
                    <div className={styles.remarkLabel}>{`${t('statements.remark')}:`}</div>
                    <div className={styles.remark}>{activityProductInOrder.remark}</div>
                    <div className="clear"></div>
                </div>
            }
        </div>
    )
}