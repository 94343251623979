import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useI18n } from 'context/i18n';

const formatTime = (ts: string | number): string => dayjs(Number(ts)).format('MM-DD-YYYY HH:mm');


export const useActivityTime = (activity?: { startTime?: number, endTime?: number }) => {
    const { t } = useI18n();

    const activityTime = useMemo(() => {
        if (!activity) return '';
        const { startTime, endTime } = activity;
        if (startTime && endTime) {
            return `${formatTime(startTime)} -- ${formatTime(endTime)}`;
        }
        if (startTime) {
            return `${t('activity.time.start')}: ${formatTime(startTime)}`;
        }
        if (endTime) {
            return `${t('activity.time.end')}: ${formatTime(endTime)}`;
        }
        return '';
    }, [activity]);
    return {activityTime}

}