import React from 'react';
import { Modal } from 'components/Modal';
import { NotAuthorized, Props } from './NotAuthorized';

export const NotAuthorizedModal: React.FC<{
    visable: boolean,
    onCancel: () => void
} & Props> = ({visable, onCancel, ...props}) => {
    return (
        <Modal
            visible={visable}
            width={450}
            style={{top: '300px'}}
            mask={true}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={false}
        >
            <NotAuthorized {...props}/>
        </Modal>
    )
}


