import React, { useCallback, useState, useEffect, useMemo, CSSProperties } from 'react';

import styles from './index.module.css';
import menuImage from './images/menu-image.png';
import { useCategoryList, CategoryListType, MODEL_TYPE } from 'modules/product-menu';
import { useLocation, useHistory } from 'react-router-dom';
import { useI18n } from 'context/i18n';
import { ACTIVITY_TYPE } from 'modules/product-menu/models';
import { useTheme } from 'modules/store';

const NORMAL_MEAL_PATH = '/normal-meal/category';
const MENU_IN_ACTIVITY_PATH = '/normal-meal/activity';
const MENU_IN_KILL_ACTIVITY_PATH = '/normal-meal/kill-activity';

interface Props {
    className?: string,
    style?: CSSProperties,
}
export const HeaderNormalMealMenu: React.FC<Props> = ({
    className = '',
    style = {},
}) => {
    const { theme: { primary }, webHeaderMenuImage_1 } = useTheme();
    const { pathname } = useLocation();
    const [ showMenu, setShowMenu ] = useState<boolean>(false);

    useEffect(() => {
        setShowMenu(false);
    }, [pathname]);
    const { push } = useHistory();

    const { showList, list, loading } = useCategoryList();
    const { t } = useI18n();
    const onMoreClick = useCallback(() => {
        if (pathname !== NORMAL_MEAL_PATH) {
            push(NORMAL_MEAL_PATH);
        }
    }, [pathname]);

    const handleItem = useCallback((item: CategoryListType) => {
        if (item.type === MODEL_TYPE.CATEGORY) {
            if (!(new RegExp(`${NORMAL_MEAL_PATH}/${item.code}`)).test(pathname)) {
                push(`${NORMAL_MEAL_PATH}/${item.code}`);
            }
        }
        if (item.type === MODEL_TYPE.ACTIVITY) {
            if (item.activityType === ACTIVITY_TYPE.NORMAL && !(new RegExp(`${MENU_IN_ACTIVITY_PATH}/${item.code}`)).test(pathname)) {
                push(`${MENU_IN_ACTIVITY_PATH}/${item.code}`);
            } else if (item.activityType === ACTIVITY_TYPE.KILL && !(new RegExp(`${MENU_IN_KILL_ACTIVITY_PATH}/${item.code}`)).test(pathname)) {
                push(`${MENU_IN_KILL_ACTIVITY_PATH}/${item.code}`);
            }
        }
    }, [pathname]);

    const sliceList = useMemo(() => {
        const list = showList.slice(0, 24);
        return { list, more: showList.length > 24 };
    }, [showList]);
    return (
        <div
            className={className}
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
            style={style}
        >
            <div style={{height: '100%'}} onClick={onMoreClick}>{t('home.menu')}</div>
            { showMenu &&
                <div className={styles.productMenuWrapper}>
                    <div className={styles.productMenu}>
                        {!loading &&
                            <div className={styles.productList}>
                                { sliceList.list.map(item => (
                                    <div
                                        key={item.code}
                                        className={styles.productItem}
                                        style={{color: primary.bg}}
                                        onClick={() => handleItem(item)}
                                    >{item.name}</div>
                                )) }
                                { sliceList.more && <div className={styles.productItem} style={{color: primary.bg}} onClick={onMoreClick}>...</div> }
                                <div className="clear"></div>
                            </div>
                        }
                        <div className={styles.moreProductWrapper}>
                            <img className={styles.menuImage}  src={webHeaderMenuImage_1} alt=""/>
                            <div className={styles.moreText} style={{color: primary.bg}} onClick={onMoreClick}>MORE -></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}