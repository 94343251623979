import { useState, useCallback } from 'react';

interface State<T> {
    loading: boolean;
    empty: boolean;
    list: T[];
    total: number;
    error?: string;
}


type SetState<T> = (p: Partial<State<T>>) => void

export const useListState = <T>(initialState: Partial<State<T>> = {}): [State<T>, SetState<T>] => {
    const [ state, set ] = useState<State<T>>({
        loading: false,
        empty: false,
        list: [],
        total: 0,
        ...initialState,
    });

    const setState = useCallback((p: Partial<State<T>>) => {
        set(s => ({...s, ...p}));
    }, [set])

    return [state, setState];
}
