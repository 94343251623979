import { CountryCodeAndName } from './types';
export const list: CountryCodeAndName[] = [
    {
        "code": "US",
        "name": "アメリカ"
    },
    {
        "code": "CN",
        "name": "中国"
    },
    {
        "code": "SG",
        "name": "シンガポール"
    },
    {
        "code": "TW",
        "name": "台湾（中国）"
    },
    {
        "code": "AD",
        "name": "アンドラ"
    },
    {
        "code": "AE",
        "name": "アラブ首長国連邦"
    },
    {
        "code": "AF",
        "name": "アフガニスタン"
    },
    {
        "code": "AL",
        "name": "アルバニア"
    },
    {
        "code": "AM",
        "name": "アルメニア"
    },
    {
        "code": "AO",
        "name": "アンゴラ"
    },
    {
        "code": "AR",
        "name": "アルゼンチン"
    },
    {
        "code": "AT",
        "name": "オーストリア"
    },
    {
        "code": "AU",
        "name": "オーストラリア"
    },
    {
        "code": "AZ",
        "name": "アゼルバイジャン"
    },
    {
        "code": "BD",
        "name": "バングラデシュ"
    },
    {
        "code": "BE",
        "name": "ベルギー"
    },
    {
        "code": "BF",
        "name": "ブルキナファソ"
    },
    {
        "code": "BG",
        "name": "ブルガリア"
    },
    {
        "code": "BH",
        "name": "バーレーン"
    },
    {
        "code": "BI",
        "name": "ブルンジ"
    },
    {
        "code": "BJ",
        "name": "ベナン"
    },
    {
        "code": "BN",
        "name": "ブルネイ"
    },
    {
        "code": "BO",
        "name": "ボリビア"
    },
    {
        "code": "BR",
        "name": "ブラジル"
    },
    {
        "code": "BS",
        "name": "バハマ"
    },
    {
        "code": "BW",
        "name": "ボツワナ"
    },
    {
        "code": "BY",
        "name": "ベラルーシ"
    },
    {
        "code": "BZ",
        "name": "ベリーズ"
    },
    {
        "code": "CA",
        "name": "カナダ"
    },
    {
        "code": "CF",
        "name": "中央アフリカ共和国"
    },
    {
        "code": "CG",
        "name": "コンゴ共和国）"
    },
    {
        "code": "CH",
        "name": "スイス"
    },
    {
        "code": "CK",
        "name": "島"
    },
    {
        "code": "CL",
        "name": "チリ"
    },
    {
        "code": "CM",
        "name": "カメルーン"
    },
    {
        "code": "CO",
        "name": "コロンビア"
    },
    {
        "code": "CR",
        "name": "コスタリカ"
    },
    {
        "code": "CU",
        "name": "キューバ"
    },
    {
        "code": "CY",
        "name": "キプロス"
    },
    {
        "code": "CZ",
        "name": "チェコ共和国"
    },
    {
        "code": "DE",
        "name": "ドイツ"
    },
    {
        "code": "DJ",
        "name": "ジブチ"
    },
    {
        "code": "DK",
        "name": "デンマーク"
    },
    {
        "code": "DZ",
        "name": "アルジェリア"
    },
    {
        "code": "EC",
        "name": "エクアドル"
    },
    {
        "code": "EE",
        "name": "エストニア"
    },
    {
        "code": "EG",
        "name": "エジプト"
    },
    {
        "code": "ES",
        "name": "スペイン"
    },
    {
        "code": "ET",
        "name": "エチオピア"
    },
    {
        "code": "FI",
        "name": "フィンランド"
    },
    {
        "code": "FJ",
        "name": "フィジー"
    },
    {
        "code": "FR",
        "name": "フランス"
    },
    {
        "code": "GA",
        "name": "ガボン"
    },
    {
        "code": "GB",
        "name": "イギリス"
    },
    {
        "code": "GE",
        "name": "ジョージア"
    },
    {
        "code": "GF",
        "name": "仏領ギアナ"
    },
    {
        "code": "GH",
        "name": "ガーナ"
    },
    {
        "code": "GI",
        "name": "ジブラルタル"
    },
    {
        "code": "GM",
        "name": "ガンビア"
    },
    {
        "code": "GN",
        "name": "ギニア"
    },
    {
        "code": "GR",
        "name": "ギリシャ"
    },
    {
        "code": "GT",
        "name": "グアテマラ"
    },
    {
        "code": "GY",
        "name": "ガイアナ"
    },
    {
        "code": "HK",
        "name": "香港（中国）"
    },
    {
        "code": "HN",
        "name": "ホンジュラス"
    },
    {
        "code": "HT",
        "name": "ハイチ"
    },
    {
        "code": "HU",
        "name": "ハンガリー"
    },
    {
        "code": "ID",
        "name": "インドネシア"
    },
    {
        "code": "IE",
        "name": "アイルランド"
    },
    {
        "code": "IL",
        "name": "イスラエル"
    },
    {
        "code": "IN",
        "name": "インド"
    },
    {
        "code": "IQ",
        "name": "イラク"
    },
    {
        "code": "IR",
        "name": "イラン"
    },
    {
        "code": "IS",
        "name": "アイスランド"
    },
    {
        "code": "IT",
        "name": "イタリア"
    },
    {
        "code": "JM",
        "name": "ジャマイカ"
    },
    {
        "code": "JO",
        "name": "ヨルダン"
    },
    {
        "code": "JP",
        "name": "日本"
    },
    {
        "code": "KE",
        "name": "ケニア"
    },
    {
        "code": "KG",
        "name": "キルギス"
    },
    {
        "code": "KH",
        "name": "カンボジア"
    },
    {
        "code": "KP",
        "name": "北朝鮮"
    },
    {
        "code": "KR",
        "name": "韓国"
    },
    {
        "code": "KW",
        "name": "クウェート"
    },
    {
        "code": "KZ",
        "name": "カザフスタン"
    },
    {
        "code": "LA",
        "name": "ラオス"
    },
    {
        "code": "LB",
        "name": "レバノン"
    },
    {
        "code": "LI",
        "name": "リヒテンシュタイン"
    },
    {
        "code": "LK",
        "name": "スリランカ"
    },
    {
        "code": "LR",
        "name": "リベリア"
    },
    {
        "code": "LS",
        "name": "レソト"
    },
    {
        "code": "LT",
        "name": "リトアニア"
    },
    {
        "code": "LU",
        "name": "ルクセンブルク"
    },
    {
        "code": "LV",
        "name": "ラトビア"
    },
    {
        "code": "LY",
        "name": "リビア"
    },
    {
        "code": "MA",
        "name": "モロッコ"
    },
    {
        "code": "MC",
        "name": "モナコ"
    },
    {
        "code": "MD",
        "name": "モルドバ"
    },
    {
        "code": "MG",
        "name": "マダガスカル"
    },
    {
        "code": "ML",
        "name": "マリ"
    },
    {
        "code": "MM",
        "name": "ミャンマー"
    },
    {
        "code": "MN",
        "name": "モンゴル"
    },
    {
        "code": "MO",
        "name": "マカオ（中国）"
    },
    {
        "code": "MT",
        "name": "マルタ"
    },
    {
        "code": "MU",
        "name": "モーリシャス"
    },
    {
        "code": "MV",
        "name": "モルディブ"
    },
    {
        "code": "MW",
        "name": "マラウイ"
    },
    {
        "code": "MX",
        "name": "メキシコ"
    },
    {
        "code": "MY",
        "name": "マレーシア"
    },
    {
        "code": "MZ",
        "name": "モザンビーク"
    },
    {
        "code": "NA",
        "name": "ナミビア"
    },
    {
        "code": "NE",
        "name": "ニジェール"
    },
    {
        "code": "NG",
        "name": "ナイジェリア"
    },
    {
        "code": "NI",
        "name": "ニカラグア"
    },
    {
        "code": "NL",
        "name": "オランダ"
    },
    {
        "code": "NO",
        "name": "ノルウェー"
    },
    {
        "code": "NP",
        "name": "ネパール"
    },
    {
        "code": "NR",
        "name": "ナウル"
    },
    {
        "code": "NZ",
        "name": "ニュージーランド"
    },
    {
        "code": "OM",
        "name": "オマーン"
    },
    {
        "code": "PA",
        "name": "パナマ"
    },
    {
        "code": "PE",
        "name": "ペルー"
    },
    {
        "code": "PF",
        "name": "フランス領ポリネシア"
    },
    {
        "code": "PG",
        "name": "パプアニューギニア"
    },
    {
        "code": "PH",
        "name": "フィリピン"
    },
    {
        "code": "PK",
        "name": "パキスタン"
    },
    {
        "code": "PL",
        "name": "ポーランド"
    },
    {
        "code": "PT",
        "name": "ポルトガル"
    },
    {
        "code": "PY",
        "name": "パラグアイ"
    },
    {
        "code": "QA",
        "name": "カタール"
    },
    {
        "code": "RO",
        "name": "ルーマニア"
    },
    {
        "code": "RU",
        "name": "ロシア"
    },
    {
        "code": "SA",
        "name": "サウジアラビア"
    },
    {
        "code": "SB",
        "name": "ソロモン諸島"
    },
    {
        "code": "SC",
        "name": "セイシェル"
    },
    {
        "code": "SD",
        "name": "スーダン"
    },
    {
        "code": "SE",
        "name": "スウェーデン"
    },
    {
        "code": "SI",
        "name": "スロベニア"
    },
    {
        "code": "SK",
        "name": "スロバキア"
    },
    {
        "code": "SL",
        "name": "シエラレオネ"
    },
    {
        "code": "SM",
        "name": "サンマリノ"
    },
    {
        "code": "SN",
        "name": "セネガル"
    },
    {
        "code": "SO",
        "name": "ソマリア"
    },
    {
        "code": "SR",
        "name": "スリナム"
    },
    {
        "code": "ST",
        "name": "サントメプリンシペ"
    },
    {
        "code": "SV",
        "name": "サルバドール"
    },
    {
        "code": "SY",
        "name": "シリア"
    },
    {
        "code": "SZ",
        "name": "スワジランド"
    },
    {
        "code": "TD",
        "name": "チャド"
    },
    {
        "code": "TG",
        "name": "トーゴ"
    },
    {
        "code": "TH",
        "name": "タイ"
    },
    {
        "code": "TJ",
        "name": "タジキスタン"
    },
    {
        "code": "TL",
        "name": "東ティモール"
    },
    {
        "code": "TM",
        "name": "トルクメニスタン"
    },
    {
        "code": "TN",
        "name": "チュニジア"
    },
    {
        "code": "TO",
        "name": "トンガ"
    },
    {
        "code": "TR",
        "name": "七面鳥"
    },
    {
        "code": "TZ",
        "name": "タンザニア"
    },
    {
        "code": "UA",
        "name": "ウクライナ"
    },
    {
        "code": "UG",
        "name": "ウガンダ"
    },
    {
        "code": "UY",
        "name": "ウルグアイ"
    },
    {
        "code": "UZ",
        "name": "ウズベキスタン"
    },
    {
        "code": "VE",
        "name": "ベネズエラ"
    },
    {
        "code": "VN",
        "name": "ベトナム"
    },
    {
        "code": "YE",
        "name": "イエメン"
    },
    {
        "code": "ZA",
        "name": "南アフリカ"
    },
    {
        "code": "ZM",
        "name": "ザンビア"
    },
    {
        "code": "ZW",
        "name": "ジンバブエ"
    }
]