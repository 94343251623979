import {
    on,
    reducer as createReducer,
} from 'ts-action';
import {
    addProductToCartSuccess,
    mergeProductInCartSuccess,
    deleteProductInCartSuccess,
    clearSuccess,


    putProductInCartEntity,
    deleteProductInCartEntity
} from './actions';
import {
    setPromoCode,
    changePromoCodeEnable,
} from './actions/coupon'
import {

    addActivityProductToCartSuccess,
    mergeActivityProductInCartSuccess,
    deleteActivityProductInCartSuccess,
    putActivityProductInCartEntities,
    deleteActivityProductInCartEntity,
} from './actions/activity-product-in-cart';
import { FEATURE_NAME, initialDataOfUserId, initialDataOfStoreId } from './constants';
import { ProductInCartStore, ActivityProductInCartStore } from '../models';
import { omit, assoc, equals, reject, assocPath } from 'ramda';



interface DataOfUserId {

    cartIdsOfProductCodeMap: {
        [productCode: string]: Array<ProductInCartStore['id']>;
    };
    productCodes: string[]; // 用来维护顺序

    cartIdsOfActivityProductIdMap: {
        [activityProductId: string]: Array<ActivityProductInCartStore['id']>;
    };
    activityProductIds: string[];
    coupon: {
        code: string;
        enable: boolean;
    }
}

export interface State {
    [storeId: string]: {
        data: {
            [userId: string]: DataOfUserId
        },
        entities: {
            [cartId: string]: ProductInCartStore
        },
        activityProductInCartEntities: {
            [cartId: string]: ActivityProductInCartStore
        }
    }
}

export interface GlobalState {
    [FEATURE_NAME]: State;
};

// data: {},
// entities: {}

export const initialState: State = {};

export const reducer = createReducer(
    initialState,
    on(
        addProductToCartSuccess,
        (state, { payload: { storeId, userId, productCode, cartId } }) => {

            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const productCodes = dataOfUserId.productCodes.includes(productCode)
                ? dataOfUserId.productCodes
                : [...dataOfUserId.productCodes, productCode];
            
            const cartIdsOfProductCode = !!dataOfUserId.cartIdsOfProductCodeMap[productCode]
                ? [ ...dataOfUserId.cartIdsOfProductCodeMap[productCode], cartId ]
                : [ cartId ];
            const newDataOfUserId = {
                ...dataOfUserId,
                productCodes,
                cartIdsOfProductCodeMap: assoc(productCode, cartIdsOfProductCode, dataOfUserId.cartIdsOfProductCodeMap),
            }
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);

            return assoc(storeId, newDataOfStoreId, state);
        }
    ),
    on(
        mergeProductInCartSuccess,
        (state, { payload: { storeId, userId, productCode, cartId: { from, to } } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const cartIdsOfProductCode = reject(equals(from), dataOfUserId.cartIdsOfProductCodeMap[productCode]);

            const newDataOfUserId = assocPath(['cartIdsOfProductCodeMap', productCode], cartIdsOfProductCode, dataOfUserId);
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);
            return assoc(storeId, newDataOfStoreId, state);
        }
    ),
    on(
        deleteProductInCartSuccess,
        (state, { payload: { storeId, userId, productCode, cartId } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const cartIdsOfProductCode = reject(equals(cartId), dataOfUserId.cartIdsOfProductCodeMap[productCode])

            const cartIdsOfProductCodeMap = cartIdsOfProductCode.length > 0
                ? assoc(productCode, cartIdsOfProductCode, dataOfUserId.cartIdsOfProductCodeMap)
                : omit([productCode], dataOfUserId.cartIdsOfProductCodeMap);

            const productCodes = cartIdsOfProductCode.length > 0
                ? dataOfUserId.productCodes
                : reject(equals(productCode), dataOfUserId.productCodes);


            const newDataOfUserId = {
                ...dataOfUserId,
                cartIdsOfProductCodeMap,
                productCodes,
            }
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);

            return assoc(storeId, newDataOfStoreId, state);
        }
    ),
    on(
        putProductInCartEntity,
        (state, { payload: { productInCartStore, storeId } }) => {
            let dataOfStoreId = state[storeId] || initialDataOfStoreId;
            dataOfStoreId = assocPath(['entities', productInCartStore.id], omit(['product'], productInCartStore), dataOfStoreId)
            return assoc(storeId, dataOfStoreId, state);
        }
    ),
    on(
        deleteProductInCartEntity,
        (state, { payload: { id, storeId } }) => {
            let dataOfStoreId = state[storeId] || initialDataOfStoreId;
            dataOfStoreId = assoc('entities', omit([id], dataOfStoreId.entities), dataOfStoreId);
            return assoc(storeId, dataOfStoreId, state);
        }
    ),






    on(
        addActivityProductToCartSuccess,
        (state, { payload: { storeId, userId, activityProductId, cartId } }) => {

            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const activityProductIds = dataOfUserId.activityProductIds.includes(activityProductId)
                ? dataOfUserId.activityProductIds
                : [...dataOfUserId.activityProductIds, activityProductId];
            
            const cartIdsOfActivityProductId = !!dataOfUserId.cartIdsOfActivityProductIdMap[activityProductId]
                ? [ ...dataOfUserId.cartIdsOfActivityProductIdMap[activityProductId], cartId ]
                : [ cartId ];
            const newDataOfUserId = {
                ...dataOfUserId,
                activityProductIds,
                cartIdsOfActivityProductIdMap: assoc(activityProductId, cartIdsOfActivityProductId, dataOfUserId.cartIdsOfActivityProductIdMap),
            }
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);

            return assoc(storeId, newDataOfStoreId, state);
        }
    ),

    on(
        mergeActivityProductInCartSuccess,
        (state, { payload: { storeId, userId, activityProductId, cartId: { from, to } } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const cartIdsOfActivityProductId = reject(equals(from), dataOfUserId.cartIdsOfActivityProductIdMap[activityProductId]);

            const newDataOfUserId = assocPath(['cartIdsOfActivityProductIdMap', activityProductId], cartIdsOfActivityProductId, dataOfUserId);
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);
            return assoc(storeId, newDataOfStoreId, state);
        }
    ),
    on(
        deleteActivityProductInCartSuccess,
        (state, { payload: { storeId, userId, activityProductId, cartId } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;

            const cartIdsOfActivityProductId = reject(equals(cartId), dataOfUserId.cartIdsOfActivityProductIdMap[activityProductId]);

            const cartIdsOfActivityProductIdMap = cartIdsOfActivityProductId.length > 0
                ? assoc(activityProductId, cartIdsOfActivityProductId, dataOfUserId.cartIdsOfActivityProductIdMap)
                : omit([activityProductId], dataOfUserId.cartIdsOfActivityProductIdMap);

            const activityProductIds = cartIdsOfActivityProductId.length > 0
                ? dataOfUserId.activityProductIds
                : reject(equals(activityProductId), dataOfUserId.activityProductIds);


            const newDataOfUserId = {
                ...dataOfUserId,
                cartIdsOfActivityProductIdMap,
                activityProductIds,
            }
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);

            return assoc(storeId, newDataOfStoreId, state);
        }
    ),

    on(
        putActivityProductInCartEntities,
        (state, { payload: { activityProductInCartStore, storeId } }) => {
            let dataOfStoreId = state[storeId] || initialDataOfStoreId;
            dataOfStoreId = assocPath(['activityProductInCartEntities', activityProductInCartStore.id], activityProductInCartStore, dataOfStoreId)
            return assoc(storeId, dataOfStoreId, state);
        }
    ),
    on(
        deleteActivityProductInCartEntity,
        (state, { payload: { id, storeId } }) => {
            let dataOfStoreId = state[storeId] || initialDataOfStoreId;
            dataOfStoreId = assoc('activityProductInCartEntities', omit([id], dataOfStoreId.activityProductInCartEntities), dataOfStoreId);
            return assoc(storeId, dataOfStoreId, state);
        }
    ),

    on(
        clearSuccess,
        (state, { payload: { storeId, userId } }) => assocPath([storeId, 'data', userId], initialDataOfUserId, state),
    ),
    on(
        setPromoCode,
        (state, { payload: { storeId, userId, code } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;
    
            const newDataOfUserId = assocPath(['coupon'], { code, enable: true }, dataOfUserId);
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);
            return assoc(storeId, newDataOfStoreId, state);
        }
    ),
    on(
        changePromoCodeEnable,
        (state, { payload: { storeId, userId, enable } }) => {
            const dataOfStoreId = state[storeId] || initialDataOfStoreId;
            const dataOfUserId = dataOfStoreId.data[userId] || initialDataOfUserId;
    
            const newDataOfUserId = assocPath(['coupon', 'enable'], enable, dataOfUserId);
            const newDataOfStoreId = assocPath(['data', `${userId}`], newDataOfUserId, dataOfStoreId);
            return assoc(storeId, newDataOfStoreId, state);
        }
    )    
);
