import React, { useState, useCallback, useContext,useEffect } from 'react';
import {  useSelector } from 'react-redux';
import { selectors } from 'modules/store/store';
import { Lang } from './Lang';
import { en } from './en';
import { ch } from './ch';
import { hk } from './hk';
import { vi } from './vi';
import { ko } from './ko';
import { jp } from './jp';

export enum IS_DEFAULT_LANGUAGE {
    IS = 1,
    NULL = 0
}

export interface I18nMap {
    'zh-CN': Lang,
    'en-US': Lang,
    'zh-HK': Lang,
    'ko-kr': Lang,
    'vi-vn': Lang,
    'ja-jp': Lang,
};
export type Language = keyof I18nMap;
export interface LanguageGroup {
    label: string;
    value: Language
}

export const languageGroups: LanguageGroup[] = [{
    label: '简体中文',
    value: 'zh-CN'
}, {
    label: 'English',
    value: 'en-US',
}, {
    label: '港澳繁體',
    value: 'zh-HK'
}, {
    label: '한국어',
    value: 'ko-kr',
}, {
    label: 'ViệtName',
    value: 'vi-vn',
}, {
    label: '日本語',
    value: 'ja-jp',
}];

type Variable = {[key: string]: string | number}
const i18nMap: I18nMap = {
    'en-US': en,
    'zh-CN': ch,
    'zh-HK': hk,
    'ko-kr': ko,
    'ja-jp': jp,
    'vi-vn': vi,
};

export interface ContextValue {
    lang: Language;
    getLang: () => Language;
    setLang: (l: Language) => void;
    t: (k: string, variable?: Variable) => string;
    translateWithLang: (lang: Language, k: string, variable?: Variable) => string;
}

const contextValue: ContextValue = {
    lang: 'zh-CN',
    getLang: () => 'zh-CN',
    setLang: (l: Language) => {},
    t: (k: string, variable?: Variable) => '',
    translateWithLang: (lang: Language, k: string, variable?: Variable) => ''
}

export const translate = (lang: Language, key: string, variable?: Variable): string => {
    const keyPath = key.split('.');
    const currentLangConfig: any = i18nMap[lang];
    const { result } = keyPath.reduce((acc, cur, index) => {
        if (acc.preConfig[cur]) {
            if (index === keyPath.length - 1) {
                return { result: acc.preConfig[cur], preConfig: {} };
            } else {
                return { result: '', preConfig: acc.preConfig[cur] as {} };
            }
        } else {
            return { result: '', preConfig: {} };
        }
    }, { result: '', preConfig: currentLangConfig });
    if (!result) return key;
    if (!variable) return result;
    return Object.keys(variable).reduce((acc, cur) => {
        return acc.replace('${{' + cur + '}}', `${variable[cur]}`);
    }, result);
}
export const i18nContext = React.createContext(contextValue);
export const useI18n = () => {
    return useContext(i18nContext);
}

interface ProviderProps {
    children: React.ReactNode;
    storage?: boolean;
}
export const I18nProvider: React.FC<ProviderProps> = ({
    children,
    storage = true,
}) => {

    const currentBrand = useSelector(selectors.getCurrentBrand);
    const [currentLang, setCurrentLang] = useState<Language>(() => {
        if (storage) {
            const storageLanguage = window.localStorage.getItem('language') || '';
            if (languageGroups.map(item => item.value as string).includes(storageLanguage)) {
                return storageLanguage as Language;
            } else {
                return 'zh-CN';
            }
        } else {
            return 'zh-CN';
        }
    });

    useEffect(()=>{
        if (currentBrand) {
            const { defaultLanguage } = currentBrand;
            // 有限使用存储的语言
            let language = window.localStorage.getItem('language') || '';
    
            if (!language && defaultLanguage && Object.keys(i18nMap).includes(defaultLanguage)) {
                // 使用机构配置的语言
                language = defaultLanguage;
            }
            if (!language) {
                // 使用浏览器的语言
                const systemLanguage = window.navigator.language;
                if (/zh/i.test(systemLanguage)) {
                    language = 'zh-CN';
                } else {
                    // 只判断语言，不判断地区
                    language = Object.keys(i18nMap).find(item => (new RegExp(item.split('-')[0], 'i')).test(systemLanguage)) || '';
                }
            }
            if (!language) {
                language = 'en-US';
            }
            setCurrentLang(language as Language);
        }
    },[currentBrand])


    const getLang = useCallback(() => {
        return currentLang;
    }, [currentLang]);
    const setLang = useCallback((lang: Language) => {
        storage && localStorage.setItem('language', lang);
        setCurrentLang(lang);
    }, [setCurrentLang]);
    const t = (key: string, variable?: Variable) => {
        return translate(currentLang, key, variable);
    }
    const translateWithLang = (lang: Language, key: string, variable?: Variable) => {
        return translate(lang, key, variable);
    }
    return (
        <i18nContext.Provider value={{
            lang: currentLang,
            setLang,
            getLang,
            t,
            translateWithLang
        }}>
            {children}
        </i18nContext.Provider>
    )
}