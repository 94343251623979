import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styles from './index.module.css';
import { useI18n } from 'context/i18n';

import { ASAP_TIME, DELIVERY_TYPE } from 'modules/order-info';
import { DatePicker } from 'components/DatePicker';
import { Select } from 'components/Select'
import dayjs from 'dayjs';
import moment from 'moment';
import { formatTime } from '../../utils';
import { DeliveryTimeRange, HourAndMinute, Store } from 'modules/store/models';

const { Option } = Select;



// 6. 那送餐选择变成区间化吧，中午分两个区间分别是：12：00-1：00；1：00-2：00；晚餐分为：6：00-7：00；7：00-8：00。
// 7. 然后餐厅出单问题，比如预约的明天上午两个区间段外送，明天上午11：00（也就是营业开始时间）出单。预约明天下午外送的，营业时间开始5：00出单。


const isBigThenByHourAndMinute = (a: HourAndMinute, b: HourAndMinute) => {
    // return a > b
    const { hour: aHour, minute: aMinute } = a;
    const { hour: bHour, minute: bMinute } = b;

    return (aHour > bHour)
        || (aHour === bHour && aMinute > bMinute);
}
const isEqualByHourAndMinute = (a: HourAndMinute, b: HourAndMinute) => a.hour === b.hour && a.minute === b.minute;


const findRange = (dayObj: dayjs.Dayjs, deliveryTimeRangeOfWeek: Array<DeliveryTimeRange[]>): DeliveryTimeRange | undefined => {
    const dayOfWeek = dayObj.day();
    const deliveryTimeRange = deliveryTimeRangeOfWeek[dayOfWeek];

    const hourAndMinute: HourAndMinute = { hour: dayObj.hour(), minute: dayObj.minute() };

    return deliveryTimeRange.find(({ start, end }) => {
        return (isBigThenByHourAndMinute(hourAndMinute, start))
            && (isBigThenByHourAndMinute(end, hourAndMinute) ||  isEqualByHourAndMinute(hourAndMinute, end));
    });
}

// 找一个之后的最近的range, 如果大于最后一个，返回undefined
const findNearlyRange = (dayObj: dayjs.Dayjs, deliveryTimeRangeOfWeek: Array<DeliveryTimeRange[]>): DeliveryTimeRange | undefined => {
    const dayOfWeek = dayObj.day();
    const deliveryTimeRange = deliveryTimeRangeOfWeek[dayOfWeek];
    if (deliveryTimeRange.length === 0) return undefined;
    const lastRange = deliveryTimeRange[deliveryTimeRange.length - 1];

    const { hour, minute } = lastRange.end;

    const paramHour = dayObj.hour();
    const paramMinute = dayObj.minute();
    if (paramHour > hour || (paramHour === hour && paramMinute >= minute)) return undefined;


    return findRange(dayObj, deliveryTimeRangeOfWeek) || findNearlyRange(dayObj.add(1, 'minute'), deliveryTimeRangeOfWeek);
}

const getTimestampOfRange = (day: dayjs.Dayjs, range: DeliveryTimeRange) => {
    if (!range) return 0;
    const { hour, minute } = range.end
    return day.hour(hour).minute(minute).startOf('minute').valueOf();
}

const formatMinute = (minute: number): string => {
    if (minute < 10) return `0${minute}`;
    return `${minute}`;
}
const getDeliveryTimeRangeText = (range: DeliveryTimeRange) => {
    const { start, end } = range;
    return `${start.hour}:${formatMinute(start.minute)} - ${end.hour}:${formatMinute(end.minute)}`;
}


export const getTimestampRangeOfTimestamp = (timestamp: number, deliveryTimeRangeOfWeek: Array<DeliveryTimeRange[]>) => {
    const dayjsObj = dayjs(timestamp);
    const { start, end } = findRange(dayjsObj, deliveryTimeRangeOfWeek)!;
    const startTimestamp = dayjsObj.hour(start.hour).minute(start.minute).valueOf();
    const endTimestamp = dayjsObj.hour(end.hour).minute(end.minute).valueOf();
    return `${startTimestamp},${endTimestamp}`;
}

const mte = (src: HourAndMinute, target: HourAndMinute) => {
    return src.hour > target.hour
        || (src.hour === target.hour && src.minute >= target.minute)
}


const getDeliveryTimeRange = (start: HourAndMinute, currentEnd: HourAndMinute, end: HourAndMinute, result: DeliveryTimeRange[] = []): DeliveryTimeRange[] => {
    if (mte(start, end)) return result;
    if (mte(currentEnd, end)) {
        return [...result, { start, end: currentEnd }];
    }
    return getDeliveryTimeRange(currentEnd, { hour: currentEnd.hour + 1, minute: currentEnd.minute }, end, [...result, { start, end: currentEnd }]);
}

export const getAsapTimestamp = (store: Store) => {
    // store.hourData;
    const currentDayHourData = store.hourData[dayjs().day()];
    const [ morning, afternoon ] = currentDayHourData;

    const nowHourAndMinute = { hour: dayjs().hour(), minute: dayjs().minute() };

    const after30minNowDayjs = dayjs().add(30, 'minute');
    const after30minNowHourAndMinute: HourAndMinute = { hour: after30minNowDayjs.hour(), minute: after30minNowDayjs.minute() }; 


    if (isBigThenByHourAndMinute(morning.end, nowHourAndMinute)) {
        // 上午
        const after1hourStart = { ...morning.start, hour: morning.start.hour + 1 };
        const start = isBigThenByHourAndMinute(after1hourStart, after30minNowHourAndMinute) ? after1hourStart : after30minNowHourAndMinute;
        return dayjs().hour(start.hour + 1).minute(start.minute).valueOf();
    }

    if (!afternoon) return dayjs().add(1, 'hour').add(30, 'minute').valueOf();
    const after1hourStart = { ...afternoon.start, hour: afternoon.start.hour + 1 };
    const start = isBigThenByHourAndMinute(after30minNowHourAndMinute, after1hourStart) ? after30minNowHourAndMinute : after1hourStart;
    return dayjs().hour(start.hour + 1).minute(start.minute).valueOf();
}

interface Props {
    store: Store
    // deliveryTimeRangeOfWeek: Array<DeliveryTimeRange[]>;
    mealTime?: string | '0';
    onTimeEdit: (time: number) => void;
}

export const DeliveryTimeSelector: React.FC<Props> = ({
    store,
    // deliveryTimeRangeOfWeek,
    mealTime: incomingMealTime,
    onTimeEdit
}) => {
    const deliveryTimeRangeOfWeek = useMemo(() => {
        const currentDay = dayjs().day();
        return store.hourData.map((currHourData, day) => {
            const [ morning, afternoon ] = currHourData;

            const after30minNowDayjs = dayjs().add(30, 'minute');

            const after30minNowHourAndMinute: HourAndMinute = { hour: after30minNowDayjs.hour(), minute: after30minNowDayjs.minute() }; 
            
            const morningRange = (() => {
                if (!morning) return [];
                const after1hourStart = { ...morning.start, hour: morning.start.hour + 1 };
                // 处理上午
                if (day === currentDay) {
                    if (isBigThenByHourAndMinute(morning.end, after30minNowHourAndMinute)) {
                        // 现在是在上午
                        const start = isBigThenByHourAndMinute(after1hourStart, after30minNowHourAndMinute) ? after1hourStart : after30minNowHourAndMinute;
                        return getDeliveryTimeRange(start, { hour: start.hour + 1, minute: start.minute }, morning.end, []);
                    } else {
                        return [];
                    }
                } else {
                    return getDeliveryTimeRange(after1hourStart, { hour: after1hourStart.hour + 1, minute: after1hourStart.minute }, morning.end, []);
                }
            })();
            


            const afternoonRange = (() => {
                if (!afternoon) return [];
                const after1hourStart = { ...afternoon.start, hour: afternoon.start.hour + 1 };
                let start = after1hourStart;
                if (day === currentDay) {
                    // 不在上午营业时间内
                    if (isBigThenByHourAndMinute(after30minNowHourAndMinute, morning.end)) {
                        start = isBigThenByHourAndMinute(after30minNowHourAndMinute, after1hourStart) ? after30minNowHourAndMinute : after1hourStart;
                    }
                }
                return getDeliveryTimeRange(start, { hour: start.hour + 1, minute: start.minute }, afternoon.end, []);
            })();

            return [...morningRange, ...afternoonRange];
        });
    }, [store]);

    const mealTime = useMemo(() => {
        if (!incomingMealTime || incomingMealTime === ASAP_TIME) {
            return ASAP_TIME;
        };
        return Number(incomingMealTime);
    }, [incomingMealTime]);

    useEffect(() => {
        if (mealTime !== ASAP_TIME) {
            const isBeforeNow = dayjs(mealTime).isBefore(dayjs());
            if (isBeforeNow) {
                // 如果时间在现在之前
                const todayNearlyRange = findNearlyRange(dayjs(), deliveryTimeRangeOfWeek);
                if (todayNearlyRange) {
                    // 今天有满足的range
                    onTimeEdit(getTimestampOfRange(dayjs(), todayNearlyRange));
                } else {
                    // 今天没有满足的，设置为第二天的第一个时间段
                    const nextDay = dayjs().add(1, 'day');

                    onTimeEdit(getTimestampOfRange(nextDay, deliveryTimeRangeOfWeek[nextDay.day()][0]));
                }
            } else {
                const isInRange = !!findRange(dayjs(mealTime), deliveryTimeRangeOfWeek);
                console.log("isInRange =>", isInRange);
                console.log("mealTime =>", mealTime)
                console.log("deliveryTimeRangeOfWeek =>", deliveryTimeRangeOfWeek)
                if (!isInRange) {
                    const nearlyRange = findNearlyRange(dayjs(mealTime), deliveryTimeRangeOfWeek);
                    if (nearlyRange) {
                        // 有满足的range
                        onTimeEdit(getTimestampOfRange(dayjs(mealTime), nearlyRange));
                    } else {
                        const isToday = dayjs(mealTime).isBefore(dayjs().endOf('d'));
                        if (isToday) {
                            const todayNearlyRange = findNearlyRange(dayjs(), deliveryTimeRangeOfWeek);
                            if (todayNearlyRange) {
                                onTimeEdit(getTimestampOfRange(dayjs(), todayNearlyRange));
                            } else {
                                const nextDay = dayjs().add(1, 'day');
                                onTimeEdit(getTimestampOfRange(nextDay, deliveryTimeRangeOfWeek[nextDay.day()][0]));
                            }
                        } else {
                            onTimeEdit(getTimestampOfRange(dayjs(mealTime), deliveryTimeRangeOfWeek[dayjs(mealTime).day()][0]));
                        }

                    }
                }
            }
        }
    }, [mealTime, deliveryTimeRangeOfWeek]);


    const timeOptions = useMemo(() => {
        const isToday = dayjs(mealTime).isBefore(dayjs().endOf('d'));
        let options = deliveryTimeRangeOfWeek[dayjs(mealTime).day()];

        if (isToday) {
            const nowHourAndMinute: HourAndMinute = { hour: dayjs().hour(), minute: dayjs().minute() };
            options = options.filter(item => {
                return isBigThenByHourAndMinute(item.start, nowHourAndMinute)
                    || isEqualByHourAndMinute(item.start, nowHourAndMinute);
            })
        }

        return options.map(( item, index ) => ({
            ...item,
            key: index
        }))
    }, [mealTime, deliveryTimeRangeOfWeek]);

    const currentOption = useMemo(() => {
        const currentHourDayObj = dayjs(mealTime);
        return timeOptions.find(({ start, end }) => {
            const hourAndMinute = {hour: currentHourDayObj.hour(), minute: currentHourDayObj.minute()};
            return (isBigThenByHourAndMinute(hourAndMinute, start))
                    && (isBigThenByHourAndMinute(end, hourAndMinute) || isEqualByHourAndMinute(hourAndMinute, start));
        })
    }, [mealTime, timeOptions]);

    const handleTimeRangeChange = (key: number) => {
        const range = timeOptions.find(item => item.key === key)!;
        onTimeEdit(getTimestampOfRange(dayjs(mealTime), range));
    }

    const handleDayChange = (m: moment.Moment | null, _: any) => {
        if (!m) return;
        const time = dayjs(mealTime);
        
        onTimeEdit(
            dayjs(m.valueOf())
                .hour(time.hour())
                .minute(time.minute())
                .second(time.second())
                .valueOf()
        );
    }

    const disabledDate = useCallback((current: any) => {
        if (current) {
            return store.hourData[current.day()].length === 0
                || current < moment().startOf('day');
        }
        return false;
    }, [store]);

    return (
        <>
            <DatePicker
                value={moment(mealTime)}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
                allowClear={false}
                showToday={false}
                onChange={handleDayChange}
            />
            <Select style={{ width: '140px' }} value={currentOption?.key} onChange={handleTimeRangeChange}>
                {timeOptions.map(item => (
                    <Option key={item.key} value={item.key}>{getDeliveryTimeRangeText(item)}</Option>
                ))}
            </Select>
        </>
    )
};



