import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { Store, Brand } from '../../models';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import * as api from '../../api';


type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;


// export const getStoreListSuccess = action(
//     getActionType('Get Store List Success'),
//     payload<Store[]>()
// )


// export function getStoreList(brandCode: string): ThunkResult<Promise<Store[]>> {
//     return async (dispatch, getState) => {
//         return api.getStoreList(brandCode).then(storeList => {
//             dispatch(getStoreListSuccess(storeList));
//             return storeList
//         })
//     }
// }

export const setCurrentStore = action(
    getActionType('Set Current Store'),
    payload<Store['storeID']>()
)

export const initSuccess = action(
    getActionType('Init Success'),
    payload<{
        storeList: Store[],
        brand: Brand,
    }>(),
)
export const startInit = action(
    getActionType('Init Started'),
)
export const initFail = action(
    getActionType('Init Fail'),
)

export function init(): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        dispatch(startInit());
        return api.getCurrentBrand().then(brand => {
            return api.getStoreList(brand.code).then(storeList => {
                dispatch(initSuccess({
                    brand,
                    storeList
                }))
            })
        }).catch(err => {
            console.error("Init Error =>", err);
            dispatch(initFail());
        })
    }
}