import React, { useMemo } from 'react';
import styles from './index.module.css';
import { Order} from '../../models';
import { useOrderStatus } from '../../facades/useOrderStatus';
import { formatTime } from '../../utils'
import { useI18n } from 'context/i18n';
interface Props {
    order: Order;
    onClick: (order: Order) => void;
}

export const OrderItem: React.FC<Props> = ({
    order,
    onClick,
}) => {
    const { t } = useI18n();
    const imageUrl = useMemo(() => {
        if (order.productList.length > 0) {
            return order.productList[0].product.images[0].url;
        } else if (order.activityProductList.length > 0) {
            return order.activityProductList[0].activityProduct.product.images[0].url;
        }
        return '';
    }, [order]);

    const storeName = useMemo(() => {
        return order.storeName;
    }, [order]);

    const createdTime = useMemo(() => {
        return formatTime(order.createTime);
    }, [order]);

    const totalPrice = useMemo(() => {
        return order.payAmount;
    }, [order])

    const orderStatus = useOrderStatus(order);

    return (
        <div className={styles.orderItem} onClick={() => onClick(order)}>
            <div className={styles.left}>
                <img className={styles.image} src={imageUrl} alt=""/>
            </div>
            <div className={styles.middle}>
                <div className={styles.storeName}>{storeName}</div>
                <div className={styles.createdTime}>{t('order.createTime')}：{createdTime}</div>
                <div className={styles.price}>{t('order.totalPrice')}：${totalPrice}</div>
            </div>
            <div className={styles.right}>
                <div className={styles.status}>{orderStatus}</div>
            </div>
        </div>
    )
}