import { action, payload } from 'ts-action';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { getActionType } from '../constants';
import * as selectors from '../selectors';
import { ActivityProductInCartStore } from '../../models';
import { CustomizableTasteSetting } from 'modules/product-menu';


import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as storeSelectors } from 'modules/store/store';


import {
    makeProductInCartId,
    findActivityProductInCartWithSameCustomizableTaste,
    isSameCustomizableTaste
} from '../utils';


export const putActivityProductInCartEntities = action(
    getActionType('Put Activity Product In Cart Entities'),
    payload<{
        activityProductInCartStore: ActivityProductInCartStore,
        storeId: string;
    }>()
)
export const deleteActivityProductInCartEntity = action(
    getActionType('Delete Activity Product In Cart Entities'),
    payload<{
        storeId: string;
        id: string;
    }>() // cart id
)
type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;

/**
 * 增加商品成功，
 * 如果要增加的商品的自定义信息。和已有的无重复，则增加
 */
export const addActivityProductToCartSuccess = action(
    getActionType('Add Activity Product To Cart Success'),
    payload<{
        storeId: string,
        userId: number,
        activityProductId: string,
        cartId: ActivityProductInCartStore['id'],
    }>()
)


export function addActivityProductToCart({
    activityProductId,
    count,
    customizableTasteSetting
}: {
    activityProductId: string;
    count: number;
    customizableTasteSetting: CustomizableTasteSetting;
}): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        const productInCartWithSameCustomizable = findActivityProductInCartWithSameCustomizableTaste(
            customizableTasteSetting,
            selectors.getActivityProductInCartOfActivityProductId(getState(), { activityProductId })
        );
        // 查看是否和已经存在的商品定制化重复
        if (productInCartWithSameCustomizable) {
            // 如果存在，累加数量
            dispatch(mergeActivityProductInCartSuccess({
                userId,
                storeId,
                activityProductId,
                cartId: { from: 'new', to: productInCartWithSameCustomizable.id }
            }));
            dispatch(putActivityProductInCartEntities({
                storeId,
                activityProductInCartStore: {
                    ...productInCartWithSameCustomizable,
                    count: productInCartWithSameCustomizable.count + count,
                    remark: productInCartWithSameCustomizable.remark || ''
                }
            }));
        } else {
            // 如果不存在, 生成cartId， 增加
            const cartId = makeProductInCartId(activityProductId);
            dispatch(putActivityProductInCartEntities({
                storeId,
                activityProductInCartStore: {
                    activityProductId,
                    id: cartId,
                    count,
                    customizableTasteSetting,
                    remark: ''
                }
            }));
            dispatch(addActivityProductToCartSuccess({
                userId,
                storeId,
                activityProductId,
                cartId
            }));
        }
    }
}

/**
 * 更新商品信息成功, 不被reducer处理，用于日志
 */
export const updateActivityProductInCartSuccess = action(
    getActionType('Update Activity Product In Cart Success'),
    payload<{
        activityProductId: string; // 字段不被reducer处理，用于日志
        cartId: ActivityProductInCartStore['id'], // 字段不被reducer处理，用于日志
    }>()
)

/**
 * 合并商品成功
 * 如果修改后的定制化信息。和已有的重复，那么合并
 */
export const mergeActivityProductInCartSuccess = action(
    getActionType('Merge Activity Product In Cart Success'),
    payload<{
        storeId: string;
        userId: number;
        activityProductId: string;
        cartId: {
            from: ActivityProductInCartStore['id'];
            to: ActivityProductInCartStore['id'];
        };
    }>()
)


/**
 * 如果count变为0
 * 则删除
 */
export const deleteActivityProductInCartSuccess = action(
    getActionType('Delete Activity Product In Cart Success'),
    payload<{
        storeId: string;
        userId: number;
        activityProductId: string;
        cartId: ActivityProductInCartStore['id'];
    }>()
)

export function deleteActivityProductInCart({
    activityProductId,
    cartId,
}: {
    activityProductId: string;
    cartId: ActivityProductInCartStore['id'];
}): ThunkResult<void> {
    return (dispatch, getState) => {

        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        dispatch(deleteActivityProductInCartSuccess({ userId, storeId, activityProductId, cartId }));
        dispatch(deleteActivityProductInCartEntity({
            storeId,
            id: cartId,
        }));
    }
}

/**
 * 修改已经在购物车那的商品
 * 可以修改 数量 | 口味
 */
export function updateActivityProductInCart({
    cartId,
    count,
    customizableTasteSetting,
    remark,
}: {
    cartId: ActivityProductInCartStore['id'];
    count: number;
    customizableTasteSetting: CustomizableTasteSetting;
    remark?: string
}): ThunkResult<void> {
    return async (dispatch, getState) => {

        const userId = authSelectors.getCurrentUser(getState())!.id;
        const storeId = storeSelectors.getCurrentStoreId(getState())!;
        const activityProductInCart = selectors.getActivityProductInCartOfId(getState(), { cartId })!;
        if (count === 0) {
            throw new Error('update product in cart error: count is 0');
        }

        if (isSameCustomizableTaste(customizableTasteSetting, activityProductInCart.customizableTasteSetting)) {
            // 定制化没有修改
            dispatch(putActivityProductInCartEntities({
                storeId,
                activityProductInCartStore: {
                    ...activityProductInCart,
                    count,
                    remark: remark === undefined? activityProductInCart.remark : remark
                }
            }));
        } else {
            // 定制化改变了
            const productInCartWithSameCustomizable = findActivityProductInCartWithSameCustomizableTaste(
                customizableTasteSetting,
                selectors.getActivityProductInCartOfActivityProductId(getState(), { activityProductId: activityProductInCart.activityProductId })
            );
            // 查看是否和其他已经存在的定制化相同
            if (productInCartWithSameCustomizable) {
                const { id: targetCartId } = productInCartWithSameCustomizable;
                // 如果有相同，累加数量
                dispatch(mergeActivityProductInCartSuccess({
                    userId,
                    storeId,
                    activityProductId: activityProductInCart.activityProductId,
                    cartId: { from: cartId, to: targetCartId }
                }));
                dispatch(deleteActivityProductInCartEntity({
                    storeId,
                    id: cartId,
                }));
                dispatch(putActivityProductInCartEntities({
                    storeId,
                    activityProductInCartStore: {
                        ...productInCartWithSameCustomizable,
                        count: productInCartWithSameCustomizable.count + count,
                        remark:remark === undefined ? productInCartWithSameCustomizable.remark : remark,
                    }
                }));
            } else {
                // 用作日志
                dispatch(updateActivityProductInCartSuccess({ activityProductId: activityProductInCart.activityProductId, cartId }));
                // 如果没有不同，修改口味和修改数量
                dispatch(putActivityProductInCartEntities({
                    storeId,
                    activityProductInCartStore: {
                        ...activityProductInCart,
                        count,
                        customizableTasteSetting,
                        remark: remark === undefined ? activityProductInCart.remark : remark,
                    }
                }));
            }
        }
    }
}
