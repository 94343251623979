import React, { CSSProperties } from 'react';
import styles from './index.module.css';



interface Props {
    className?: string;
    source: string;
    onClick?: () => void;
    tags?: React.ReactNode[]
}

export const ProductImage: React.FC<Props> = ({
    className = '',
    source,
    onClick = () => {},
    tags = []
}) => {
    return (
        <div className={`${className} ${styles.productImage}`} onClick={onClick}>
            <img className={styles.image} src={source} alt="" />
            { tags.map((tag, index) => (
                <div key={index} style={{maxWidth: '90%', position: 'absolute', top: `${20 * (index + 1) + 20 * index}px`, left: '0'}}>{tag}</div>
            )) }
        </div>
    )
}
