import { initialDataOfUserId } from './constants';
import { FEATURE_NAME } from './constants';
import { GlobalState } from './reducer';
import { createSelector } from 'reselect';
import { selectors as authSelectors } from 'modules/auth/store';
const select = (state: GlobalState) => state[FEATURE_NAME];

export const getCurrentUserOrderInfo = createSelector([
    authSelectors.getCurrentUser,
    select
], (currentUser, state) => {
    if (!currentUser?.id) return undefined;
    return state[currentUser.id];
})



export const getDeliveryType = createSelector(getCurrentUserOrderInfo, data => data?.deliveryType);

export const getOrderReceiver = createSelector(getCurrentUserOrderInfo, data => data?.receiverInfo);
export const getOrderPickUpReceiver = createSelector(getCurrentUserOrderInfo, data => data?.pickUpReceiverInfo);

export const getOrderReceiverId = createSelector(getOrderReceiver, data => data?.id);

export const getOrderMealTime = createSelector(getCurrentUserOrderInfo, data => data ? data.mealTime : undefined);

export const getRemark = createSelector(getCurrentUserOrderInfo, data => data?.remark);
