import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useCheckAccess } from '../../hooks/useCheckAccess';
export const AuthRoute: React.FC<RouteProps> = (props) => {
    const { isLogin } = useCheckAccess();

    if (!isLogin) return <Redirect to="/home" />;

    return (
        <Route {...props}/>
    )
}