import React, { lazy, Suspense, useCallback, CSSProperties } from 'react';
// import meBackground from '../images/me-background.png';
import styles from './index.module.css';
import { Switch, Redirect, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { SubPageLayout } from 'layout/SubPageLayout';
import { AddressList } from './AddressList';
import { AddressCreate } from './AddressCreate';
import { AddressEdit } from './AddressEdit';
import { MyOrderList } from './MyOrderList';
import { MyOrderDetail } from './MyOrderDetail';
import { useLogout } from 'modules/auth';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';

export const Me: React.FC = () => {
    const { t } = useI18n();
    const { theme: { primary, secondary }, webMeBanner_1 } = useTheme();
    const menus = [{
        name: t('history.historyOrders'),
        path: 'order',
    }, {
        name: t('address.addressManagement'),
        path: 'address'
    }];
    const { path, url } = useRouteMatch();
    const { replace } = useHistory();
    const { pathname } = useLocation();
    const { methods: { logout } } = useLogout();
    const checkActive = useCallback((p: string) => {
        return pathname.indexOf(`${url}/${p}`) > -1;
    }, [pathname, url]);

    const getActiveClassName = useCallback((p: string) => {
        return checkActive(p) ? styles.activeMenu : '';
    }, [checkActive]);
    
    const getActiveStyle = useCallback((p: string): CSSProperties => {
        return checkActive(p) ? { color: primary.bg } : {};
    }, [checkActive]);

    return (

        <SubPageLayout backgroundSrc={webMeBanner_1}>
            <div className={styles.me}>
                <div className={styles.sidebar}>
                    {menus.map(menu => (
                        <div
                            className={`${styles.menuItem} ${getActiveClassName(menu.path)}`}
                            style={getActiveStyle(menu.path)}
                            key={menu.path}
                            onClick={() => replace(`${url}/${menu.path}`)}
                        >{menu.name}</div>
                    ))}
                    <div
                        className={styles.menuItem}
                        onClick={logout}
                    >{t('auth.logOut')}</div>
                </div>
                <div className={styles.routeView}>
                    <Switch>
                        <Route path={`${path}/order/:id`} component={MyOrderDetail}/>
                        <Route path={`${path}/order`} component={MyOrderList}/>
                        <Route path={`${path}/address/create`} component={AddressCreate}/>
                        <Route path={`${path}/address/:id`} component={AddressEdit}/>
                        <Route path={`${path}/address`} component={AddressList}/>
                        <Redirect to={`${path}/order`}/>
                    </Switch>
                </div>
            </div>
        </SubPageLayout>
    );
}