import React, { lazy, Suspense, useCallback, useState, useEffect } from 'react';
import { Header } from './layout/Header';
import { Body } from './layout/Body';
import { Footer } from './layout/Footer';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { Home } from 'modules/home';
import { AuthRoute, NotAuthorizedModal } from 'modules/auth';
import { AccessAndOrderInfoProvider } from 'context/accessAndOrderInfo';
import { ProductToCartProvider } from 'modules/product-menu';
import { ActivityProductToCartProvider } from 'modules/product-menu';

import { ThemeProvider, useStore } from 'modules/store';
import { Cart } from 'modules/buy';
import { PayContainer } from 'modules/buy';
import { Me } from 'modules/me';
import { PayCardList} from 'modules/buy/pages/Pay/components/PayCardList';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase';
import { viewAnalyticsLogger } from 'analytics/view-analytics-logger';
import { setAnalytics } from 'analytics/get-firebase-app';
const NormalMeal = lazy(() => import('modules/product-menu/pages/NormalMeal'));
const OrderInfo = lazy(() => import('modules/order-info'));



console.log("process.env", process.env.REACT_APP_STRIPE_KEY);



// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const Main: React.FC = () => {
    const { currentBrand, isInitLoading, methods: { init } } = useStore();
    const { push } = useHistory();
    const [ authModalVisable, setAuthModalVisable ] = useState<boolean>(false);
    const [ redirectAfterLogin, setRedirectAfterLogin ] = useState<string>('');
    const { pathname } = useLocation();
    useEffect(() => {
        viewAnalyticsLogger(pathname);
    }, [pathname]);
    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (currentBrand) {
            document.title = currentBrand.name;
        }
    }, [currentBrand]);

    // useEffect(() => {
    //     if (currentBrand) {
    //         document.title = currentBrand.name;
    //         const gtagScript = document.createElement('script');
    //         gtagScript.src = `/repast/images/${currentBrand.abbreviation}/statistics.js`;
    //         document.body.appendChild(gtagScript);
    //     }
    // }, [currentBrand]);

    useEffect(() => {
        if (currentBrand && firebase.apps.length === 0) {
            const firebaseConfig: any = {
                apiKey: "AIzaSyAGJ-hmR54Tgx9ls9dlrHtouvkaTJmDdBI",
                authDomain: "sabachat-4403b.firebaseapp.com",
                databaseURL: "https://sabachat-4403b.firebaseio.com",
                projectId: "sabachat-4403b",
                storageBucket: "sabachat-4403b.appspot.com",
                messagingSenderId: "368744552945",
                appId: "1:368744552945:web:5925e5c423b28e1f660c50",
            }
            if (currentBrand.analytics && currentBrand.analytics.platform === 'google') {
                firebaseConfig.measurementId = currentBrand.analytics.id;
                setAnalytics(true);
            }
            firebase.initializeApp(firebaseConfig);
        }
    }, [currentBrand]);

    const login = useCallback((redirect?: string) => {
        setRedirectAfterLogin(redirect || '');
        setAuthModalVisable(true);
    }, []);

    const loginSuccess = useCallback(() => {
        setAuthModalVisable(false);
        if (redirectAfterLogin) {
            push(redirectAfterLogin);
            setRedirectAfterLogin('');
        }
    }, [redirectAfterLogin, setRedirectAfterLogin, push]);

    const handleLoginCancel = useCallback(() => {
        setAuthModalVisable(false);
        setRedirectAfterLogin('');
    }, [setRedirectAfterLogin]);


    return (
        <ThemeProvider>
                <AccessAndOrderInfoProvider login={login}>
                    <ActivityProductToCartProvider>
                        <ProductToCartProvider>
                            {!isInitLoading &&
                                <>
                                    <Header />
                                    <div style={{
                                        width: '100%',
                                        maxHeight: 'calc(100% - 80px)',
                                        overflowY: 'auto'
                                    }}>
                                        <Body>
                                            <Suspense fallback={<div></div>}>
                                                <Switch>
                                                    <Route path='/home' component={Home}/>
                                                    <AuthRoute path='/me' component={Me}/>
                                                    <Route path='/normal-meal' component={NormalMeal}/>
                                                    <AuthRoute path='/order-info' component={OrderInfo} />
                                                    <AuthRoute path='/cart' component={Cart} />
                                                    <AuthRoute path ='/pay/:orderId/card' component={PayCardList} />
                                                    <AuthRoute path='/pay/:orderId' component={PayContainer} />
                                                    <Redirect to="/home"></Redirect>
                                                </Switch>
                                            </Suspense>
                                            {/* <div style={{ height: '10000px' }}></div> */}
                                        </Body>
                                        <Footer />
                                    </div>
                                </>
                            }
                            <NotAuthorizedModal
                                visable={authModalVisable}
                                onCancel={handleLoginCancel}
                                loginSuccess={loginSuccess}
                            />
                        </ProductToCartProvider>
                    </ActivityProductToCartProvider>
                </AccessAndOrderInfoProvider>
            </ThemeProvider>
    );
}
