import React, { createRef } from 'react';
import styles from './index.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { ActionButton } from 'components/ActionButton';
import { useReceiverInfoEdit } from '../../facades/useReceiverInfoEdit';
import { AddressForm, RefProps } from '../../components/AddressForm';
import { omit } from 'ramda';
import { Spin } from 'components/Spin';
import { message } from 'components/Message';
import { useI18n } from 'context/i18n';
export const AddressEdit: React.FC = () => {
    const { t } = useI18n();
    const formRef = createRef<RefProps>();
    const { goBack } = useHistory();
    const { id } = useParams<{ id: string }>();
    const { loading, value, methods } = useReceiverInfoEdit(id!);


    const handleBack = () => {
        goBack();
    }

    const handleOk = () => {
        formRef.current!.submit((data) => {
            const formData = {
                ...value,
                ...data,
                ...data.geo
            };
            methods.submit(omit(['geo'], formData)).then(() => {
                goBack();
            }).catch(({ data }) => {
                data.msg && message.error(t(data.msg));
            })
        })
    }

    return (
        <div>
            <Spin spinning={loading}>
                <Header title={t('form.actions.edit')}>
                <ActionButton onClick={handleOk}>{t('form.actions.confirm')}</ActionButton>
                <ActionButton onClick={handleBack}>{t('form.actions.back')}</ActionButton>
                </Header>

                <div className={styles.addressFormWrapper}>
                    <AddressForm value={value!} ref={formRef}/>
                </div>
            </Spin>
        </div>
    )
}