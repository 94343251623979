import React, { useEffect, useCallback, useState } from 'react';
import './index.css';


function test(onSelect: (item: any) => void, dragCallback: (address: any[]) => void) {
    const google = (window as any).google;
    var geocoder = new google.maps.Geocoder();
    function geolocate() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    return resolve(geolocation);
                }, () => resolve());
            } else {
                resolve()
            }
        })
    }

    geolocate().then((geolocation) => {
        var markers: any[] = [];
        var map = new google.maps.Map(document.getElementById('map'), {
            center: geolocation,
            zoom: 16,
            mapTypeId: 'roadmap',
            mapTypeControlOptions: {
                mapTypeIds: [],
            }
        });

        function callbackAddressList(position: any) {
            geocoder.geocode({ location: position}, (results: any, status: any) => {
                if (status === 'OK') {
                    const ret = results.filter((item: any) => {
                        return /street_address|premise|route/g.test(item.types.join(','))
                    })
                    dragCallback(ret)
                }
            })
        }

        function newMarker(latLng: any) {
            markers.forEach(m => m.setMap(null));
            markers = [];
            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                draggable: true
            });
            callbackAddressList(latLng);
            marker.addListener('dragend', (...args: any[]) => {
                callbackAddressList(marker.getPosition());
            })
            markers.push(marker);
            map.setCenter(marker.position);
        }

        map.addListener('click', (event: any) => {
            newMarker(event.latLng);
        })
        newMarker(geolocation);
    
          // Create the search box and link it to the UI element.
        var input = document.getElementById('pac-input');
    
        // var searchBox = new google.maps.places.SearchBox(input);
        var autocomplete = new google.maps.places.Autocomplete(input, {
              types: ['address']
        });
    
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        autocomplete.addListener('place_changed', function() {
            newMarker(autocomplete.getPlace().geometry.location);
            return;
        });
    })
}


function initMap(onSelect: (item: any) => void, addressListCallback: (addressList: any) => void, initialLatLng?: any) {

    const mapInited = (window as any).mapInited;
    if (mapInited) {
        test(onSelect, addressListCallback);
    } else {
        setTimeout(() => initMap(initialLatLng, addressListCallback), 200);
    }
}


export interface Props {
    onSelect: (payload: {
        coordinateDesc: string;
        longitude: string;
        latitude: string
    }) => void;
}
export const GoogleMap: React.FC<Props> = ({
    onSelect
}) => {
    const [ list, setList ] = useState<any>([]);

    const handleSelect = useCallback((item: any) => {
        console.log("item =>", item);
        onSelect({
            coordinateDesc: item.formatted_address,
            longitude: `${item.geometry.location.lng()}`,
            latitude: `${item.geometry.location.lat()}`,
        })
    }, [])

    const handleDrag = useCallback((rets: any) => {
        setList(rets);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            initMap(handleSelect, handleDrag);
        }, 200)
    }, []);

    return (
        <div style={{ height: '960px', width: '100%' }}>
                

            <div id="map"></div>
            <input id="pac-input" className="controls" type="text" placeholder="Search" />
            <div className="map_address_list">
                {list.map((item: any, index: number) => (
                    <div className="map_address_item" onClick={() => handleSelect(item)} key={index}>{item.formatted_address}</div>
                ))}
            </div>
        </div>
    )
}