import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { Category, Product, ActivityReference } from '../../models';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { Language } from 'context/i18n';
import * as categoryApi from '../../api/category';
import * as productApi from '../../api/product';
import * as selectors from '../selectors';

type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;


export const fetchingCategoryList = action(
    getActionType('Fetching Category List'),
    payload<{
        storeId: string,
        language: Language,
    }>()
)

export const getCategoryListSuccess = action(
    getActionType('Get Category List Success'),
    payload<{
        storeId: string,
        language: Language,
        categoryList: Category[]
    }>()
)


export function getCategoryList(storeId: string, language: Language): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {

        const fetching = selectors.getCategoryFetchingOfLanguage(getState(), { language });
        if (fetching) return;

        dispatch(fetchingCategoryList({ storeId, language }));

        return categoryApi.getCategoryList(storeId, language).then(({ data }) => {
            dispatch(getCategoryListSuccess({
                storeId,
                language,
                categoryList: data
            }));
        })
    }
}
