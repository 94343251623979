import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Taste, SUB_TASTE_RADIO, CustomizableTasteSetting } from '../../models';
import { CustomizableSettingItem } from '../CustomizableSettingItem';
import styles from './index.module.css';
import { useI18n } from 'context/i18n';

interface Props {
    taste: Taste[];
    tasteSelected?: CustomizableTasteSetting;
}


export interface RefProps {
    getCustomizableTasteSetting: () => CustomizableTasteSetting
}
 export const CustomizableSetting = forwardRef<RefProps,Props>(({
    taste,
    tasteSelected
}, ref) => {
    const [ selected, setSelected ] = useState<CustomizableTasteSetting>(() => {
        if (tasteSelected) return tasteSelected;
        return taste.reduce((acc, item) => {
            return { ...acc, [item.code]: SUB_TASTE_RADIO.NORMAL };
        }, {})
    });

    useImperativeHandle(ref, () => ({
        getCustomizableTasteSetting: () => selected
    }), [selected])
    
    const handleChangeSelected = (tasteCode: string, tasteOptionId: SUB_TASTE_RADIO) => {
        setSelected({
            ...selected,
            [tasteCode]: tasteOptionId
        })
    }

    return (
        <div className={styles.customizableSetting}>
            { taste.map(item => (
                <CustomizableSettingItem
                    key={item.code}
                    taste={item}
                    selected={selected[item.code]}
                    onSelected={handleChangeSelected}
                />
            )) }
        </div>
    )
})  

// export const CustomizableSetting: React.FC<Props> =({
//     taste,
//     tasteSelected,
// }) => {

//     const [ selected, setSelected ] = useState<CustomizableTasteSetting>(() => {
//         if (tasteSelected) return tasteSelected;
//         return taste.reduce((acc, item) => {
//             return { ...acc, [item.code]: SUB_TASTE_RADIO.NORMAL };
//         }, {})
//     });
    
//     const handleChangeSelected = (tasteCode: string, tasteOptionId: SUB_TASTE_RADIO) => {
//         setSelected({
//             ...selected,
//             [tasteCode]: tasteOptionId
//         })
//     }

//     return (
//         <div className={styles.customizableSetting}>
//             { taste.map(item => (
//                 <CustomizableSettingItem
//                     key={item.code}
//                     taste={item}
//                     selected={selected[item.code]}
//                     onSelected={handleChangeSelected}
//                 />
//             )) }
//         </div>
//     )
// }