import React, { useEffect } from 'react';
import styles from './index.module.css';
import { ActivityProductInCartItem } from '../ActivityProductInCartItem';
import { useActivityProductInCartAction } from '../../facades/useActivityProductInCartAction'
import { ActivityProductInCart } from '../../models';
interface Props {
    className?: string;
    activityProductList: ActivityProductInCart[];
}

export const ActivityProductInCartList: React.FC<Props> = ({
    className = '',
    activityProductList,
}) => {

    const { methods: { deleteActivityProduct, changeCount, onActivityProductClick, onChangeClick, changeRemark } } = useActivityProductInCartAction();
    return (
        <div className={`${className} ${styles.productInCartList}`}>
            {activityProductList.map(item => (
                <ActivityProductInCartItem
                    key={item.id}
                    activityProductInCart={item}
                    onCountChange={changeCount}
                    onDelete={deleteActivityProduct}
                    onItemClick={onActivityProductClick}
                    onChangeClick={onChangeClick}
                    onChangeRemark={changeRemark}
                />
            ))}
        </div>
    )
}