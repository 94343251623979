import React, { useMemo } from 'react';
import styles from './index.module.css';

import { Store, IS_EXCEED, useTheme } from 'modules/store';
import { useI18n } from 'context/i18n';

export type StoreProps = Store & {

    dayText: string;
    hourText: React.ReactNode
    telText: string;
    telHref: string;
    disabled: boolean;
    disabledIcon: string;
}


interface Props {
    store: StoreProps;
    onClick: (store: Store) => void;
    selected: boolean;
    className?: string;
}
export const StoreItem: React.FC<Props> = ({
    store,
    onClick,
    selected,
    className = ''
}) => {
    const { getLang } = useI18n();
    const { theme: { secondary } } = useTheme();
    // const outIcon = useMemo(() => {
    //     if (/zh/.test(getLang())) {
    //         return outOfIconCh;
    //     }
    //     return outOfIconEn;
    // }, [getLang])


    const handleClick = () => {
        if (store.disabled) return;
        onClick(store);
    }

    return (
        <div
            key={store.storeID}
            className={`${className} ${styles.itemWarp} ${store.disabled ? styles.outOfDelivery : ''} ${selected ? styles.selected : ''}`}
            style={selected ? { borderColor: secondary.bg } : {}}
            onClick={handleClick}
        >
            { store.disabled &&
                <img className={styles.outOfPosition} src={store.disabledIcon} alt=""/>
            }

            {/* <div className={styles.storeItemIcon}>
                <img className={styles.storeItemIconImg} src={storeIconSrc} alt="" />
            </div> */}

            <div className={styles.storeDetail}>
                <div className={styles.basicInfo}>
                    <div className={styles.name}>{store.name}</div>
                    {store.distance &&
                        <div className={styles.distance}>{store.distance}Min</div>
                    }
                    <div className={styles.time}>
                        <span className={styles.timeText}>{store.dayText + ":"}</span>
                        <ul className={styles.timeUl}>
                            {store.hourText}
                        </ul>
                    </div>
    
                    {store.telText &&
                        <div className={styles.tel}>
                            <div className={styles.telHref}  onClick={ e => e.stopPropagation() }>{store.telText}</div>
                        </div>
                    }

                    <div className={styles.address}>
                        <div className={styles.addressText}>{store.addr}</div>
                    </div>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}