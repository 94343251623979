import { FEATURE_NAME } from './constants';
import { GlobalState } from './reducer';
import { createSelector } from 'reselect';

const select = (state: GlobalState) => state[FEATURE_NAME];


export const getStoreList = createSelector(select, state => state.storeList);
export const getCurrentStoreId = createSelector(select, state => state.currentStoreId);

export const getCurrentStore = createSelector(getStoreList, getCurrentStoreId, (list, id) => {
    return list.find(item => item.storeID === id);
} );

// 是否正在初始化
export const isInitLoading = createSelector(select, state => state.loading);

// 获取当前机构
export const getCurrentBrand = createSelector(select, state => state.currentBrand);

// 初始化是否有错误
export const isInitError = createSelector(select, state => state.isError);