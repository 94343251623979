import React from 'react';
import styles from './index.module.css';

import { OrderStatus } from '../../models';
import { useI18n } from 'context/i18n';
import { formatTime } from '../../utils';


interface Props {
    order: OrderStatus
}



export const OrderTimeInfo: React.FC<Props> = ({
    order
}) => {
    const { t } = useI18n();


    return (
        <>

            <div className={styles.row}>
                <div className={styles.label}>{t('order.createTime')}:</div>
                <div className={styles.content}>{formatTime(order.createTime)}</div>
                <div className="clear"></div>
            </div>

            {order.payTime && 
                <div className={styles.row}>
                    <div className={styles.label}>{t('order.payTime')}:</div>
                    <div className={styles.content}>{formatTime(order.payTime)}</div>
                    <div className="clear"></div>
                </div>
            }
            {order.prepareCompleteTime && 
                <div className={styles.row}>
                    <div className={styles.label}>{t('order.prepareCompleteTime')}:</div>
                    <div className={styles.content}>{formatTime(order.prepareCompleteTime)}</div>
                    <div className="clear"></div>
                </div>
            }
            {order.completeTime && 
                <div className={styles.row}>
                    <div className={styles.label}>{t('order.completeTime')}:</div>
                    <div className={styles.content}>{formatTime(order.completeTime)}</div>
                    <div className="clear"></div>
                </div>
            }
            {order.cancelTime && 
                <div className={styles.row}>
                    <div className={styles.label}>{t('order.invalidTime')}:</div>
                    <div className={styles.content}>{formatTime(order.cancelTime)}</div>
                    <div className="clear"></div>
                </div>
            }
            {order.payClosedTime && 
                <div className={styles.row}>
                    <div className={styles.label}>{t('order.payClosedTime')}:</div>
                    <div className={styles.content}>{formatTime(order.payClosedTime)}</div>
                    <div className="clear"></div>
                </div>
            }
        </>
    )
}