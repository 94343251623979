import React, { useState, useCallback } from 'react';
import styles from './index.module.css';
import { useI18n, Language } from 'context/i18n';
import { useTheme } from 'modules/store';
interface Props {
    className?: string
}
const languagePickerData: Array<{
    value: Language,
    label: string,
}> = [{
    value: 'zh-CN',
    label: '简体中文'
}, {
    value: 'zh-HK',
    label: '港澳繁体'
}, {
    value: 'en-US',
    label: 'English'
}, {
    label: '한국어',
    value: 'ko-kr',
}, {
    label: 'ViệtName',
    value: 'vi-vn',
}, {
    label: '日本語',
    value: 'ja-jp',
}]
export const LanguagePicker: React.FC<Props> = ({
    className = ''
}) => {
    const { theme } = useTheme();
    const { setLang,getLang } = useI18n();
    const [ showMenu, setShowMenu ] = useState<boolean>(false);

    const { t } = useI18n();
    const handleClick = useCallback((value: Language) => {
        setShowMenu(false)
        if (getLang() !== value) {
            setLang(value);
        }
    }, [showMenu, setLang, getLang]);

    const highlightStyle = useCallback((value: Language) => {
        return value === getLang() ? { color: theme.primary.bg } : {};
    }, [getLang, theme])

    return (
        <div
            className={`${className} ${styles.languagePicker}`}
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
        >
            <div style={{height: '100%'}}>Language</div>
            {showMenu &&
                <div className={styles.languageSelect}>
                    {languagePickerData.map(item => (
                        <div
                            className={styles.languageOption}
                            style={highlightStyle(item.value)}
                            key={item.value}
                            onClick={() => handleClick(item.value)}
                        >{item.label}</div>
                    ))}
                </div>
            }
        </div>
    )
}