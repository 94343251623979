import { Language, IS_DEFAULT_LANGUAGE } from 'context/i18n';

// 各model那的iamge
export interface Image {
    id: string;
    url: string;
}

export interface Category {
    id: string;
    code: string;
    name: string;
    fileUrl: string;
    type: MODEL_TYPE.CATEGORY

    language: Language;
    isDefault: IS_DEFAULT_LANGUAGE;
}

export enum MODEL_TYPE {
    PRODUCT = 'product',
    CATEGORY = 'category',
    TASTE = 'taste',
    DISCOUNT = 'discount',
    ACTIVITY = 'activity',
    ACTIVITY_PRODUCT = 'activityProduct',
    COUPON = 'coupon'
}
export enum PRODUCT_TYPE {
    FIVE_DAY = '1',
    NORMAL = '0'
}

export interface Product {
    id: string;
    code: string; 
    name: string; // 商品名称
    categoryCode: string;  // 商品分类code
    productFeatures: string;
    description: string;
    price: number;
    salePrice: number;
    customizableTasteList: Taste[];
    rawMaterials: string; // 原料

    images: Image[];
    type: MODEL_TYPE.PRODUCT;
    language: Language;
    isDefault: IS_DEFAULT_LANGUAGE;


    // 这个是响应就有的
    discountPrices: DiscountInProductRefernece[];

    // 当前生效的优惠
    currentDiscount?: DiscountVO;

    // 菜品参加的所有活动，每个活动内有对应的优惠
    activities: NormalActivityVO[];
}

// 菜品口味
export interface Taste {
    code: string;
    name: string;
    child: SubTasteRadio;
    type: MODEL_TYPE.TASTE;
    language: Language;
    isDefault: IS_DEFAULT_LANGUAGE;
}

// 单选类型的子口味
export interface SubTasteRadio {
    type: SUB_TASTE_TYPE.RADIO,
    options: SUB_TASTE_RADIO[]
}

// 子口味类型
export enum SUB_TASTE_TYPE {
    RADIO = 'radio' // 单选
}
export enum SUB_TASTE_RADIO {
    NONE = 0, // 不要
    VERY_LIGHT = 1, // 非常少,暂时忽略
    LIGHT = 2, // 少
    NORMAL = 3, // 正常
    EXTRA = 4, // 多
    SUPER_EXTRA = 5 // 超级多,暂时忽略
}
// 选择的口味
export interface CustomizableTasteSetting {
    [key: string]: SUB_TASTE_RADIO
}




// 频率类型
export enum CYCLE_TYPE {
    ALWAYS = 0, // 和活动日期一直
    DAY_IN_WEEK = 1, // 每周几
}

// 和活动周期一致的周期数据
interface AlwaysRuleCycle {
    cycleType: CYCLE_TYPE.ALWAYS
}

export type DayInWeekCycleData = Array<'0' | '1' | '2' | '3' | '4' | '5' | '6'>;
// 每周几的周期数据
interface DayInWeekRuleCycle {
    cycleType: CYCLE_TYPE.DAY_IN_WEEK,
    cycleData: DayInWeekCycleData
}

type RuleCycle = AlwaysRuleCycle | DayInWeekRuleCycle;

// 优惠类型
export enum RULE_TYPE{
    OFF = 1 // 折扣
}

interface RuleBase {
    name: string,
    ruleType: RULE_TYPE,
    value: string,
}


// 响应的优惠
export type DiscountReference = {
    id: string;
    storeId: string;

    activityCode: string;

    productCodes: string;
    type: MODEL_TYPE.DISCOUNT;
} & RuleBase & RuleCycle;

// 活动状态
enum ACTIVITY_STATUS {
    OFF = 0, // 未启用
    ON = 1, // 已启用
    FINISH = 2, // 已经结束
}


// 活动的类型
export enum ACTIVITY_TYPE {
    NORMAL = 'normal',
    KILL = 'kill'
}


// 响应的活动基础信息
export interface BaseActivityReference {
    code: string;
    id: string;
    storeId: string;
    name: string;
    showPosition: string;
    status: ACTIVITY_STATUS;
    startTime?: number;
    endTime?: number;
    type: MODEL_TYPE.ACTIVITY;
    fileUrl?: string;
    language: Language;
    isDefault: IS_DEFAULT_LANGUAGE;
}

export interface NormalActivityReference extends BaseActivityReference {
    discountIds: string[];
    activityType: ACTIVITY_TYPE.NORMAL;
}

export interface KillActivityReference extends BaseActivityReference {
    activityProducts: ActivityProductResponseDTO[];
    activityType: ACTIVITY_TYPE.KILL
}

// 响应的活动
export type ActivityReference = NormalActivityReference | KillActivityReference;

// 优惠是否当前生效
export enum IS_DISCOUNT_OPTIMAL {
    IS = 1,
    NO = 0,
}


// 优惠在菜里的信息
export interface DiscountInProductRefernece {
    discountId: string;
    salePrice: string;
    isOptimal: IS_DISCOUNT_OPTIMAL;
}


// 优惠 展示数据
export type DiscountVO = DiscountReference & DiscountInProductRefernece;

// 普通活动VO
export type NormalActivityVO = ActivityReference & {
    discounts: DiscountVO[];
};


// 秒杀活动VO
export interface KillActivityVO extends KillActivityReference {
    activityProducts: ActivityProductVO[]
}

export interface ActivityProductVO extends  ActivityProductResponseDTO{
    activity: KillActivityReference;
    product: Product
}

export enum ACTIVITY_PRODUCT_STATUS {
    PEDDING = 0, // 未开始
    RUNNING = 1, // 进行中
    COMPLETE = 2, // 已结束
}

export interface ActivityProductResponseDTO {
    id: string;
    activityCode: string;
    activityStatus: ACTIVITY_PRODUCT_STATUS
    productCode: string;
    salePrice: string;
    inventory: number; // 当前库存
    totalInventory: number, // 库存
    buyNum: number, // 本人已经买了多少
    maxBuyNum: number, // 单人动最大购买量
    type: MODEL_TYPE.ACTIVITY_PRODUCT;
}

