import { Category, Product, ActivityReference, ActivityProductVO } from '../models';
import { IS_DEFAULT_LANGUAGE, Language } from 'context/i18n';
import {
    on,
    reducer as createReducer,
} from 'ts-action';
import {
    getCategoryListSuccess,
    fetchingCategoryList,
} from './actions/category';
import {
    getProductListSuccess,
    fetchingProductList,
} from './actions/product-in-category';
import {
    fetchShowInCategoryActivityListRequest,
    fetchShowInCategoryActivityListSuccess,
} from './actions/activity';

import {
    fetchingProductListInActivity,
    fetchingProductListInActivitySuccess
} from './actions/product-in-activity';
import {
    fetchActivityDetailRequest,
    fetchActivityDetailSuccess,
    fetchActivityProductOfIdSuccess,
    updateActivityProductEntities,
} from './actions/product-in-kill-activity';

import { FEATURE_NAME } from './constants';
import { omit, assoc, equals, reject, assocPath, path, prop, uniq } from 'ramda';



export type ModelInLanguage<T> = {
    [K in Language]?: T
} & { default: T }

interface DataStatusOfLanguage {
    cached: boolean,
    fetching: boolean,
}
// 不同语言下的请求和缓存状态
export type DataStatusOfLanguageMap = Partial<Record<Language, DataStatusOfLanguage>>;
// 初始值
const initialDataStatusOfLanguage: DataStatusOfLanguage = {
    cached: false,
    fetching: false,
}

// 按分类分组的产品数据
interface ProductDataOfCode {
    codes: Array<Product['code']>,
    status: DataStatusOfLanguageMap
}

// 按分类分组的产品数据
interface ActivityProductDataOfCode {
    ids: string[],
    status: DataStatusOfLanguageMap
}

interface StateOfStoreId {
    category: {
        entities: {[key: string]: ModelInLanguage<Category>};
        codes: Array<Category['code']>;
        status: DataStatusOfLanguageMap;
    }
    product: {
        entities: { [key: string]: ModelInLanguage<Product> };
        dataOfCategoryCode: {
            [categoryCode: string]: ProductDataOfCode
        },
        dataOfActivityCode: {
            [activityCode: string]: ProductDataOfCode
        }
    },
    showInCategoryActivityList: {
        entities: {[key: string]: ModelInLanguage<ActivityReference>};
        codes: Array<ActivityReference['code']>;
        status: DataStatusOfLanguageMap;
    },
    activityProduct: {
        entities: { [key: string]: ModelInLanguage<ActivityProductVO> };
        dataOfActivityCode: {
            [activityCode: string]: ActivityProductDataOfCode
        }
    }
}

export const initialStateOfStoreId: StateOfStoreId = {
    category: {
        entities: {},
        codes: [],
        status: {},
    },
    product: {
        entities: {},
        dataOfCategoryCode: {},
        dataOfActivityCode: {},
    },
    showInCategoryActivityList: {
        entities: {},
        codes: [],
        status: {},
    },
    activityProduct: {
        entities: {},
        dataOfActivityCode: {}
    }
}

export const initialProductDataOfCode: ProductDataOfCode = {
    codes: [],
    status: {},
}
export const initialActivityProductDataOfCode: ActivityProductDataOfCode = {
    ids: [],
    status: {},
}
export interface State {
    [key: string]: StateOfStoreId
}


export interface GlobalState {
    [FEATURE_NAME]: State;
};

export const initialState: State = {
};

export const reducer = createReducer(
    initialState,
    on(
        fetchingCategoryList,
        (state, { payload: { storeId, language } }) => {
            const stateOfStoreId = state[storeId] || initialStateOfStoreId;
            const currentLanguageDataStatus = stateOfStoreId.category.status[language] || initialDataStatusOfLanguage;
            const newCurrentLanguageDataStatus = assoc('fetching', true, currentLanguageDataStatus);
            const newStateOfStoreId = assocPath(['category', 'status', language], newCurrentLanguageDataStatus, stateOfStoreId);
            return assoc(storeId, newStateOfStoreId, state);
        }
    ),
    on(
        getCategoryListSuccess,
        (state, { payload: {
            storeId,
            language,
            categoryList
        } }) => {
            const categoryDataOfStoreId = state[storeId].category;
            const categoryListCodes = uniq(categoryList.map(prop('code')));
            const categoryEntities = categoryList.reduce<{[key: string]: ModelInLanguage<Category>}>((acc, currCategory) => {
                if (!acc[currCategory.code]) {
                    acc = assocPath([currCategory.code], { default: currCategory }, acc);
                }

                acc = assocPath([currCategory.code, currCategory.language], currCategory, acc);
                if (currCategory.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currCategory.code, 'default'], currCategory, acc);
                }
                return acc;
            }, categoryDataOfStoreId.entities);

            const status = assoc(language, { fetching: false, cached: true }, categoryDataOfStoreId.status);

            return assocPath([storeId, 'category'], {
                entities: categoryEntities,
                codes: categoryListCodes,
                status
            }, state);
        }
    ),
    on(
        fetchingProductList,
        (state, { payload: { storeId, language, categoryCode } }) => {

            const stateOfStoreId = state[storeId] || initialStateOfStoreId;
            const productDataOfCategoryCode = stateOfStoreId.product.dataOfCategoryCode[categoryCode] || initialProductDataOfCode;
            const currentLanguageDataStatus = productDataOfCategoryCode.status[language] || initialDataStatusOfLanguage;
            const newCurrentLanguageDataStatus = assoc('fetching', true, currentLanguageDataStatus);

            const newProductDataOfCategoryCode = assocPath(['status', language], newCurrentLanguageDataStatus, productDataOfCategoryCode);
            const newStateOfStoreId = assocPath(['product', 'dataOfCategoryCode', categoryCode], newProductDataOfCategoryCode, stateOfStoreId);

            return assoc(storeId, newStateOfStoreId, state);
        }
    ),
    on(
        getProductListSuccess,
        (state, { payload: { storeId, language, categoryCode, productList } }) => {
            const productDataOfStoreId = state[storeId].product;

            const productListCodes = uniq(productList.map(prop('code')));
            const oldProductEntities = state[storeId].product.entities;
            const status = assoc(language, { fetching: false, cached: true }, productDataOfStoreId.dataOfCategoryCode[categoryCode].status);

            const newProductDataOfCategoryCode = {
                codes: productListCodes,
                status
            };

            const productEntities = productList.reduce<{[key: string]: ModelInLanguage<Product>}>((acc, currProduct) => {
                if (!acc[currProduct.code]) {
                    acc = assocPath([currProduct.code], { default: currProduct }, acc);
                }

                acc = assocPath([currProduct.code, currProduct.language], currProduct, acc);
                if (currProduct.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currProduct.code, 'default'], currProduct, acc);
                }
                return acc;
            }, oldProductEntities);

            const newState = assocPath([storeId, 'product', 'entities'], productEntities, state);
            return assocPath([storeId, 'product', 'dataOfCategoryCode', categoryCode], newProductDataOfCategoryCode, newState);
        }
    ),
    on(
        fetchActivityDetailRequest,
        (state, { payload: { storeId, language, activityCode } }) => {

            const stateOfStoreId = state[storeId] || initialStateOfStoreId;
            const activityProductDataOfActivityCode = stateOfStoreId.activityProduct.dataOfActivityCode[activityCode] || initialActivityProductDataOfCode;
            const currentLanguageDataStatus = activityProductDataOfActivityCode.status[language] || initialDataStatusOfLanguage;
            const newCurrentLanguageDataStatus = assoc('fetching', true, currentLanguageDataStatus);

            const newActivityProductDataOfActivityCode = assocPath(['status', language], newCurrentLanguageDataStatus, activityProductDataOfActivityCode);
            const newStateOfStoreId = assocPath(['activityProduct', 'dataOfActivityCode', activityCode], newActivityProductDataOfActivityCode, stateOfStoreId);

            return assoc(storeId, newStateOfStoreId, state);
        }
    ),

    on(
        fetchActivityDetailSuccess,
        (state, { payload: { storeId, language, activityCode, list } }) => {
            const activityProductDataOfStoreId = state[storeId].activityProduct;

            const listIds = uniq(list.map(prop('id')));
            const oldActivityProductEntities = state[storeId].activityProduct.entities;

            const status = assoc(language, { fetching: false, cached: true }, activityProductDataOfStoreId.dataOfActivityCode[activityCode].status);

            const newActivityProductDataOfActivityCode = {
                ids: listIds,
                status
            };

            const activityProductEntities = list.reduce<{[key: string]: ModelInLanguage<ActivityProductVO>}>((acc, currActivityProduct) => {
                if (!acc[currActivityProduct.id]) {
                    acc = assocPath([currActivityProduct.id], { default: currActivityProduct }, acc);
                }

                acc = assocPath([currActivityProduct.id, currActivityProduct.product.language], currActivityProduct, acc);
                if (currActivityProduct.product.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currActivityProduct.id, 'default'], currActivityProduct, acc);
                }
                return acc;
            }, oldActivityProductEntities);

            const newState = assocPath([storeId, 'activityProduct', 'entities'], activityProductEntities, state);
            return assocPath([storeId, 'activityProduct', 'dataOfActivityCode', activityCode], newActivityProductDataOfActivityCode, newState);
        }
    ),
    on(
        fetchActivityProductOfIdSuccess,
        (state, { payload: { storeId, language, activityCode, activityProduct } }) => {
            let activityProductEntities = state[storeId].activityProduct.entities;
            if (!activityProductEntities[activityProduct.id]) {
                activityProductEntities = assocPath([activityProduct.id], { default: activityProduct }, activityProductEntities);
            }
            activityProductEntities = assocPath([activityProduct.id, activityProduct.product.language], activityProduct, activityProductEntities);
            if (activityProduct.product.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                activityProductEntities = assocPath([activityProduct.id, 'default'], activityProduct, activityProductEntities);
            }
            return assocPath([storeId, 'activityProduct', 'entities'], activityProductEntities, state);
        }
    ),
    on(
        updateActivityProductEntities,
        (state, { payload: { storeId, activityProducts } }) => {
            const { entities: oldEntities } = state[storeId].activityProduct;
            const entities = activityProducts.reduce<{[key: string]: ModelInLanguage<ActivityProductVO>}>((acc, currActivityProduct) => {
                if (!acc[currActivityProduct.id]) {
                    acc = assocPath([currActivityProduct.id], { default: currActivityProduct }, acc);
                }

                acc = assocPath([currActivityProduct.id, currActivityProduct.product.language], currActivityProduct, acc);
                if (currActivityProduct.product.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currActivityProduct.id, 'default'], currActivityProduct, acc);
                }
                return acc;
            }, oldEntities);
            return assocPath([storeId, 'activityProduct', 'entities'], entities, state);
        }
    ),

    on(
        fetchShowInCategoryActivityListRequest,
        (state, { payload: { storeId, language } }) => {
            const stateOfStoreId = state[storeId] || initialStateOfStoreId;
            const currentLanguageDataStatus = stateOfStoreId.showInCategoryActivityList.status[language] || initialDataStatusOfLanguage;
            const newCurrentLanguageDataStatus = assoc('fetching', true, currentLanguageDataStatus);
            const newStateOfStoreId = assocPath(['showInCategoryActivityList', 'status', language], newCurrentLanguageDataStatus, stateOfStoreId);
            return assoc(storeId, newStateOfStoreId, state);

        }
    ),

    on(
        fetchShowInCategoryActivityListSuccess,
        (state, { payload: {
            storeId,
            language,
            list
        } }) => {
            const activityDataOfStoreId = state[storeId].showInCategoryActivityList;
            const activityListCodes = uniq(list.map(prop('code')));
            const activityEntities = list.reduce<{[key: string]: ModelInLanguage<ActivityReference>}>((acc, currActivity) => {
                if (!acc[currActivity.code]) {
                    acc = assocPath([currActivity.code], { default: currActivity }, acc);
                }

                acc = assocPath([currActivity.code, currActivity.language], currActivity, acc);
                if (currActivity.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currActivity.code, 'default'], currActivity, acc);
                }
                return acc;
            }, activityDataOfStoreId.entities);

            const status = assoc(language, { fetching: false, cached: true }, activityDataOfStoreId.status);

            return assocPath([storeId, 'showInCategoryActivityList'], {
                entities: activityEntities,
                codes: activityListCodes,
                status
            }, state);
        }
    ),



    on(
        fetchingProductListInActivity,
        (state, { payload: { storeId, language, activityCode } }) => {

            const stateOfStoreId = state[storeId] || initialStateOfStoreId;
            const productDataOfActivityCode = stateOfStoreId.product.dataOfActivityCode[activityCode] || initialProductDataOfCode;
            const currentLanguageDataStatus = productDataOfActivityCode.status[language] || initialDataStatusOfLanguage;
            const newCurrentLanguageDataStatus = assoc('fetching', true, currentLanguageDataStatus);

            const newProductDataOfActivityCode = assocPath(['status', language], newCurrentLanguageDataStatus, productDataOfActivityCode);
            const newStateOfStoreId = assocPath(['product', 'dataOfActivityCode', activityCode], newProductDataOfActivityCode, stateOfStoreId);

            return assoc(storeId, newStateOfStoreId, state);
        }
    ),
    on(
        fetchingProductListInActivitySuccess,
        (state, { payload: { storeId, language, activityCode, list } }) => {
            const productDataOfStoreId = state[storeId].product;

            const productListCodes = uniq(list.map(prop('code')));
            const oldProductEntities = state[storeId].product.entities;
            const status = assoc(language, { fetching: false, cached: true }, productDataOfStoreId.dataOfActivityCode[activityCode].status);

            const newProductDataOfActivityCode = {
                codes: productListCodes,
                status
            };

            const productEntities = list.reduce<{[key: string]: ModelInLanguage<Product>}>((acc, currProduct) => {
                if (!acc[currProduct.code]) {
                    acc = assocPath([currProduct.code], { default: currProduct }, acc);
                }

                acc = assocPath([currProduct.code, currProduct.language], currProduct, acc);
                if (currProduct.isDefault === IS_DEFAULT_LANGUAGE.IS) {
                    acc = assocPath([currProduct.code, 'default'], currProduct, acc);
                }
                return acc;
            }, oldProductEntities);

            const newState = assocPath([storeId, 'product', 'entities'], productEntities, state);
            return assocPath([storeId, 'product', 'dataOfActivityCode', activityCode], newProductDataOfActivityCode, newState);
        }
    ),
);
