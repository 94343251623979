import React, { useMemo } from 'react';
import styles from './index.module.css';
import { Store } from 'modules/store/models';

const accountsConfig = [{
    name: 'Paypal',
    field: 'paypalAccount' as const
}, {
    name: 'Venmo',
    field: 'venmoAccount' as const
}, {
    name: 'Zelle',
    field: 'zelleAccount' as const
}];


interface Props {
    className?: string;
    store: Store;
}

export const PaymentInformation: React.FC<Props> = ({
    className = '',
    store,
}) => {
    const offlineAccount = useMemo(() => {
        return accountsConfig.map(({ field, name }) => {
            return {
                account: store.offlinePayment[field],
                name
            }
        });
    }, [store]);

    const validOfflineAccount = useMemo(() => {
        return offlineAccount.filter(item => !!item.account);
    }, [offlineAccount]);

    const validOfflineAccountText = useMemo(() => {
        return `亲，网上预付款我们支持${validOfflineAccount.map(({ name }) => name).join('、')}`;
    }, [validOfflineAccount]);

    const invalidOfflineAccount = useMemo(() => {
        return offlineAccount.filter(item => !item.account);
    }, [offlineAccount]);
    const invalidOfflineAccountText = useMemo(() => {
        if (invalidOfflineAccount.length === 0) return '';
        return `请注意：我们不接受${invalidOfflineAccount.map(({name}) => name).join('、')}。`
    }, [invalidOfflineAccount]);

    const showInformation = useMemo(() => {
        return store.offlinePayment.enable && validOfflineAccount.length > 0;
    }, [store, validOfflineAccount]);


    if (!showInformation) return null;
    return (
        <div className={`${styles.paymentTip} ${className}`}>
            <div>
                <div>
                {validOfflineAccountText}
                {invalidOfflineAccountText &&
                    <div className = {styles.redText}>{invalidOfflineAccountText}</div>
                }
                </div>
                {validOfflineAccount.map(({ name, account }) => (
                    <div key={name}>
                        {`${name}账号：`}<span className={styles.paymentAccount}>{account}</span>。
                    </div>
                ))}
                注：付款时请备注一下店名和单号。谢谢哦！
            </div>
        </div>
    )
}