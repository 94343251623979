import { useCallback } from 'react';
import { createReceiverInfo } from '../api';
import { ReceiverInfo } from '../models';
import { useMountedState } from 'react-use';


export const useReceiverInfoCreate = () => {
    const mounted = useMountedState();
    const submit = useCallback((formData: Omit<ReceiverInfo, 'id'>) => {
        return new Promise((resolve, reject) => {
            createReceiverInfo(formData)
                .then(ret => {
                    mounted() && resolve(ret)
                })
                .catch(err => {
                    mounted() && reject(err)
                })

        })
    }, [mounted])

    return {
        methods: { submit }
    };
}