import { Product, CustomizableTasteSetting } from 'modules/product-menu';
import { ProductInCartStore, ActivityProductInCartStore } from '../models';

// 判断定制化是否相同
export function isSameCustomizableTaste(tasteA: CustomizableTasteSetting, tasteB: CustomizableTasteSetting) {
    return Object.keys(tasteA).reduce((acc: boolean, key: string) => {
        if (!acc) return acc;
        return tasteA[key] === tasteB[key]
    }, true);
}

// 是否和其他定制化相同
export function findProductInCartWithSameCustomizableTaste(
    currentCustomizable: CustomizableTasteSetting,
    otherProducts: ProductInCartStore[]
) {
    return otherProducts.reduce<ProductInCartStore | undefined>((acc, cur) => {
        if (acc) return acc;
        if (isSameCustomizableTaste(currentCustomizable, cur.customizableTasteSetting)) {
            return cur;
        }
        return undefined;
    }, undefined);
}

// 生成product in cart id
export function makeProductInCartId(productCode: Product['code']) {
    return `${productCode}_${Date.now()}${Math.floor(Math.random() * 1000)}`;
}


// 是否和其他定制化相同
export function findActivityProductInCartWithSameCustomizableTaste(
    currentCustomizable: CustomizableTasteSetting,
    otherActivityProducts: ActivityProductInCartStore[]
) {
    return otherActivityProducts.reduce<ActivityProductInCartStore | undefined>((acc, cur) => {
        if (acc) return acc;
        if (isSameCustomizableTaste(currentCustomizable, cur.customizableTasteSetting)) {
            return cur;
        }
        return undefined;
    }, undefined);
}
