import React, { lazy, Suspense, useCallback, useState } from 'react';
import { store } from 'store';
import { Provider as StoreProvider } from 'react-redux';
import { Header } from './layout/Header';
import { Body } from './layout/Body';
import { Footer } from './layout/Footer';
import { HashRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { I18nProvider } from 'context/i18n';
import { Main } from './Main';


const Providers: React.FC<{ children: React.ReactNode }> = ({
    children
}) => (
        <StoreProvider store={store}>
            <I18nProvider>
                {children}
            </I18nProvider>
        </StoreProvider>
)


function App() {
    return (
        <Providers>
            <Router>
                <Main />
            </Router>
        </Providers>
    );
}

export default App;
