import { FEATURE_NAME, initialDataOfUserId, initialDataOfStoreId } from './constants';
import { GlobalState } from './reducer';
import { createSelector } from 'reselect';
import { ProductInCartStore, ProductInCart, ActivityProductInCart } from '../models';
import { getTotalPrice as getTotalPriceUtil } from 'globalUtils';
import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as mealSelectors } from 'modules/product-menu/store';
import { selectors as storeSelectors } from 'modules/store/store';
import { Language } from 'context/i18n';
import { ACTIVITY_PRODUCT_STATUS } from 'modules/product-menu/models';


const select = (state: GlobalState) => state[FEATURE_NAME];


export const getDataOfStoreId = createSelector(
    select,
    storeSelectors.getCurrentStoreId,
    (state, storeId) => state[storeId!] || initialDataOfStoreId,
)

export const getProductInCartEntities = createSelector(getDataOfStoreId, state => state.entities)



export const getData = createSelector(getDataOfStoreId, state => state.data);

export const getDataOfUserId = createSelector([
    getData,
    authSelectors.getCurrentUser,    
], (data, currentUser) => {
    if (!currentUser) return initialDataOfUserId;
    return data[currentUser.id] || initialDataOfUserId;
})

// 获取所有商品ids
export const getProductCodes = createSelector(getDataOfUserId, dataOfUserId => dataOfUserId.productCodes);
export const getCartIdsOfProductCodeMap = createSelector(getDataOfUserId, dataOfUserId => dataOfUserId.cartIdsOfProductCodeMap);

// 获取所有普通商品对应的cartIds
export const getAllCartIds = createSelector(
    getProductCodes,
    getCartIdsOfProductCodeMap,
    (codes, cartIdsOfProductCodeMap) => codes.reduce<Array<ProductInCartStore['id']>>((acc, id) => {
        return [...acc, ...cartIdsOfProductCodeMap[id]]
    }, [])
)

// 获取购物车内所有商品
export const getAllProductInCart = createSelector(
    (_: any, props: { lang?: Language }) => props.lang,
    getAllCartIds,
    getProductInCartEntities,
    mealSelectors.getProductEntitiesOfStoreId,
    (lang, cartIds, entities, productEntities) => cartIds.map(id => {
        const productInCart = entities[id];
        const productOfLanguage = productEntities[productInCart.productCode];
        const product = lang && productOfLanguage[lang] ? productOfLanguage[lang] : productOfLanguage.default;
        return {
            ...productInCart,
            product,
        } as ProductInCart;
    })
)

export const getCartIdsOfProductCode = createSelector(
    (_: any, props: { code: ProductInCart['productCode'] }) => props.code,
    getCartIdsOfProductCodeMap,
    (code, entities) => entities[code] || []
);

// 获取某个商品在购物车内的所有信息
export const getProductInCartOfProductCode = createSelector(
    (_: any, props: { lang?: Language }) => props.lang,
    getCartIdsOfProductCode,
    getProductInCartEntities,
    mealSelectors.getProductEntitiesOfStoreId,
    (lang, cartIds, entities, productEntities) => cartIds.map(id => {
        const productInCart = entities[id];
        const productOfLanguage = productEntities[productInCart.productCode];
        const product = lang && productOfLanguage[lang] ? productOfLanguage[lang] : productOfLanguage.default;
        return {
            ...productInCart,
            product,
        }
    })
);

// 获取所有活动商品ids
export const getActivityProductIds = createSelector(getDataOfUserId, dataOfUserId => dataOfUserId.activityProductIds);
// 获取购物车和活动商品的映射
export const getCartIdsOfActivityProductIdMap = createSelector(getDataOfUserId, dataOfUserId => dataOfUserId.cartIdsOfActivityProductIdMap);

// 获取所有活动商品对应的cartIds
export const getAllActivityProductCartIds = createSelector(
    getActivityProductIds,
    getCartIdsOfActivityProductIdMap,
    (codes, cartIdsOfActivityProductIdMap) => codes.reduce<string[]>((acc, id) => {
        return [...acc, ...cartIdsOfActivityProductIdMap[id]]
    }, [])
)

// 获取活动商品在购物车内的entities
const getActivityProductInCartEntities = createSelector(
    getDataOfStoreId,
    dataOfStoreId => dataOfStoreId.activityProductInCartEntities
);
export const getActivityProductInCartOfId = createSelector(
    (_: any, props: { cartId?: string }) => props,
    getActivityProductInCartEntities,
    ({ cartId }, entities) => {
        if (!cartId) return undefined;
        return entities[cartId];
    }
)

// 获取购物车内所有活动商品
export const getAllActivityProductInCart = createSelector(
    (_: any, props: { lang?: Language }) => props.lang,
    getActivityProductIds,
    getAllActivityProductCartIds,
    getActivityProductInCartEntities,
    mealSelectors.getActivityProductEntities,
    (lang, activityProductIds, cartIds, entities, activityProductEntities) => {

        const errorMsgMap = activityProductIds.reduce<{ [activityProductId: string]: string }>((acc, activityProductId) => {
            const activityOfLanguage = activityProductEntities[activityProductId];
            const activityProduct = (lang && activityOfLanguage[lang] ? activityOfLanguage[lang] : activityOfLanguage.default)!;
            const { inventory, buyNum, maxBuyNum, activityStatus } = activityProduct;
            
            if (activityStatus === ACTIVITY_PRODUCT_STATUS.PEDDING) {
                return {...acc, [activityProductId]: 'activityProduct.label.event_starts_later' };
            }
            if (activityStatus === ACTIVITY_PRODUCT_STATUS.COMPLETE) {
                return {...acc, [activityProductId]: 'activityProduct.label.event_is_over' };
            }

            const activityProductInCartList = cartIds.map(cartId => entities[cartId]).filter(item => item.activityProductId === activityProductId);
            const currentBuyNum = activityProductInCartList.reduce((acc, curr) => acc + curr.count, 0);
            if (currentBuyNum > (maxBuyNum - buyNum)) {
                return {...acc, [activityProductId]: 'activityProduct.label.purchase_limit_reached' };
            }

            if (currentBuyNum > inventory) {
                return {...acc, [activityProductId]: 'activityProduct.label.not_enough_items_to_sell' };
            }

            return acc;
        }, {});


        return cartIds.map(id => {

            const activityProductInCart = entities[id];

            const activityOfLanguage = activityProductEntities[activityProductInCart.activityProductId];
            const activityProduct = (lang && activityOfLanguage[lang] ? activityOfLanguage[lang] : activityOfLanguage.default)!;

            const ret: ActivityProductInCart = {
                ...activityProductInCart,
                activityProduct,
                error: errorMsgMap[activityProductInCart.activityProductId]
            };
            return ret;
        })
    }
)

// 根据活动商品id获取cartIds
const getCartIdsOfActivityOfProductId = createSelector(
    (_: any, props: { activityProductId: string }) => props.activityProductId,
    getCartIdsOfActivityProductIdMap,
    (id, entities) => entities[id] || []
);


// 获取某个活动商品在购物车内的所有信息
export const getActivityProductInCartOfActivityProductId = createSelector(
    (_: any, props: { lang?: Language }) => props.lang,
    getCartIdsOfActivityOfProductId,
    getActivityProductInCartEntities,
    mealSelectors.getActivityProductEntities,
    (lang, cartIds, entities, activityProductEntities) => cartIds.map(id => {
        const activityProductInCart = entities[id];

        const activityProductOfLanguage = activityProductEntities[activityProductInCart.activityProductId];
        const activityProduct = lang && activityProductOfLanguage[lang] ? activityProductOfLanguage[lang] : activityProductOfLanguage.default;
        
        return {
            ...activityProductInCart,
            activityProduct
        }
    })
);

// 获取普通商品的全数量
const getNormalProductTotalCount = createSelector(
    getAllProductInCart,
    allCart => allCart.reduce((acc, cur) => acc + cur.count, 0)
)
// 获取活动商品的全数量
const getActivityProductTotalCount = createSelector(
    getAllActivityProductInCart,
    allCart => allCart.reduce((acc, cur) => acc + cur.count, 0)
)

// 获取商品数量
export const getTotalCount = createSelector(
    getNormalProductTotalCount,
    getActivityProductTotalCount,
    (normalProductTotalCount, activityProductTotalCount) => normalProductTotalCount + activityProductTotalCount
);

// 根据活动菜品id获取除了该cartId以外的在购物车内的总数量
export const getCountOfActivityProductIdAndCartId = createSelector(
    (_: any, props: { cartId?: string }) => props.cartId,
    getActivityProductInCartOfActivityProductId,
    (cartId, activityProductsInCart) => activityProductsInCart.reduce((acc, curr) => {
        if (cartId === curr.id) return acc;
        return acc + curr.count
    }, 0)
)

const getNormalProductSubTotalPrice = createSelector(
    getAllProductInCart,
    allCart => getTotalPriceUtil(
        allCart.map(item => ({ price: item.product.price, count: item.count }))
    )
);

const getActivityProductSubTotalPrice = createSelector(
    getAllActivityProductInCart,
    allCart => getTotalPriceUtil(
        allCart.map(item => ({ price: item.activityProduct.salePrice, count: item.count }))
    )
);

// 获取所有商品总价
export const getSubTotalPrice = createSelector(
    getNormalProductSubTotalPrice,
    getActivityProductSubTotalPrice,
    (normalProductSubTotalPrice, activityProductSubTotalPrice) => normalProductSubTotalPrice + activityProductSubTotalPrice
);

export const getProductInCartOfId = createSelector(
    getProductInCartEntities,
    (_: any, props: { id?: ProductInCartStore['id'] }) => props.id,
    (entities, id) => id ? entities[id] : undefined
);
export const getPromoData = createSelector(
    getDataOfUserId,
    dataOfUserId => dataOfUserId.coupon
)