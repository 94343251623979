import React from 'react';
import styles from './index.module.css';


interface Props {
    header: React.ReactNode;
    children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
    header,
    children
}) => {
    return (
        <div className={styles.orderInfoLayout}>
            {header}
            <div className={styles.container}>
                {children}
            </div>
        </div>
    )
}