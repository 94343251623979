import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../store';
import { Category, ActivityReference } from '../models';

import { RootState } from 'store';
import { useI18n } from 'context/i18n';
import { useStore } from 'modules/store';


export type CategoryListType = Category | ActivityReference;

export const useCategoryList = () => {
    const { currentStoreId } = useStore();
    const dispatch = useDispatch();
    const { lang } = useI18n();
    const getList = useCallback(() => {
        if (currentStoreId) {
            dispatch(actions.getCategoryList(currentStoreId, lang));
            dispatch(actions.fetchShowInCategoryActivityList(currentStoreId, lang));
        }
    }, [currentStoreId, dispatch, lang]);
    const loading = useSelector<RootState, boolean>(state => selectors.getCategoryLoadingOfStoreId(state, { language: lang }));
    const list = useSelector<RootState, Category[]>(state => selectors.getCategoryListOfLanguage(state, { language: lang }));


    const showInCategoryActivityListLoading = useSelector<RootState, boolean>(state => selectors.getActivityListLoadingOfStoreId(state, { language: lang }));
    const showInCategoryActivityList = useSelector<RootState, ActivityReference[]>(state => selectors.getActivityListOfLanguage(state, { language: lang }));

    const showList = useMemo(() => {
        return [ ...showInCategoryActivityList, ...list ];
    }, [list, showInCategoryActivityList]);

    return { 
        loading: (loading || showInCategoryActivityListLoading),
        showList,
        list,
        showInCategoryActivityList,
        methods: { getList }
    };

}