import React, { useCallback } from 'react';
import styles from './index.module.css';
import { OrderList, useOrderList } from 'modules/buy';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Spin } from 'components/Spin';
import { Header } from '../../components/Header';
import { useI18n } from 'context/i18n';
export const MyOrderList: React.FC = () => {
    const { url } = useRouteMatch();
    const { push } = useHistory();
    const { loading, value, empty } = useOrderList();
    const { t } =useI18n();
    const handleClick = useCallback((id: string) => {
        push(`${url}/${id}`);
    }, [push, url]);

    return (


        <div className={styles.orderList}>
            <Spin spinning={loading}>
                <Header title={t('order.name')} />
                <div className={styles.listWrapper}>
                    { !loading && !empty &&
                            <OrderList list={value} onItemClick={handleClick} />
                    }
                    {!loading && empty &&
                        <div className={styles.listEmpty}>{t('order.listEmpty')}</div>
                    }
                </div>
            </Spin>
        </div>
    );
}
