import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './index.module.css';
import { useMountedState } from 'react-use';
import dayjs from 'dayjs';

import { useStore, Store, IS_EXCEED, isInHour, isNearClose, useTheme } from 'modules/store';
import { Language, useI18n } from 'context/i18n';
import { DELIVERY_TYPE } from 'modules/order-info';

import { ActionButton } from 'components/ActionButton';
import { promiseAlert } from 'components/Modal';
import { Layout } from '../Layout';
import { Header } from '../Header';
import { StoreItem, StoreProps } from '../StoreItem';

import outOfIconEn from '../../images/out_of_delivery_en.png';
import outOfIconCh from '../../images/out_of_delivery_ch.png';

import deliveryDisabledIconEn from '../../images/delivery_disabled_en.png';
import deliveryDisabledIconCh from '../../images/delivery_disabled_ch.png';

const rapidPickUpDisabledIconEn = '';
const rapidPickUpDisabledIconCh = '';
const lockerPickUpDisabledIconEn = '';
const lockerPickUpDisabledIconCh = '';

const DEFAULT_HOUR = '9:00 - 20:00';

function getDayKey(day: number/** 0-6 */): keyof Store['hour'] {
    return (['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'] as Array<keyof Store['hour']>)[day];
}

function getDayText(day: number, lang: Language) {
    const map: Record<Language, string[]> = {
        'zh-CN': ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        'en-US': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'zh-HK': ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        'ko-kr': ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        'ja-jp': ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        'vi-vn': ['Chủ nhật', 'Thứ Hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ Sáu', 'Thứ bảy'],
    };
    return map[lang][day];
}

function geyDayHour(day: number, hour: Store['hour']) {
    return hour[getDayKey(day)] || DEFAULT_HOUR;
}

function formatHour(hourStr: string) {
    if (!hourStr) return;
    return hourStr.replace(/(\d.*?)((am|pm))/g, function ($0, $1, $2) {
        if ($1.indexOf(':') == -1) {
            return $1 + ':00 ' + $2;
        } else {
            return $1 + ' ' + $2;
        }
    });
}

interface Geo {
    longitude: string;
    latitude: string;
}

function geolocate(): Promise<Geo | undefined> {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var geolocation = {
                    longitude: `${position.coords.latitude}`,
                    latitude: `${position.coords.longitude}`
                };
                return resolve(geolocation);
            }, (err) => {
                resolve();
            });
        } else {
            resolve();
        }
    });
}

interface Props {
    initialSelectedId?: string,
    onNext: (selectedId: string) => void,
    deliveryType: DELIVERY_TYPE,
    geo?: {
        latitude: string,
        longitude: string
    } | boolean,
    goBack: () => void;
}

export const StoreList: React.FC<Props> = (
    {
        initialSelectedId,
        onNext,
        deliveryType,
        geo = false,
        goBack,
    }
) => {
    const disabledIconMap = useMemo(() => ({
        zh: {
            outOfDelivery: outOfIconCh,
            eatType: {
                [DELIVERY_TYPE.DELIVERY]: deliveryDisabledIconCh,
                [DELIVERY_TYPE.RAPID_PICK_UP]: rapidPickUpDisabledIconCh,
                [DELIVERY_TYPE.LOCKER_PICK_UP]: lockerPickUpDisabledIconCh
            }
        },
        en: {
            outOfDelivery: outOfIconEn,
            eatType: {
                [DELIVERY_TYPE.DELIVERY]: deliveryDisabledIconEn,
                [DELIVERY_TYPE.RAPID_PICK_UP]: rapidPickUpDisabledIconEn,
                [DELIVERY_TYPE.LOCKER_PICK_UP]: lockerPickUpDisabledIconEn
            }
        }
    }), []);
    const { theme: { primary } } = useTheme();
    const { getLang, t } = useI18n();
    const mounted = useMountedState();
    const [loading, setLoading] = useState<boolean>(false);
    const { methods: { getStoreList } } = useStore();
    const [selected, setSelected] = useState<Store>();
    const [storeList, setStoreList] = useState<Store[]>([]);

    useEffect(() => {
        setLoading(true);
        (() => {
            if (typeof geo === 'boolean' && geo) {
                return geo ? geolocate() : Promise.resolve(undefined);
            } else {
                return Promise.resolve(geo as Geo);
            }
        })().then((result) => {
            const params = result ? { latitude: result.latitude, longitude: result.longitude } : undefined;

            getStoreList(params)
                .then(list => {
                    if (mounted()) {
                        setLoading(false);
                        console.log('list =>', list);
                        setStoreList(list);
                    }
                });
        });
    }, [mounted, geo, getStoreList]);

    const handleNext = useCallback(() => {
        if (!selected) return;
        // 是不是营业时间以内
        (() => {
            if (isInHour(new Date(), selected.hourData)) {
                if (isNearClose(new Date(), selected.hourData)) {
                    // 提示快要到点了
                    return promiseAlert(
                        t,
                        '',
                        t('orderTime.error.nowNearClose')
                    );
                }
                return Promise.resolve('ok');
            } else {
                // 提示不在营业时间内，只能预定
                return promiseAlert(
                    t,
                    '',
                    t('orderTime.error.nowOutOfHour')
                );
            }
        })().then(ret => {
            if (ret === 'ok') {
                onNext(selected.storeID);
            }
        });
    }, [selected, t, onNext]);

    const list: StoreProps[] = useMemo(() => {
        const day = Number(dayjs().format('d'));
        return storeList.map((item) => {
            const hourTextOfDay = item.hourDataTextOfWeek[day];
            const isClosed = hourTextOfDay.length === 0;

            const eatTypeEnable = item.eatTypes.includes(deliveryType);
            const outOfDelivery = deliveryType === DELIVERY_TYPE.DELIVERY && item.isExceed === IS_EXCEED.IS;
            const disabled = !eatTypeEnable || outOfDelivery;

            const lang = /zh/.test(getLang()) ? 'zh' : 'en';
            let disabledIcon = '';
            if (!eatTypeEnable) {
                disabledIcon = disabledIconMap[lang].eatType[deliveryType];
            } else if (outOfDelivery) {
                disabledIcon = disabledIconMap[lang].outOfDelivery;
            }

            const hourText = isClosed
                ? <li className={styles.timeLi}>Closed</li>
                : hourTextOfDay.map((h, idx) => (
                    <li key={idx} className={styles.timeLi}>{h}</li>
                ));

            return {
                ...item,
                dayText: getDayText(day, getLang()),
                hourText,
                telText: item.tel,
                telHref: `tel:${(item.tel || '').replace(/\(|\)|-|\s/gu, '')}`,
                disabled,
                disabledIcon,
            };
        });
    }, [storeList, deliveryType, getLang, disabledIconMap]);

    useEffect(() => {
        const selectedItem = list.find(item => item.storeID === initialSelectedId);
        if (selectedItem && !selectedItem.disabled) {
            setSelected(selectedItem);
        } else {
            setSelected(undefined);
        }
    }, [initialSelectedId, list]);
    const renderList = () => {
        const nextButtonClassName = selected ? '' : styles.disabled;
        const nextButtonStyle = selected ? { background: primary.bg, color: primary.color } : {
            background: '#f8f8f8',
            color: '#333'
        };
        return (
            <>
                <div>
                    {list.map(store => (
                        <StoreItem
                            className={styles.storeItem}
                            key={store.storeID}
                            selected={selected?.storeID === store.storeID}
                            store={store}
                            onClick={setSelected}
                        />
                    ))}
                    <div className="clear"></div>
                </div>
                <div
                    className={`${styles.nextButton} ${nextButtonClassName}`}
                    style={nextButtonStyle}
                    onClick={handleNext}
                >{t('form.actions.confirmAndNext')}</div>
            </>
        );
    };

    return (
        <Layout header={
            <Header title={t('receiverInfo.selectStore')}>
                <ActionButton onClick={goBack}>{t('form.actions.back')}</ActionButton>
            </Header>
        }>
            {loading && <div>loading...</div>}
            {!loading && renderList()}
        </Layout>
    );
};
