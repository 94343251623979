//登录



import React, { useState, useCallback, useMemo } from 'react';
import { NextButton } from './components/NextButton';
import { PasswordInput } from './components/PasswordInput';
import { Mobile, MobileWithCountryCode } from './components/Mobile';
import { actions } from 'modules/auth/store';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { message } from 'components/Message';
import { useI18n } from 'context/i18n';
import { GoogleLogin} from 'modules/auth/components/GoogleLogin';


interface Props {
    onSuccess: () => void
}

export const SignIn: React.FC<Props> = ({
    onSuccess,
}) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const mounted = useMountedState();
    const [ loading, setLoading ] = useState<boolean>(false);
    // 手机号
    const [mobileWithCountryCode, setMobileWithCountryCode] = useState<MobileWithCountryCode>(new MobileWithCountryCode());

    // 密码
    const [password, setPassword] = useState("");


    const isSubmitDisabled = useMemo(() => {
        return password.length === 0 || mobileWithCountryCode.isEmptyMobile();
    }, [mobileWithCountryCode, password]);

    const handleSubmit = useCallback(() => {
        setLoading(true);
        const loginName = mobileWithCountryCode.getFullMobileText();
        (dispatch(actions.login({
            login_name: loginName,
            password
        })) as any as Promise<any>).then(() => {
            if (mounted()) {
                setLoading(false);
                onSuccess();
            }
        }).catch(res => {
            if (mounted()) {
                setLoading(false);
                setTimeout(() => {
                    if (res.status === 400 || res.status === 403) {
                        message.error(res.data.errors.message, 2);
                    } else {
                        message.error(t('当前网络状态不好，请稍后尝试'), 2);
                    }
                })
            }
        });

    }, [mobileWithCountryCode, password, dispatch, mounted]);

    const handleTouristLogin = useCallback(() => {
        console.log("handleTouristLogin =>");
        setLoading(true);
        (dispatch(actions.touristLogin()) as any as Promise<any>)
            .then(() => {
                if (mounted()) {
                    setLoading(false);
                    onSuccess();
                }
            }).catch(res => {
                console.log("res =>", res);
                if (mounted()) {
                    setLoading(false);
                    setTimeout(() => {
                        if (res.status === 400 || res.status === 403) {
                            message.error(res.data.errors.message, 2);
                        } else {
                            message.error(t('当前网络状态不好，请稍后尝试'), 2);
                        }
                    })
                }
            });
    }, [dispatch, mounted]);

    return (
        <>
            <Mobile value={mobileWithCountryCode} onChange={setMobileWithCountryCode}/>

            <PasswordInput
                value={password}
                onChange={setPassword}
                placeholder={t('auth.placeholder.password')}
            />
            <NextButton disabled={isSubmitDisabled} onClick={handleSubmit}>{t('auth.signIn')}</NextButton>
            <NextButton onClick={handleTouristLogin}>{t('auth.touristSignIn')}</NextButton>
            {/* <div style={{display:'flex',justifyContent:'center',justifyItems:'center'}}>
                <GoogleLogin  onSuccess={onSuccess}/>
            </div> */}

        </>
    )
}