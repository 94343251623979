import { useCallback } from 'react';
import { ProductInCart, ActivityProductInCart } from '../models';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { selectors } from '../store';
import { useI18n } from 'context/i18n';
import { useCalculateAmount } from './useCalculateAmount';
import { actions } from '../store';

import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as storeSelectors } from 'modules/store/store';
export const useProductListInCart = () => {
    const { lang } = useI18n();
    const dispatch = useDispatch();

    const currentUser = useSelector(authSelectors.getCurrentUser);
    const storeId = useSelector(storeSelectors.getCurrentStoreId);
    const count = useSelector<RootState, number>(state => selectors.getTotalCount(state, {}));

    const subTotalPrice = useSelector<RootState, number>(state => selectors.getSubTotalPrice(state, {}));

    const productList = useSelector<RootState, ProductInCart[]>(state => selectors.getAllProductInCart(state, { lang }));
    const activityProductList = useSelector<RootState, ActivityProductInCart[]>(state => selectors.getAllActivityProductInCart(state, { lang }));

    const promoCodeData = useSelector(selectors.getPromoData);

    const setPromoCode = useCallback((code: string) => { dispatch(actions.setPromoCode({ code, userId: currentUser!.id, storeId: storeId! })) }, []);
    const changePromoCodeEnable = useCallback((enable: boolean) => dispatch(actions.changePromoCodeEnable({ enable, userId: currentUser!.id, storeId: storeId! })), []);

    const { loading, orderAmount, productListInCartVO, methods: { calculate } } = useCalculateAmount({
        productListInCart: productList,
        activityProductListInCart: activityProductList,
        subTotalPrice,
        promoCodeData,
        setPromoCode
    });


    return {
        count,
        subTotalPrice,
        productList,
        activityProductList,
        loading, orderAmount, productListInCartVO,
        promoCodeData,
        methods: {
            calculate,
            setPromoCode,
            changePromoCodeEnable
        }
    };
}