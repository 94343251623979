import React from 'react';
import { Modal } from 'components/Modal';
import { Payment, Props } from '../Payment';

export const PaymentModal: React.FC<{
    visable: boolean,
} & Props> = ({visable, onCancel, ...props}) => {
    return (
        <Modal
            visible={visable}
            width={600}
            style={{top: '300px'}}
            mask={true}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={false}
        >
            <Payment {...props} onCancel={onCancel}/>
        </Modal>
    )
}


