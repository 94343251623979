import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { routerReducer, routerMiddleware, RouterState, ConnectedRouter } from 'react-router-redux';
import thunk from 'redux-thunk';

import * as fromAuth from 'modules/auth/store';
import * as fromMeal from 'modules/product-menu/store';
import * as fromStore from 'modules/store/store';
import * as fromOrderInfo from 'modules/order-info/store';
import * as fromBuy from 'modules/buy/store';
import { AnalyticsLogger } from './analytics-middleware';
import createHashHistory from 'history/createHashHistory';
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface RootState {
    [fromAuth.FEATURE_NAME]: fromAuth.State;
    [fromMeal.FEATURE_NAME]: fromMeal.State;
    [fromStore.FEATURE_NAME]: fromStore.State;
    [fromOrderInfo.FEATURE_NAME]: fromOrderInfo.State;
    [fromBuy.FEATURE_NAME]: fromBuy.State;
}

const reducers = combineReducers<RootState>({
    [fromAuth.FEATURE_NAME]: fromAuth.reducer,
    [fromMeal.FEATURE_NAME]: fromMeal.reducer,
    [fromStore.FEATURE_NAME]: fromStore.reducer,
    [fromOrderInfo.FEATURE_NAME]: fromOrderInfo.reducer,
    [fromBuy.FEATURE_NAME]: fromBuy.reducer,
});


export const store = createStore(reducers, composeEnhancers(
    applyMiddleware(thunk, AnalyticsLogger)
));