import { CountryCodeAndName } from './types';
export const list: CountryCodeAndName[] = [
    {
        "code": "US",
        "name": "Hoa Kỳ"
    },
    {
        "code": "CN",
        "name": "Trung Quốc"
    },
    {
        "code": "SG",
        "name": "Singapore"
    },
    {
        "code": "TW",
        "name": "Đài Loan (Trung Quốc)"
    },
    {
        "code": "AD",
        "name": "Andorra"
    },
    {
        "code": "AE",
        "name": "các Tiểu Vương Quốc Ả Rập Thống Nhất"
    },
    {
        "code": "AF",
        "name": "Afghanistan"
    },
    {
        "code": "AL",
        "name": "Albania"
    },
    {
        "code": "AM",
        "name": "Armenia"
    },
    {
        "code": "AO",
        "name": "Ăng-gô"
    },
    {
        "code": "AR",
        "name": "Argentina"
    },
    {
        "code": "AT",
        "name": "Áo"
    },
    {
        "code": "AU",
        "name": "Châu Úc"
    },
    {
        "code": "AZ",
        "name": "Ailen"
    },
    {
        "code": "BD",
        "name": "Bangladesh"
    },
    {
        "code": "BE",
        "name": "nước Bỉ"
    },
    {
        "code": "BF",
        "name": "Burkina Faso"
    },
    {
        "code": "BG",
        "name": "Bulgaria"
    },
    {
        "code": "BH",
        "name": "Bahrain"
    },
    {
        "code": "BI",
        "name": "Burundi"
    },
    {
        "code": "BJ",
        "name": "Bénin"
    },
    {
        "code": "BN",
        "name": "Brunei"
    },
    {
        "code": "BO",
        "name": "Bôlivia"
    },
    {
        "code": "BR",
        "name": "Brazil"
    },
    {
        "code": "BS",
        "name": "Ba Tư"
    },
    {
        "code": "BW",
        "name": "Botswana"
    },
    {
        "code": "BY",
        "name": "Bêlarut"
    },
    {
        "code": "BZ",
        "name": "Belize"
    },
    {
        "code": "CA",
        "name": "Canada"
    },
    {
        "code": "CF",
        "name": "Cộng hòa trung phi"
    },
    {
        "code": "CG",
        "name": "Cộng hòa Congo)"
    },
    {
        "code": "CH",
        "name": "Thụy sĩ"
    },
    {
        "code": "CK",
        "name": "Đảo"
    },
    {
        "code": "CL",
        "name": "Chile"
    },
    {
        "code": "CM",
        "name": "Ca-mơ-run"
    },
    {
        "code": "CO",
        "name": "Colombia"
    },
    {
        "code": "CR",
        "name": "Costa rica"
    },
    {
        "code": "CU",
        "name": "Cuba"
    },
    {
        "code": "CY",
        "name": "Síp"
    },
    {
        "code": "CZ",
        "name": "Cộng hòa Séc"
    },
    {
        "code": "DE",
        "name": "nước Đức"
    },
    {
        "code": "DJ",
        "name": "Djibouti"
    },
    {
        "code": "DK",
        "name": "Đan mạch"
    },
    {
        "code": "DZ",
        "name": "Algeria"
    },
    {
        "code": "EC",
        "name": "Ecuador"
    },
    {
        "code": "EE",
        "name": "Estonia"
    },
    {
        "code": "EG",
        "name": "Ai Cập"
    },
    {
        "code": "ES",
        "name": "Tây Ban Nha"
    },
    {
        "code": "ET",
        "name": "Ê-díp-tô"
    },
    {
        "code": "FI",
        "name": "Phần Lan"
    },
    {
        "code": "FJ",
        "name": "Phi-gi"
    },
    {
        "code": "FR",
        "name": "Pháp"
    },
    {
        "code": "GA",
        "name": "Gabon"
    },
    {
        "code": "GB",
        "name": "Vương quốc Anh"
    },
    {
        "code": "GE",
        "name": "Georgia"
    },
    {
        "code": "GF",
        "name": "Guiana thuộc Pháp"
    },
    {
        "code": "GH",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "name": "Gibraltar"
    },
    {
        "code": "GM",
        "name": "Gambia"
    },
    {
        "code": "GN",
        "name": "Guinea"
    },
    {
        "code": "GR",
        "name": "Hy Lạp"
    },
    {
        "code": "GT",
        "name": "Guatemala"
    },
    {
        "code": "GY",
        "name": "Guyana"
    },
    {
        "code": "HK",
        "name": "Hồng Kông, Trung Quốc)"
    },
    {
        "code": "HN",
        "name": "Honduras"
    },
    {
        "code": "HT",
        "name": "Haiti"
    },
    {
        "code": "HU",
        "name": "Hungary"
    },
    {
        "code": "ID",
        "name": "Indonesia"
    },
    {
        "code": "IE",
        "name": "Ai-len"
    },
    {
        "code": "IL",
        "name": "Người israel"
    },
    {
        "code": "IN",
        "name": "Ấn Độ"
    },
    {
        "code": "IQ",
        "name": "Irac"
    },
    {
        "code": "IR",
        "name": "Iran"
    },
    {
        "code": "IS",
        "name": "Nước Iceland"
    },
    {
        "code": "IT",
        "name": "Nước Ý"
    },
    {
        "code": "JM",
        "name": "Jamaica"
    },
    {
        "code": "JO",
        "name": "Jordan"
    },
    {
        "code": "JP",
        "name": "Nhật Bản"
    },
    {
        "code": "KE",
        "name": "Kenya"
    },
    {
        "code": "KG",
        "name": "Kít-sinh-gơ"
    },
    {
        "code": "KH",
        "name": "Campuchia"
    },
    {
        "code": "KP",
        "name": "Bắc Triều Tiên"
    },
    {
        "code": "KR",
        "name": "Hàn Quốc"
    },
    {
        "code": "KW",
        "name": "Cô-oét"
    },
    {
        "code": "KZ",
        "name": "Kazakhstan"
    },
    {
        "code": "LA",
        "name": "Nước Lào"
    },
    {
        "code": "LB",
        "name": "Lebanon"
    },
    {
        "code": "LI",
        "name": "Liechtenstein"
    },
    {
        "code": "LK",
        "name": "Sri Lanka"
    },
    {
        "code": "LR",
        "name": "Liberia"
    },
    {
        "code": "LS",
        "name": "Lesicia"
    },
    {
        "code": "LT",
        "name": "Litva"
    },
    {
        "code": "LU",
        "name": "Tiếng Séc"
    },
    {
        "code": "LV",
        "name": "Latvia"
    },
    {
        "code": "LY",
        "name": "Libya"
    },
    {
        "code": "MA",
        "name": "Ma-rốc"
    },
    {
        "code": "MC",
        "name": "Monaco"
    },
    {
        "code": "MD",
        "name": "Moldova"
    },
    {
        "code": "MG",
        "name": "Madagascar"
    },
    {
        "code": "ML",
        "name": "Ma-rốc"
    },
    {
        "code": "MM",
        "name": "Myanmar"
    },
    {
        "code": "MN",
        "name": "Mông Cổ"
    },
    {
        "code": "MO",
        "name": "Ma Cao (Trung Quốc)"
    },
    {
        "code": "MT",
        "name": "Malta"
    },
    {
        "code": "MU",
        "name": "Mô-ri-xơ"
    },
    {
        "code": "MV",
        "name": "Maldives"
    },
    {
        "code": "MW",
        "name": "Ma-rốc"
    },
    {
        "code": "MX",
        "name": "Mexico"
    },
    {
        "code": "MY",
        "name": "Malaysia"
    },
    {
        "code": "MZ",
        "name": "Mozambique"
    },
    {
        "code": "NA",
        "name": "Namibia"
    },
    {
        "code": "NE",
        "name": "Nigeria"
    },
    {
        "code": "NG",
        "name": "Nigeria"
    },
    {
        "code": "NI",
        "name": "Nicaragua"
    },
    {
        "code": "NL",
        "name": "nước Hà Lan"
    },
    {
        "code": "NO",
        "name": "Na Uy"
    },
    {
        "code": "NP",
        "name": "Nepal"
    },
    {
        "code": "NR",
        "name": "Nauru"
    },
    {
        "code": "NZ",
        "name": "New Zealand"
    },
    {
        "code": "OM",
        "name": "Ô-man"
    },
    {
        "code": "PA",
        "name": "Panama"
    },
    {
        "code": "PE",
        "name": "Peru"
    },
    {
        "code": "PF",
        "name": "Polynesia thuộc Pháp"
    },
    {
        "code": "PG",
        "name": "Papua New Guinea"
    },
    {
        "code": "PH",
        "name": "Philippines"
    },
    {
        "code": "PK",
        "name": "Pakistan"
    },
    {
        "code": "PL",
        "name": "Ba Lan"
    },
    {
        "code": "PT",
        "name": "Bồ Đào Nha"
    },
    {
        "code": "PY",
        "name": "Paraguay"
    },
    {
        "code": "QA",
        "name": "Qatar"
    },
    {
        "code": "RO",
        "name": "Rumani"
    },
    {
        "code": "RU",
        "name": "Nga"
    },
    {
        "code": "SA",
        "name": "Ả Rập Saudi"
    },
    {
        "code": "SB",
        "name": "Quần đảo Solomon"
    },
    {
        "code": "SC",
        "name": "Seychelles"
    },
    {
        "code": "SD",
        "name": "Sudan"
    },
    {
        "code": "SE",
        "name": "Thụy Điển"
    },
    {
        "code": "SI",
        "name": "Slovenia"
    },
    {
        "code": "SK",
        "name": "Slovakia"
    },
    {
        "code": "SL",
        "name": "Sierra Leone"
    },
    {
        "code": "SM",
        "name": "San Marino"
    },
    {
        "code": "SN",
        "name": "Sê-nê-gan"
    },
    {
        "code": "SO",
        "name": "Somalia"
    },
    {
        "code": "SR",
        "name": "Xuameame"
    },
    {
        "code": "ST",
        "name": "Sao Tome và Principe"
    },
    {
        "code": "SV",
        "name": "El Salvador"
    },
    {
        "code": "SY",
        "name": "Syria"
    },
    {
        "code": "SZ",
        "name": "Swaziland"
    },
    {
        "code": "TD",
        "name": "Chad"
    },
    {
        "code": "TG",
        "name": "Đi"
    },
    {
        "code": "TH",
        "name": "nước Thái Lan"
    },
    {
        "code": "TJ",
        "name": "Tajikistan"
    },
    {
        "code": "TL",
        "name": "Đông Timor"
    },
    {
        "code": "TM",
        "name": "Turkmenistan"
    },
    {
        "code": "TN",
        "name": "Tunisia"
    },
    {
        "code": "TO",
        "name": "Tống"
    },
    {
        "code": "TR",
        "name": "gà tây"
    },
    {
        "code": "TZ",
        "name": "Tanzania"
    },
    {
        "code": "UA",
        "name": "Ukraine"
    },
    {
        "code": "UG",
        "name": "Nhật Bản"
    },
    {
        "code": "UY",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "name": "Uzbekistan"
    },
    {
        "code": "VE",
        "name": "Venezuela"
    },
    {
        "code": "VN",
        "name": "Việt Nam"
    },
    {
        "code": "YE",
        "name": "Yemen"
    },
    {
        "code": "ZA",
        "name": "Nam Phi"
    },
    {
        "code": "ZM",
        "name": "Zambia"
    },
    {
        "code": "ZW",
        "name": "Bêlarut"
    }
]