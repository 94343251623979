import React, { useState, useRef, useLayoutEffect } from 'react';
import { Modal } from 'components/Modal';
import { Input } from 'components/Input';
import { useI18n } from 'context/i18n';



export const PromoCodeModal: React.FC<{
    visable: boolean,
    onCancel: () => void,
    onConfirm: (code: string) => void
}> = ({
    visable,
    onCancel,
    onConfirm
}) => {
    const { t } = useI18n();
    const inputRef = useRef<Input>(null);
    useLayoutEffect(() => {
        if (visable) {
            setTimeout(() => inputRef.current?.focus(), 100);
        }
    }, [visable]);
    const [code, setCode] = useState<string>('');
    const handleOk = () => {
        onConfirm(code.replace(/\s/g, ''));
        setCode('');
        onCancel();
    }
    return (
        <Modal
            visible={visable}
            closable={false}
            width={450}
            style={{top: '300px'}}
            mask={true}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            onOk={handleOk}
            cancelText={t('form.actions.cancel')}
            okText={t('form.actions.confirm')}
            title={t('coupon.promoCode.name')}
        >
            <Input value={code} onChange={e => setCode(e.target.value)} ref={inputRef}/>
        </Modal>
    )
}

