import React, { useState, useRef, useLayoutEffect, useMemo } from 'react';

import styles from './index.module.css';
import { ProductInCart, ProductInCartVO } from '../../models';
import { Counter } from 'components/Counter';
import { Language, useI18n } from 'context/i18n';
import { SUB_TASTE_RADIO } from 'modules/product-menu';
import { Input } from 'components/Input';
import { getPrice } from 'globalUtils';
import { useDiscountName } from 'modules/product-menu';
// 获取自定义口味的文案
function getCustomText(
    productInCart: ProductInCart,
    t: (s: string) => string,
    lang: Language
): string {
    const { customizableTasteSetting, product: { customizableTasteList } } = productInCart;

    // 中文的拼中文逗号
    const joinText = /ch/.test(lang) ? '，' : ',';

    return customizableTasteList.reduce<string[]>((acc, taste) => {
        const selected = customizableTasteSetting[taste.code];
        if (selected === SUB_TASTE_RADIO.NORMAL) return acc;

        const middleText = /ch/.test(lang)  ? '' : ' ';

        return [...acc, `${t(`taste.child.radio.${selected}`)}${middleText}${taste.name}`];

    }, []).join(joinText);
}

interface Props {
    showDiscountPlaceholder: boolean;
    productInCart: ProductInCartVO;
    onCountChange: (productInCart: ProductInCart, count: number) => void;
    onDelete: (productInCart: ProductInCart) => void;
    onItemClick: (productInCart: ProductInCart) => void;
    onChangeClick: (productInCart: ProductInCart) => void;
    onChangeRemark: (productInCart: ProductInCart, remark: string) => void;
}

export const ProductInCartItem: React.FC<Props> = ({
    showDiscountPlaceholder,
    productInCart,
    onCountChange,
    onDelete,
    onItemClick,
    onChangeClick,
    onChangeRemark
}) => {
    const { methods: { getName: getDiscountTitle } } = useDiscountName();
    
    const remarkInputRef = useRef<HTMLTextAreaElement>(null);

    const { t, getLang } = useI18n();
    // 数量加
    const onInc = () => onCountChange(productInCart, productInCart.count + 1);

    // 数量减
    const onDec = () => {
        productInCart.count > 1 && onCountChange(productInCart, productInCart.count - 1);
    }
    
    const { product, count, remark } = productInCart;

    const [ remarkEdit, setRemarkEdit ] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (remarkEdit && remarkInputRef.current) {
            remarkInputRef.current.focus();
        }
    }, [remarkInputRef, remarkEdit])

    const priceTextNode = useMemo(() => {
        const originPrice = getPrice(productInCart.product.price, productInCart.count);
        const hasDiscount = productInCart.discounts.length > 0;
        
        if (hasDiscount) {
            return (
                <>
                    <div aria-label="Price" className={styles.salePrice}>${productInCart.salePrice}</div>
                    <div className={styles.unitPrice}>{originPrice}</div>
                    <div className="clear"></div>
                </>
            )
        } else {
            return <div aria-label="Price" className={styles.salePrice}>{originPrice}</div>  
        }
    }, [productInCart]);

    return (
        <div className={styles.productInCartItem}>
            <div className={styles.productInCartInfo}>
                <div className={styles.left} onClick={() => onItemClick(productInCart)}>
                    <img className={styles.img} src={product.images[0].url} alt=""/>
                </div>
                <div className={styles.middle}>
                    <div className={styles.name}>{product.name}</div>
                    <div className={styles.price}>{priceTextNode}</div>
                    <div className={styles.custom}>{getCustomText(productInCart, t, getLang())}</div>
                </div>
                <div className={styles.right}>
                    <Counter
                        className={styles.counter}
                        count={count}
                        onDec={onDec}
                        onInc={onInc}
                    />
                    <div className={styles.actions}>
                        { product.customizableTasteList.length > 0 && <div className={styles.actionButton} onClick={() => onChangeClick(productInCart)}>{t('foodDetail.customize')}</div> }
                        <div className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => onDelete(productInCart)}>{t('form.actions.delete')}</div>
                    </div>
                    { !remarkEdit && !remark && <div style={{textAlign: 'right', marginTop: '10px'}} className={styles.actionButton} onClick={() => setRemarkEdit(true)}>{t('statements.addRemark')}</div> }
                </div>
            </div>
            {!showDiscountPlaceholder &&
                <div className={styles.discountList}>
                    {productInCart.discounts.map(discount => (
                        <div key={discount.id}>{t('activity.name')}: {discount.activity.name} {discount.value}% OFF ({getDiscountTitle(discount)})</div>
                    ))}
                </div>
            }
            {showDiscountPlaceholder && productInCart.product.discountPrices.length > 0 &&
                <div className={styles.discountList}>
                    {t('activity.placeholder.chooseTimeToView')}
                </div>
            }

            { (remark || remarkEdit) &&
                <div style={{paddingTop: '10px'}}>
                    <div style={{ float: 'left', width: '60px', paddingTop: '4px' }}>{t('statements.remark')}</div>
                    <Input.TextArea
                        placeholder={t('statements.placeholder.remark')}
                        style={{ float: 'left', width: 'calc(100% - 60px)' }}
                        rows={3}
                        ref={remarkInputRef as any}
                        onBlur={() => setRemarkEdit(false)}
                        onFocus={() => setRemarkEdit(true)}
                        value={remark}
                        onChange={e => onChangeRemark(productInCart, e.target.value)}
                    />
                    <div className="clear"></div>
                </div>
            }
        </div>
    )
}