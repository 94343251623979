import { getFirebaseAppAnalytics } from './get-firebase-app';
export const createOrderAnalytics = (orderId: string, items: Array<{ code: string, buyNum: number }>) => {
    getFirebaseAppAnalytics()?.logEvent('begin_checkout', {
        transaction_id: orderId,
        items: items.map(({ code, buyNum }) => ({ id: code, quantity: buyNum }))
    })
}


export const purchaseAnalytics = (orderId: string) => {
    getFirebaseAppAnalytics()?.logEvent('purchase', {
        transaction_id: orderId,
    })
}