import { useCallback } from 'react';
import { ProductInCart } from '../models';
import { useDispatch } from 'react-redux';
import { actions } from '../store';
import { useI18n } from 'context/i18n';
import { useProductToCart } from 'modules/product-menu';



export const useProductInCartAction = () => {
    const { lang } = useI18n();

    const { showProductDetail, editProductDetail } = useProductToCart();

    const dispatch = useDispatch();


    const deleteProduct = useCallback(({ productCode, id: cartId }: ProductInCart) => {
        dispatch(actions.deleteProductInCart({
            productCode,
            cartId,
        }));
    }, [dispatch]);

    const changeCount = useCallback(({ id: cartId, customizableTasteSetting }: ProductInCart, count: number) => {
        dispatch(actions.updateProductInCart({
            cartId,
            count,
            customizableTasteSetting
        }));
    }, [dispatch]);

    const onProductClick = useCallback(({ product }: ProductInCart) => {
        showProductDetail(product);
    }, [showProductDetail]);

    const onChangeClick = useCallback(({ product, id, count, customizableTasteSetting }: ProductInCart) => {
        editProductDetail({
            code: product.code,
            id,
            count,
            customizableTasteSetting
        })
    }, [editProductDetail]);



    const changeRemark = (productInCart: ProductInCart, remark: string) => {
        dispatch(actions.updateProductInCart({
            cartId: productInCart.id,
            count: productInCart.count,
            customizableTasteSetting: productInCart.customizableTasteSetting,
            remark,
        }));
    }
    return {
        methods: {
            deleteProduct,
            changeCount,
            onProductClick,
            onChangeClick,
            changeRemark,
        }
    };
}