import { CountryCodeAndName } from './types';
export const list: CountryCodeAndName[] = [
    {
        "code": "US",
        "name": "美国"
    },
    {
        "code": "CN",
        "name": "中国"
    },
    {
        "code": "SG",
        "name": "新加坡"
    },
    {
        "code": "TW",
        "name": "台湾（中国）"
    },
    {
        "code": "AD",
        "name": "安道尔"
    },
    {
        "code": "AE",
        "name": "阿拉伯联合酋长国"
    },
    {
        "code": "AF",
        "name": "阿富汗"
    },
    {
        "code": "AL",
        "name": "阿尔巴尼亚"
    },
    {
        "code": "AM",
        "name": "亚美尼亚"
    },
    {
        "code": "AO",
        "name": "安哥拉"
    },
    {
        "code": "AR",
        "name": "阿根廷"
    },
    {
        "code": "AT",
        "name": "奥地利"
    },
    {
        "code": "AU",
        "name": "澳大利亚"
    },
    {
        "code": "AZ",
        "name": "阿塞拜疆"
    },
    {
        "code": "BD",
        "name": "孟加拉国"
    },
    {
        "code": "BE",
        "name": "比利时"
    },
    {
        "code": "BF",
        "name": "布基纳法索"
    },
    {
        "code": "BG",
        "name": "保加利亚"
    },
    {
        "code": "BH",
        "name": "巴林"
    },
    {
        "code": "BI",
        "name": "布隆迪"
    },
    {
        "code": "BJ",
        "name": "贝宁"
    },
    {
        "code": "BN",
        "name": "文莱"
    },
    {
        "code": "BO",
        "name": "玻利维亚"
    },
    {
        "code": "BR",
        "name": "巴西"
    },
    {
        "code": "BS",
        "name": "巴哈马"
    },
    {
        "code": "BW",
        "name": "博茨瓦纳"
    },
    {
        "code": "BY",
        "name": "白俄罗斯"
    },
    {
        "code": "BZ",
        "name": "伯利兹"
    },
    {
        "code": "CA",
        "name": "加拿大"
    },
    {
        "code": "CF",
        "name": "中非共和国"
    },
    {
        "code": "CG",
        "name": "刚果（布）"
    },
    {
        "code": "CH",
        "name": "瑞士"
    },
    {
        "code": "CK",
        "name": "库克群岛"
    },
    {
        "code": "CL",
        "name": "智利"
    },
    {
        "code": "CM",
        "name": "喀麦隆"
    },
    {
        "code": "CO",
        "name": "哥伦比亚"
    },
    {
        "code": "CR",
        "name": "哥斯达黎加"
    },
    {
        "code": "CU",
        "name": "古巴"
    },
    {
        "code": "CY",
        "name": "塞浦路斯"
    },
    {
        "code": "CZ",
        "name": "捷克共和国"
    },
    {
        "code": "DE",
        "name": "德国"
    },
    {
        "code": "DJ",
        "name": "吉布提"
    },
    {
        "code": "DK",
        "name": "丹麦"
    },
    {
        "code": "DZ",
        "name": "阿尔及利亚"
    },
    {
        "code": "EC",
        "name": "厄瓜多尔"
    },
    {
        "code": "EE",
        "name": "爱沙尼亚"
    },
    {
        "code": "EG",
        "name": "埃及"
    },
    {
        "code": "ES",
        "name": "西班牙"
    },
    {
        "code": "ET",
        "name": "埃塞俄比亚"
    },
    {
        "code": "FI",
        "name": "芬兰"
    },
    {
        "code": "FJ",
        "name": "斐济"
    },
    {
        "code": "FR",
        "name": "法国"
    },
    {
        "code": "GA",
        "name": "加蓬"
    },
    {
        "code": "GB",
        "name": "英国"
    },
    {
        "code": "GE",
        "name": "格鲁吉亚"
    },
    {
        "code": "GF",
        "name": "法属圭亚那"
    },
    {
        "code": "GH",
        "name": "加纳"
    },
    {
        "code": "GI",
        "name": "直布罗陀"
    },
    {
        "code": "GM",
        "name": "冈比亚"
    },
    {
        "code": "GN",
        "name": "几内亚"
    },
    {
        "code": "GR",
        "name": "希腊"
    },
    {
        "code": "GT",
        "name": "危地马拉"
    },
    {
        "code": "GY",
        "name": "圭亚那"
    },
    {
        "code": "HK",
        "name": "香港（中国）"
    },
    {
        "code": "HN",
        "name": "洪都拉斯"
    },
    {
        "code": "HT",
        "name": "海地"
    },
    {
        "code": "HU",
        "name": "匈牙利"
    },
    {
        "code": "ID",
        "name": "印度尼西亚"
    },
    {
        "code": "IE",
        "name": "爱尔兰"
    },
    {
        "code": "IL",
        "name": "以色列"
    },
    {
        "code": "IN",
        "name": "印度"
    },
    {
        "code": "IQ",
        "name": "伊拉克"
    },
    {
        "code": "IR",
        "name": "伊朗"
    },
    {
        "code": "IS",
        "name": "冰岛"
    },
    {
        "code": "IT",
        "name": "意大利"
    },
    {
        "code": "JM",
        "name": "牙买加"
    },
    {
        "code": "JO",
        "name": "约旦"
    },
    {
        "code": "JP",
        "name": "日本"
    },
    {
        "code": "KE",
        "name": "肯尼亚"
    },
    {
        "code": "KG",
        "name": "吉尔吉斯斯坦"
    },
    {
        "code": "KH",
        "name": "柬埔寨"
    },
    {
        "code": "KP",
        "name": "朝鲜"
    },
    {
        "code": "KR",
        "name": "韩国"
    },
    {
        "code": "KW",
        "name": "科威特"
    },
    {
        "code": "KZ",
        "name": "哈萨克斯坦"
    },
    {
        "code": "LA",
        "name": "老挝"
    },
    {
        "code": "LB",
        "name": "黎巴嫩"
    },
    {
        "code": "LI",
        "name": "列支敦士登"
    },
    {
        "code": "LK",
        "name": "斯里兰卡"
    },
    {
        "code": "LR",
        "name": "利比里亚"
    },
    {
        "code": "LS",
        "name": "莱索托"
    },
    {
        "code": "LT",
        "name": "立陶宛"
    },
    {
        "code": "LU",
        "name": "卢森堡"
    },
    {
        "code": "LV",
        "name": "拉脱维亚"
    },
    {
        "code": "LY",
        "name": "利比亚"
    },
    {
        "code": "MA",
        "name": "摩洛哥"
    },
    {
        "code": "MC",
        "name": "摩纳哥"
    },
    {
        "code": "MD",
        "name": "摩尔多瓦"
    },
    {
        "code": "MG",
        "name": "马达加斯加"
    },
    {
        "code": "ML",
        "name": "马里"
    },
    {
        "code": "MM",
        "name": "缅甸"
    },
    {
        "code": "MN",
        "name": "蒙古"
    },
    {
        "code": "MO",
        "name": "澳门（中国）"
    },
    {
        "code": "MT",
        "name": "马耳他"
    },
    {
        "code": "MU",
        "name": "毛里求斯"
    },
    {
        "code": "MV",
        "name": "马尔代夫"
    },
    {
        "code": "MW",
        "name": "马拉维"
    },
    {
        "code": "MX",
        "name": "墨西哥"
    },
    {
        "code": "MY",
        "name": "马来西亚"
    },
    {
        "code": "MZ",
        "name": "莫桑比克"
    },
    {
        "code": "NA",
        "name": "纳米比亚"
    },
    {
        "code": "NE",
        "name": "尼日尔"
    },
    {
        "code": "NG",
        "name": "尼日利亚"
    },
    {
        "code": "NI",
        "name": "尼加拉瓜"
    },
    {
        "code": "NL",
        "name": "荷兰"
    },
    {
        "code": "NO",
        "name": "挪威"
    },
    {
        "code": "NP",
        "name": "尼泊尔"
    },
    {
        "code": "NR",
        "name": "瑙鲁"
    },
    {
        "code": "NZ",
        "name": "新西兰"
    },
    {
        "code": "OM",
        "name": "阿曼"
    },
    {
        "code": "PA",
        "name": "巴拿马"
    },
    {
        "code": "PE",
        "name": "秘鲁"
    },
    {
        "code": "PF",
        "name": "法属波利尼西亚"
    },
    {
        "code": "PG",
        "name": "巴布亚新几内亚"
    },
    {
        "code": "PH",
        "name": "菲律宾"
    },
    {
        "code": "PK",
        "name": "巴基斯坦"
    },
    {
        "code": "PL",
        "name": "波兰"
    },
    {
        "code": "PT",
        "name": "葡萄牙"
    },
    {
        "code": "PY",
        "name": "巴拉圭"
    },
    {
        "code": "QA",
        "name": "卡塔尔"
    },
    {
        "code": "RO",
        "name": "罗马尼亚"
    },
    {
        "code": "RU",
        "name": "俄罗斯"
    },
    {
        "code": "SA",
        "name": "沙特阿拉伯"
    },
    {
        "code": "SB",
        "name": "所罗门群岛"
    },
    {
        "code": "SC",
        "name": "塞舌尔"
    },
    {
        "code": "SD",
        "name": "苏丹"
    },
    {
        "code": "SE",
        "name": "瑞典"
    },
    {
        "code": "SI",
        "name": "斯洛文尼亚"
    },
    {
        "code": "SK",
        "name": "斯洛伐克"
    },
    {
        "code": "SL",
        "name": "塞拉利昂"
    },
    {
        "code": "SM",
        "name": "圣马力诺"
    },
    {
        "code": "SN",
        "name": "塞内加尔"
    },
    {
        "code": "SO",
        "name": "索马里"
    },
    {
        "code": "SR",
        "name": "苏里南"
    },
    {
        "code": "ST",
        "name": "圣多美和普林西比"
    },
    {
        "code": "SV",
        "name": "萨尔瓦多"
    },
    {
        "code": "SY",
        "name": "叙利亚"
    },
    {
        "code": "SZ",
        "name": "斯威士兰"
    },
    {
        "code": "TD",
        "name": "乍得"
    },
    {
        "code": "TG",
        "name": "多哥"
    },
    {
        "code": "TH",
        "name": "泰国"
    },
    {
        "code": "TJ",
        "name": "塔吉克斯坦"
    },
    {
        "code": "TL",
        "name": "东帝汶"
    },
    {
        "code": "TM",
        "name": "土库曼斯坦"
    },
    {
        "code": "TN",
        "name": "突尼斯"
    },
    {
        "code": "TO",
        "name": "汤加"
    },
    {
        "code": "TR",
        "name": "土耳其"
    },
    {
        "code": "TZ",
        "name": "坦桑尼亚"
    },
    {
        "code": "UA",
        "name": "乌克兰"
    },
    {
        "code": "UG",
        "name": "乌干达"
    },
    {
        "code": "UY",
        "name": "乌拉圭"
    },
    {
        "code": "UZ",
        "name": "乌兹别克斯坦"
    },
    {
        "code": "VE",
        "name": "委内瑞拉"
    },
    {
        "code": "VN",
        "name": "越南"
    },
    {
        "code": "YE",
        "name": "也门"
    },
    {
        "code": "ZA",
        "name": "南非"
    },
    {
        "code": "ZM",
        "name": "赞比亚"
    },
    {
        "code": "ZW",
        "name": "津巴布韦"
    }
]