import { Product, CustomizableTasteSetting, Taste, SUB_TASTE_RADIO } from 'modules/product-menu';
import { DELIVERY_TYPE } from 'modules/order-info';
import { DiscountReference, ActivityReference } from 'modules/product-menu';
import { ActivityProductVO } from 'modules/product-menu/models';
import { Store } from 'modules/store/models';

/**************************
 * 商品在购物车内的信息
 **************************/
// 存储在store中的
export interface ProductInCartStore {
    productCode: Product['code'];
    id: string;
    count: number;
    customizableTasteSetting: CustomizableTasteSetting;
    remark?: string,
}

/**************************
 * 活动商品在购物车内的信息
 **************************/
// 存储在store中的
export interface ActivityProductInCartStore {
    activityProductId: string;
    id: string;
    count: number;
    customizableTasteSetting: CustomizableTasteSetting;
    remark?: string;
}

// 用于组件的
export type ActivityProductInCart = ActivityProductInCartStore & {
    activityProduct: ActivityProductVO
    error: string | undefined;
};



/**************************
 * 订单
 **************************/
export interface ProductInOrder {
    code: Product['code'];
    buyNum: number;
    taste: Array<{
        code: Taste['code'];
        name: Taste['name'];
        selected: SUB_TASTE_RADIO
    }>;
    product: Omit<Product, 'customizableTasteList'>;
    remark: string;
    salePrice: string;
    discounts: Array<DiscountReference & { activity: ActivityReference }>
}

export interface ActivityProductInOrder {
    activityProduct: ActivityProductVO;
    buyNum: number;
    taste: Array<{
        code: Taste['code'];
        name: Taste['name'];
        selected: SUB_TASTE_RADIO
    }>;
    remark: string;
    salePrice: string; // 乘过购买数量的价格
}


// 订单支付状态
export enum ORDER_PAY_STATUS {
    // 待支付
    PENDING = '10',
    // 已支付
    COMPLETED = '11',
    //支付中
    PAYING = '12'

}

// 订单准备状态
export enum ORDER_PREPARE_STATUS {
    // 准备中
    PENDING = '20',
    // 准备完成
    COMPLETED = '21',
}

// 订单整体状态
export enum ORDER_STATUS {

    PENDING_CONFIRM = '5',
    // 完成
    COMPLETED = '30',
    // 失效
    INVALID = '98',
}
// 支付方式
export enum ORDER_PAY_METHOD {
    NULL = 0,
    ONLINE = 1,
    OFFLINE = 2
}


// 订单状态
export interface OrderStatus {
    status: string;
    // 创建时间
    createTime: number;
    // 支付时间
    payTime?: number;
    // 完成时间
    completeTime?: number;
    // 失效时间
    cancelTime?: number;

    // 交易关闭时间
    payClosedTime?: number;
    // 准备完成时间
    prepareCompleteTime?: number;
}

// 订单接受人
export interface OrderReceiver {
    receiverAddress: string;
    receiverMobile: string;
    receiverName: string;
    coordinateDesc: string;
}

// 订单钱
export interface OrderAmount {
    discountAmount: string;
    totalAmount: string; // 小计
    tip: number;
    taxes: string;
    courierAmount: string; // 邮费
    payAmount: string; // 总计
}

export type Order = {
    id: string;
    storeId: string;
    code: string;
    eatType: DELIVERY_TYPE;
    mealTime: string | '0';
    productList: ProductInOrder[];
    activityProductList: ActivityProductInOrder[];
    storeName: string;
    store: Store;
    remark: string;
    payMethod: ORDER_PAY_METHOD;
    takeCode?: string;
} & OrderStatus & OrderReceiver & OrderAmount;


// 用于组件的
export type ProductInCart = ProductInCartStore & {
    product: Product
};
export type ProductInCartVO = ProductInCart & {
    salePrice: string;
    discounts: Array<DiscountReference & { activity: ActivityReference }>
}

export type TasteReference = Omit<Taste, 'child'> & { child: string };

export type ProductReference = Omit<Product, 'customizableTasteList'> & {
    customizableTasteList: Pick<TasteReference, 'code' | 'child'>[]
}


export type OrderReference = Omit<Order, 'store' | 'productList' | 'mealTime' | 'activityProductList'> & {
    mealTime: string;
    productList: Array<{
        code: Product['code'];
        buyNum: number;
        taste: string;  // JSON string  -> { code, selected }
        remark: string;
        salePrice: string;
        discountIds: string;
        activityProductId?: string;
    }>
}



export interface OrderActivityProduct {
    activityProductId: string;
    buyNum: number;
    taste: Array<{
        code: Taste['code'];
        selected: SUB_TASTE_RADIO;
    }>;
}


export enum COUPON_TYPE {
    PROMO_CODE= 0, // 优惠码
    DISCOUNT_COUPON = 1 // 优惠券
}

export enum IS_COUPON_OPTIMAL {
    IS = 1,
    NO = 0,
}

export interface Card{
    id:string;
    billing_details:{
        address:{
            postal_code:string
        },
        email:string;
        name:string;
        phone:string
    },
    card:{
        brand:string;
        country:string;
        funding:string;
        last4:string;
        exp_month:string;
        exp_year:string
    }
}
