import React, { CSSProperties } from 'react';
import styles from './index.module.css';
import { ThemeDiv } from 'modules/store';

interface Props {
    count: number;
    onInc: () => void;
    onDec: () => void;
    style?: CSSProperties;
    className?: string;
}

export const Counter: React.FC<Props> = ({
    count,
    onInc,
    onDec,
    style = {},
    className = ''
}) => {
    return <div className={`${styles.counter} ${className}`} style={style}>
        <ThemeDiv className={styles.button} type="primary" role="button" aria-label="Decrement" onClick={onDec}>-</ThemeDiv>
        <div className={styles.count} aria-label="Count">{count}</div>
        <ThemeDiv className={styles.button} type="primary" role="button" aria-label="Increment" onClick={onInc}>+</ThemeDiv>
        <div className="clear"></div>
    </div>
}

export { useCounter } from './useCounter';