import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { ThunkAction } from 'redux-thunk'
import { getActivityProductFetchingOfActivityCodeAndLanguage } from '../selectors';
import { getActivityDetailOfCode, getActivityProductOfId } from '../../api/product';
import { Language } from 'context/i18n';

import { fetchShowInCategoryActivityDetailSuccess } from './activity';
import { AnyAction } from 'redux';
import { KillActivityVO, ActivityReference } from '../../models';
import { ActivityProductVO } from 'modules/product-menu/models';
type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;


export const updateActivityProductEntities = action(
    getActionType('Update Activity Product Entities'),
    payload<{
        storeId: string;
        activityProducts: ActivityProductVO[]
    }>()
)

export const fetchActivityDetailRequest = action(
    getActionType('Fetch Activity Detail Of Code'),
    payload<{
        storeId: string,
        language: Language,
        activityCode: string,
    }>()
)

export const fetchActivityDetailSuccess = action(
    getActionType('Fetch Activity Detail Of Code Success'),
    payload<{
        storeId: string,
        language: Language,
        activityCode: string,
        list: ActivityProductVO[]
    }>()
);
export const fetchActivityProductOfIdSuccess = action(
    getActionType('Fetch Activity Product Of Id Success'),
    payload<{
        storeId: string,
        language: Language,
        activityCode: string,
        activityProduct: ActivityProductVO
    }>()
)

// export const fetchActivityDetailFailure = action(
//     getActionType('Fetch Activity Detail Of Id Fail'),
//     payload<string>()
// );


export function fetchActivityDetail(storeId: string, language: Language, activityCode: string): ThunkResult<Promise<KillActivityVO>> {
    return async (dispatch, getState) => {
        
        // const fetching = getActivityProductFetchingOfActivityCodeAndLanguage(getState(), { activityCode, language });
        // if (fetching) return;
        dispatch(fetchActivityDetailRequest({ storeId, language, activityCode }));
        // dispatch(fetchActivityDetailRequest(code));


        return getActivityDetailOfCode(storeId, language, activityCode)
            .then(killActivityVO => {

                dispatch(fetchActivityDetailSuccess({
                    storeId,
                    language,
                    activityCode,
                    list: killActivityVO.activityProducts,
                }));
                
                return killActivityVO;
            })
            .catch(err => {
                // dispatch(fetchActivityDetailFailure(code));
                return Promise.reject(err)
            });
    }
}





export function fetchActivityProductOfId(storeId: string, language: Language, activityCode: string, activityProductId: string): ThunkResult<Promise<ActivityReference>> {
    return async (dispatch, getState) => {

        return getActivityProductOfId(storeId!, language, activityCode, activityProductId)
            .then(({ activityProductVO, activityReference }) => {
                dispatch(fetchActivityProductOfIdSuccess({
                    storeId,
                    language,
                    activityCode,
                    activityProduct: activityProductVO
                }))
                return activityReference;
            })
            .catch(err => {
                return Promise.reject(err)
            });
    }
}




// export const setListScrollTop = action(
//     getActionType('Set List Scroll Top'),
//     payload<number>(),
// );