import cryptoJs from 'crypto-js';

const chars = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
    'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
];

function stringToHex(str: string) {
    let val = '';
    for (let i = 0; i < str.length; i = i + 1) {
        const charNum = str.charCodeAt(i).toString(16);
        val += charNum.length < 2 ? `0${charNum}` : charNum;
    }
    return val;
}

/*产生随机数用的，用于密码加密，n表示需要生成多少位的随机数*/
export function generateKey(n: number) {
    let res = '';
    for (let i = 0; i < n; i = i + 1) {
        const id = Math.ceil(Math.random() * 35);
        res += chars[id];
    }
    return res;
}

/*加密*/
export function encrypt(data: string, key: string, ivKey: string) {
    const md5Key = cryptoJs.MD5(cryptoJs.MD5(key).toString()).toString();
    const hexkey = cryptoJs.enc.Hex.parse(md5Key);
    const iv = cryptoJs.enc.Utf8.parse(ivKey);
    const str = cryptoJs.AES.encrypt(data, hexkey, {
        iv,
        mode: cryptoJs.mode.CBC,
        padding: cryptoJs.pad.Pkcs7,
    });
    return stringToHex(atob(str.toString()));
}

const IV_KEY = 'a0fe7c7c98e09e8c';
// 加密密码
export const encryptPassword = (pwd: string): {
    password: string,
    nonce: string,
} => {
    const nonce = generateKey(16);
    const password = encrypt(pwd, nonce, IV_KEY);
    return {
        password,
        nonce,
    };
};

export function decrypt(word:string,key:string,ivKey:string) {
    const encryptedHexStr = cryptoJs.enc.Hex.parse(word);
    const srcs = cryptoJs.enc.Base64.stringify(encryptedHexStr);
    const md5Key = cryptoJs.MD5(cryptoJs.MD5(key).toString()).toString();
    const hexkey = cryptoJs.enc.Hex.parse(md5Key);
    const iv = cryptoJs.enc.Utf8.parse(ivKey);
    let decrypt = cryptoJs.AES.decrypt(srcs, hexkey, { iv: iv, mode: cryptoJs.mode.CBC, padding: cryptoJs.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(cryptoJs.enc.Utf8);
    return decryptedStr;
}
