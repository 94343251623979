import React, { useMemo } from 'react';
import styles from './index.module.css';
import editIcon from 'assets/edit.png';
import { ThemeDiv } from 'modules/store';
interface Props {
    className?: string;
    label: string;
    content: string;
    buttonLabel: string;
    onButtonClick: () => void;
    onEditClick: () => void;
}

export const OrderInfoItem: React.FC<Props> = ({
    className = '',
    label,
    content,
    buttonLabel,
    onButtonClick,
    onEditClick,
}) => {

    const hasContent = useMemo(() => {
        return !!content;
    }, [content]);

    return (
        <div className={className}>
            <div className={styles.label}>
                {label}
                {hasContent && <img className={styles.editIcon} onClick={onEditClick} src={editIcon} alt=""/> }
            </div>

            {hasContent && <div className={styles.content}>{content}</div>}

            {!hasContent && <div className={styles.button} onClick={onButtonClick}>{buttonLabel}</div>}

        </div>
    )
}