import React, { useState } from 'react';
import styles from './index.module.css';
import { ReceiverInfoItem } from '../../components/ReceiverInfoItem';
import { useReceiverInfoList } from '../../facades/useReceiverInfoList';
import { ReceiverInfo } from '../../models';
import { message } from 'components/Message';
import { useOrderInfo } from 'modules/order-info';
import { useHistory } from 'react-router-dom';
import { AddressEmpty } from '../../components/AddressEmpty';
import { Spin } from 'components/Spin';
import { Header } from '../../components/Header';
import { ActionButton } from 'components/ActionButton';
import { useI18n } from 'context/i18n';

export const AddressList: React.FC = () => {
    const { t } = useI18n();
    const { push } = useHistory();
    const { receiver } = useOrderInfo();

    const { loading, list, empty, methods } = useReceiverInfoList();

    const [ selectedId, setSelectedId ] = useState<string>();


    const [ manageState, setManageState ] = useState<boolean>(false)


    const handleManageButtonClick = () => {
        setSelectedId(undefined);
        setManageState(true);
    }

    const handleAddButtonClick = () => {
        push('/me/address/create');
    }

    const handleDeleteButtonClick = () => {
        if (!selectedId) return;
        methods.deleteReceiverInfo(selectedId)
            .then(() => {
                setSelectedId(undefined);
            })
            .catch(({ data }) => {
                data.msg && message.error(t(data.msg));
            })
    }

    const handleSetDefaultButtonClick = () => {
        if (!selectedId) return;
        methods.setDefaultStatus(selectedId)
            .catch(() => handleCancelButtonClick());
    }

    const handleCancelButtonClick = () => {
        setSelectedId(undefined);
        setManageState(false);
    }

    const handleSelect = (id: string) => {
        if (!manageState) return;
        if (receiver?.id === id) {
            message.error(t('address.messages.cannotChangeWhenUsing'));
        } else {
            setSelectedId(id);
        }
    }

    const handleEdit = (id: string) => {
        if (receiver?.id === id) {
            message.error(t('address.messages.cannotChangeWhenUsing'));
        } else {
            push(`/me/address/${id}`);
        }
    }

    return (
        <div className={styles.addressManagement}>
            <Spin spinning={loading}>
                <Header title={t('address.addressManagement')}>
                    { !manageState &&
                        <>
                            <ActionButton onClick={handleAddButtonClick}>{t('address.add')}</ActionButton>
                    <ActionButton onClick={handleManageButtonClick}>{t('address.management')}</ActionButton>
                        </>
                    }
                    { manageState &&
                        <>
                            <ActionButton onClick={handleSetDefaultButtonClick}>{t('address.setAsDefault')}</ActionButton>
                    <ActionButton onClick={handleDeleteButtonClick}>{t('form.actions.delete')}</ActionButton>
                    <ActionButton onClick={handleCancelButtonClick}>{t('form.actions.cancel')}</ActionButton>
                        </>
                    }
                </Header>

                <div className={styles.listWrapper}>
                    {!loading && !empty &&
                        list.map(item => (
                            <ReceiverInfoItem
                                className={styles.receiverInfoItem}
                                key={item.id}
                                receiverInfo={item}
                                selectedId={selectedId}
                                onClick={() => handleSelect(item.id)}
                                onEditClick={({ id }) => handleEdit(id)}
                            />
                        ))
                    }
                    {!loading && empty &&
                        <AddressEmpty onAddClick={handleAddButtonClick}/>
                    }



                    
                </div>
            </Spin>
        </div>
    );
}

export default AddressList;