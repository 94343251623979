import React, { useRef, useLayoutEffect } from 'react';
import styles from './index.module.css';
import { PickUpReceiverInfo } from 'modules/me';
import { useForm, Controller } from 'react-hook-form';
import { useI18n } from 'context/i18n';
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { message } from 'components/Message';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { CommonButton } from 'components/CommonButton';
import { useTheme } from 'modules/store';

interface Props {
    initial?: PickUpReceiverInfo;
    onNext: (info: PickUpReceiverInfo) => void
}
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
export const RapidPickUpReceiver: React.FC<Props> = ({
    initial = {
        receiverMobile: '',
        receiverName: ''
    },
    onNext,
}) => {
    const { theme: { primary } } = useTheme();
    const ref = useRef<Input>(null);
    const { t } = useI18n();

    const { control, handleSubmit:submitForm, errors, triggerValidation, setValue} = useForm<PickUpReceiverInfo>({
        mode: 'onBlur',
        defaultValues: initial,
    });

    const handleNext = () => {
        triggerValidation().then(noError => {
            if (noError) {
                submitForm(data => {
                    onNext(data);
                })();
            } else {
                message.error(t('form.rules.makeSure'));
            }
        })
    }
    useLayoutEffect(() => {
        ref.current?.focus();
    }, [ref.current]);

    return (
        <Layout header={
            <Header title={t('receiverInfo.contactInfo')}/>
        }>
            <div className={styles.formItem}>
                <Form.Item {...formItemLayout} label={t('receiverInfo.contactName')}>
                    <Controller
                        as={<Input ref={ref}/>}
                        name={'receiverName'}
                        control={control}
                    />
                </Form.Item>
            </div>

            <div className={styles.formItem}>
                <Form.Item {...formItemLayout} label={t('receiverInfo.contactPhone')} style={{border:errors?.receiverMobile?.message?"1px solid #b20202":"none"}}>
                    <Controller
                        as={<Input />}
                        name={'receiverMobile'}
                        control={control}
                        rules={{ required: t('form.rules.required') }}
                    />
                </Form.Item>
            </div>
            <CommonButton
                className={`${styles.nextButton}`}
                style={{ background: primary.bg, color: primary.color }}
                onClick={handleNext}
                height={'54px'}
            >{t('form.actions.confirmAndNext')}</CommonButton>
        </Layout>
    )
}