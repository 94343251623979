import React from 'react';

import styles from './index.module.css';

import { CommonButton } from 'components/CommonButton';
import { useI18n } from 'context/i18n';
import noAddress from '../../../me/images/no_address.png';
import { useTheme } from 'modules/store';

interface Props {
    onAddClick: () => void;
}

export const AddressEmpty: React.FC<Props> = ({
    onAddClick
}) => {
    const { theme: { primary } } = useTheme();
    const { t } = useI18n();
    return (
        <div className={styles.empty}>
            <img className={styles.emptyImage} src={noAddress} alt=""/>
            <div className={styles.emptyDescription}>
                {t('address.noAddress')}
            </div>
            <CommonButton
                className={styles.addButton}
                style={{ background: primary.bg, color: primary.color }}
                onClick={onAddClick}
                height={'54px'}
            >{t('address.add')}</CommonButton>
        </div>
    )
}