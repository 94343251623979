import React, { useState, useCallback, useMemo } from 'react';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { useI18n } from 'context/i18n';


enum SIGN_STATUS {
    SIGN_IN = 'sign_in', // 登录
    SIGN_UP = 'sign_up',  // 注册
}
export interface Props {
    loginSuccess: () => void
}

export const NotAuthorized: React.FC<Props> = ({
    loginSuccess
}) => {
    const { t } = useI18n();
    const [ signStatus, setSignStatus ] = useState<SIGN_STATUS>(SIGN_STATUS.SIGN_IN);

    const gotoSignIn = useCallback(() => {
        setSignStatus(SIGN_STATUS.SIGN_IN);
    }, []);

    const gotoSignUp = useCallback(() => {
        setSignStatus(SIGN_STATUS.SIGN_UP);
    }, []);


    const footerButtonName = useMemo(() => {
        const name = signStatus === SIGN_STATUS.SIGN_IN
            ? t('auth.gotoSignUp')
            : t('auth.gotoSignIn');
        return `${name} >`;
    }, [signStatus, t])

    const changeStatus = useCallback(() => {
        signStatus === SIGN_STATUS.SIGN_IN
            ? gotoSignUp()
            : gotoSignIn();
    }, [signStatus])
    return (
        <div style={{paddingTop: '20px'}}>
            {signStatus === SIGN_STATUS.SIGN_IN &&
                <SignIn onSuccess={loginSuccess}/>
            }
            {signStatus === SIGN_STATUS.SIGN_UP &&
                <SignUp onSuccess={gotoSignIn}/>
            }
            <div style={{textAlign: 'right', marginTop: '10px'}} onClick={changeStatus}>
                {footerButtonName}
            </div>
        </div>
    );
}