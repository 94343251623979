import React, { useState, useCallback, useEffect, CSSProperties } from 'react';
import styles from './index.module.css';
import { useI18n, Language } from 'context/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { useLogout } from 'modules/auth';
interface Props {
    className?: string,
    style?: CSSProperties,
}

export const Person: React.FC<Props> = ({
    className = '',
    style = {},
}) => {

    const { push } = useHistory();
    const { pathname } = useLocation();
    const { methods: { logout } } = useLogout();
    const [ showMenu, setShowMenu ] = useState<boolean>(false);
    const { t } = useI18n();
    useEffect(() => {
        setShowMenu(false);
    }, [pathname]);
    const handlePush = useCallback((path: string) => {
        const targetPath = `/me/${path}`;
        if (targetPath !== pathname) {
            push(targetPath);
        }
    }, [push, pathname]);
    const onClick = useCallback(() => {
        if (!/\/me/.test(pathname)) {
            push('/me');
        }
    }, [pathname]);


    return (
        <div
            className={`${className} ${styles.person}`}
            style={style}
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
        >
            <div
                style={{height: '100%'}} 
                onClick={onClick}
            >{t('me.name')}</div>
            {showMenu &&
                <div className={styles.personMenu}>
                    <div className={styles.personMenuItem} onClick={() => handlePush('order')}>{t('history.historyOrders')}</div>
                    <div className={styles.personMenuItem} onClick={() => handlePush('address')}>{t('address.addressManagement')}</div>
                    <div className={styles.personMenuItem} onClick={logout}>{t('auth.logOut')}</div>
                </div>
            }
        </div>
    )
}