import { User } from '../models';

import { ajaxGet, ajaxPost, SuccessResponse } from 'api';

export const getCaptcha = () => {
    return ajaxGet<any>('/captcha/create').then(({ data }) => data);
}

interface SendAuthCodeParams {
    mobile: string;
    captcha_hash: string;
    captcha_code: string;
}

export const sendAuthCode = (params: SendAuthCodeParams) => {
    return ajaxPost<any>('/api/v1/registers/send_auth_code', { app_name: 'KitBot', ...params });

}

interface ValidateAuthCodeParams {
    mobile: string;
    auth_code: string;
}

export const validateAuthCode = (params: ValidateAuthCodeParams) => {
    return ajaxPost<any>('/api/v1/registers/validate_auth_code', params);
}



export const checkPasswordStrength = ( password: string ) => {
    return ajaxPost<any>('/api/v1/password/strength', { password });
}


export interface RegisterProps {
    mobile: string;
    password: string;
    confirm_password: string;
    auth_code: string;
}

export const register = (params: RegisterProps) => {
    return ajaxPost<any>('/repast/api/v2/registers', params);
}

export const getCurrentUser = () => {
    return ajaxGet<User>('/repast/api/v2/users/current');
}

interface LoginParams {
    login_name: string;
    password: string;
    nonce: string;
}

export const login = (params: LoginParams) => {
    const p = {
        ...params,
        client_id: 0,
        grant_type: 'password'
    }
    return ajaxPost<{ access_token: string }>('/repast/api/v2/login', p);
}


export const touristLogin = () => {
    return ajaxPost<SuccessResponse<{
        userName: string;
        token: string;
    }>>('/repast/api/v2/auth/tourist', {}).then(({ data }) => data);
}
export const refreshTouristLogin = () => {
    return ajaxPost<SuccessResponse<{
        userName: string;
        token: string;
    }>>('/repast/api/v2/auth/tourist/refreshToken', {}).then(({ data }) => data);
}
