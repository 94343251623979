import { list as chList } from './ch';
import { list as enList } from './en';
import { list as hkList } from './hk';
import { list as viList } from './vi';
import { list as jpList } from './jp';
import { list as koList } from './ko';

import { countryMapRegionNumber } from './country_map_region_number';
import { CountryCodeAndName } from './types';

interface ListOfLanguage {
    'zh-CN': CountryCodeAndName[],
    'en-US': CountryCodeAndName[],
    'zh-HK': CountryCodeAndName[],
    'ko-kr': CountryCodeAndName[],
    'ja-jp': CountryCodeAndName[],
    'vi-vn': CountryCodeAndName[],
}

const listOfLanguage: ListOfLanguage = {
    'zh-CN': chList,
    'en-US': enList,
    'zh-HK': hkList,
    'ko-kr': koList,
    'ja-jp': jpList,
    'vi-vn': viList,
}


export const getList = (lang: keyof ListOfLanguage): CountryCodeAndName[] => {
    return listOfLanguage[lang];
}

export const getCountryNameOfCode = (lang: keyof ListOfLanguage, code: CountryCodeAndName['code']): CountryCodeAndName['name'] => {
    return getList(lang).find(item => item.code === code)!.name;
}

export const getCountryNumberOfCode = (code: CountryCodeAndName['code']): string => {
    return countryMapRegionNumber[code]!;
}

export const getStorageCountryCode = () => window.localStorage.getItem('COUNTRY_CODE');
export const setStorageCountryCode = (code: string) => {
    window.localStorage.setItem('COUNTRY_CODE', code);
}