import React, { useMemo } from 'react';
import styles from './index.module.css';
import { useI18n } from 'context/i18n';
import { OrderAmount } from '../../models';

export const Summary: React.FC<OrderAmount & {
    className?: string
    totalCount: number
}> = ({
    className = '',
    totalAmount: subtotal,
    taxes,
    payAmount: total,
    courierAmount: postage,
    discountAmount,
    totalCount
}) => {
    const { t, getLang } = useI18n();


    
    const itemsInfoText = useMemo(() => {
        if (/zh/.test(getLang())) {
            return `商品 * ${totalCount}`;
        }
        const textSuffix = totalCount > 1 ? 'items' : 'item';
        return `${totalCount} ${textSuffix}`;
    }, [totalCount, getLang]);
    return (
        <div className={`${styles.summary} ${className}`}>
            <div className={styles.row}>
                <div className={styles.label}>{t('statements.summary.subtotal')}</div>
                <div className={styles.price}>${subtotal}</div>
            </div>

            <div className={styles.row}>
                <div className={styles.label}>{t('statements.summary.postage')}</div>
                <div className={styles.price}>${postage}</div>
            </div>

            <div className={styles.row}>
                <div className={styles.label}>{t('statements.summary.taxes')}</div>
                <div className={styles.price}>${taxes}</div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.label} ${styles.discount}`}>{t('statements.summary.discount')}</div>
                <div className={`${styles.price} ${styles.discount}`}>{Number(discountAmount)>0?"-":""} ${discountAmount}</div>
            </div>

            <div className={styles.total}>
                <span className={styles.count}>{itemsInfoText}</span>
                <span className={styles.totalLabel}>{t('statements.summary.total')}</span>
                <span className={styles.totalPrice}>${total}</span>
            </div>
            <div className="clear"></div>
        </div>
    )
}