import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { DELIVERY_TYPE } from '../../models';
import { ReceiverInfo, PickUpReceiverInfo } from 'modules/me';
import { selectors as authSelectors } from 'modules/auth/store';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { editShippingInfoAnalytics } from 'analytics/shipping-info-analytics';
type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;



export const setOrderInfoSuccess = action(
    getActionType('Set Order Info Success'),
    payload<{
        userId: number,
        deliveryType: DELIVERY_TYPE,
        receiverInfo?: ReceiverInfo,
        pickUpReceiverInfo?: PickUpReceiverInfo
    }>()
)


export function setOrderInfo(params: {
    deliveryType: DELIVERY_TYPE,
    receiverInfo?: ReceiverInfo,
    pickUpReceiverInfo?: PickUpReceiverInfo
}): ThunkResult<void> {
    return (dispatch, getState) => {
        const currentUser = authSelectors.getCurrentUser(getState())!;
        editShippingInfoAnalytics();
        dispatch(setOrderInfoSuccess({
            ...params,
            userId: currentUser.id
        }))
    }
}


export const setMealTimeSuccess = action(
    getActionType('Set Meal Time Success'),
    payload<{
        userId: number,
        time: number | '0',
    }>()
)

export function setMealTime(params: {
    time: number | '0'
}): ThunkResult<void> {
    return (dispatch, getState) => {
        const currentUser = authSelectors.getCurrentUser(getState())!;
        dispatch(setMealTimeSuccess({
            ...params,
            userId: currentUser.id
        }))
    }
}

export const setRemarkSuccess = action(
    getActionType('Set Remark'),
    payload<{
        userId: number,
        remark: string
    }>()
)

export function setRemark(remark: string): ThunkResult<void> {
    return (dispatch, getState) => {
        const currentUser = authSelectors.getCurrentUser(getState())!;
        dispatch(setRemarkSuccess({
            remark,
            userId: currentUser.id
        }))
    }
}
export const clearRemarkSuccess = action(
    getActionType('Set Remark'),
    payload<{
        userId: number,
    }>()
)

export function clearRemark(): ThunkResult<void> {
    return (dispatch, getState) => {
        const currentUser = authSelectors.getCurrentUser(getState())!;
        dispatch(clearRemarkSuccess({
            userId: currentUser.id
        }))
    }
}