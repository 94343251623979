import React, { useState, useCallback } from 'react';
import { NextButton } from '../components/NextButton';
import * as authService from '../services';

import { message } from 'components/Message';
import { PasswordInput } from '../components/PasswordInput';
import { useI18n } from 'context/i18n';
interface Props {
    onNext: (password: string) => void
}

export const SetPassword: React.FC<Props> = ({
    onNext
}) => {
    const { t } = useI18n();
    const [ password, setPassword ]= useState('');
    const [ confirmPassword, setConfirmPassword ]= useState('');


    const checkPassword = useCallback(() => {
        if (password !== confirmPassword) {

            return Promise.reject(t('auth.messages.checkPasswordEqual'));
        } else if (/[<>]/im.test(password)) {
            return Promise.reject('密码不能使用特殊字符!!!');
        } else {
            return authService.checkPasswordStrength(password).catch(res => {
                var msg = res.status === 400 ? res.data.errors.message : '当前网络状况不佳，请稍后再试';
                return Promise.reject(msg);
            })
        }
    }, [password, confirmPassword]);


    const handleSubmit = useCallback(() => {
        checkPassword().then(() => onNext(password)).catch(errorMsg => {
            message.error(errorMsg, 2);
        })
    }, [password, confirmPassword]);


    return (
        <>
            <PasswordInput
                value={password}
                onChange={setPassword}
                placeholder={t('auth.placeholder.setPassword')}
            />
             <PasswordInput
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder={t('auth.placeholder.setConfirmPassword')}
            />
            <NextButton
                onClick={handleSubmit}
                disabled={!password || !confirmPassword}
            >{t('auth.signUp')}</NextButton>

        </>
    )
}