import { getActionTypeFn } from 'store/utils';
export const FEATURE_NAME = 'Cart';
export const getActionType = getActionTypeFn(FEATURE_NAME);

export const initialDataOfStoreId = {
    data: {},
    entities: {},
}

export const initialDataOfUserId = {
    cartIdsOfProductCodeMap: {},
    productCodes: [],
    cartIdsOfActivityProductIdMap: {},
    activityProductIds: [],
    coupon: {
        code: '',
        enable: true,
    }
}