import { useCallback } from 'react';
import { ActivityProductInCart } from '../models';
import { useDispatch } from 'react-redux';
import { actions } from '../store';
import { useI18n } from 'context/i18n';
import { useActivityProductToCart } from 'modules/product-menu';



export const useActivityProductInCartAction = () => {
    const { lang } = useI18n();

    const { showProductDetail, editProductDetail } = useActivityProductToCart();

    const dispatch = useDispatch();


    const deleteActivityProduct = useCallback(({ activityProductId, id: cartId }: ActivityProductInCart) => {
        dispatch(actions.deleteActivityProductInCart({
            activityProductId,
            cartId,
        }));
    }, [dispatch]);

    const changeCount = useCallback(({ id: cartId, customizableTasteSetting }: ActivityProductInCart, count: number) => {
        dispatch(actions.updateActivityProductInCart({
            cartId,
            count,
            customizableTasteSetting
        }));
    }, [dispatch]);

    const onActivityProductClick = useCallback(({ activityProduct }: ActivityProductInCart) => {
        showProductDetail(activityProduct);
    }, [showProductDetail]);

    const onChangeClick = useCallback(({ activityProduct, activityProductId, id, count, customizableTasteSetting }: ActivityProductInCart) => {
        editProductDetail({
            activityProductId,
            activityCode: activityProduct.activityCode,
            id,
            count,
            customizableTasteSetting
        })
    }, [editProductDetail]);



    const changeRemark = (productInCart: ActivityProductInCart, remark: string) => {
        dispatch(actions.updateActivityProductInCart({
            cartId: productInCart.id,
            count: productInCart.count,
            customizableTasteSetting: productInCart.customizableTasteSetting,
            remark,
        }));
    }
    return {
        methods: {
            deleteActivityProduct,
            changeCount,
            onActivityProductClick,
            onChangeClick,
            changeRemark,
        }
    };
}