import React, { useCallback, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductDetailModal, ProductDetailProps } from '../components/ProductDetailModal';
import { useAccessAndOrderInfo } from 'context/accessAndOrderInfo';
import { actions as buyActions } from 'modules/buy/store';
import { message } from 'components/Message';
import { useDispatch } from 'react-redux';
import { Product, SUB_TASTE_RADIO } from '../models';
import { assoc } from 'ramda';
import { useI18n } from 'context/i18n';
import { viewProductItemAnalytics} from 'analytics/view-item-analytics';
type ProductDetail = ProductDetailProps['productDetail'];
interface ProviderProps {
    children: React.ReactNode;
}
interface ContextValue{
    addToCart: ProductDetailProps['onAddToCart'];
    addtoCartDirectly: (product: Product) => void;
    showProductDetail: (product: Product) => void;
    editProductDetail: (productDetail: ProductDetail) => void;
}

const contextValue: ContextValue = {
    addToCart: () => {},
    addtoCartDirectly: () => {},
    showProductDetail: () => {},
    editProductDetail: () => {},
}

export const productToCartContext = React.createContext(contextValue);

export const ProductToCartProvider: React.FC<ProviderProps> = ({
    children,
}) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [detailModalVisable, setDetailModalVisable] = useState<boolean>(false);
    const [detailProduct, setDetailProduct] = useState<ProductDetail>();

    const { pathname } = useLocation();
    const { hasOrderInfo, editOrderInfo } = useAccessAndOrderInfo();


     const handleAddToCart: ProductDetailProps['onAddToCart'] = useCallback(({ product, count, customizableTasteSetting }) => {
        if (!hasOrderInfo()) {
            setDetailModalVisable(false);
            editOrderInfo(pathname);
        } else {
            dispatch(buyActions.addProductToCart({
                productCode: product.code,
                count,
                customizableTasteSetting,
            }));
            message.success(t('form.messages.success', { action: t('form.actions.add') }));
            setDetailModalVisable(false);
        }
    }, [hasOrderInfo, pathname, dispatch, t]);


    const handleAddtoCartDirectly = useCallback((product: Product) => {
        const customizableTasteSetting = product.customizableTasteList.reduce((acc, cur) => {
            return assoc(cur.code, SUB_TASTE_RADIO.NORMAL, acc);
        }, {});
        handleAddToCart({ product, count: 1, customizableTasteSetting });
    }, [handleAddToCart]);

    const handleChangeProductInCart: ProductDetailProps['onChangeProductInCart'] = useCallback(({id, count, customizableTasteSetting}) => {
        dispatch(buyActions.updateProductInCart({
            cartId: id,
            count,
            customizableTasteSetting,
        }))
        message.success(t('form.messages.success', { action: t('form.actions.change') }));
        setDetailModalVisable(false);
    }, [dispatch, t]);

    const showProductDetail = useCallback((product: Product) => {
        viewProductItemAnalytics(product.code);
        setDetailProduct({ code: product.code });
        setDetailModalVisable(true);
    }, []);

    const editProductDetail = useCallback((productDetail: ProductDetail) => {
        viewProductItemAnalytics(productDetail.code);
        setDetailProduct(productDetail);
        setDetailModalVisable(true);
    }, []);

    return (
        <productToCartContext.Provider value={{
            addToCart: handleAddToCart,
            addtoCartDirectly: handleAddtoCartDirectly,
            showProductDetail,
            editProductDetail,
        }}>
            {children}
            <ProductDetailModal
                productDetail={detailProduct!}
                visable={detailModalVisable}
                onAddToCart={handleAddToCart}
                onChangeProductInCart={handleChangeProductInCart}
                onCancel={() => setDetailModalVisable(false)}
            />
        </productToCartContext.Provider>
    )
}
export const useProductToCart = () => {
    return useContext(productToCartContext);
}