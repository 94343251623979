import { ajaxGet, SuccessResponse, ListResponse } from 'api';
import {
    Category,
    ActivityReference,
} from '../models';
import { store } from 'store';
import { selectors } from 'modules/store/store';
// export const getCategoryList = (storeId: string): Promise<any> => {
//     return new Promise(resolve => resolve());
// }
import defaultCategoryImage_1 from 'assets/default_category_1.png';
import defaultCategoryImage_2 from 'assets/default_category_2.png';
import defaultCategoryImage_3 from 'assets/default_category_3.png';
import defaultCategoryImage_4 from 'assets/default_category_4.png';
import { Language } from 'context/i18n';

let cacheMap: {
    [key: string]: string
} = {};
const getDefaultImage = (item: { code: string }) => {
    if (!cacheMap[item.code]) {
        const index = Math.floor(Math.random() * 100) % 4;
        cacheMap[item.code] = `/repast/images/${selectors.getCurrentBrand(store.getState())!.abbreviation}/category_default_image_${index + 1}.png`;
    }

    return cacheMap[item.code];
}


// 获取分类列表
export const getCategoryList = async (storeId: string, language: Language): Promise<SuccessResponse<Category[]>> => {
    const params = { storeId, pageNum: 1, pageSize: 999 };
    const url = '/repast/api/v2/product/category'
    
    
    return ajaxGet<SuccessResponse<ListResponse<Category, any>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const categoryList = data.data.value.map(category => {
            const fileUrl = category.fileUrl
                ? category.fileUrl
                : getDefaultImage(category);
            return {
                ...category,
                fileUrl,
            };
        })
        return { ...data, data: categoryList };
    });
}



export const getShowInCategoryActivityList = (
    storeId: string,
    language: Language
) => {
    const params = { storeId, showPosition: 'show_in_category', pageNum: 1, pageSize: 999 };
    const url = `/repast/api/v2/activity`;
    
    return ajaxGet<SuccessResponse<ListResponse<ActivityReference, any>>>(
        url, params, { 'Accept-Language': language }
    ).then(({ data }) => {
        const list: ActivityReference[] = data.data.value.map(item => ({
            ...item,
            fileUrl: getDefaultImage(item)
        }))
        return { ...data, data: list };
    });
}


