
export const countryMapRegionNumber: {
    [key: string]: string
} = {
    "CN": "86",
    "HK": "852",
    "TH": "66",
    "MY": "60",
    "VN": "84",
    "ID": "62",
    "IN": "91",
    "TW": "886",
    "US": "1",
    "PH": "63",
    "KH": "855",
    "LA": "856",
    "MM": "95",
    "SG": "65",
    "IR": "98",
    "MO": "853",
    "AU": "61",
    "GB": "44",
    "NZ": "64",
    "IT": "39",
    "JP": "81",
    "AT": "43",
    "ES": "34",
    "CA": "1",
    "RU": "7",
    "EG": "20",
    "ZA": "27",
    "GR": "30",
    "NL": "31",
    "BE": "32",
    "FR": "33",
    "HU": "36",
    "RO": "40",
    "CH": "41",
    "DK": "45",
    "SE": "46",
    "NO": "47",
    "PL": "48",
    "DE": "49",
    "PE": "51",
    "MX": "52",
    "CU": "53",
    "AR": "54",
    "BR": "55",
    "CL": "56",
    "CO": "57",
    "VE": "58",
    "KR": "82",
    "TR": "90",
    "PK": "92",
    "AF": "93",
    "LK": "94",
    "MA": "212",
    "DZ": "213",
    "TN": "216",
    "LY": "218",
    "GM": "220",
    "SN": "221",
    "ML": "223",
    "GN": "224",
    "BF": "226",
    "NE": "227",
    "TG": "228",
    "BJ": "229",
    "MU": "230",
    "LR": "231",
    "SL": "232",
    "GH": "233",
    "UZ": "998",
    "NG": "234",
    "TD": "235",
    "CF": "236",
    "CM": "237",
    "ST": "239",
    "GA": "241",
    "CG": "242",
    "AO": "244",
    "SC": "248",
    "SD": "249",
    "ET": "251",
    "SO": "252",
    "DJ": "253",
    "KE": "254",
    "TZ": "255",
    "UG": "256",
    "BI": "257",
    "MZ": "258",
    "ZM": "260",
    "MG": "261",
    "ZW": "263",
    "NA": "264",
    "MW": "265",
    "LS": "266",
    "BW": "267",
    "SZ": "268",
    "KZ": "7",
    "KG": "996",
    "GI": "350",
    "PT": "351",
    "LU": "352",
    "IE": "353",
    "IS": "354",
    "AL": "355",
    "MT": "356",
    "CY": "357",
    "FI": "358",
    "BG": "359",
    "LT": "370",
    "LV": "371",
    "EE": "372",
    "MD": "373",
    "AM": "374",
    "BY": "375",
    "AD": "376",
    "MC": "377",
    "SM": "378",
    "UA": "380",
    "SI": "386",
    "CZ": "420",
    "SK": "421",
    "LI": "423",
    "BZ": "501",
    "GT": "502",
    "SV": "503",
    "HN": "504",
    "NI": "505",
    "CR": "506",
    "PA": "507",
    "HT": "509",
    "BO": "591",
    "GY": "592",
    "EC": "593",
    "GF": "594",
    "PY": "595",
    "SR": "597",
    "UY": "598",
    "TL": "670",
    "BN": "673",
    "NR": "674",
    "PG": "675",
    "TO": "676",
    "SB": "677",
    "FJ": "679",
    "CK": "682",
    "PF": "689",
    "KP": "850",
    "BD": "880",
    "MV": "960",
    "LB": "961",
    "JO": "962",
    "SY": "963",
    "IQ": "964",
    "KW": "965",
    "SA": "966",
    "YE": "967",
    "OM": "968",
    "AE": "971",
    "IL": "972",
    "BH": "973",
    "QA": "974",
    "MN": "976",
    "NP": "977",
    "TJ": "992",
    "TM": "993",
    "AZ": "994",
    "GE": "995",
    "BS": "1242",
    "JM": "1876"
}