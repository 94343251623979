import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'components/Modal';
import { GoogleMap, Props } from '../Map';
import { useI18n } from 'context/i18n';


export const GoogleMapModal: React.FC<Props & {
    visable: boolean,
    onCancel: () => void
}> = ({visable, onCancel, ...props}) => {
    const { t } = useI18n();

    return (
        <Modal
            title={t('receiverInfo.selectAddress')}
            visible={visable}
            width={'1024px'}
            style={{top: '300px'}}
            mask={true}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={[]}
        >
            <GoogleMap
                {...props}
            />
        </Modal>
    )
}


