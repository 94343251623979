import React, { useCallback, useMemo, useState } from 'react';
import styles from './index.module.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';

import { useI18n } from 'context/i18n';
import { selectors } from 'modules/store/store';
import { Order, ORDER_STATUS } from '../../models';
import { useOrderStatus } from '../../facades/useOrderStatus';
import { ASAP_TIME, DELIVERY_TYPE } from 'modules/order-info';
import { DeliveryTimeSelector, getAsapTimestamp, getTimestampRangeOfTimestamp } from '../DeliveryTimeSelector';
import { Store } from 'modules/store/models';

import { DatePicker } from 'components/DatePicker';
import { Select } from 'components/Select';

export { getTimestampRangeOfTimestamp, getAsapTimestamp };
const { Option } = Select;

interface Props {
    order: Partial<Order>,
    onTimeEdit?: (time: number | '0' ) => void;
    store?: Store
}
enum MEAL_TIME_TYPE {
    ASAP = 0,
    LATER = 1,
}
const MEAL_TIME_DELAY_MINUTE = {
    WEEK_DAY: { less: 30, more: 30 },
    WEEK_END: { less: 30, more: 40 }
}

const isWeekday = (timestamp: string | number) => [1, 2, 3, 4].includes(dayjs(Number(timestamp)).day());
const isLessProductCount = (productCount: number) => productCount < 4;
const formatMealTimeText = (time: dayjs.Dayjs): string => {
    return time.format('MM-DD-YYYY hh:mma');
};

export const OrderBasicInfo: React.FC<Props> = ({
    order,
    onTimeEdit,
    store,
}) => {
    console.log("order =>", order);
    const { t } = useI18n();
    const statusText = useOrderStatus(order);
    const storeList = useSelector(selectors.getStoreList);
    const storeId = useSelector(selectors.getCurrentStoreId);
    const currentStore = storeList.find((store)=>{
        return store.storeID === storeId;
    });
    const hourData = currentStore!.hourData;
    const [mealTimeType, setMealTimeType] = useState<MEAL_TIME_TYPE | null>(() => {
        if (!order.mealTime) return null;
        return order.mealTime! === ASAP_TIME
            ? MEAL_TIME_TYPE.ASAP
            : MEAL_TIME_TYPE.LATER;
    });

    const mealTimeText = useMemo(() => {
        if (!order || !order.productList || !order.status || !order.createTime) return false;
        if (([ ORDER_STATUS.COMPLETED, ORDER_STATUS.INVALID ] as string[]).includes(order.status)) return false;

        if (order.mealTime === ASAP_TIME) {
            const productCount = order.productList.reduce((acc, curr) => acc + curr.buyNum, 0);
            const prefix = order.eatType === DELIVERY_TYPE.RAPID_PICK_UP ? t('order.mealTime.rapidPickUp') : t('order.mealTime.deliveryStart');
            const timestamp = order.createTime;
            const delayOfDay = isWeekday(timestamp) ? MEAL_TIME_DELAY_MINUTE.WEEK_DAY : MEAL_TIME_DELAY_MINUTE.WEEK_END;
            const delay = isLessProductCount(productCount) ? delayOfDay.less : delayOfDay.more;
            const formattedTime = formatMealTimeText(dayjs(order.createTime).add(delay, 'minute'))
            return {
                prefix,
                time: formattedTime,
            }
        } else {
            const prefix = order.eatType === DELIVERY_TYPE.RAPID_PICK_UP  ? t('order.mealTime.rapidPickUp') : t('order.mealTime.deliveryEnd');
            const formattedTime = formatMealTimeText(dayjs(Number(order.mealTime)));
            return {
                prefix,
                time: formattedTime,
            }
        }
    }, [order]);


    const handleChangeMealTimeType = useCallback((target: MEAL_TIME_TYPE) => {
        setMealTimeType(target);
        if (target === MEAL_TIME_TYPE.ASAP) {
            onTimeEdit!(ASAP_TIME);
        } else {
            const timeAfterTenMinute = moment().add(10, 'minute');
            const nearlyTenMinute = Math.ceil(timeAfterTenMinute.minute() / 10) * 10;


            const timestamp = nearlyTenMinute === 60
                ? timeAfterTenMinute.add(1, 'h').minute(0)
                : timeAfterTenMinute.minute(nearlyTenMinute);
            onTimeEdit!(timestamp.valueOf());
        }
    }, []);


    const asapText = useMemo(() => {
        return order?.eatType === DELIVERY_TYPE.DELIVERY
            ? t('orderTime.asap.delivery')
            : t('orderTime.asap.pickUp');
    }, [order]);


    const disabledDate = useCallback((current: any) => {
        const day = moment(current).day();
        const openingHours = hourData[day];
        const start =openingHours &&  openingHours[0] && openingHours[0].start;
        const end = openingHours &&  openingHours[openingHours.length - 1] && openingHours[openingHours.length - 1].end;

        const result = (current && current < moment().startOf('day')) || openingHours.length === 0 || (start && end && start.hour === 0 && start.minute=== 0 && end.hour === 0 && end.minute === 0);
        return result;
    }, []);
    const disabledDateTime = useCallback((date:any) => {
        //const date = moment();
        const isToday = date < moment().endOf('day');
        const isCurrentHour = date < moment().endOf('hour');
        const Day = moment(date).day();
        const Hour = moment(date).hour();
        const currentMinute = moment().minute();
        const currentDay = moment().day();
        const currentHour = moment().hour();


        let startHour = hourData[Day][0] ? hourData[Day][0].start.hour:0
        const endHour =  hourData[Day][hourData[Day].length - 1] ?  hourData[Day][hourData[Day].length - 1].end.hour:0;
        let startMinute = hourData[Day][0]? hourData[Day][0].start.minute : 0 ;
        let endMinute = hourData[Day][hourData[Day].length-1] ?hourData[Day][hourData[Day].length-1].end.minute : 0 ;
        let  disabledHours:any[] = [];
        let disabledMinutes:any[] = [];
        if(isToday){
            if( currentHour > endHour){
                disabledHours = new Array(24).fill(0).map((_,i)=>i);
            }else if(currentHour >= startHour){
                disabledHours = new Array(currentHour).fill(0).map((_,i)=>i).concat(new Array(24 - endHour ).fill(endHour + 1).map((_,i)=> _ + i));
           }else{
                disabledHours = new Array(startHour).fill(0).map((_,i)=>i).concat(new Array(24 - endHour ).fill(endHour + 1).map((_,i)=> _ + i));
           }

           if(isCurrentHour){
               if(hourData[Day].length == 0){
                   disabledMinutes = new Array(60).fill(0).map((_,i)=> i + 1);
               }else if(Hour === startHour){
                    disabledMinutes = currentMinute >= startMinute ?new Array(currentMinute).fill(0).map((_,i)=> i):new Array(startMinute).fill(0).map((_,i)=> i);new Array(startMinute).fill(0).map((_,i)=> i);
               }else if(Hour === endHour){
                    disabledMinutes = currentMinute <= endMinute ?new Array(currentMinute).fill(0).map((_,i)=> i).concat(new Array( 60 - endMinute).fill(endMinute).map((_,i)=>_ + i + 1)):new Array(60).fill(0).map((_,i)=>i + 1)
               }else{
                     disabledMinutes = new Array(currentMinute).fill(0).map((_,i)=>i );
               }
           }else{
                if( Hour === endHour){
                    disabledMinutes = new Array(60 - endMinute).fill(endMinute).map((_,i)=> _ + i + 1);
                }else if( Hour === startHour){
                    disabledMinutes = new Array(startMinute).fill(0).map((_,i)=> i +1 );
                }else{
                    disabledMinutes=[];
                }

           }
        }else{

            disabledHours = new Array(startHour).fill(0).map((_,i)=>i).concat(new Array(24 - endHour ).fill(endHour + 1).map((_,i)=> _ + i));
            if( Hour === endHour){
                disabledMinutes = new Array(60 - endMinute).fill(endMinute).map((_,i)=> _ + i +1 );
            }else if(Hour === startHour){
                disabledMinutes = new Array(startMinute).fill(0).map((_,i)=> i );
            }else{
                disabledMinutes=[];
            }

        }

        if(hourData[Day].length == 0){
            disabledHours = new Array(24).fill(0).map((_,i)=>i);
            disabledMinutes = new Array(60).fill(0).map((_,i)=>i);
        }

        return {
         disabledHours: () => disabledHours,
         disabledMinutes:() => disabledMinutes
        }
    }, []);


    const datePickerValue = useMemo(() => {
        return moment(Number(order.mealTime!));
    }, [order.mealTime]);

    return (
        <div className={styles.orderBasicInfo}>
            <div className={styles.left}>
                {order?.eatType === DELIVERY_TYPE.LOCKER_PICK_UP && order?.takeCode ? (
                    <div className={styles.row}>
                        <span className={styles.label}>{t('order.takeCode')}:</span>
                        <span className={styles.content}>{order.takeCode}</span>
                    </div>
                ) : null}
                { statusText &&
                    <div className={styles.row}>
                        <span className={styles.label}>{t('order.orderStatus')}:</span>
                        <span className={styles.content}>{statusText}</span>
                    </div>
                }
                <div className={styles.row}>
                    <span className={styles.label}>{t('receiverInfo.eatType')}:</span>
                    <span className={styles.content}>{t(`orderType.${order.eatType}`)}</span>
                </div>
                {onTimeEdit &&
                    <div className={styles.row}>
                        <span className={styles.label}>{t('receiverInfo.eatTime')}:</span>

                            <div className={styles.selectWrapper}>
                                <Select style={{ width: '100px' }} value={mealTimeType!} placeholder="请选择时间" onChange={handleChangeMealTimeType}>
                                    <Option value={MEAL_TIME_TYPE.ASAP}>{asapText}</Option>
                                    <Option value={MEAL_TIME_TYPE.LATER}>{t('orderTime.asap.laterly')}</Option>
                                </Select>
                                {mealTimeType === MEAL_TIME_TYPE.LATER && order?.eatType !== DELIVERY_TYPE.DELIVERY &&
                                    <DatePicker
                                        value={datePickerValue}
                                        disabledDate={disabledDate}
                                        disabledTime={disabledDateTime}
                                        format="YYYY-MM-DD HH:mm"
                                        showTime={{
                                            minuteStep: 10,
                                            showSecond: false,
                                        }}
                                        allowClear={false}
                                        // showToday={false}
                                        onOk={date => onTimeEdit(date.valueOf())}
                                    />
                                }
                                {mealTimeType === MEAL_TIME_TYPE.LATER && order?.eatType === DELIVERY_TYPE.DELIVERY &&
                                    <DeliveryTimeSelector store={store!} mealTime={order.mealTime} onTimeEdit={onTimeEdit!}/>
                                }
                            </div>


                    </div>
                }
                {mealTimeText &&
                    <div className={styles.row}>
                        <span className={styles.label}>{mealTimeText.prefix}:</span>
                        <span className={styles.content}>{mealTimeText.time}</span>
                    </div>
                }

                {order.remark &&
                    <div className={styles.row}>
                        <span className={styles.label}>{t('statements.remark')}:</span>
                        <span className={styles.content}>{order.remark}</span>
                    </div>
                }
            </div>


            <div className={styles.right}>
                {order.coordinateDesc &&
                    <div className={styles.row}>
                        <span className={styles.label}>{t('receiverInfo.coordinateDesc')}:</span>
                        <span className={styles.content}>{order.coordinateDesc} {order.receiverAddress}</span>
                    </div>
                }
                {order.receiverName &&
                    <div className={styles.row}>
                        <span className={styles.label}>{t('receiverInfo.contactName')}:</span>
                        <span className={styles.content}>{order.receiverName}</span>
                    </div>
                }

                <div className={styles.row}>
                    <span className={styles.label}>{t('receiverInfo.contactPhone')}:</span>
                    <span className={styles.content}>{order.receiverMobile}</span>
                </div>
            </div>
        </div>
    )
}