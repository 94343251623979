import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { User } from '../../models';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import * as authService from '../../services';
import { encryptPassword } from '../encrypt';
import { ajaxPost } from 'api';

type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;

export const getCurrentUserSuccess = action(
    getActionType('Get Current User Success'),
    payload<User>()
)


export function getCurrentUser(): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        return authService.getCurrentUser().then(({ data }) => {
            dispatch(getCurrentUserSuccess(data));
        })
    }
}


export const loginSuccess = action(
    getActionType('Login Success'),
    payload<{ accessToken: string }>()
)

export const loginError = action(
    getActionType('Login Error'),
)

interface LoginParams {
    login_name: string;
    password: string;
}


export function login(params: LoginParams): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {

        const { password, nonce } = encryptPassword(params.password);

        return authService.login({
            login_name: params.login_name,
            password,
            nonce
        }).then(({data}) => {
            const accessToken = data.access_token;
            dispatch(loginSuccess({ accessToken }));

            return dispatch(getCurrentUser())
                .then(() => {
                    window.localStorage.setItem('access_token', accessToken);
                })
                .catch(err => {
                    dispatch(loginError());
                    return Promise.reject(err);
                })
        })
    }
}


export const touristLoginSuccess = action(
    getActionType('Tourist Login Success'),
    payload<{ jwt: string }>()
)

export function touristLogin(): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        return authService.touristLogin().then(({ data }) => {
            const { token, userName } = data;
            window.localStorage.setItem('jwt', token);
            dispatch(touristLoginSuccess({ jwt: token }));
            const currentUser: User = { id: -1, name: userName };
            dispatch(getCurrentUserSuccess(currentUser))
        });
    }
}


export const googleLoginApi = (idToken:string) => {
    const uri = '/repast/api/v2/auth/google';
    return ajaxPost<any>(uri,{platform:'web',idToken:idToken})
}

export const GoogleLoginSuccess = action(
    getActionType('Tourist Login Success'),
    payload<{ jwt: string }>()
)

export function googleLogin(idToken:string): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        return googleLoginApi(idToken).then(({ data }) => {
            const { token } = data.data;
            window.localStorage.setItem('jwt', token);
            dispatch(touristLoginSuccess({ jwt: token }));
            const currentUser: User = { id: -1, name: '' };
            dispatch(getCurrentUserSuccess(currentUser))
        });
    }
}


export function refreshJwt(): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        return authService.refreshTouristLogin().then(({ data }) => {
            const { token, userName } = data;
            window.localStorage.setItem('jwt', token);
            dispatch(touristLoginSuccess({ jwt: token }));
            const currentUser: User = { id: -1, name: userName };
            dispatch(getCurrentUserSuccess(currentUser))
        });
    }
}


export function logout(): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('jwt');
        dispatch(logoutSuccess());
        return;
    }
}
export const logoutSuccess = action(
    getActionType('Login Out'),
)