import { action, payload } from 'ts-action';
import { getActionType } from '../constants';
import { Product } from '../../models';
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { Language } from 'context/i18n';
import * as productApi from '../../api/product';
import * as selectors from '../selectors';

type ThunkResult<R> = ThunkAction<R, any, void, AnyAction>;
export const fetchingProductList = action(
    getActionType('Fetching Product List'),
    payload<{
        storeId: string,
        language: Language,
        categoryCode: string,
    }>()
)

export const getProductListSuccess = action(
    getActionType('Get Product List Success'),
    payload<{
        storeId: string,
        language: Language,
        categoryCode: string,
        productList: Product[]
    }>()
)


export function getProductList(storeId: string, language: Language, categoryCode: string): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {

        const fetching = selectors.getProductFetchingOfCategoryCodeAndLanguage(getState(), { categoryCode, language });
        if (fetching) return;

        dispatch(fetchingProductList({ storeId, categoryCode, language }));

        
        return productApi.getProductsOfCategoryCode(storeId, language, categoryCode).then(({ data }) => {
            console.log("data =>", data);
            dispatch(getProductListSuccess({
                storeId,
                language,
                categoryCode,
                productList: data
            }));
        })
    }
}


