import React, { useMemo } from 'react';
import { Product } from '../../models';
import { ProductImage } from '../ProductImage';
import { DiscountTag } from '../DiscountTag';

interface Props {
    product: Product
    className?: string;
    onClick?: () => void;
    tagCount?: number;
}
export const ProductImageWithTag: React.FC<Props> = ({
    className = '',
    product,
    onClick = () => {},
    tagCount = 2
}) => {

    const activities = useMemo(() => {
        if (product.currentDiscount) {
            return product.activities.filter(activity => !activity.discounts.map(item => item.id).includes(product.currentDiscount!.id));
        } else {
            return product.activities;
        }
    }, [product]);

    const discountTags = useMemo(() => {
        const activityNodes = activities.map(activity => <DiscountTag activity={activity}/>);
        const tags = product.currentDiscount ? [<DiscountTag discountVO={product.currentDiscount}/>, ...activityNodes] : activityNodes;
        return tags.slice(0, tagCount);
    }, [product, activities]);

    return (
        <ProductImage
            className={className}
            source={product.images[0].url}
            onClick={onClick}
            tags={discountTags}
        />
    )
}