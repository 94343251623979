import React from 'react';

const style: React.CSSProperties = {
    width: '100%',
    height: '60px',
    borderBottom: '1px solid #FEDFE3',
    padding: '10px 0',
}

interface Props {
    children: React.ReactNode
}
export const RowWrapper: React.FC<Props> = ({
    children
}) => {

    return <div style={style}>
        {children}
    </div>
}