
import React ,{ useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from "./index.module.css";
import {CardItem} from '../CardItem';
import { Card } from 'modules/buy/models';
import { usePayCardList } from './hooks/usePayCardlist';
import { Spin } from 'components/Spin';
import { SubPageLayout } from 'layout/SubPageLayout';
import { Collapse} from 'components/Collapse';
import { useI18n } from 'context/i18n';
import { ActionButton} from 'components/ActionButton';

const { Panel } = Collapse;

export const PayCardList:React.FC = () =>{
    const [ checkedIndex ,setCheckedIndex] = useState<number>(0);

    const { push,goBack } = useHistory();
    const { t  } = useI18n();

    const { loading,cardList } = usePayCardList();
    
    const handleItemClick = (index:number) => {
        setCheckedIndex(index);

    }

    return ( <Spin spinning = { loading}>
        <div className={styles.cardListWrapper} >
                <header style={{textAlign:'right'}}> <ActionButton className={styles.button} onClick={()=>{ goBack()}}>{t('form.actions.back')}</ActionButton></header>
            <Collapse style={{border:'none',background:'none'}} >
            {cardList.map((card,index)=>{
                    return( <Panel key={index} style={{border:'none',background:'transparent'}}  showArrow={false} header={<CardItem  handleItemClick={handleItemClick} card={card} index={index}  showCheck={false}/>}>
                             <div className={styles.cardHolderInfo} >
                                        <input value={card.billing_details.name}  className={styles.cardHolderInfoInput} type="text" placeholder={t('order.pay.cardHolder.name')}/>
                                        <input value={card.billing_details.phone}  className={styles.cardHolderInfoInput} type="text" placeholder={t('order.pay.cardHolder.phone')}/>
                                        <input value={card.billing_details.email}  className={styles.cardHolderInfoInput} type="email" placeholder={t('order.pay.cardHolder.email')}/>
                                        <input value={card.billing_details.address.postal_code}  className={styles.cardHolderInfoInput} type="text" placeholder={t('order.pay.cardHolder.zipcode')}/>
                                    </div>

                    </Panel>)
                })}
            </Collapse>
                
        </div>
    </Spin>)
}
