// 注册


import React, { useState, useCallback } from 'react';

import { SmsAuth } from './SmsAuth';
import { SetPassword } from './SetPassword';
import { register as registerApi, RegisterProps } from './services';
import { useMountedState } from 'react-use';
import { message } from 'components/Message';
import { useI18n } from 'context/i18n';
interface Props {
    onSuccess: () => void;
}

interface SignUpInfo {
    mobile: string;
    password: string;
    auth_code: string;
}


enum SIGN_UP_STATUS {
    SMS_AUTH = 'sms auth',
    SET_PASSWORD = 'set password',
}

export const SignUp: React.FC<Props> = ({
    onSuccess
}) => {
    const { t } = useI18n();
    const mounted = useMountedState();
    const [ registerLoading, setRegisterLoading ] = useState<boolean>(false);

    const [ signInStatus, setSignInStatus ] = useState<SIGN_UP_STATUS>(SIGN_UP_STATUS.SMS_AUTH);
    const [ signInInfo, setSignInInfo ] = useState<SignUpInfo>({
        mobile: '',
        password: '',
        auth_code: ''
    });


    const onSmsAuthSuccess = useCallback((mobile: string, auth_code: string) => {
        setSignInInfo(state => ({...state, mobile, auth_code}));
        setSignInStatus(SIGN_UP_STATUS.SET_PASSWORD);
    }, [setSignInStatus]);

    const onSetPasswordSuccess = useCallback((password: string) => {
        setSignInInfo(state => ({...state, password}));
        register({
            mobile: signInInfo.mobile,
            auth_code: signInInfo.auth_code,
            password,
            confirm_password: password,
        });
    }, [setSignInInfo, signInInfo]);
    

    const register = useCallback((params: RegisterProps) => {
        setRegisterLoading(true);
        registerApi(params).then(() => {
            if (mounted()) {
                setRegisterLoading(false);
                setTimeout(() => {
                    message.info(t("form.messages.success", { action: t('auth.signUp') }), 1.5);
                    onSuccess();
                });
            }
        }).catch(res => {
            console.log("error =>", res);
            setRegisterLoading(false);
            if (res.status === 400 || res.status === 403) {
                message.error(res.data.errors.message, 2);
            } else {
                message.error(t('当前网络状态不好，请稍后尝试'), 2);
            }
        })
    }, [mounted, setRegisterLoading]);

    return <div>
        { signInStatus === SIGN_UP_STATUS.SMS_AUTH &&    
            <SmsAuth onNext={onSmsAuthSuccess} />
        }
        {signInStatus === SIGN_UP_STATUS.SET_PASSWORD &&
            <SetPassword onNext={onSetPasswordSuccess}/>
        }
    </div>
}
