import { ajaxGet, ajaxPost, ajaxDelete, ajaxPut, SuccessResponse, ListResponse } from 'api';

import { ReceiverInfo } from '../models';


export const getReceiverInfoOfId = (id: string) => {
    let url = `/repast/api/v2/receiver/${id}`;
    return ajaxGet<SuccessResponse<ReceiverInfo>>(url).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
       return data.data.data
    });
}

export const getReceiverInfoList = (storeId?: string): Promise<ReceiverInfo[]> => {
    let url = '/repast/api/v2/receiver?pageNum=1&pageSize=99';
    if (storeId) url = `${url}&storeId=${storeId}`;
    return ajaxGet<SuccessResponse<ListResponse<ReceiverInfo, any>>>(url).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
       return data.data.data.value
    });
}

export const createReceiverInfo = (receiverInfo: Omit<ReceiverInfo, 'id'>) : Promise<SuccessResponse<ReceiverInfo>> => {
    const url = '/repast/api/v2/receiver';
    return ajaxPost<SuccessResponse<ReceiverInfo>>(url, receiverInfo).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
        return data.data;
    });
}

export const updateReceiverInfo = (receiverInfo: ReceiverInfo): Promise<SuccessResponse<ReceiverInfo>> => {
   const url = `/repast/api/v2/receiver/${receiverInfo.id}`;
   return ajaxPut<SuccessResponse<ReceiverInfo>>(url, receiverInfo).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
        return data.data;
    });
}
export const deleteReceiverInfoOfId = (id: ReceiverInfo['id']): Promise<SuccessResponse<any>> => {
   const url = `/repast/api/v2/receiver/${id}`;
   return ajaxDelete<SuccessResponse<any>>(url).then(data => {
        if (data.data.code !== 200) return Promise.reject(data);
        return data.data;
    });
}

