import { Lang } from './Lang';
export const hk: Lang = {

    auth: {
        signIn: '登錄',
        signUp: '註冊',
        touristSignIn: '遊客登錄',
        logOut: '登出', 
        gotoSignIn: '去登錄',
        gotoSignUp: '去註冊',
        country: '國家',
        smsCodeSending: '正在發送',
        messages: {
            checkPasswordEqual: '兩次輸入的密碼不相同, 請重新設置',
            mobileIsExists: '該手機號已被註冊'
        },
        placeholder: {
            mobile: '請填寫手機號',
            password: '請填寫密碼',
            setPassword: '請設置密碼',
            setConfirmPassword: '請再次填寫密碼',
            grahicInput: '不區分大小寫',
            getSmsCode: '獲取驗證碼',
            smsCodeInput: '短訊驗證碼',

        }
    },

    form: {

        actions: {
            delete: '刪除',
            add: '添加',
            new: '新增',
            detail: '詳情',
            edit: '編輯',
            save: '保存',
            submit: '提交',
            cancel: '取消',
            change: '修改',
            clear: '清空',
            upload: '上傳',
            update: '更新',
            addLocalization: '新增語言',
            search: '搜索',
            confirm: '確認',
            back:'返回',
            confirmAndNext:"確認,下一步"
        },
        

        rules: {
            maxLength: '最大長度為${{length}}',
            ltSize: '圖片尺寸不能超過${{size}}',
            noLocalization: '至少需要一種語言的信息，請您添加',
            makeSure: '表單錯誤，請檢查'
        },
        messages: {
            success: '${{action}}成功',
            fail: '${{action}}失敗',
        },
        alert: {
            isSure: '您確認么？'
        }
    },
    home: {
        title: '眷湘',
        orderNow: '立刻點餐',
        newOrder: '新訂單',
        recorder: '以往訂單',
        changeLanguage: '語言',
        menu:'菜單',
        homePage:'首頁',
        messages: {
            switchStoreWillClearCart: '切換店鋪將會清空購物車'
        }

    },
    orderType: {
        0: '自取外賣',
        1: '送餐',
        2: '無接觸取餐',
    },
    productType: {
        0: '普通餐',
        1: '五日餐',
        fiveDayDescription: '五日餐（需提前一天預訂，下午5點截單。第二天可取或送餐。5點之後點餐後天可取或可送餐）',
        alert: {
            crossToFiveDayMeal: '您已經選擇了不是來自「五日餐」的菜品，如果切換到五日餐，將會清空購物車。',
            crossFromFiveDayMeal: '您已經選擇了來自「五日餐」的菜品，如果切換到非「五日餐」，將會清空購物車。',
        }
    },
    orderTime: {
        asap: {
            delivery: '立刻送餐',
            pickUp: '立刻取餐',
            laterly:"稍後"
        },
        later: '選擇時間',
        businessHour: '營業時間',
        error: {
            selectTime: '請選擇就餐時間',
            nowOutOfHour: '已經超出營業時間，只接受預定',
            selectedTimeOutOfHour: '選擇的時間已超出營業時間範圍',
            selectedTimeExpire: '選擇的時間已過期',
            nowNearClose: '即將超出營業時間，請儘快點餐或進行預定'
        }
    },
    ourMenu: {
        title: '今日推薦',
        category: '菜品分類'
    },
    menuInCategory: {
        searchPlaceholder: '搜索菜品',
        addToOrder: '加入訂單',
    },

    foodDetail: {
        ingredients: '主要原料',
        customize: '定製口味',
        addToOrder: '加入訂單',
        update: '更新',
        next: '下一步',
        addToCart:'加入購物車',
    },
    taste: {
        child: {
            radio: {
                0: "不要",
                1: "非常少",
                2: "少",
                3: "正常",
                4: "多",
                5: "超級多"
            }
        }
    },
    cart: {
        header: {
            title: '購物車',
            keepOrdering: '繼續點餐'
        },
        next: '結算',
        detail:'購物車詳情'
    },

    statements: {
        header: {
            title: '您的訂單'
        },
        error: {
            enterMobile: '請輸入預留手機號'
        },
        next: '提交訂單',
        mobile: '手機號',
        placeholder: {
            mobile: "請輸入預留手機號",
            remark: "請輸入備註"
        },
        remark: '備註',
        addRemark: '添加備註',
        messages: {
            submitTo: '當前訂單會被提交到${{name}}'
        },
        summary: {
            title: '概要',
            subtotal: '小計',
            discount: '優惠',
            taxes: '稅',
            total: '總計',
            addPromoCode: '添加優惠碼',
            postage: '運費'
        },
    },
    order: {
        shortStatus: {
            all: '全部',
            5: '待確認',
            10: '待支付',
            11: '已支付',
            12: '支付中',
            20: '準備中',
            21: '準備完成',
            22: '已移動至前台',
            30: '已完成',
            98: '已失效',
        },
        status: {
            5: '待確認',
            10: '待支付',
            11: '已支付',
            12: '支付中',
            20: '準備中',
            21: '準備完成',
            22: '已移動至前台',
            30: '已完成',
            98: '已失效',
        },
        actions: {
            cancel: '取消訂單',
            delete: '刪除訂單',
            pay: '付款',
            confirm: '確認收貨',
            offlinePay: '轉為線下支付',
            onlinePay: '去支付',
        },
        payMethod: {
            1: '線上支付',
            2: '線下支付'
        },
        total: '合計',
        listEmpty: '您還沒有相關訂單',
        info: '訂單信息',
        id: '訂單編號',
        storeName: '店鋪名稱',
        createTime: '創建時間',
        // 支付時間
        payTime: '支付時間',
        // 準備完成時間
        prepareCompleteTime: '準備完成時間',
        // 完成時間
        completeTime: '完成時間',
        // 失效時間
        invalidTime: '失效時間',

        payClosedTime: '關閉時間',
        totalPrice:"总价",
        orderStatus:"訂單狀態",
        takeCode: '取餐碼',
        amount:'數量',
        name:"订单",
        noYet:"暫無",
        mealTime: {
            rapidPickUp: '預計取餐時間',
            deliveryStart: '預計配送時間',
            deliveryEnd: '預計送達時間'
        },
        error: {
            can_not_deliver: {
                lt_price: '我們外送的起送價是$${{value}}(折後)'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: '您的訂單中有秒殺活動的菜品: ${{nameText}}, 此訂單需要選擇今日[自取][送餐]，請更改日期後重試。'
                }
            }
        },
        pay: {
            title: '支付',
            credit:{
                name:'信用卡',
                total:'共',
                item:'张',
                more:'顯示更多',
            },
            error: {
                download_app_to_pay: '請下載App進行在線支付',
                download_app_to_pay_or_offline: '請下載App進行在線支付，或點擊「轉為線下支付」按鈕 ',
                store_doesnt_support_online_payment: "當前店鋪不支持線上支付",
                doesnt_support_offline_payment: '當前就餐方式不支持線下支付',
                enter_right_cardholder_detail: '請正確填寫持卡人信息',
                coupon_not_found: '優惠碼不存在',
                coupon_cannot_use: '優惠碼無法使用',
                coupon_num_is_zero: '優惠碼可用數量不足',
                coupon_not_use_condition: '優惠碼未達到使用條件',
                order_payment_in_progress:'訂單支付中',
                order_does_not_exist:'订单不存在',
                order_has_been_paid:'订单已支付',
            },
            cardHolder: {
                title: '持卡人信息',
                name: '持卡人姓名',
                phone: '電話',
                email: '郵箱',
                zipcode: '郵編',
            },
            cardDetails: {
                title: '銀行卡信息',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: '保存',
            add: '新增'
        },
        shippingInfo: '聯繫方式',
        enterShippingInfo: '請填寫聯繫方式',
        name: '姓名',
        phone: '電話',
        coordinateDesc: '地址',
        address: '詳細地址',
        fillInAddress:"请填写地址",
        selectAddress:"請選擇收穫地址",
        makeFormRight: '請確認聯繫方式填寫正確',
        empty: '暫無常用聯繫方式，請添加',
        pleaseSelect: '暫無聯繫方式，請選擇',
        pleaseEnter: '請輸入',
        shippingMethod:"配送方式",
        selectShippingMethod:"請選擇配送方式",
        selectShippingMethod1:"歡迎您,請選擇訂餐方式",
        store:"門店",
        selectStore:"請選擇門店",
        contactName:"聯繫人姓名",
        contactPhone:"聯繫人電話",
        contactInfo:"請填寫聯繫人方式",
        eatType:"就餐方式",
        eatTime:"就餐時間"
    },
    history:{
        historyOrders:'歷史訂單'
    },
    me:{
        name:"个人"
    },
    address:{
        addressManagement:"地址管理",
        add:"添加地址",
        management:"管理",
        setAsDefault:"设为默認",
        default: '默認',
        addressDelivery:"送餐地址",
        fullAddress:"詳細地址",
        noAddress:"很抱歉，沒有地址",
        selectDeliveryAddress:"請選擇送餐地址",
        messages: {
            cannotChangeWhenUsing: '該地址正在使用，無法修改'
        }
    },
    dayInWeek: {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
    },
    searchProduct:{
        search:"開始搜索",
        noResult:"無搜索結果,換個詞試試吧~"
    },
    activity: {
        name: '優惠活動',
        time: {
            start: '開始時間',
            end: '結束時間',
            full: '全活動有效',
        },
        placeholder: {
            chooseTimeToView: '選擇送餐或取餐時間，查看對應優惠價'
        },
        description: '活動說明',
        activityStock: '活動庫存',
        singlePurchaseLimit: '單人最大購買限制'
    },
    activityProduct: {
        label: {
            event_starts_later: '活動還未開始',
            event_is_over: '活動已結束',
            purchase_limit_reached: '已超出個人購買限制',
            not_enough_items_to_sell: '剩餘活動商品庫存不足',
        },
        error: {
            purchase_limit_reached: '已超出最大購買限制',
            not_enough_items: '剩餘庫存不足',
            unable_to_add: '無法繼續添加',
            event_is_over_please_check: '部分秒殺活動已結束，請檢查購物車列表 ',
            purchase_limit_reached_please_check: '${{name}}已超出最大購買限制，請檢查購物車列表',
            not_enough_items_please_check: '${{name}}的數量已超出現有活動庫存，請檢查購物車列表',
        }
    },
    coupon: {
        promoCode: {
            name: '優惠碼',
            error: {
                coupon_not_found: '優惠碼不存在',
                coupon_cannot_use: '優惠碼無法使用',
                coupon_num_is_zero: '優惠碼可用數量不足',
                coupon_not_use_condition: '優惠碼未達到使用條件'
            },
            placeholder: {
                chooseTimeToView: '選擇送餐或取餐時間，查看對應優惠價'
            }
        }
    }
};