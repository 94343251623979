import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { calculateAmount, CalculateAmountParams } from '../api';
import { useMountedState } from 'react-use';
import { OrderAmount, ProductInCartVO, ProductInCart, ActivityProductInCart, IS_COUPON_OPTIMAL } from '../models';
import { useOrderInfo, DELIVERY_TYPE } from 'modules/order-info';
import { useThrottleLoading } from 'hooks/useThrottleLoading';
import { DiscountReference, ActivityReference } from 'modules/product-menu';
import { actions } from 'modules/product-menu/store';
import { useI18n } from 'context/i18n';
import { ACTIVITY_PRODUCT_STATUS } from 'modules/product-menu/models';
import { message } from 'components/Message';
import { useDispatch } from 'react-redux';

interface ProductItemWithDiscount {
    id: string;
    code: string;
    salePrice: string;
    discounts: Array<DiscountReference & { activity: ActivityReference }>
}

interface Props {
    productListInCart: ProductInCart[];
    activityProductListInCart: ActivityProductInCart[];
    subTotalPrice: number;
    promoCodeData: {code: string, enable: boolean};
    setPromoCode: (code: string) => void
}

export const useCalculateAmount = ({
    productListInCart,
    activityProductListInCart,
    subTotalPrice,
    promoCodeData,
    setPromoCode
}: Props) => {
    const { t, lang } = useI18n();
    const mounted = useMountedState();
    const requestTag = useRef<string>();
    const productListInCartRef = useRef<ProductInCart[]>(productListInCart);
    const activityProductListInCartRef = useRef<ActivityProductInCart[]>(activityProductListInCart);
    const dispatch = useDispatch();
    useEffect(() => {
        productListInCartRef.current = productListInCart;
        activityProductListInCartRef.current = activityProductListInCart;

    }, [productListInCart, activityProductListInCart]);

    const { currentStoreId, deliveryType, receiver, mealTime } = useOrderInfo();
    const [ loading, setLoading ] = useThrottleLoading(2000);

    const [ orderAmount, setOrderAmount ] = useState<OrderAmount>();
    const [ productListWithDiscount, setProductListWithDiscount ] = useState<ProductItemWithDiscount[]>([]);

    const calculate = useCallback(() => {
        if (subTotalPrice > 0 && mealTime) {
            setLoading(true);
            const tag = `${subTotalPrice}_${Date.now()}`;
            requestTag.current = tag;
    
            const productList = productListInCartRef.current.map(item => {
                const { customizableTasteSetting, count: buyNum, productCode, id } = item;
                const tasteList = Object.keys(customizableTasteSetting).map(tasteCode => ({
                    code: tasteCode, selected: customizableTasteSetting[tasteCode]
                }));
                return { id, buyNum, code: productCode, taste: tasteList }
            });
            const activityProductList = activityProductListInCartRef.current.map(item => {
                const { customizableTasteSetting, count: buyNum, activityProductId } = item;
                const tasteList = Object.keys(customizableTasteSetting).map(tasteCode => ({
                    code: tasteCode, selected: customizableTasteSetting[tasteCode]
                }));
                return { buyNum, activityProductId, taste: tasteList  };
            })
    
            const params: CalculateAmountParams = {
                storeId: currentStoreId!,
                productList,
                activityProductList,
                eatType: deliveryType,
                mealTime,
                promoCode: promoCodeData.enable ? promoCodeData.code : '',
            };
            if (deliveryType === DELIVERY_TYPE.DELIVERY && receiver) params.receiverId = receiver.id;
    
            calculateAmount(lang, params)
                .then(value => {
                    dispatch(actions.updateActivityProductEntities({
                        storeId: currentStoreId!,
                        activityProducts: value.activityProductVOList,
                    }));
                    if (mounted() && tag === requestTag.current) {
                        setLoading(false);
                        setOrderAmount(value);
                        setProductListWithDiscount(value.productList);

                        if (promoCodeData.enable && promoCodeData.code) {
                            const currentPromoCodeItem = value.couponList.find(item => item.promoCode === promoCodeData.code);
                            console.log("currentPromoCodeItem =>", currentPromoCodeItem);
                            if (!currentPromoCodeItem) {
                                setTimeout(() => message.error(t('coupon.promoCode.error.coupon_not_found')), 100);
                                setPromoCode('');
                            } else if (currentPromoCodeItem.isOptimal === IS_COUPON_OPTIMAL.NO) {
                                setTimeout(() => message.error(t(`coupon.promoCode.${currentPromoCodeItem.message}`)), 100);
                                setPromoCode('');
                            }
                        }

                        const completeStatusActivityProduct = value.activityProductVOList.find(item => item.activityStatus === ACTIVITY_PRODUCT_STATUS.COMPLETE);
                        if (completeStatusActivityProduct) {
                            message.error(t('activityProduct.error.event_is_over_please_check'));
                            return;
                        }
                        const errorBuyNumActivityProduct = value.activityProductVOList.find(item => {
                            const activityProductsInCart = activityProductListInCartRef.current.filter(activityProductInCart => activityProductInCart.activityProductId === item.id);
                            const totalBuyNum = activityProductsInCart.reduce((acc, curr) => acc + curr.count, 0);
                            if (totalBuyNum > (item.maxBuyNum - item.buyNum)) {
                                // 如果当前购物车内的总数量，大于限制
                                return true;
                            }
                        })
                        if (errorBuyNumActivityProduct) {
                            message.error(t('activityProduct.error.purchase_limit_reached_please_check', { name: errorBuyNumActivityProduct.product.name }));
                            return;
                        }
        
                        const errorInventoryActivityProduct = value.activityProductVOList.find(item => {
                            const activityProductsInCart = activityProductListInCartRef.current.filter(activityProductInCart => activityProductInCart.activityProductId === item.id);
                            const totalBuyNum = activityProductsInCart.reduce((acc, curr) => acc + curr.count, 0);
                            if (totalBuyNum > item.inventory) {
                                // 如果当前购物车内的总数量，大于限制
                                return true;
                            }
                        })
                        if (errorInventoryActivityProduct) {
                            message.error(t('activityProduct.error.not_enough_items_please_check', { name: errorInventoryActivityProduct.product.name }));
                            return;
                        }


                    }
                })
                .catch(err => {
                    if (mounted() && tag === requestTag.current) {
                        setLoading(false);
                    }
                })
        }
    }, [subTotalPrice, mealTime, t, lang, promoCodeData]);

    const productListInCartVO: ProductInCartVO[] = useMemo(() => {
        return productListInCart.map((productInCart, idx) => {
            console.log("productListInCart =>", productListInCart);
            const curr = productListWithDiscount.find(item => item.id === productInCart.id);
            const { discounts, salePrice } = curr || { discounts: [], salePrice: '' };
            return {...productInCart, discounts, salePrice}
        })
    }, [JSON.stringify(productListInCart), productListWithDiscount]);


    return {
        loading,
        orderAmount,
        productListInCartVO,
        methods: { calculate }
    };

}


