import React from 'react';
import styles from './index.module.css';
import { useTheme } from 'modules/store';
interface Props {
    className?: string;
    onClick: () => void;
    children: React.ReactNode
}

export const ActionButton: React.FC<Props> = ({
    className,
    onClick,
    children
}) => {
    const { theme: { secondary } } = useTheme();
    return (
        <div
            className={`${styles.actionButton} ${className}`}
            style={{borderColor: secondary.bg}}
            onClick={onClick}
        >{children}</div>
    )
}