import React from 'react';
import styles from './index.module.css';
import { ActivityItem } from '../ActivityItem';
import { useI18n } from 'context/i18n';
import { Product } from '../../models';

interface Props {
    className?: string;
    product: Product;
}

export const ActivityList: React.FC<Props> = ({
    className = '',
    product: { activities, currentDiscount },
}) => {
    const { t } = useI18n();

    if (activities.length === 0) return null;

    return (
        <div className={`${styles.activityList} ${className}`}>
            <div className={styles.title}>{t('activity.name')}</div>
            {activities.map(activity => (
                <ActivityItem className={styles.activityItem} currentDiscount={currentDiscount} key={activity.code} activity={activity} />
            ))}
        </div>
    )
}
