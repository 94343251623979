import React, { useMemo } from 'react';
import styles from './index.module.css';
import { Button } from 'components/Button'

import qrcode from 'assets/qrcode.png';
import { useI18n } from 'context/i18n';
import { useTheme } from 'modules/store';

export interface Props {
    online: boolean,
    offline: boolean,  // 至少有一个是true
    offlinePayment: () => void
    onCancel: () => void
}

export const Payment: React.FC<Props> = ({
    online,
    offline,
    offlinePayment,
    onCancel
}) => {
    const { t } = useI18n();
    const { downloadAppQrcode } = useTheme();

    const content = useMemo(() => {
        if (online) {
            const title = offline ? t('order.pay.error.download_app_to_pay_or_offline') : t('order.pay.error.download_app_to_pay');
            return (
                <>
                    <div className={styles.title}>{title}</div>
                    <img className={styles.qrcode} src={downloadAppQrcode} alt=""/>
                </>
            )
        } else {
            return <div className={styles.title}>{t('order.pay.error.store_doesnt_support_online_payment')}</div>;
        }
    }, [online, offline, downloadAppQrcode]);

    const footer = useMemo(() => {
        if (offline) {
            return (
                <div className={styles.footer}>
                    <Button onClick={offlinePayment}>{t('order.actions.offlinePay')}</Button>
                    <Button onClick={onCancel}>{t('form.actions.cancel')}</Button>
                </div>
            )
        } else {
            return (
                <div className={styles.footer}>
                    <Button onClick={onCancel}>{t('form.actions.cancel')}</Button>
                </div>
            )
        }
    }, [online, offline]);


    return (
        <div className={styles.pay}>
            <div className={styles.container}>
                {content}
            </div>
            {footer}
            <div className="clear"></div>
        </div>
    )
}