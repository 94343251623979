import React, { createRef, useMemo } from 'react';
import styles from './index.module.css';
import { Product, CustomizableTasteSetting } from '../../models';
import { CustomizableSetting, RefProps } from '../CustomizableSetting';
import { Counter, useCounter } from 'components/Counter';
import { useProductInCart } from 'modules/buy';
import { CommonButton } from 'components/CommonButton';
import { useI18n } from 'context/i18n';
import { ProductImageWithTag } from '../ProductImageWithTag';
import { ActivityList } from '../ActivityList';
import { useProductDetail } from '../../facades/useProductDetail';
import { useTheme } from 'modules/store';

export interface Props {
    productDetail: {
        // product: Product,
        code: string,
        id?: string,
        count?: number,
        customizableTasteSetting?: CustomizableTasteSetting
    }
    onAddToCart: (result: {
        product: Product,
        count: number,
        customizableTasteSetting: CustomizableTasteSetting
    }) => void,
    onChangeProductInCart: (result: {
        id: string,
        count: number,
        customizableTasteSetting: CustomizableTasteSetting
    }) => void,
}

export const ProductDetail: React.FC<Props> = ({
    productDetail,
    onAddToCart,
    onChangeProductInCart
}) => {
    const { theme: { primary } } = useTheme();
    const { count: productInCartCount } = useProductInCart(productDetail.code);
    const { count, onInc, onDec } = useCounter(productDetail.count || 1);
    const formRef = createRef<RefProps>();
    const { detail: product } = useProductDetail(productDetail.code);

    const { t } = useI18n();
    const isEdit = useMemo(() => {
        return !!productDetail.id;
    }, [productDetail]);

    const handleSubmitButton = () => {
        const params = {
            product,
            count,
            customizableTasteSetting: formRef.current!.getCustomizableTasteSetting()
        };
        isEdit
            ? onChangeProductInCart({...params, id: productDetail.id!})
            : onAddToCart(params);
    }

    const salePrice = useMemo(() => {
        if (!product) return '';
        return product.currentDiscount ? product.currentDiscount.salePrice : product.price;
    }, [product]);
    const originPrice = useMemo(() => {
        if (!product) return '';
        return product.currentDiscount ? product.price : '';
    }, [product]);

    return (
        <div className={styles.productDetail}>
            <div className={styles.left}>
                <ProductImageWithTag
                    className={styles.image}
                    product={product}
                    tagCount={0}
                />
                {/* <div className={styles.blank}></div> */}
                <ActivityList className={styles.blank} product={product} />
                <div className={styles.price}>
                    <div aria-label="Price" className={styles.salePrice}>${salePrice}</div>
                    {originPrice && <div className={styles.unitPrice}>${originPrice}</div>}
                    <div className="clear"></div>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.name}>{product.name}</div>
                <div className={styles.description}>{product.description}</div>
                
                {product.rawMaterials && <div className={styles.description}>{product.rawMaterials}</div>}
                
                <CustomizableSetting
                    taste={product.customizableTasteList}
                    tasteSelected={productDetail.customizableTasteSetting}
                    ref={formRef}
                />
                <div className={styles.blank}></div>

                <div className={styles.buttons}>
                    <Counter className={styles.counter} count={count} onInc={onInc} onDec={onDec} />
                    <CommonButton
                        className={styles.addToCartButton}
                        style={{ background: primary.bg, color: primary.color }}
                        onClick={handleSubmitButton}
                        count={productInCartCount}
                    >{isEdit ? t('form.actions.confirm'): t('foodDetail.addToCart')}</CommonButton>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}