import {
    on,
    reducer as createReducer,
} from 'ts-action';
import {
    setCurrentStore,
    // getStoreListSuccess,


    startInit,
    initSuccess,
    initFail,

} from './actions';
import { Store, Brand } from '../models';
import { FEATURE_NAME } from './constants';


export interface State {
    loading: boolean;
    currentBrand?: Brand;
    currentStoreId?: Store['storeID'];
    storeList: Store[];
    isError: boolean;
}

export interface GlobalState {
    [FEATURE_NAME]: State;
};

export const initialState: State = {
    loading: true,
    storeList: [],
    isError: false,
};

export const reducer = createReducer(
    initialState,
    on(
        setCurrentStore,
        (state, { payload: currentStoreId }) => ({
            ...state,
            currentStoreId
        })
    ),
    // on(
    //     getStoreListSuccess,
    //     (state, { payload: storeList }) => ({ ...state, storeList })
    // ),
    on(
        startInit,
        _ => ({...initialState, loading: true, isError: false }),
    ),
    on(
        initFail,
        _ => ({ ...initialState, loading: false, isError: true }),
    ),
    on(
        initSuccess,
        (_, { payload: { storeList, brand } }) => ({
            storeList,
            currentBrand: brand,
            currentStoreId: storeList[0].storeID,
            loading: false,
            isError: false,
        })
    )
);
