import { Lang } from './Lang';
export const jp: Lang = {

    auth: {
        signIn: 'ログイン',
        signUp: '登録',
        touristSignIn: 'ゲストログイン',
        logOut: 'ログアウト', 
        gotoSignIn: 'ログインへ',
        gotoSignUp: '登録ヘ',
        country: '國',
        smsCodeSending: '発信しています',
        messages: {
            checkPasswordEqual: 'パスワードの入力が違います。入力し直してください。',
            mobileIsExists: 'お使いの携帯番号はすでに使用されています'
        },
        placeholder: {
            mobile: '携帯番号を入力してください',
            password: 'パスワードを入力してください',
            setPassword: 'パスワードを入力してください',
            setConfirmPassword: 'パスワード確認',
            grahicInput: '大文字と小文字を区別しません',
            getSmsCode: '認証コードをゲット',
            smsCodeInput: '認証コード',

        }
    },

    form: {

        actions: {
            delete: '削除',
            add: '追加',
            new: '作成',
            detail: '詳細',
            edit: '編集',
            save: '保存',
            submit: 'サブミット',
            cancel: 'キャンセル',
            change: '修正',
            clear: 'クリア',
            upload: 'アップロード',
            update: '更新',
            addLocalization: '言語作成',
            search: '検索',
            confirm: '確認',
            back:"前へ",
            confirmAndNext:"次へ"
        },
        

        rules: {
            maxLength: '文字列の長さは${{length}}が上限です',
            ltSize: '画像データのサイズは${{size}}が上限です',
            noLocalization: '言語を設定してください',
            makeSure: '入力に不備がありました。チェックしてください'
        },
        messages: {
            success: '${{action}}完了',
            fail: '${{action}}に失敗しました',
        },
        alert: {
            isSure: '以上でよろしいですか？'
        }
    },
    home: {
        title: '眷湘（ジュアンシャン）',
        orderNow: '今すぐ注文',
        newOrder: '新しく注文',
        recorder: '注文履歴',
        changeLanguage: '言語',
        menu:'メニュー表',
        homePage:'ホームページ',
        messages: {
            switchStoreWillClearCart: '店を切り替えたら、未注文のものは消えますがよろしいですか？'
        }

    },
    orderType: {
        0: '自分でテイクアウト',
        1: '指定の位置に配送',
        2: 'Contactless Pick-Up',
    },
    productType: {
        0: '普通食',
        1: '５日食',
        fiveDayDescription: '「５日食」前日５時まで予約して、その翌日でテイクアウトまたは配送できます。５時以後は予約しても、その翌々日まではテイクアウトまたは配送できません。',
        alert: {
            crossToFiveDayMeal: '普通食からに５日食切り替えたら、未注文リストはクリアされますがよろしいですか？',
            crossFromFiveDayMeal: '５日食から普通食に切り替えたら、未注文リストはクリアされますがよろしいですか？',
        }
    },

    orderTime: {
        asap: {
            delivery: '今から配送',
            pickUp: '今からテイクアウト',
            laterly:"後ほど"
        },
        later: '時間を指定',
        businessHour: '営業時間',
        error: {
            selectTime: '食事時間を選択してください',
            nowOutOfHour: '営業時間外では予約しか受け付けません。',
            selectedTimeOutOfHour: 'ご指定の時間が営業時間外にいます',
            selectedTimeExpire: 'ご指定の時間はもう過ぎています',
            nowNearClose: 'もうすぐ営業時間外ですので、注文を早めにください'
        }
    },
    ourMenu: {
        title: '今日のおすすめ',
        category: 'カテゴリー'
    },
    menuInCategory: {
        searchPlaceholder: 'メニューで検索',
        addToOrder: '注文リストに追加',
    },

    foodDetail: {
        ingredients: '主な原材料',
        customize: 'カスタマイズ',
        addToOrder: '注文リストに追加',
        update: '更新',
        next: '次へ',
        addToCart:'注文リストに追加'
    },
    taste: {
        child: {
            radio: {
                0: "不要",
                1: "超少なめ",
                2: "少なめ",
                3: "正常",
                4: "多め",
                5: "超多め"
            }
        }
    },
    cart: {
        header: {
            title: '注文リスト',
            keepOrdering: '更に追加'
        },
        next: '会計へ',
        detail:'注文リストの詳細'
    },
    statements: {
        header: {
            title: '注文'
        },
        error: {
            enterMobile: '携帯番号を入力してください'
        },
        next: '注文へ',
        mobile: '携帯番号',
        placeholder: {
            mobile: "携帯番号を入力してください",
            remark: "備考",
        },
        messages: {
            submitTo: '${{name}}へ注文'
        },
        addRemark: '備考を書く',
        remark: '備考',
        summary: {
            title: 'タイトル',
            subtotal: '小計',
            discount: '割引',
            taxes: '税',
            total: '合計',
            addPromoCode: '割引クーポンを使用',
            postage: '配送料'
        }
    },
    order: {
        shortStatus: {
            all: 'All',
            5: 'Placed',
            10: 'Unpaid',
            11: 'Paid',
            12: 'Paying',
            20: 'Preparing',
            21: 'Ready',
            22: 'Moved to Front Desk',
            30: 'Completed',
            98: 'Invalid',
        },
        status: {
            5: '注文済み',
            10: '支払い待つ',
            11: '支払い済み',
            12: '継続的な支払い',
            20: '準備中',
            21: '準備完了',
            22: 'Moved to Front Desk',
            30: '完成した',
            98: 'キャンセル',
        },
        actions: {
            cancel: '注文をキャンセル',
            delete: '注文を削除',
            pay: '会計へ',
            confirm: '配達確認',
            offlinePay: '現金で支払う',
            onlinePay: 'オンラインで支払う',
        },
        payMethod: {
            1: 'オンラインで支払う',
            2: '現金で支払う'
        },
        total: '合計',
        listEmpty: '注文履歴はありません',
        info: '注文詳細',
        id: '注文番号',
        storeName: '店ネーム',

        createTime: '注文時間',
        // 支付时间
        payTime: '支払う時間',
        // 准备完成时间
        prepareCompleteTime: '準備完了時間',
        // 完成时间
        completeTime: '完成時間',
        // 失效时间
        invalidTime: '失効時間',

        payClosedTime: 'キャンセル時間',
        totalPrice:'総価額',
        orderStatus:"注文ステータス",
        takeCode: 'Take Code',
        amount:"数量",
        name:"注文",
        noYet:"なし",
        mealTime: {
            rapidPickUp: '予定到着時刻',
            deliveryStart: '予定発送時刻',
            deliveryEnd: '予定届く時刻'
        },
        error: {
            can_not_deliver: {
                lt_price: '配送サービスは＄${{value}}（合計）からご利用できます'
            },
            can_not_create_order: {
                mealtime: {
                    flash_sale_time_out: '秒殺キャンペーンの商品(${{nameText}})は当日でテイクアウトか、配送かしか注文できません。'
                }
            }
        },
        pay: {
            title: '支払う',
            credit:{
                name:'クレジットカード',
                total:'合計',
                item:' ',
                more:'もっと見せる',
            },
            error: {
                download_app_to_pay: 'アプリでオンライン支払う',
                download_app_to_pay_or_offline: 'アプリでオンライン支払うか、「オンラインで支払う」で注文ください',
                store_doesnt_support_online_payment: '当店はオンライン支払うをサポートしていません。',
                doesnt_support_offline_payment: 'The current eat method does not support offline payment.',
                enter_right_cardholder_detail: 'カードの名義人を確認ください',
                coupon_not_found: 'このコードは見つかりません',
                coupon_cannot_use: 'このコードは使用できません',
                coupon_num_is_zero: '使用できるコードが足りません',
                coupon_not_use_condition: 'コードの使用条件が満たされていません',
                order_payment_in_progress:'주문 결제',
                order_does_not_exist:'Order does not exist',
                order_has_been_paid:'Order has been paid',
            },
            cardHolder: {
                title: 'カードの名義人',
                name: '名前',
                phone: '電話番号',
                email: 'メールアドレス',
                zipcode: '郵便番号',
            },
            cardDetails: {
                title: 'カードの詳細',
            }
        }
    },
    receiverInfo: {
        formAction: {
            save: '保存',
            add: '追加'
        },
        shippingInfo: '連絡先',
        enterShippingInfo: '連絡先を入力してください',
        name: '名前',
        phone: '電話番号',
        address: 'アドレス',
        fillInAddress:'アドレスを入力してください',
        selectAddress:"お届け先を選択してください",
        coordinateDesc: 'アドレス',
        makeFormRight: '連絡方法をご確認の上、入力し直してください',
        empty: 'よく使われる連絡先はありません。追加してください。',
        pleaseSelect: '連絡先はありません。選択してください',
        pleaseEnter: '入力してください',
        shippingMethod:"配送方法",
        selectShippingMethod:"配送方法を選択してください",
        selectShippingMethod1:"いらっしゃいませ。注文方法を選んでください",
        store:'店',
        selectStore:'店を選んでください',
        contactName:"お名前",
        contactPhone:"電話番号",
        contactInfo:"連絡先を入力してください",
        eatType:"配送とテイクアウト",
        eatTime:"予定時間"
    },
    history:{
        historyOrders:"注文履歴"
    },
    me:{
        name:"自分"
    },
    address:{
        addressManagement:"アドレス管理",
        add:"アドレスを追加",
        management:"管理",
        setAsDefault:"デフォルトにする",
        default: "デフォルト",
        addressDelivery:"お届け先",
        fullAddress:"詳細アドレス",
        noAddress:"申し訳ありません。そのアドレスは見つかりませんでした",
        selectDeliveryAddress:"お届け先を選んでください",
        messages: {
            cannotChangeWhenUsing: 'このアドレスはすでに使用されていますので、今は変更できません'
        }
    },

    dayInWeek: {
        0: '日曜日',
        1: '月曜日',
        2: '火曜日',
        3: '水曜日',
        4: '木曜日',
        5: '金曜日',
        6: '土曜日',
    },
    searchProduct:{
        search:"検索",
        noResult:"該当ありません"
    },
    activity: {
        name: 'お得キャンペーン',
        time: {
            start: '開始時間',
            end: '終わり時間',
            full: 'すべての項目に有効',
        },
        placeholder: {
            chooseTimeToView: '時間を選択し、お得キャンペーンの割引価格を確認'
        },
        description: 'キャンペーン説明',
        activityStock: 'キャンペーン参加数量',
        singlePurchaseLimit: '購買数制限（一人に付き）'
    },

    activityProduct: {
        label: {
            event_starts_later: 'お得キャンペーンはまだ始まってません',
            event_is_over: 'お得キャンペーンはもう終了しました',
            purchase_limit_reached: '注文数制限に達しました',
            not_enough_items_to_sell: 'ご注文いただいた一部のキャンペーン商品は在庫不足のため、ご用意できませんでした',
        },
        error: {
            purchase_limit_reached: '注文数制限に達しました',
            not_enough_items: '在庫切れました',
            unable_to_add: '注文できません',
            event_is_over_please_check: 'ご注文いただいた一部のキャンペーンが終了したため、注文リストを再チェックしてください。',
            purchase_limit_reached_please_check: '${{name}}は注文数制限に達しました。　注文リストを再チェックしてください。',
            not_enough_items_please_check: '${{name}}は在庫不足のため、ご用意できませんでした。注文リストを再チェックしてください。',
        }
    },
    coupon: {
        promoCode: {
            name: 'プロモコード',
            error: {
                coupon_not_found: 'このコードは見つかりません',
                coupon_cannot_use: 'このコードは使用できません',
                coupon_num_is_zero: '使用できるコードが足りません',
                coupon_not_use_condition: 'コードの使用条件が満たされていません'
            },
            placeholder: {
                chooseTimeToView: '時間を選択し、お得キャンペーンの割引価格を確認'
            },
        }
    }
};
