import React from 'react';
import { Modal } from 'components/Modal';
import { ActivityProductDetail, Props } from '../ActivityProductDetail';

export type ActivityProductDetailProps = Props;

export const ActivityProductDetailModal: React.FC<{
    visable: boolean,
    onCancel: () => void
} & Props> = ({visable, onCancel, ...props}) => {
    return (
        <Modal
            visible={visable}
            width={900}
            style={{top: '300px'}}
            mask={true}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={false}
        >
            <ActivityProductDetail {...props}/>
        </Modal>
    )
}


