import React, { useMemo } from 'react';

import styles from './index.module.css';
import { ProductInOrder } from '../../models';
import { Counter } from 'components/Counter';
import { Language, useI18n } from 'context/i18n';
import { SUB_TASTE_RADIO, useDiscountName } from 'modules/product-menu';
import { getPrice } from 'globalUtils';

// 获取自定义口味的文案
function getCustomText(
    productInOrder: ProductInOrder,
    t: (s: string) => string,
    lang: Language
): string {
    const { taste } = productInOrder;

    // 中文的拼中文逗号
    const joinText = /ch/.test(lang) ? '，' : ',';

    return taste.reduce<string[]>((acc, item) => {
        if (item.selected === SUB_TASTE_RADIO.NORMAL) return acc;

        const middleText = /ch/.test(lang)  ? '' : ' ';

        return [...acc, `${t(`taste.child.radio.${item.selected}`)}${middleText}${item.name}`];

    }, []).join(joinText);
}

interface Props {
    productInOrder: ProductInOrder;
}

export const ProductInOrderItem: React.FC<Props> = ({
    productInOrder,
}) => {
    const { t, getLang } = useI18n();
    
    const { product, buyNum: count } = productInOrder;

    const { methods: { getName: getDiscountTitle } } = useDiscountName();

    const priceTextNode = useMemo(() => {
        const originPrice = getPrice(productInOrder.product.price, productInOrder.buyNum);
        const hasDiscount = productInOrder.discounts.length > 0;
        
        if (hasDiscount) {
            return (
                <>
                    <div aria-label="Price" className={styles.salePrice}>${productInOrder.salePrice}</div>
                    <div className={styles.unitPrice}>{originPrice}</div>
                    <div className="clear"></div>
                </>
            )
        } else {
            return <div aria-label="Price" className={styles.salePrice}>{originPrice}</div>  
        }
    }, [productInOrder]);

    return (
        <div className={styles.productInOrderItem}>
            <div className={styles.productInOrderInfo}>
                <div className={styles.left}>
                    <img className={styles.img} src={product.images[0].url} alt=""/>
                </div>
                <div className={styles.middle}>
                    <div className={styles.name}>{product.name}</div>
                    <div className={styles.price}>{priceTextNode}</div>
                    <div className={styles.custom}>{getCustomText(productInOrder, t, getLang())}</div>
                </div>
                <div className={styles.right}>
                    <div className={styles.count}>{t('order.amount')}：{count}</div>
                    
                    {/* <div className={styles.blank}></div>
                    <div className={`${styles.subTotalPrice}`}>{t('statements.summary.subtotal')}:${(product.price * count).toFixed(2)}</div> */}
                </div>
            </div>
            <div className={styles.discountList}>
                {productInOrder.discounts.map(discount => (
                    <div className={styles.discountItem} key={discount.id}>{t('activity.name')}: {discount.activity.name} {discount.value}% OFF ({getDiscountTitle(discount)})</div>
                ))}
            </div>
            { productInOrder.remark &&
                <div className={styles.remarkWrapper}>
                    <div className={styles.remarkLabel}>{`${t('statements.remark')}:`}</div>
                    <div className={styles.remark}>{productInOrder.remark}</div>
                    <div className="clear"></div>
                </div>
            }
        </div>
    )
}